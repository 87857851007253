// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { SearchOutlined } from "@material-ui/icons";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle, roseColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment-jalaali";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { connect } from "react-redux/";
import { BeatLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import { ApiUrl } from "../../config";
import UserAction from "../../js/actions/user_action";
import MessageDetailPopUp from "components/MessageDetailPopUp/MessageDetailPopUp";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem",
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999",
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
  searchContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black ",
    minWidth: "200px",
    maxWidth: "600px",
    borderRadius: "5px",
    padding: "5px 10px",
    "& h5": {
      color: "white",
    },
    "& input": {
      width: "100%",
      padding: "10px",
      outline: "none",
      borderRadius: "5px",
      border: "none",
    },
    "& button": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      background: "#9c27b0",
      border: "1px solid #9c27b0",
      borderRadius: "5px",
      width: "100%",
      maxWidth: "150px",
      padding: "5px",
      color: "white",
      cursor: "pointer",
      transition: ".3s",
      "&:disabled": {
        opacity: 0.5,
        cursor: "not-allowed",
        transition: ".3s",
      },
    },
  },
  table: {
    width: "100%",
    display: "table",
    textAlign: "center",
    "& > td,th": {
      padding: "20px",
    },
  },
};

const useStyles = makeStyles(styles);
const token = localStorage.getItem("token");

function SearchTrackingNumber(props) {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [isEmpty, setIsEmpty] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [messages, setMessage] = useState([]);
  const [showMsg, setShowMsg] = useState(false);

  const searchHandler = async (e) => {
    try {
      setLoading(true);
      const res = await axios.post(
        ApiUrl + "/search-tracking/",
        { traking_namber: search },
        { headers: { Authorization: token } }
      );
      const resHistory = await axios.get(ApiUrl + "/get-history/" + res.data.data._id, {
        headers: { Authorization: token },
      });
      setMessage(resHistory.data.data.history.messages);
      setData(res.data.data);
      setLoading(false);
    } catch (e) {
      toast.error("کد رهگیری را به درستی وارد نکرده اید");
      setLoading(false);
    }
  };
  const searchInputHandler = (e) => {
    if (e.target.value.length > 0) {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
    setSearch(e.target.value);
  };

  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title> جست و جو شماره پیگیری </title>
      </Helmet>
      <Card>
        <CardHeader>
          <p>جست و جو با شماره پیگیری</p>
        </CardHeader>
        <CardBody>
          <div className={classes.searchContainer}>
            <SearchOutlined style={{ fontSize: "2rem", cursor: "pointer" }} />
            <input type="text" onChange={searchInputHandler} value={search} autoFocus={true} />
            <button onClick={searchHandler} disabled={isEmpty}>
              {!loading ? (
                <>
                  <h5>جست و جو</h5>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    flexDirection: "column",
                    direction: "rtl",
                  }}
                >
                  <BeatLoader
                    loading={loading}
                    cssOverride={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      margin: "0 auto",
                      borderColor: "#9c27b0",
                    }}
                    color={"white"}
                  />
                </div>
              )}
            </button>
          </div>
        </CardBody>
      </Card>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <table className={classes.table}>
                <tr>
                  <th>
                    <p>زمان شروع تماس</p>
                  </th>
                  <th>
                    <p>تاریخ شروع تماس</p>
                  </th>
                  <th>
                    <p>زمان پایان تماس</p>
                  </th>
                  <th>
                    <p>تاریخ پایان تماس</p>
                  </th>
                  <th>
                    <p>زمان ورود</p>
                  </th>
                  <th>
                    <p>تاریخ ورود</p>
                  </th>
                  <th>
                    <p>کد رهگیری</p>
                  </th>
                  <th>
                    <p>شماره ملی</p>
                  </th>
                  <th>
                    <p>وضعیت</p>
                  </th>
                  <th>
                    <p>توضحیات</p>
                  </th>
                </tr>
                <tr>
                  <td>
                    {
                      <p>
                        {data.start
                          ? moment(data.start, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss") === "Invalid date"
                            ? moment(data.start, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")
                            : moment(data.start, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss")
                          : "---"}
                      </p>
                    }
                  </td>
                  <td>
                    {
                      <p>
                        {data.start
                          ? moment(data.end, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD") === "Invalid date"
                            ? moment(data.end, "YYYY-MM-DD HH:mm:ss").format("jYYYY/jM/jD")
                            : moment(data.end, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD")
                          : "---"}
                      </p>
                    }
                  </td>
                  <td>
                    {data.end
                      ? moment(data.end, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss") === "Invalid date"
                        ? moment(data.end, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")
                        : moment(data.end, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss")
                      : "---"}
                  </td>
                  <td>
                    <p>
                      {data.end
                        ? moment(data.end, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD") === "Invalid date"
                          ? moment(data.end, "YYYY-MM-DD HH:mm:ss").format("jYYYY/jM/jD")
                          : moment(data.end, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD")
                        : "---"}
                    </p>
                  </td>
                  <td>
                    <p>{data.time_login ? data.time_login : "---"}</p>
                  </td>
                  <td>
                    <p>
                      {data.date_login
                        ? moment(data.date_login, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD") === "Invalid date"
                          ? moment(data.date_login, "YYYY-MM-DD HH:mm:ss").format("jYYYY/jM/jD")
                          : moment(data.date_login, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD")
                        : "---"}
                    </p>
                  </td>
                  <td>
                    <p>{data.tracking_number ? data.tracking_number : "---"}</p>
                  </td>
                  <td>
                    <p>{data.user_national_code ? data.user_national_code : "---"}</p>
                  </td>
                  <td>
                    {
                      <>
                        {data.status === "calling" ? (
                          <p>عدم پاسخ</p>
                        ) : data.status === "answer" ? (
                          <p>پاسخ داده شده</p>
                        ) : data.status === "rejected" ? (
                          <p>رد شده</p>
                        ) : data.status === "calling-chat" ? (
                          <p>عدم پاسخ</p>
                        ) : data.status === "answer-chat" ? (
                          <p>پاسخ داده شده</p>
                        ) : data.status === "rejected-chat" ? (
                          <p>رد شده</p>
                        ) : data.status === "diverted" ? (
                          <p>منتقل شده</p>
                        ) : (
                          "---"
                        )}
                      </>
                    }
                  </td>
                  <td>
                    <a href="#" onClick={(e) => setShowMsg(true)}>
                      مشاهده جزئیات
                    </a>
                  </td>
                </tr>
              </table>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ToastContainer />
      {showMsg && <MessageDetailPopUp showMsg={showMsg} setShowMsg={setShowMsg} messages={messages} />}
    </div>
  );
}

const mapStateToProps = (state) => {
  // 
  return {
    historyCall: state.user.history,
    pagination: state.user.pagination_call,
    loading: state.user.loading,
    error: state.user.error,
    operators: state.user.operators,
    queues: state.reports_2.queues,
  };
};
const mapDispatchToProps = (dispatch) => ({
  UserAction: bindActionCreators({ UserAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchTrackingNumber);
