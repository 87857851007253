import React from "react";
import { connect } from "react-redux/";
import logo from "./files/logo.png";

require("./style.css");

function Index(props) {
  return (
    <div dir="rtl" className={"firstpage"}>
      <div className={"welcome-container"}>
        <div className={"welcome-input"}>
          <div className={"logo"}>
            <img src={logo} />
          </div>
          <div>
            <h1 className={"welcome"}>قوانین و مقررات استفاده از سامانه ارتباط تصویری با مشتریان حامی چت</h1>
          </div>
          <hr />

          <ul>
            <li>
              به جهت افزایش رضایت و پاسخگویی بهتر به شما عزیزان کلیه مکالمات تصویری و متنی با کارشناسان ما ضبط می گردد .
            </li>
            <li>رعایت کلیه شئونات اسلامی در هنگام برقراری ارتباط با کارشناسان ما الزامی می باشد.</li>
            <li>جهت استفاده بهتر از این سرویس می بایست از یک بستر اینترنت پایدار و پرسرعت استفاده نمائید.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  // UserAction: bindActionCreators({UserAction}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
