import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { TimePicker } from "antd";
import "antd/dist/antd.css";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment-jalaali";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { connect } from "react-redux/";
import CreatableSelect from "react-select/creatable";
import ReactTable from "react-table";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import { ApiUrl } from "../../config";
import ReportAction from "../../js/actions/report_action";
import Spinner from "../../shared/Spinner/Spinner";
import Reset from "./../../assets/img/reset.png";

const axios = require("axios");

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function TextBoxReports(props) {
  // 

  const { loading, pagination } = props;
  useEffect(() => {
    getMenus();
    getAllReports();
  }, loading);

  const getMenus = () => {
    return props.store.dispatch(ReportAction.GetAllQueues());
  };

  const getAllReports = (pagination = { per_page: 20, page: 1 }) => {
    return props.store.dispatch(ReportAction.getTextBoxReportAll(pagination));
  };
  const getFilterReports = (pagination = { per_page: 20, page: 1 }) => {
    // 
    let queuesId = [];
    if (queue) {
      queue.forEach((item) => {
        queuesId.push(item.value);
      });
    }
    let mobileArray = [];
    if (stateMobile && stateMobile.length) {
      stateMobile.forEach((item) => {
        mobileArray.push(item.value);
      });
    }
    let nationalCodeArray = [];
    if (stateNationalCode && stateNationalCode.length) {
      stateNationalCode.forEach((item) => {
        nationalCodeArray.push(item.value);
      });
    }
    props.store.dispatch(
      ReportAction.getTextBoxReportFilter(
        pagination,
        selectedDayRange,
        queuesId,
        mobileArray,
        nationalCodeArray,
        timeStart,
        timeEnd
      )
    );
  };

  const [flag, setFlag] = React.useState("ALL");
  ////
  useEffect(() => {
    let data = props.queues;
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.name === "کیپاد" ? "پی پاد" : item.name,
        });
      });
      setDataSelectQueue(options);
    }
  }, [props.queues]);
  const [queue, setQueue] = React.useState("");
  const [dataSelectQueue, setDataSelectQueue] = React.useState([]);
  let handleChangeQueue = (newValue, actionMeta) => {
    setQueue(newValue);
  };
  ////

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 55,
      border: "1px solid black",
    }),
  };
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });

  const renderCustomInput = ({ ref }) => (
    <>
      <input
        readOnly
        ref={ref} // necessary
        placeholder="زمان گزارش را انتخاب کنید"
        value={
          selectedDayRange.from && selectedDayRange.to
            ? `${selectedDayRange.from.year}` +
              "/" +
              `${selectedDayRange.from.month}` +
              "/" +
              `${selectedDayRange.from.day}` +
              " تا " +
              `${selectedDayRange.to.year}` +
              "/" +
              `${selectedDayRange.to.month}` +
              "/" +
              `${selectedDayRange.to.day}`
            : ""
        }
        style={{
          textAlign: "center",
          padding: "8px",
          fontSize: "1rem",
          border: "1px solid ",
          borderRadius: "5px",
          outline: "none",
          height: 55,
          minHeight: 35,
          width: 500,
        }}
        className="my-custom-input-class" // a styling class
      />
      <a>
        <img
          src={Reset}
          style={{ width: "30px", height: "30px" }}
          onClick={() => {
            setSelectedDayRange({
              from: null,
              to: null,
            });
          }}
        />
      </a>
    </>
  );

  const [size, setSize] = React.useState(20);

  const onPageChangeFunction = (pageIndex) => {
    // 
    if (flag === "ALL") {
      getAllReports({ per_page: size, page: pageIndex + 1 });
    }
    if (flag === "FILTER") {
      getFilterReports({ per_page: size, page: pageIndex + 1 });
    }
  };
  const onPageSizeChangeFunction = (pageSize, pageIndex) => {
    // 
    setSize(pageSize);
    if (flag === "ALL") {
      getAllReports({ per_page: pageSize, page: pageIndex + 1 });
    }
    if (flag === "FILTER") {
      getFilterReports({ per_page: 20, page: pageIndex + 1 });
    }
  };

  ///////////
  const { RangePicker } = TimePicker;
  const [timeStart, settimeStart] = useState("");
  const [timeEnd, settimeEnd] = useState("");
  function onChange(time, timeString) {
    // 
    settimeStart(timeString[0]);
    settimeEnd(timeString[1]);
  }
  ///////////
  const getFileExcell = () => {
    let dateG_from;
    let dateG_to;
    let queuesId = [];
    if (queue) {
      queue.forEach((item) => {
        queuesId.push(item.value);
      });
    }
    if (selectedDayRange.to) {
      let dateFromString =
        selectedDayRange.from.year + "-" + selectedDayRange.from.month + "-" + selectedDayRange.from.day;
      let dateToString = selectedDayRange.to.year + "-" + selectedDayRange.to.month + "-" + selectedDayRange.to.day;
      dateG_from = moment(dateFromString, "jYYYY-jM-jD").format("YYYY-MM-DD");
      dateG_to = moment(dateToString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    }
    let mobileArray = [];
    if (stateMobile && stateMobile.length) {
      stateMobile.forEach((item) => {
        mobileArray.push(item.value);
      });
    }
    let nationalCodeArray = [];
    if (stateNationalCode && stateNationalCode.length) {
      stateNationalCode.forEach((item) => {
        nationalCodeArray.push(item.value);
      });
    }
    axios({
      url: ApiUrl + "/text-box-excell",
      method: "post",
      params: {},
      data: {
        dateTo: dateG_to,
        dateFrom: dateG_from,
        queuesId,
        nationalCodeArray,
        mobileArray,
        timeStart,
        timeEnd,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
      responseType: "arraybuffer",
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.xlsx");
        link.click();
      })
      .catch(() => {
        toast.error("دانلود فایل با خطا مواجه شد", { position: toast.POSITION.TOP_CENTER });
      });
  };

  ///////////////////////////////////////////////////////////////////
  const components = {
    DropdownIndicator: null,
  };
  const createOptionNationalCode = (label) => ({
    label,
    value: label,
  });
  const [stateNationalCode, setStateNationalCode] = React.useState([]);
  const [inputNationalCode, setInputNationalCode] = React.useState("");
  const handleChangeNationalCode = (value, actionMeta) => {
    setStateNationalCode(value);
  };
  const handleInputChangeNationalCode = (inputValue) => {
    setInputNationalCode(inputValue);
  };
  const handleKeyDownNationalCode = (event) => {
    if (!inputNationalCode) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        let stateV = stateNationalCode;
        let found;
        if (stateV && stateV.length) {
          found = stateV.find((element) => {
            return element.value == inputNationalCode;
          });
        }
        if (!found) {
          setInputNationalCode("");
          let stateB;
          if (stateV) {
            stateB = [...stateV, createOptionNationalCode(inputNationalCode)];
          } else {
            stateB = [createOptionNationalCode(inputNationalCode)];
          }

          setStateNationalCode(stateB);
        }
        event.preventDefault();
    }
  };

  //////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  const createOptionMobile = (label) => ({
    label,
    value: label,
  });
  const [stateMobile, setStateMobile] = React.useState([]);
  const [inputMobile, setInputMobile] = React.useState("");
  const handleChangeMobile = (newValue, actionMeta) => {
    setStateMobile(newValue);
  };
  const handleInputChangeMobile = (inputValueMobile) => {
    setInputMobile(inputValueMobile);
  };
  const handleKeyDownMobile = (event) => {
    if (!inputMobile) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        let stateV = stateMobile;
        let found;
        if (stateV && stateV.length) {
          found = stateV.find((element) => {
            return element.value == inputMobile;
          });
        }

        if (!found) {
          setInputMobile("");
          let stateB;

          if (stateV) {
            stateB = [...stateV, createOptionMobile(inputMobile)];
          } else {
            stateB = [createOptionMobile(inputMobile)];
          }
          setStateMobile(stateB);
        }
        event.preventDefault();
    }
  };
  //////////////////////////////////

  const classes = useStyles();
  if (loading) return <Spinner />;

  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>گزارش ورود و خروج - حامی چت</title>
      </Helmet>
      <GridContainer>
        <Card>
          <Grid container spacing={1}>
            <Grid item xs={5} style={{ margin: "24px", marginButton: "0px" }}>
              <CreatableSelect
                isMulti
                isClearable
                onChange={handleChangeQueue}
                options={dataSelectQueue}
                styles={customStyles}
                defaultValue={queue}
                closeMenuOnSelect={false}
                placeholder="صف مورد نظر خود را انتخاب کنید"
              />
            </Grid>
            <Grid item xs={6} style={{ margin: "24px", marginButton: "0px" }}>
              <DatePicker
                value={selectedDayRange}
                renderInput={renderCustomInput}
                onChange={setSelectedDayRange}
                inputPlaceholder="زمان گزارش"
                shouldHighlightWeekends
                calendarPopperPosition="bottom"
                locale="fa"
              />
            </Grid>
            <Grid item xs={5} style={{ margin: "24px", marginRight: "24px", marginTop: "0px" }}>
              <CreatableSelect
                components={components}
                inputValue={inputMobile}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={handleChangeMobile}
                onInputChange={handleInputChangeMobile}
                onKeyDown={handleKeyDownMobile}
                placeholder="شماره تلفن کاربر"
                styles={customStyles}
                value={stateMobile}
              />
            </Grid>
            <Grid item xs={5} style={{ margin: "24px", marginTop: "0px" }}>
              <CreatableSelect
                components={components}
                inputValue={inputNationalCode}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={handleChangeNationalCode}
                onInputChange={handleInputChangeNationalCode}
                onKeyDown={handleKeyDownNationalCode}
                styles={customStyles}
                placeholder="کدملی کاربر"
                value={stateNationalCode}
              />
            </Grid>

            <Grid
              item
              xs={5}
              style={{
                margin: "24px",
                marginLeft: "0px",
                marginRight: "24px",
                marginTop: "0px",
              }}
            >
              <RangePicker
                onChange={onChange}
                placeholder={["زمان آغاز", "زمان پایان"]}
                getPopupContainer={(trigger) => trigger.parentElement}
                defaultValue={[moment(timeStart, "HH:mm:ss"), moment(timeEnd, "HH:mm:ss")]}
                defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                style={{
                  height: "60px",
                  width: "300px",
                  border: "1px solid black",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "17px",
                }}
              />
            </Grid>

            <Grid item xs={6} style={{ marginBottom: "24px", marginRight: "42px" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                name="buttonname"
                style={{ height: 55 }}
                onClick={() => {
                  // 
                  setFlag("FILTER");
                  getFilterReports({ per_page: 20, page: 1 });
                }}
              >
                اعمال
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                name="buttonname"
                style={{ height: 55 }}
                onClick={() => {
                  setFlag("ALL");
                  getAllReports();
                }}
              >
                دریافت همه گزارشات
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                name="buttonname"
                style={{ height: 55 }}
                onClick={() => {
                  getFileExcell();
                }}
              >
                دریافت فایل
              </Button>
            </Grid>
          </Grid>
        </Card>

        <GridItem xs={12}>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <ReactTable
                rtl
                data={props.textBox ? props.textBox : []}
                loading={props.loading}
                pagination={true}
                filterable
                manual
                columns={[
                  {
                    Header: "نام مشتری",
                    accessor: "fullname",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "کدملی",
                    accessor: "nationalCode",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "تلفن",
                    accessor: "mobile",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: " صف",
                    accessor: "menu.name",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "تاریخ",
                    accessor: "date",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div>{moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD HH:mm:ss")}</div>
                    ),
                  },
                  {
                    Header: "وضعیت",
                    accessor: "comment",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => <div>{row.value ? "بررسی شده" : "عدم بررسی"}</div>,
                  },
                  {
                    Header: "جزییات",
                    accessor: "_id",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div>
                        <a href={"/admin/text-box-message/" + row.value}>مشاهده جزییات</a>
                      </div>
                    ),
                  },
                ]}
                pageSize={props.pagination && props.pagination.size}
                page={props.pagination && props.pagination.page}
                pages={props.pagination && props.pagination.total}
                onPageChange={(pageIndex) => {
                  // 
                  onPageChangeFunction(pageIndex);
                }}
                onPageSizeChange={(pageSize, pageIndex) => {
                  // 
                  onPageSizeChangeFunction(pageSize, pageIndex);
                }}
                onFilteredChange={(column, value) => {
                  // 
                }}
                className="-striped -highlight"
                showPaginationTop={false}
                showPaginationBottom={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => {
  // 
  return {
    pagination: state.textBox.pagination,
    loading: state.textBox.loading,
    error: state.textBox.error,
    textBox: state.textBox.textBox,
    queues: state.reports_2.queues,
  };
};

const mapDispatchToProps = (dispatch) => {
  // 
  return {
    ReportAction: bindActionCreators({ ReportAction }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextBoxReports);
