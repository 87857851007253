import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  progressLine: {
    width: "100%",
    height: "4px",
    position: "relative",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 255, 255, 0.40)",
    direction: "ltr",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cap: {
    position: "absolute",
    right: "-2px",
    top: "-1px",
    width: "16px",
    height: "6px",
    backgroundColor: "var(--white)",
    borderRadius: "4px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.15);",
  },
  step: {
    width: "2px",
    height: "2px",
    backgroundColor: "var(--white)",
    borderRadius: "4px",
  },
});

function ProgressBar({ cancel, userCount }) {
  const [filled, setFilled] = useState(0);
  const classes = useStyles();
  const waitingTime = userCount * 2;
  const waitingTimeString = waitingTime.toString().padEnd(4, "0");
  useEffect(() => {
    if (filled === 100 && userCount > 0) {
      return (window.location.href = "/client/message");
    }
    if (filled < 100) {
      setTimeout(() => setFilled((prev) => (prev += 2)), Number(waitingTimeString) / 2);
    }
  }, [filled]);
  return (
    <div className={classes.progressLine}>
      <div
        style={{
          height: "100%",
          width: `${filled}%`,
          left: 0,
          backgroundColor: "var(--white)",
          transition: "width 0.5s",
          position: "absolute",
        }}
      >
        <div className={classes.cap} />
      </div>
      <div className={classes.step} />
      <div className={classes.step} />
      <div className={classes.step} />
      <div className={classes.step} />
      <div className={classes.step} />
    </div>
  );
}

export default ProgressBar;
