import moment from "moment-jalaali";
import { ApiUrl } from "../../config";
import {
  CREATE_FAILED,
  CREATE_OPERATOR_SUCCESS,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  GET_HISTORY_FAILED,
  GET_HISTORY_LIST_FAILED,
  GET_HISTORY_LIST_REQUEST,
  GET_HISTORY_LIST_SUCCESS,
  GET_HISTORY_REQUEST,
  GET_HISTORY_SUCCESS,
  GET_INPUT_CALL_FAILED,
  GET_INPUT_CALL_REQUEST,
  GET_INPUT_CALL_SUCCESS,
  GET_MEDIA_BOX_FAILED,
  GET_MEDIA_BOX_REQUEST,
  GET_MEDIA_BOX_SUCCESS,
  GET_OUT_CALL_FAILED,
  GET_OUT_CALL_REQUEST,
  GET_OUT_CALL_SUCCESS,
  GET_USER_LIST_FAILED,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_SFAILED,
  LOGIN_SUCCESS,
} from "../type/user_type";
import { toast } from "react-toastify";

const axios = require("axios");

const GetAllUsers = (pagination) => {
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(ApiUrl + `/get-users?size=${pagination.per_page}&page=${pagination.page}`, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        dispatch(success(response));
      })
      .catch(function (error) {
        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });

    function request() {
      return { type: GET_USER_LIST_REQUEST };
    }

    function success(result) {
      return { type: GET_USER_LIST_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: GET_USER_LIST_FAILED, payload: { error } };
    }
  };
};

const GetFilterUsers = (pagination, nationalCodeArray, mobileArray, queuesId, typeArray) => {
  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + `/get-users?size=${pagination.per_page}&page=${pagination.page}`,
      method: "post",
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
      data: {
        nationalCodeArray,
        mobileArray,
        queuesId,
        typeArray,
      },
    })
      .then(function (response) {
        // console.dir(response, "response filter users");
        dispatch(success(response));
      })
      .catch(function (error) {
        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });
    function request() {
      return { type: GET_USER_LIST_REQUEST };
    }
    function success(result) {
      return { type: GET_USER_LIST_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_USER_LIST_FAILED, payload: { error } };
    }
  };
};
const GetAllHIstories = (pagination) => {
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(ApiUrl + `/get-histories?size=${pagination.per_page}&page=${pagination.page}`, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {

        dispatch(success(response));
      })
      .catch(function (error) {

        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });

    function request() {
      return { type: GET_HISTORY_LIST_REQUEST };
    }

    function success(result) {
      return { type: GET_HISTORY_LIST_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: GET_HISTORY_LIST_FAILED, payload: { error } };
    }
  };
};

const GetAllMediaBox = (pagination) => {
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(ApiUrl + `/reports-media-box?size=${pagination.per_page}&page=${pagination.page}`, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {

        dispatch(success(response));
      })
      .catch(function (error) {

        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });

    function request() {
      return { type: GET_MEDIA_BOX_REQUEST };
    }

    function success(result) {
      return { type: GET_MEDIA_BOX_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: GET_MEDIA_BOX_FAILED, payload: { error } };
    }
  };
};

const GetAllHIstory = (id) => {
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(ApiUrl + `/get-history/${id}`, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        dispatch(success(response));
      })
      .catch(function (error) {
        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });

    function request() {
      return { type: GET_HISTORY_REQUEST };
    }

    function success(result) {
      return { type: GET_HISTORY_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: GET_HISTORY_FAILED, payload: { error } };
    }
  };
};

const GetInputCallsReports = (
  callStatusValues,
  operatorsId,
  dateJalali,
  queuesId,
  pagination,
  timeStart,
  timeEnd,
  dayOfWeek
) => {
  let url;
  let data;
  let dateG_from;
  let dateG_to;
  if (dateJalali.to) {
    let dateFromString = dateJalali.from.year + "-" + dateJalali.from.month + "-" + dateJalali.from.day;
    let dateToString = dateJalali.to.year + "-" + dateJalali.to.month + "-" + dateJalali.to.day;
    dateG_from = moment(dateFromString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    dateG_to = moment(dateToString, "jYYYY-jM-jD").format("YYYY-MM-DD");
  }

  url = ApiUrl + "/reports-call-input" + `?size=${pagination.per_page}&page=${pagination.page}`;

  data = {
    arrayOperatorsId: operatorsId,
    queuesId,
    statusArray: callStatusValues,
    // endStatusArray,
    // dateG_to_logout,
    // dateG_from_logout,
    // timeStart_Logout,
    // timeEnd_Logout,
    // dateG_to_login,
    // dateG_from_login,
    // timeStart_Login,
    // timeEnd_Login,
    // dateG_to_menu,
    // dateG_from_menu,
    // timeStart_Menu,
    // timeEnd_Menu,
    // dateG_to_wait,
    // dateG_from_wait,
    // timeStart_Wait,
    // timeEnd_Wait,
    dateG_to_start: dateG_to,
    dateG_from_start: dateG_from,
    // timeStart_start,
    timeEnd_start: timeStart,
    // dateG_to_end,
    // dateG_from_end,
    // timeStart_End,
    timeEnd_End: timeEnd,
  };

  return (dispatch) => {
    dispatch(request());
    return axios({
      url: url,
      method: "post",
      params: {},
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {

        dispatch(success(response));
      })
      .catch(function (error) {

        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });
    function request() {
      return { type: GET_HISTORY_LIST_REQUEST };
    }
    function success(result) {

      return { type: GET_HISTORY_LIST_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_HISTORY_LIST_FAILED, payload: { error } };
    }
  };
};

const GetAllInputCalls = (pagination) => {
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(ApiUrl + `/reports-call-input?size=${pagination.per_page}&page=${pagination.page}`, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {

        dispatch(success(response));
      })
      .catch(function (error) {

        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });

    function request() {
      return { type: GET_INPUT_CALL_REQUEST };
    }
    function success(result) {

      return { type: GET_INPUT_CALL_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: GET_INPUT_CALL_FAILED, payload: { error } };
    }
  };
};

const GetCallHistoryReports = (
  callStatusValues,
  operatorsId,
  dateJalali,
  queuesId,
  pagination1,
  timeStart,
  timeEnd,
  dayOfWeek
) => {



  let url;
  let data;
  let dateG_from;
  let dateG_to;
  if (dateJalali.to) {
    let dateFromString = dateJalali.from.year + "-" + dateJalali.from.month + "-" + dateJalali.from.day;
    let dateToString = dateJalali.to.year + "-" + dateJalali.to.month + "-" + dateJalali.to.day;
    dateG_from = moment(dateFromString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    dateG_to = moment(dateToString, "jYYYY-jM-jD").format("YYYY-MM-DD");
  }
  url = ApiUrl + "/reports-call-history" + `?size=20&page=1`;
  data = {
    operators: operatorsId,
    dateFrom: dateG_from,
    dateTo: dateG_to,
    queuesId: queuesId,
    status: callStatusValues,
    timeStart,
    timeEnd,
    dayOfWeek,
  };

  return (dispatch) => {
    dispatch(request());
    return axios({
      url: url,
      method: "post",
      params: {},
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {

        dispatch(success(response));
      })
      .catch(function (error) {

        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });
    function request() {
      return { type: GET_HISTORY_LIST_REQUEST };
    }
    function success(result) {

      return { type: GET_HISTORY_LIST_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: GET_HISTORY_LIST_FAILED, payload: { error } };
    }
  };
};

const GetFilterMediaBox = (dateJalali, queuesId, pagination, timeStart, timeEnd) => {
  let url;
  let data;
  let dateG_from;
  let dateG_to;
  if (dateJalali.to) {
    let dateFromString = dateJalali.from.year + "-" + dateJalali.from.month + "-" + dateJalali.from.day;
    let dateToString = dateJalali.to.year + "-" + dateJalali.to.month + "-" + dateJalali.to.day;
    dateG_from = moment(dateFromString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    dateG_to = moment(dateToString, "jYYYY-jM-jD").format("YYYY-MM-DD");
  }
  url = ApiUrl + "/reports-media-box" + `?size=${pagination.per_page}&page=${pagination.page}`;
  data = {
    dateFrom: dateG_from,
    dateTo: dateG_to,
    queuesId: queuesId,
    timeStart,
    timeEnd,
  };

  return (dispatch) => {
    dispatch(request());
    return axios({
      url: url,
      method: "post",
      params: {},
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {

        dispatch(success(response));
      })
      .catch(function (error) {

        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });
    function request() {
      return { type: GET_MEDIA_BOX_REQUEST };
    }
    function success(result) {

      return { type: GET_MEDIA_BOX_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: GET_MEDIA_BOX_FAILED, payload: { error } };
    }
  };
};

const Login = (data) => {
  let email = data.get("mobile");
  let password = data.get("password");
  return (dispatch) => {
    dispatch(request());
    return axios({
      method: "post",
      url: ApiUrl + `/login`,
      data: {
        user: {
          mobile: email,
          password: password,
        },
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {

        dispatch(success(response));
      })
      .catch(function (error) {


        dispatch(failure(error));
      });

    function request() {
      return { type: LOGIN_REQUEST };
    }

    function success(result) {
      return { type: LOGIN_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: LOGIN_SFAILED, payload: { error } };
    }
  };
};

const CreateNewUser = (data) => {
  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/create-user",
      method: "post",
      params: {},
      data: {
        user: {
          email: data.email,
          username: data.username,
          password: data.national_code,
          first_name: data.first_name,
          last_name: data.last_name,
          national_code: data.national_code,
          mobile: data.mobile,
          expertList: data.menu,
          type: data.type,
          roles: data.roles,
        },
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        toast.success("اپراتور با موفقیت ایجاد شد")
        dispatch(success(response));
      })
      .catch(function (error) {


        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });

    function request() {
      return { type: CREATE_REQUEST };
    }

    function success(result) {
      return { type: CREATE_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: CREATE_FAILED, payload: { error } };
    }
  };
};
const Edituser = (data, id) => {
  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/edit-user/" + id,
      method: "post",
      params: {},
      data: {
        user: {
          email: data.email,
          username: data.username,
          first_name: data.first_name,
          last_name: data.last_name,
          national_code: data.national_code,
          mobile: data.mobile,
          expertList: data.menu,
        },
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        dispatch(success(response));
      })
      .catch(function (error) {
        // 

        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });

    function request() {
      return { type: CREATE_REQUEST };
    }

    function success(result) {
      return { type: CREATE_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: CREATE_FAILED, payload: { error } };
    }
  };
};

const GetOperators = () => {
  // 
  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/get-operators",
      method: "get",
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        // 
        dispatch(success(response));
      })
      .catch(function (error) {
        // 
        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });

    function request() {
      return { type: CREATE_REQUEST };
    }

    function success(result) {
      return { type: CREATE_OPERATOR_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: CREATE_FAILED, payload: { error } };
    }
  };
};
const GetOperatorCallHistory = (pagination, id) => {
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(ApiUrl + `/operator-call-history/${id}?size=${pagination.per_page}&page=${pagination.page}`, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        // 
        dispatch(success(response));
      })
      .catch(function (error) {
        // 
        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });

    function request() {
      return { type: GET_HISTORY_LIST_REQUEST };
    }

    function success(result) {
      return { type: GET_HISTORY_LIST_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: GET_HISTORY_LIST_FAILED, payload: { error } };
    }
  };
};
const GetClientCallHistory = (pagination, id) => {
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(ApiUrl + `/client-call-history/${id}?size=${pagination.per_page}&page=${pagination.page}`, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        dispatch(success(response));
      })
      .catch(function (error) {
        // 
        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });

    function request() {
      return { type: GET_HISTORY_LIST_REQUEST };
    }

    function success(result) {
      // 
      return { type: GET_HISTORY_LIST_SUCCESS, payload: result.data };
    }

    function failure(error) {
      // 
      return { type: GET_HISTORY_LIST_FAILED, payload: { error } };
    }
  };
};
//get oprator-roll
const GetOperatorRoll = (id) => {
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(ApiUrl + ` /oprator-roll/${id}`, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        dispatch(success(response));
      })
      .catch(function (error) {
        // 
        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });

    function request() {
      return { type: GET_HISTORY_LIST_REQUEST };
    }

    function success(result) {
      // 
      return { type: GET_HISTORY_LIST_SUCCESS, payload: result.data };
    }

    function failure(error) {
      // 
      return { type: GET_HISTORY_LIST_FAILED, payload: { error } };
    }
  };
};

export default {
  GetAllUsers,
  GetFilterUsers,
  Login,
  GetAllHIstories,
  GetAllHIstory,
  CreateNewUser,
  Edituser,
  GetOperators,
  GetCallHistoryReports,
  GetAllMediaBox,
  GetFilterMediaBox,
  GetInputCallsReports,
  GetAllInputCalls,
  GetOperatorCallHistory,
  GetClientCallHistory,
  GetOperatorRoll,
};
