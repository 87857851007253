import { css } from "@emotion/react";
import axios from "axios";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import { API_URL } from "../../contacs";
import { PrimaryBtn } from "../commen/Btn";
import DefaultBox from "../commen/DefaultBox";

function MakeTicket({ setTicket, personalid, phone }) {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;
  const [info, setInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [upload, setUpload] = useState("");
  const [upload1, setUpload1] = useState("");
  const [loading1, setLoading1] = useState(false);
  const [active, setActive] = useState("ارسال کد تایید");
  const [eventName, setEventName] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setLoading(true);
    // data.file.length !== 0 && setUpload({ file: data.file });
    if (data.file.length === 0) {
      setInfo({
        n_id: localStorage.getItem("data_nationalcode"),
        phone_number: localStorage.getItem("data_mobile"),
        status: "NEW",
        priority: data.priority,
        text: data.text,
        title: data.title,
      });
      handleMakeTicket();
    } else {
      let formData = new FormData();
      formData.append("file", data.file[0]);
      axios
        .post(`${API_URL}/v1/storage/upload/ticket`, formData, {
          headers: {
            "content-type": "multipart/form-data",
            // authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setInfo({
            n_id: localStorage.getItem("data_nationalcode"),
            phone_number: localStorage.getItem("data_mobile"),
            status: "NEW",
            priority: data.priority,
            text: data.text,
            title: data.title,
            attach: response,
          });
          handleMakeTicket();
        })
        .catch((error) => {
          // 
          setLoading(false);
        });
    }
    // handleMakeTicket();
    // 
  };
  const firstUpdate = useRef(true);
  // useLayoutEffect(() => {
  //   if (firstUpdate.current) {
  //     firstUpdate.current = false;
  //     return;
  //   }
  //   // 
  //   handleUpload();
  // }, [upload]);

  const handleMakeTicket = () => {
    axios({
      url: `${API_URL}/v1/ticket-hamichat`,
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: info,
    })
      .then(function (response) {
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const handleChange = (evt) => {
    const financialGoal = evt.target.value;

    setEventName(financialGoal);
  };
  const handleactivationCode = () => {
    setLoading1(true);
  };
  const timeoutHandler = (timeOut) => {
    setLoading1(!timeOut);
    setActive("ارسال مجدد کد تایید");
    setEventName("");
  };

  // 
  return (
    <div className="Ticket">
      <DefaultBox className="Ticket__DefaultBox" title="ایجاد تیکت" main={false} setTicket={setTicket}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="MakeTicket__form">
            <div className={errors.title ? "MakeTicket__inputBox-err" : "MakeTicket__inputBox"}>
              <label>عنوان</label>
              <input
                {...register("title", { required: true })}
                placeholder=" عنوان را وارد نمایید"
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => (e.target.placeholder = " عنوان را وارد نمایید")}
              />
              <span>{errors.title?.type === "required" && "وارد کردن عنوان الزامی است"}</span>
            </div>
            <div className="MakeTicket__inputBox">
              <label>افزودن فایل</label>
              <input {...register("file", { required: false })} type="file" />
            </div>
            <div className={errors.priority ? "MakeTicket__inputBox-err" : "MakeTicket__inputBox"}>
              <label>اولویت</label>
              <select defaultValue="HIGH" {...register("priority", { required: true })}>
                <option value="HIGH">بالا</option>
                <option value="MID">متوسط</option>
                <option value="LOW">پایین</option>
              </select>
              <span>{errors.priority?.type === "required" && "وارد کردن عنوان الزامی است"}</span>
            </div>
            <div
              className={
                errors.text
                  ? "MakeTicket__inputBox-err MakeTicket__textarea-err"
                  : "MakeTicket__inputBox MakeTicket__textarea"
              }
            >
              <label>توضیحات</label>
              <textarea
                {...register("text", { required: true })}
                className={errors.text ? "MakeTicket__textarea-err" : "MakeTicket__textarea"}
                placeholder=" توضیحات را وارد نمایید"
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => (e.target.placeholder = " توضیحات را وارد نمایید")}
              />
              <span>{errors.text?.type === "required" && "وارد کردن توضیحات الزامی است"}</span>
            </div>
            {/* <div
              className={
                errors.verify_code
                  ? "MakeTicket__inputBox-err"
                  : "MakeTicket__inputBox"
              }
            >
              <label>کد تایید</label>
              <input
                {...register("verify_code", { required: true })}
                placeholder="کد تایید را وارد نمایید"
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) =>
                  (e.target.placeholder = "کد تایید را وارد نمایید")
                }
                onInput={handleChange}
                value={eventName}
                // onChange={(e) => {
                //   setEventName(e.target.value);
                // }}
              />
              <span>
                {errors.verify_code?.type === "required" &&
                  "وارد کردن کدتایید برای باز شدن دکمه ایجاد الزامی است"}
              </span>
            </div> */}
            {/* <div
              className={
                errors.verify_code
                  ? "MakeTicket__BtnBox-1-err d-flex"
                  : "MakeTicket__BtnBox-1 d-flex"
              }
            >
              <PrimaryBtntypebtn
                className="MakeTicket__Btn"
                onClick={handleactivationCode}
              >
                {loading1 ? (
                  <ClipLoader
                    color="#ffffff"
                    loading={loading1}
                    css={override}
                    size={15}
                  />
                ) : (
                  active
                )}
              </PrimaryBtntypebtn>
              {loading1 ? (
                <div className="MakeTicket__timer">
                  <Timer Min={2} Sec={0} onTimeout={timeoutHandler} />
                </div>
              ) : null}
            </div> */}
          </div>
          <div className="MakeTicket__BtnBox d-flex">
            <PrimaryBtn
              className={eventName !== "" ? "MakeTicket__Btn-disable" : "MakeTicket__Btn"}
              // disabled={eventName === "" ? true : false}
            >
              {loading ? <ClipLoader color="#ffffff" loading={loading} css={override} size={15} /> : "ایجاد"}
            </PrimaryBtn>
          </div>
        </form>
      </DefaultBox>
    </div>
  );
}

export default MakeTicket;
