/* eslint-disable no-redeclare */
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import $ from "jquery";
import { Device } from "mediasoup-client";
import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { sendRequest, Url } from "../../config";

let local_media_stream = null;

function ClientRoomConfrence(props) {
  let producerTransport = null;
  let consumerTransport = null;
  let videoConsumers = {};
  let audioConsumers = {};
  let device = null;
  const [socketIo, setSocket] = useState(null);

  useEffect(() => {
    connection();
  }, []);

  async function loadDevice(routerRtpCapabilities) {
    try {
      device = new Device();
    } catch (error) {
      if (error.name === "UnsupportedError") {
        alert("browser not supported");
      }
    }
    await device.load({ routerRtpCapabilities });
  }
  const connection = async () => {
    let socket = io.connect(Url);

    socket.on("connect", async function () {
      setSocket(socket);
      let room = props.match.params.id;
      await sendRequest("joinRoom", { roomId: room }, socket);
      confirm(room);
    });
    socket.on("disconnect", function () {
      socketIo && socketIo.disconnect();
    });

    async function subscribe(socketIo) {
      if (!consumerTransport) {
        const params = await sendRequest("createConsumerTransport", {}, socketIo);

        consumerTransport = await device.createRecvTransport(params);
        consumerTransport.on("connect", async ({ dtlsParameters }, callback, errback) => {
          sendRequest("connectConsumerTransport", { dtlsParameters: dtlsParameters }, socketIo)
            .then(callback)
            .catch(errback);
        });

        consumerTransport.on("connectionstatechange", (state) => {
          switch (state) {
            case "connecting":
              break;

            case "connected":
              break;

            case "failed":
              producerTransport.close();
              break;

            default:
              break;
          }
        });

        consumeCurrentProducers(0, socketIo);
      }
    }
    function removeRemoteVideo(id) {
      $("#remote_" + id).remove();
      $("#audio_remote_" + id).remove();
    }

    async function consumeCurrentProducers(clientId, socketIo) {
      const remoteInfo = await sendRequest("getCurrentProducers", { localId: socketIo.id }, socketIo).catch((err) => {
        return;
      });

      consumeAll(consumerTransport, remoteInfo.remoteVideoIds, remoteInfo.remoteAudioIds, socketIo);
    }
    const confirm = async (course_id) => {
      if (producerTransport) producerTransport.close();
      producerTransport = null;

      if (consumerTransport) consumerTransport.close();
      consumerTransport = null;

      device = null;

      init(async function (stream) {
        const data = await sendRequest("getRouterRtpCapabilities", { room_id: course_id }, socket);
        await loadDevice(data);
        subscribe(socket);
        const params = await sendRequest("createProducerTransport", {}, socket);
        producerTransport = device.createSendTransport(params);

        producerTransport.on("connect", async ({ dtlsParameters }, callback, errback) => {
          sendRequest("connectProducerTransport", { dtlsParameters: dtlsParameters }, socket)
            .then(callback)
            .catch(errback);
        });

        producerTransport.on("produce", async ({ kind, rtpParameters }, callback, errback) => {
          try {
            const { id } = await sendRequest(
              "produce",
              {
                transportId: producerTransport.id,
                kind,
                rtpParameters,
                type: "teacher",
              },
              socket
            );

            callback({ id });
          } catch (err) {
            errback(err);
          }
        });

        producerTransport.on("connectionstatechange", (state) => {
          switch (state) {
            case "connecting":
              break;

            case "connected":
              break;

            case "failed":
              producerTransport.close();
              break;

            default:
              break;
          }
        });

        const videoTrack = local_media_stream.getVideoTracks()[0];

        if (videoTrack) {
          const trackParams = {
            track: videoTrack,
            encodings: [{ maxBitrate: 50000 }],
          };
          await producerTransport.produce(trackParams);
        }
        const audioTrack = local_media_stream.getAudioTracks()[0];
        if (audioTrack) {
          const trackParams = {
            track: audioTrack,
            encodings: [{ maxBitrate: 100000 }],
          };
          await producerTransport.produce(trackParams);
        }

        subscribe(socket);
      });
    };

    socket.on("newProducer", async function (message) {
      const remoteId = message.socketId;
      const prdId = message.producerId;
      const kind = message.kind;
      const name = message.name;
      const type = message.type;
      subscribe(socket);

      if (type !== "screen") {
        if (kind === "video") {
          consumeAdd(consumerTransport, remoteId, prdId, kind, socket, name, type);
        } else if (kind === "audio") {
          consumeAdd(consumerTransport, remoteId, prdId, kind, socket, name, type);
        }
      }
    });
    socket.on("producerClosed", function (message) {
      const remoteId = message.remoteId;
      const kind = message.kind;

      removeConsumer(remoteId, kind);
      removeRemoteVideo(remoteId);
    });

    function removeConsumer(id, kind) {
      if (kind === "video") {
        delete videoConsumers[id];
      } else if (kind === "audio") {
        delete audioConsumers[id];
      }
    }

    function consumeAll(transport, remoteVideoIds, remotAudioIds, socket) {
      remoteVideoIds.forEach((rId) => {
        consumeAdd(transport, rId.id, null, "video", socket, rId.name, rId.type);
      });
      remotAudioIds.forEach((rId) => {
        consumeAdd(transport, rId.id, null, "audio", socket, rId.name, rId.type);
      });
    }
    async function consumeAdd(transport, remoteSocketId, prdId, trackKind, socket, name = "", type = "student") {
      const { rtpCapabilities } = device;

      const data = await sendRequest(
        "consumeAdd",
        {
          rtpCapabilities: rtpCapabilities,
          remoteId: remoteSocketId,
          kind: trackKind,
        },
        socket
      );

      const { producerId, id, kind, rtpParameters } = data;

      let codecOptions = {};
      const consumer = await transport.consume({
        id,
        producerId,
        kind,
        rtpParameters,
        codecOptions,
      });

      addRemoteTrack(remoteSocketId, consumer.track, name, type, kind);
      addConsumer(remoteSocketId, consumer, kind);
      consumer.remoteId = remoteSocketId;

      consumer.on("producerclose", () => {
        consumer.close();
        removeConsumer(consumer.remoteId, kind);
        removeRemoteVideo(consumer.remoteId);
      });

      consumer.on("trackended", () => {});

      if (kind === "video") {
        await sendRequest("resumeAdd", { remoteId: remoteSocketId, kind: kind }, socket);
      }
    }

    function addConsumer(id, consumer, kind) {
      if (kind === "video") {
        videoConsumers[id] = consumer;
      } else if (kind === "audio") {
        audioConsumers[id] = consumer;
      }
    }

    function findRemoteVideo(id, type, kind) {
      let element;
      if (kind === "audio") element = document.querySelector(`#audio_remote video`);
      else element = document.querySelector(`#remote video`);

      return element;
    }

    function addRemoteVideo(id, name = "", type = "", kind, track) {
      let existElement = findRemoteVideo(id, type, kind);
      if (existElement) {
        return existElement;
      }

      switch (type) {
        case "screen":
          var remote_media = document.createElement("video");
          var parent = document.createElement("div");

          remote_media.setAttribute("autoplay", "autoplay");
          remote_media.setAttribute("playsinline", "playsinline");

          parent.setAttribute("id", "remote_" + id);
          if (kind === "audio") parent.setAttribute("style", "display:none");
          else parent.setAttribute("style", "");
          parent.append(remote_media);
          $("#display-container").append(parent);

          return remote_media;

        case "student":
        case "teacher":
          var remote_media = document.createElement("video");
          var parent = document.createElement("div");
          var details = document.createElement("div");
          var username = document.createElement("h3");
          remote_media.setAttribute("style", "height:auto");

          details.setAttribute("class", "details-container");

          username.setAttribute("class", "name");
          username.innerText = name;

          details.append(username);
          remote_media.setAttribute("autoplay", "autoplay");
          remote_media.setAttribute("playsinline", "playsinline");
          parent.setAttribute("class", "student-camera-container");
          parent.setAttribute("style", "width:80%");

          if (kind === "audio") {
            parent.setAttribute("id", "audio_remote_" + id);
            parent.append(remote_media);
            $("#audio-container").append(parent);
          } else {
            parent.setAttribute("id", "remote_" + id);
            parent.append(remote_media);
            parent.append(details);
            $("#video-container").append(parent);
          }
          return remote_media;
        default:
          break;
      }
    }

    function addRemoteTrack(id, track, name = "", type = "", kind) {
      let video = findRemoteVideo(id, type, kind);

      if (!video) {
        video = addRemoteVideo(id, name, type, kind, track);
      }

      if (video.srcObject) {
        video.srcObject.addTrack(track);
        return;
      }

      const newStream = new MediaStream();
      newStream.addTrack(track);

      playVideo(video, newStream).then(() => {
        video.volume = 1.0;
      });
    }

    function playVideo(element, stream) {
      if (element.srcObject) {
        return;
      }
      element.srcObject = stream;
      element.volume = 0;
      return element.play();
    }
  };

  const init = (callback) => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(function (stream) {
      local_media_stream = stream;
      let self = document.getElementById("self");

      self.srcObject = stream;
      self.setAttribute("autoplay", "autoplay");
      self.setAttribute("playsinline", "playsinline");
      callback(stream);
    });
  };

  return (
    <GridContainer>
      <GridItem md={12} sm={12}>
        <div dir="rtl">
          <div className={"video-container"} style={{ width: "97%", margin: "1rem 2rem 0" }}>
            <div className={"calling admin"}>
              <div className={"btn-container-top"}>
                <div className="btns">
                  <a href="/admin">
                    <button>خروج</button>
                  </a>
                </div>
              </div>

              <div
                id="video-container"
                style={{
                  maxWidth: "100%",
                  display: "grid",
                  gridTemplateRows: "1fr",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "1rem",
                }}
                className="video"
              >
                <div className={"self"} style={{ width: "80%", justifySelf: "center" }}>
                  <video
                    muted={true}
                    autoPlay={true}
                    id={"self"}
                    style={{ position: "static", width: "100%", height: "auto", maxHeight: "fit-content" }}
                  />
                </div>
              </div>

              <div id="audio-container" className="video" style={{ display: "none" }}></div>
            </div>
          </div>
        </div>
      </GridItem>
    </GridContainer>
  );
}

export default ClientRoomConfrence;
