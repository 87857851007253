import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { connect } from "react-redux/";
import CreatableSelect from "react-select/creatable";
import ReactTable from "react-table";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import { ApiUrl } from "../../config";
import ReportAction from "../../js/actions/report_action";
import UserAction from "../../js/actions/user_action";

const axios = require("axios");

// 

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function Clients(props) {
  // 

  const { loading, pagination } = props;
  useEffect(() => {
    getAllReports();
  }, loading);

  const getAllReports = (pagination = { per_page: 20, page: 1 }) => {
    return props.store.dispatch(ReportAction.GetClients(pagination));
  };
  const getReportsFilter = (pagination = { per_page: 20, page: 1 }, arrayObj) => {
    let arrayTypeCall = [];
    if (arrayObj) {
      if (arrayObj.length) {
        arrayObj.forEach((item) => {
          arrayTypeCall.push(item.value);
        });
      }
    }
    let mobileArray = [];
    if (stateMobile && stateMobile.length) {
      stateMobile.forEach((item) => {
        mobileArray.push(item.value);
      });
    }
    let nationalCodeArray = [];
    if (stateNationalCode && stateNationalCode.length) {
      stateNationalCode.forEach((item) => {
        nationalCodeArray.push(item.value);
      });
    }

    return props.store.dispatch(
      ReportAction.GetClientsFilter(pagination, arrayTypeCall, nationalCodeArray, mobileArray)
    );
  };

  // 
  // 

  ///////////////////////////////////////////////////////////////////
  const createOptionMobile = (label) => ({
    label,
    value: label,
  });
  const [stateMobile, setStateMobile] = React.useState([]);
  const [inputMobile, setInputMobile] = React.useState("");
  const handleChangeMobile = (newValue, actionMeta) => {
    setStateMobile(newValue);
  };
  const handleInputChangeMobile = (inputValueMobile) => {
    setInputMobile(inputValueMobile);
  };
  const handleKeyDownMobile = (event) => {
    if (!inputMobile) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        let stateV = stateMobile;
        let found;
        if (stateV && stateV.length) {
          found = stateV.find((element) => {
            return element.value == inputMobile;
          });
        }

        if (!found) {
          setInputMobile("");
          let stateB;
          if (stateV) {
            stateB = [...stateV, createOptionMobile(inputMobile)];
          } else {
            stateB = [createOptionMobile(inputMobile)];
          }
          setStateMobile(stateB);
        }
        event.preventDefault();
    }
  };
  //////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  const components = {
    DropdownIndicator: null,
  };
  const createOptionNationalCode = (label) => ({
    label,
    value: label,
  });
  const [stateNationalCode, setStateNationalCode] = React.useState([]);
  const [inputNationalCode, setInputNationalCode] = React.useState("");
  const handleChangeNationalCode = (value, actionMeta) => {
    setStateNationalCode(value);
  };
  const handleInputChangeNationalCode = (inputValue) => {
    setInputNationalCode(inputValue);
  };
  const handleKeyDownNationalCode = (event) => {
    if (!inputNationalCode) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        let stateV = stateNationalCode;
        let found;
        if (stateV && stateV.length) {
          found = stateV.find((element) => {
            return element.value == inputNationalCode;
          });
        }
        if (!found) {
          setInputNationalCode("");
          let stateB;
          if (stateV) {
            stateB = [...stateV, createOptionNationalCode(inputNationalCode)];
          } else {
            stateB = [createOptionNationalCode(inputNationalCode)];
          }
          setStateNationalCode(stateB);
        }
        event.preventDefault();
    }
  };

  //////////////////////////////////

  const [flag, setFlag] = React.useState("ALL");
  const [size, setSize] = React.useState(20);

  const onPageChangeFunction = (pageIndex) => {
    // 
    if (flag === "ALL") {
      getAllReports({ per_page: size, page: pageIndex + 1 });
    }
    if (flag === "FILTER") {
      getReportsFilter({ per_page: size, page: pageIndex + 1 }, typeEnterExit);
    }
  };
  const onPageSizeChangeFunction = (pageSize, pageIndex) => {
    // 
    setSize(pageSize);
    if (flag === "ALL") {
      getAllReports({ per_page: pageSize, page: pageIndex + 1 });
    }
    if (flag === "FILTER") {
      getReportsFilter({ per_page: pageSize, page: pageIndex + 1 }, typeEnterExit);
    }
  };

  ///////
  const [typeEnterExit, setTypeEnterExit] = React.useState("");
  let handleChangeType = (newValue, actionMeta) => {
    setTypeEnterExit(newValue);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 55,
      border: "1px solid black",
    }),
  };
  ////////

  const getFileExcell = () => {
    let arrayTypeCall = [];
    if (typeEnterExit) {
      if (typeEnterExit.length) {
        typeEnterExit.forEach((item) => {
          arrayTypeCall.push(item.value);
        });
      }
    }
    let mobileArray = [];
    if (stateMobile && stateMobile.length) {
      stateMobile.forEach((item) => {
        mobileArray.push(item.value);
      });
    }
    let nationalCodeArray = [];
    if (stateNationalCode && stateNationalCode.length) {
      stateNationalCode.forEach((item) => {
        nationalCodeArray.push(item.value);
      });
    }
    axios({
      url: ApiUrl + "/report-clients-excell",
      method: "post",
      data: {
        arrayTypeCall,
        mobileArray,
        nationalCodeArray,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
      responseType: "arraybuffer",
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.xlsx");
        link.click();
      })
      .catch(() => {
        toast.error("دانلود فایل با خطا مواجه شد", { position: toast.POSITION.TOP_CENTER });
      });
  };

  // const [number, setNumber]=useState(0)
  var number = 0;

  const classes = useStyles();
  if (loading) return "loading";

  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>گزارش مشتری ها - حامی چت</title>
      </Helmet>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
            </CardHeader>

            <Grid container spacing={3}>
              <Grid
                item
                xs={5}
                style={{
                  margin: "24px",
                  marginTop: "8px",
                  marginBottom: "0px",
                }}
              >
                <CreatableSelect
                  components={components}
                  inputValue={inputMobile}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={handleChangeMobile}
                  onInputChange={handleInputChangeMobile}
                  onKeyDown={handleKeyDownMobile}
                  placeholder="شماره تلفن کاربر"
                  styles={customStyles}
                  value={stateMobile}
                />
              </Grid>
              <Grid
                item
                xs={5}
                style={{
                  margin: "24px",
                  marginTop: "8px",
                  marginBottom: "0px",
                }}
              >
                <CreatableSelect
                  components={components}
                  inputValue={inputNationalCode}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={handleChangeNationalCode}
                  onInputChange={handleInputChangeNationalCode}
                  onKeyDown={handleKeyDownNationalCode}
                  styles={customStyles}
                  placeholder="کدملی کاربر"
                  value={stateNationalCode}
                />
              </Grid>

              <Grid item xs={5} style={{ marginBottom: "24px", marginRight: "24px" }}>
                <CreatableSelect
                  isMulti
                  closeMenuOnSelect={false}
                  isClearable
                  onChange={handleChangeType}
                  options={[
                    { value: "call", label: "برقراری تماس" },
                    { value: "noCall", label: "عدم برقراری تماس" },
                  ]}
                  styles={customStyles}
                  defaultValue={typeEnterExit}
                  placeholder="نوع گزارش تماس مشتری"
                />
              </Grid>
              <Grid item xs={5} style={{ marginBottom: "24px", marginRight: "48px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  name="buttonname"
                  style={{ height: 55 }}
                  onClick={() => {
                    // 
                    setFlag("FILTER");
                    getReportsFilter({ per_page: 20, page: 1 }, typeEnterExit);
                  }}
                >
                  اعمال
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  name="buttonname"
                  style={{ height: 55 }}
                  onClick={() => {
                    setFlag("ALL");
                    getAllReports();
                  }}
                >
                  دریافت همه گزارشات
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  name="buttonname"
                  style={{ height: 55 }}
                  onClick={() => {
                    getFileExcell();
                  }}
                >
                  دریافت فایل
                </Button>
              </Grid>
            </Grid>

            <CardBody>
              <ReactTable
                rtl
                getTableProps={() => ({
                  style: { display: "block", textAlign: "right" },
                })}
                data={props.clients ? props.clients : []}
                loading={props.loading}
                pagination={true}
                filterable
                manual
                columns={[
                  {
                    Header: "ردیف",
                    accessor: "_",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => {
                      number++;
                      return <div>{number}</div>;
                    },
                  },
                  {
                    Header: "کدملی مشتری",
                    accessor: "nationalCode",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "شماره تلفن",
                    accessor: "mobile",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "گزارش تماس",
                    accessor: "call",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => <div>{row.value ? "تماس برقرار شده است" : "عدم برقراری تماس"} </div>,
                  },
                  {
                    Header: "عملیات",
                    accessor: "_id",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => {
                      return (
                        <div>
                          <a href={"/admin/report-clients-enter-exit/" + row.value} rel="noopener noreferrer">
                            زمان های ورود و خروج
                          </a>
                        </div>
                      );
                    },
                  },
                  {
                    Header: "عملیات",
                    accessor: "_id",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => {
                      return (
                        <div>
                          <a href={"/admin/report-clients-calls/" + row.value} rel="noopener noreferrer">
                            گزارش تماس ها
                          </a>
                        </div>
                      );
                    },
                  },
                ]}
                pageSize={props.pagination && props.pagination.size}
                page={props.pagination && props.pagination.page}
                pages={props.pagination && props.pagination.total}
                onPageChange={(pageIndex) => {
                  // 
                  onPageChangeFunction(pageIndex);
                }}
                onPageSizeChange={(pageSize, pageIndex) => {
                  // 
                  onPageSizeChangeFunction(pageSize, pageIndex);
                }}
                onFilteredChange={(column, value) => {
                  // 
                }}
                className="-striped -highlight"
                showPaginationTop={false}
                showPaginationBottom={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => {
  // 
  return {
    pagination: state.reports_2.pagination,
    loading: state.reports_2.loading,
    error: state.reports_2.error,
    clients: state.reports_2.clients,
    operators: state.user.operators,
  };
};

const mapDispatchToProps = (dispatch) => {
  // 
  return {
    UserAction: bindActionCreators({ UserAction }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
