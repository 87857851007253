import React, { useEffect, useRef, useState } from "react";
import { ReactMediaRecorder } from "react-media-recorder";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import icon from "../../assets/img/messageBox/download (1).png";

const RecordView = ({ stream }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);
  if (!stream) {
    return null;
  }
  return <video ref={videoRef} width={200} height={200} autoPlay controls />;
};

const VideoPreview = (props) => {
  const [isRecording, setIsRecording] = useState(false);
  const [avatar, setAvatar] = useState(false);
  const [avatar1, setAvatar1] = useState(false);
  const [test, setTest] = useState("");
  const handleStartRecording = (startRecording) => {
    setIsRecording(true);
    startRecording();
    setAvatar(true);
    setAvatar1(true);
  };
  const handleStopRecording = (stopRecording, mediaBlobUrl) => {
    setIsRecording(false);
    stopRecording();
    setAvatar(true);
    props.setTitle(true);
    props.setSrc(mediaBlobUrl);
  };
  const TimeNotify = () => {
    toast.error("زمان ویدیو زیاد است،برای ادامه بر روی پایان ضبط کلیک نمایید", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  //   useEffect(() => {
  //     if (avatar1) {
  //       setTimeout(() => {
  //         setIsRecording(false);
  //         // <ReactMediaRecorder/>;
  //         setAvatar(true);
  //         props.setTitle(true);
  //         // alert("زمان ویدیو زیاد است");
  //         TimeNotify();
  //       }, 5000);
  //     }
  //   }, [avatar1]);
  return (
    <div>
      <ReactMediaRecorder
        video
        render={({ status, startRecording, stopRecording, mediaBlobUrl, previewStream }) => (
          <div className="VideoPreview">
            <div className="VideoPreview__btnBox">
              <button onClick={() => handleStartRecording(startRecording)}>شروع ضبط</button>
              <button onClick={() => handleStopRecording(stopRecording, mediaBlobUrl)}>پایان ضبط</button>
            </div>
            <div className="VideoPreview__videoBox">
              {isRecording && <RecordView stream={previewStream} />}
              {!isRecording && avatar && <video src={mediaBlobUrl} controls autoPlay loop />}
              {!isRecording && !avatar && <img src={icon} alt={icon} className="VideoPreview__avatar" />}
            </div>
            <ToastContainer />
          </div>
        )}
      />
    </div>
  );
};
export default VideoPreview;
