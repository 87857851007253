const prefix = "CUSTOMER/";

export const GET_CUSTOMER_REQUEST = prefix + "GET_CUSTOMER_REQUEST";
export const GET_CUSTOMER_SUCCESS = prefix + "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAILED = prefix + "GET_CUSTOMER_FAILED";
export const CREATE_CUSTOMER_REQUEST = prefix + "CREATE_CUSTOMER_REQUEST";
export const CREATE_CUSTOMER_SUCCESS = prefix + "CREATE_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_FAILED = prefix + "CREATE_CUSTOMER_FAILED";
export const GET_PROBLEMS_REQUEST = prefix + "GET_PROBLEMS_REQUEST";
export const GET_PROBLEMS1_REQUEST = prefix + "GET_PROBLEMS1_REQUEST";
export const GET_PROBLEMS_SUCCESS = prefix + "GET_PROBLEMS_SUCCESS";
export const GET_PROBLEMS1_SUCCESS = prefix + "GET_PROBLEMS1_SUCCESS";
export const GET_PROBLEMS_FAILED = prefix + "GET_PROBLEMS_FAILED";
export const GET_PROBLEMS1_FAILED = prefix + "GET_PROBLEMS1_FAILED";

export const EDIT_CUSTOMER_REQUEST = prefix + "EDIT_CUSTOMER_REQUEST";
export const EDIT_CUSTOMER_SUCCESS = prefix + "EDIT_CUSTOMER_SUCCESS";
export const EDIT_CUSTOMER_FAILED = prefix + "EDIT_CUSTOMER_FAILED";

export const GET_CUSTOMERS_REQUEST = prefix + "GET_CUSTOMERS_REQUEST";
export const GET_CUSTOMERS_SUCCESS = prefix + "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILED = prefix + "GET_CUSTOMERS_FAILED";

export const GET_CUSTOMER_PROBLEMS_REQUEST = prefix + "GET_CUSTOMER_PROBLEMS_REQUEST";
export const GET_CUSTOMER_PROBLEMS1_REQUEST = prefix + "GET_CUSTOMER_PROBLEMS1_REQUEST";
export const GET_CUSTOMER_PROBLEMS_SUCCESS = prefix + "GET_CUSTOMER_PROBLEMS_SUCCESS";
export const GET_CUSTOMER_PROBLEMS1_SUCCESS = prefix + "GET_CUSTOMER_PROBLEMS1_SUCCESS";
export const GET_CUSTOMER_PROBLEMS_FAILED = prefix + "GET_CUSTOMER_PROBLEMS_FAILED";
export const GET_CUSTOMER_PROBLEMS1_FAILED = prefix + "GET_CUSTOMER_PROBLEMS1_FAILED";
