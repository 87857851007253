import React, { useEffect, useState } from "react";
import CustomCard from "shared/CustomCard/CustomCard";
import FixedList from "shared/FixedList/FixedList";
import io from "socket.io-client";
import { Url } from "../../config";

const data = [
  {
    id: 1,
    nationalCode: "207852146",
    mobile: "09214568592",
    vip: "0",
  },
  {
    id: 2,
    nationalCode: "1285469632",
    mobile: "09134568657",
    vip: "0",
  },
  {
    id: 3,
    nationalCode: "9027245696",
    mobile: "09457221450",
    vip: "1",
  },
  {
    id: 4,
    nationalCode: "7034569674",
    mobile: "09137251492",
    vip: "0",
  },
  {
    id: 5,
    nationalCode: "9925467496",
    mobile: "09125487485",
    vip: "0",
  },
  {
    id: 6,
    nationalCode: "3125598532",
    mobile: "09137936545",
    vip: "1",
  },
  {
    id: 7,
    nationalCode: "7124598753",
    mobile: "09395492822",
    vip: "0",
  },
  {
    id: 8,
    nationalCode: "7798645214",
    mobile: "09224431779",
    vip: "0",
  },
];

// let socket = io.connect(Url);

// socket.on("connect", function () {
//   let menuList = [];
//   menus.forEach((item, index) => {
//     if (selectedMenu[index] == true) {
//       menuList.push(item._id);
//     }
//   });
//   //
//   setSocket(socket);
//   socket.emit("join", {
//     type: "operator",
//     _id: localStorage.getItem("_id"),
//     menuList: menuList,
//   });
// });
// socket.on("disconnect", function () {
//   setIsFree(false);
//   socketIo && socketIo.disconnect();
//   setStatus("off");
// });

// socket.on("checkAlive", () => {
//   socket.emit("stillAlive");
// });

const OnlineClients = (props) => {
  const [onlineUsers, setOnlineUsers] = useState([]);

  useEffect(() => {
    let socket = io.connect(Url);
    socket.on("online_users_list", (users) => {
      setOnlineUsers(users);
    });
    // return () => {

    // }
  }, []);

  return (
    <CustomCard style={{ backgroundColor: "#f50057" }} title={"مشتریان در صف"} type={"onlineClients"}>
      <FixedList clientData={onlineUsers} />
      {/* <FixedList clientData={null} /> */}
    </CustomCard>
  );
};

export default OnlineClients;
