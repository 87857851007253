import { Box, Typography } from "@mui/material";
import React from "react";
import saniarLogo from "../../assets/img/logo/saniarLogo.svg";
function Tickets(props) {
  return (
    <Box
      display={"flex"}
      padding={"16px"}
      flexDirection={"column"}
      width={"100%"}
      gap={"8px"}
      marginTop={"8px"}
      sx={{ backgroundColor: "var(--white)", borderRadius: "16px" }}
    >
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Box
          display={"flex"}
          padding={"4px 8px"}
          sx={{ borderRadius: "8px", backgroundColor: "var(--mbLightOrange2)" }}
        >
          <Typography color={"var(--mbOrange)"} fontSize={"12px"} fontWeight={400}>
            پاسخ داده شده
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={"8px"}>
          <Typography fontSize={"14px"} fontWeight={600}>
            سانیار
          </Typography>
          <img width={"16px"} height={"16px"} src={saniarLogo} alt="logo" />
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Box
          sx={{ borderRadius: "4px", backgroundColor: "var(--mbOrange)" }}
          padding={"4px 6px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography fontSize={"10px"} color={"var(--white)"}>
            آنی
          </Typography>
        </Box>
        <Typography fontSize={"12px"} color={"var(--mbGray)"}>
          چهارشنبه، 15 شهریور 1401
        </Typography>
      </Box>
      <Box>
        <Typography fontSize={"12px"} fontWeight={500} color={"var(--mbGray)"}>
          درخواست رول
        </Typography>
      </Box>
      <Box>
        <Typography fontSize={"10px"} color={"var(--mbGray)"}>
          سلام رول کاغذم تموم شده لطفا هرچه سریعتر برام ارسال کنید ممنون
        </Typography>
      </Box>
      <Box
        display={"flex"}
        gap={"10px"}
        flexDirection={"column"}
        padding={"8px"}
        sx={{ backgroundColor: "var(--mbBgMono)", borderRadius: "8px" }}
      >
        <Typography fontSize={"12px"}>چهارشنبه، 15 شهریور 1401</Typography>
        <Typography fontSize={"10px"}>
          با سلام و احترام درخواست شما به پشتیبان دستگاه تان جناب آقای مرتضی احمدی متصل گردید . تا 48 ساعت آینده درخواست
          شما رفع می گردد. باتشکر - پشتیبانی سانیار
        </Typography>
      </Box>
    </Box>
  );
}

export default Tickets;
