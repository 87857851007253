import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { SendRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  gridItem: {
    padding: "10px",
    borderRadius: "10px",
    height: "100px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "var(--white)",
    margin: "10px !important",
    border: "1px solid var(--lightGray)",
    cursor: "pointer",
    transition: ".2s",
    fontFamily: "IRANSans",
    "&:hover": {
      backgroundColor: "var(--white)",
      boxShadow: "0 0 20px rgba(0,0,0,.2)",
      transition: ".5s",
      transform: "scale(105%)",
    },
  },
});

function QueueCards({ name, img, onClick, colors = "white" }, props) {
  const classes = useStyles(colors);
  return (
    <Grid
      sx={{ backgroundColor: colors }}
      onClick={onClick}
      item
      xs={6}
      sm={4}
      md={3}
      lg={3}
      className={classes.gridItem}
    >
      <Box width={"50px"} height={"50px"} margin={"10px"}>
        <img src={img} alt="صف" width={"100%"} height={"100%"} style={{ objectFit: "contain" }} />
      </Box>
      <Box margin={"10px"} width={"80px"} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
        <Typography fontFamily={"IRANSans"}>{name}</Typography>
      </Box>
    </Grid>
  );
}

export default QueueCards;
