// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactTable from "react-table";
import { ApiUrl, Url } from "../../config";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function Users(props) {
  const [data, setData] = useState([]);
  const [loading, isLoading] = useState(false);

  const getUsers = () => {
    isLoading(true);

    axios
      .get(ApiUrl + "/get-avrs", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        //
        isLoading(false);
        setData(response.data.data.videos);
      })
      .catch(function (error) {
        isLoading(false);
        //
      })
      .finally(function () {
        isLoading(false);
      });
  };
  const changestatus = (id) => {
    isLoading(true);

    axios
      .get(ApiUrl + "/delete-avr/" + id, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        isLoading(false);

        getUsers();
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };

  useEffect(() => {
    getUsers();
  }, loading);

  const classes = useStyles();
  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>ویدئوها - حامی چت</title>
      </Helmet>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>React Table</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                rtl
                getTableProps={() => ({ style: { display: "block", textAlign: "right" } })}
                data={data ? data : []}
                loading={loading}
                filterable
                columns={[
                  {
                    Header: "",
                    accessor: "is_active",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div className="actions-right">
                        <video
                          controls
                          style={{ width: 150 }}
                          src={row.original.link ? row.original.link : Url + row.original.file}
                        />
                      </div>
                    ),
                  },

                  {
                    Header: "نوع فایل",
                    accessor: "type",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div className="actions-right">
                        {row.value === "holding"
                          ? "پخش هنگام نگه داشتن تماس"
                          : row.value == "waiting"
                          ? "پخش هنگام تماس"
                          : "پخش خارج از ساعت اداری"}
                      </div>
                    ),
                  },

                  {
                    Header: " صف",
                    accessor: "MenuItem",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => <div className="actions-right">{row.value && row.value.name}</div>,
                  },

                  {
                    Header: "وضعیت",
                    accessor: "is_active",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => <div className="actions-right">{row.value == true ? "فعال" : "غیر فعال"}</div>,
                  },

                  {
                    Header: "عملیات",
                    accessor: "_id",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div className="actions-right">
                        <button onClick={(e) => changestatus(row.value)} className={"status-btn active"}>
                          حذف
                        </button>
                      </div>
                    ),
                  },
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default Users;
