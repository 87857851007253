import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import backspace from "../../assets/img/icons/backspace.svg";
import { makeStyles } from "@material-ui/core/styles";
import mic from "../../assets/img/icons/mic.svg";
import TextField from "@material-ui/core/TextField";
import profile from "../../assets/img/faces/avatar.png";
import cameraPhoto from "../../assets/img/icons/cameraPhoto.svg";
import emoji from "../../assets/img/icons/imojie.svg";
import attach from "../../assets/img/icons/attach.svg";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { LinkMessage, Message, MessageDelivered, MessageList, MessageSended, Socketid } from "../../layouts/Recoil";
import { DoneAll, TimelapseOutlined, Videocam } from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import { Url } from "../../config";
import RecordVoice from "../../components/RecordVoice/RecordVoice";
import EmojiPicker, { Categories } from "emoji-picker-react";
import IsTyping from "../../components/IsTyping/IsTyping";
import micWhite from "../../assets/img/icons/micWhite.svg";
import sound from "../../assets/img/icons/sound.svg";
import switchCamera from "../../assets/img/icons/switchCamera.svg";
import end from "../../assets/img/icons/endCall.svg";
import camera from "../../assets/img/icons/camera.svg";
import useWindowSize from "../../Sizes/UseWindowSize";

const useStyles = makeStyles({
  container: {
    background: "linear-gradient(139deg, #FF9C67 0%, #FF751A 58.3%)",
    width: "100%",
    height: "100dvh",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  backspace: {
    backgroundColor: "rgba(255, 255, 255, 0.10)",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    padding: "8px",
    width: "40px",
    height: "40px",
    borderRadius: "8px",
  },
  chatContainer: {
    display: "flex",
    width: "100%",
    height: (props) => props.divHeight + "px",
    backgroundColor: "var(--mbBgMono)",
    flexDirection: "column",
    marginTop: "auto",
    borderRadius: "24px 24px 0 0",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    position: "relative",
    overflow: "hidden",
    gap: "24px",
    transition: ".5s",
    minHeight: "200px",
    maxHeight: "500px",
  },
  lineTop: {
    marginTop: "24px",
    backgroundColor: "var(--white)",
    borderRadius: "24px 24px 0 0",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
  },
  inputContainer: {
    display: "flex",
    gap: "8px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "var(--mbBgMono)",
    position: "relative",
  },
  inputText: {
    border: "none",
    outline: "none",
    width: "100%",
    fontSize: "12px",
  },
  operatorMessageContainer: {
    display: "flex",
    minWidth: "64px",
    maxWidth: "294px",
    padding: "8px 8px 4px 8px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    float: "left",
    gap: "4px",
    backgroundColor: "var(--white)",
    borderRadius: "16px 16px 16px 4px",
    overflow: "hidden",
    wordBreak: "break-word",
    textAlign: "right",
  },
  meMessageContainer: {
    display: "flex",
    minWidth: "64px",
    maxWidth: "294px",
    padding: "8px 8px 4px 8px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    float: "right",
    gap: "4px",
    backgroundColor: "var(--mbLightOrange2)",
    borderRadius: "16px 16px 4px 16px",
    overflow: "hidden",
    wordBreak: "break-word",
    textAlign: "right",
  },
  meVideo: {
    width: "96px",
    height: "144px",
    borderRadius: "20px",
    objectFit: "cover",
    position: "absolute",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.20), 0px 0px 2px 0px rgba(0, 0, 0, 0.60)",
    right: "24px",
    bottom: "300px",
  },
  otherVideo: {
    width: "100%",
    height: "100dvh",
    objectFit: "cover",
    position: "absolute",
    top: 0,
    zIndex: 0,
  },
});

function MobileCall({
  cancel,
  chats,
  otherTyping,
  onTypingMessage,
  uploadEvent,
  setUploadEvent,
  setFlagSend,
  localMedia,
  endCall,
  track,
  sendMessage,
}) {
  const windowSize = useWindowSize()
  const history = useHistory();
  const [messagList, setMessagList] = useRecoilState(MessageList);
  const [socktid, setSocktid] = useRecoilState(Socketid);
  const [linkMessag, setLinkMessag] = useRecoilState(LinkMessage);
  const [messageSended, setMessageSended] = useRecoilState(MessageSended);
  const [messageDelivered, setMessageDelivered] = useRecoilState(MessageDelivered);
  const [message, setMessageForm] = useRecoilState(Message);
  const [recorder, setRecorder] = useState(false);
  const [emojiShow, setEmojiShow] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState("");

  const [muted, setMuted] = useState(false);
  const [camOff, setCamOff] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const [newMessage, setNewMessage] = useState(0);
  const [editMessageInput, setEditMessageInput] = useState({
    message: "",
    msg_id: "",
    is_delete: false,
  });
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [divHeight, setDivHeight] = useState(200);
  const classes = useStyles({ newMessage, divHeight });
  const srcMe = useRef(null);
  const srcOther = useRef(null);
  const resizeRef = useRef();
  let nationalCode = localStorage.getItem("data_nationalcode");
  let mobile = localStorage.getItem("data_mobile");
  let id = localStorage.getItem("data_idClient");
  let name = localStorage.getItem("name");

  let startY = null;

  const handleTouchStart = (e) => {
    startY = e.touches[0].clientY;
  };

  const handleTouchMove = (e) => {
    if (startY === null) return;

    const deltaY = e.touches[0].clientY - startY;
    startY = e.touches[0].clientY;

    // تعیین جهت Swipe و تغییر ارتفاع div
    if (deltaY < 0) {
      // Swipe به سمت بالا
      setDivHeight(500);
    } else {
      // Swipe به سمت پایین
      setDivHeight(200);
      scrollDownChat();
    }
  };

  const handleTouchEnd = () => {
    startY = null;
  };
  const getTypeUrl = (url) => {
    return url.split("?")[0].split(".").pop();
  };
  const chatRef = useRef(null);
  const scrollDownChat = () => {
    if (chatRef && chatRef.current) {
      chatRef.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
  };
  useEffect(() => {
    scrollDownChat();
  }, [messagList]);
  function emojiHandler(emojiData, event) {
    setMessageForm((inputValue) => inputValue + (emojiData.isCustom ? emojiData.unified : emojiData.emoji));
    setSelectedEmoji(emojiData.unified);
  }

  const onKeyDown = (event) => {
    setEmojiShow(false);
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    } else if (event.key === "Enter" && event.shiftKey) {
    }
  };
  useEffect(() => {
    if (localMedia) localMedia.getAudioTracks()[0].enabled = !muted;
    if (localMedia) localMedia.getVideoTracks()[0].enabled = !camOff;
  }, [camOff, muted]);

  useEffect(() => {
    if (localMedia) return (srcMe.current.srcObject = localMedia);
  }, [localMedia]);
  useEffect(() => {
    if (track) {
      const stream = new MediaStream([track]);
      if (srcOther.current.srcObject) {
        srcOther.current.srcObject.addTrack(track);
      } else {
        srcOther.current.srcObject = stream;
        srcOther.current.play();
      }
    }
  }, [track,windowSize]);

  return (
    <div className={classes.container}>
      <video ref={srcOther} className={classes.otherVideo} autoPlay={true} playsInline={true} />
      <video ref={srcMe} className={classes.meVideo} muted={true} autoPlay={true} />
      <Box
        padding={"48px 24px 0 24px"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
        zIndex={1}
      >
        <Box className={classes.backspace}>
          <img src={backspace} alt="icon" style={{ width: "14px", height: "10px" }} onClick={cancel} />
        </Box>
        <Box width={"40px"} height={"40px"}></Box>
      </Box>
      <Box
        padding={"8px 24px 0 24px"}
        width={"100%"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        zIndex={1}
      >
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={"8px"}>
          <img
            style={{ width: "48px", height: "48px", borderRadius: "50%" }}
            src={chats ? (chats.operator ? chats.operator.avatar :Url+profile):Url+profile  }
          />
          <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography fontSize={"10px"} color={"var(--mbText)"}>
                کارشناس :
              </Typography>
              <Typography fontSize={"16px"} color={"var(--white)"}>
                {chats ? chats.operator.last_name : ""}
              </Typography>
            </Box>
            <Typography fontSize={"10px"} color={"var(--mbText)"}>
              آنلاین
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        borderRadius={"16px 16px 0px 0px"}
        sx={{ backgroundColor: "var(--mbBrown)" }}
        width={"100%"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"8px 40px 24px 40px"}
        position={"absolute"}
        bottom={"180px"}
      >
        <Box
          borderRadius={"50%"}
          sx={{ backgroundColor: "var(--mbError)" }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          padding={"16px"}
          gap={"8px"}
          onClick={endCall}
        >
          <img src={end} alt="" width={"32px"} height={"32px"} />
        </Box>
        <Box
          borderRadius={"50%"}
          sx={{ backgroundColor: "var(--mbDark)" }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          padding={"16px"}
          gap={"8px"}
          onClick={(e) => setCamOff(!camOff)}
        >
          <img src={camera} alt="" width={"32px"} height={"32px"} />
        </Box>
        <Box
          borderRadius={"50%"}
          sx={{ backgroundColor: "var(--mbDark)" }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          padding={"16px"}
          gap={"8px"}
          onClick={(e) => setMuted(!muted)}
        >
          <img src={micWhite} alt="" width={"32px"} height={"32px"} />
        </Box>
        <Box
          borderRadius={"50%"}
          sx={{ backgroundColor: "var(--mbDark)" }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          padding={"16px"}
          gap={"8px"}
        >
          <img src={switchCamera} alt="" width={"32px"} height={"32px"} />
        </Box>
      </Box>
      <div
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        className={classes.chatContainer}
        ref={resizeRef}
      >
        <Box
          sx={{
            backgroundColor: "var(--mbLineGray)",
            width: "106px",
            height: "6px",
            borderRadius: "12px",
            justifySelf: "flex-start",
            margin: "0 auto auto auto",
          }}
        />
        <Box width={"100%"} overflow={"auto"} display={"flex"} flexDirection={"column"} gap={"24px"} ref={chatRef}>
          {messagList.map((item, index) => {
            return (
              <Box width={"100%"} key={index}>
                <div className={item.type === "user" ? classes.meMessageContainer : classes.operatorMessageContainer}>
                  {item.file && getTypeUrl(item.message) === "webm" ? (
                    <Box>
                      <audio src={item.message} controls />
                    </Box>
                  ) : item.file && item.type !== "user" ? (
                    <a target="_blank" href={item.message} rel="noopener noreferrer">
                      جهت دانلود فایل کلیک کنید
                    </a>
                  ) : (
                    <Box>{item.message}</Box>
                  )}
                  <Box
                    display={"flex"}
                    justifyContent={item.type === "user" ? "space-between" : "flex-end"}
                    alignItems={"center"}
                    width={"100%"}
                  >
                    <Typography textAlign={"left"} fontSize={"8px"} color={"var(--mbGray2)"}>
                      {new Date(item.date).toLocaleTimeString("fa-IR", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </Typography>
                    {item.type === "user" && (
                      <DoneAll style={{ width: "12px", height: "12px", color: "var(--mbBlue)" }} />
                    )}
                  </Box>
                </div>
              </Box>
            );
          })}
          {otherTyping && (
            <Box width={"100%"}>
              <div style={{ float: "left" }}>
                <IsTyping />
              </div>
            </Box>
          )}
        </Box>
        <Box className={classes.inputContainer}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"8px"}
            width={"32px"}
            height={"32px"}
            padding={"4px"}
          >
            <RecordVoice
              sendMessage={sendMessage}
              setFlagSend={setFlagSend}
              setUploadEvent={setUploadEvent}
              uploadEvent={uploadEvent}
            />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            padding={"12px 14px 12px 12px"}
            alignItems={"center"}
            gap={"8px"}
            width={"100%"}
            sx={{
              backgroundColor: "var(--white)",
              borderRadius: "34px",
            }}
          >
            <Box width={"100%"}>
              <input
                type="text"
                className={classes.inputText}
                placeholder={"تایپ کنید"}
                value={message}
                onKeyDown={onKeyDown}
                onChange={(e) => {
                  setMessageForm(e.target.value);
                  onTypingMessage();
                  // sendMessageDelivery()
                }}
              />
            </Box>
            <Box width={"100%"} display={"flex"} gap={"8px"} justifyContent={"flex-end"} alignItems={"center"}>
              <Button variant={"text"} component={"label"} sx={{ width: "24px", height: "24px", minWidth: "24px" }}>
                <input
                  type="file"
                  hidden
                  accept=".jpg,.jpeg,.doc,.docx,.xls,.xlsx,.pdf"
                  onChange={(e) => {
                    setFlagSend(false);
                    setUploadEvent(e.target.files[0]);
                    setMessageForm(e.target.files[0].name);
                  }}
                />
                <img src={attach} alt="attach" width={"24px"} height={"24px"} />
              </Button>
              {/*<img src={cameraPhoto} alt="camera" width={"24px"} height={"24px"} />*/}
              <Box position={"relative"}>
                {emojiShow && (
                  <Box position={"absolute"} bottom={"50px"} right={"-300px"}>
                    <EmojiPicker
                      onEmojiClick={emojiHandler}
                      searchDisabled={true}
                      lazyLoadEmojis={true}
                      suggestedEmojisMode={"recent"}
                      categories={[
                        {
                          name: "Smiles & Emotions",
                          category: Categories.SMILEYS_PEOPLE,
                        },
                      ]}
                    />
                  </Box>
                )}
                <img src={emoji} alt="emoji" width={"24px"} height={"24px"} onClick={(e) => setEmojiShow(!emojiShow)} />
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default MobileCall;
