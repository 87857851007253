import React from "react";
import { Box, Typography } from "@mui/material";
import MobileQueueCard from "../../components/MobileQueueCard/MobileQueueCard";
import { Close } from "@material-ui/icons";
import { Url } from "../../config";
import { useHistory } from "react-router-dom";
import camera from "../../assets/img/icons/camera.svg";
import chatIcon from "../../assets/img/icons/chat.svg";

function MobileDirection({ setStep,chatPath="/client/chat",callPath="/client/call" ,menuId="",menuName=""}) {
  const history = useHistory();
  const nationalCode = localStorage.getItem("data_nationalcode");
  const mobile = localStorage.getItem("data_mobile");
  const idClient = localStorage.getItem("data_idClient")
  const id = localStorage.getItem("id");
  const name = localStorage.getItem("name");
  const makeChat = () => {
    history.push({
      pathname: chatPath,
      state: {
        nationalCode: localStorage.getItem("data_nationalcode"),
        menu: menuId ? menuId : id,
        name: menuName ? menuName : name,
        mobile:localStorage.getItem("data_mobile"),
        idClient
      },
    });
  };
  const makeCall = () => {
    history.push({
      pathname: callPath,
      state: {
        nationalCode : localStorage.getItem("data_nationalcode"),
        menu: menuId ? menuId : id,
        name: menuName ? menuName : name,
        mobile:localStorage.getItem("data_mobile"),
      },
    });
  };
  return (
    <Box
      display={"flex"}
      padding={"16px"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      flexDirection={"column"}
      backgroundColor={"var(--white)"}
      borderRadius={"16px"}
      gap={"24px"}
    >
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
        <Box
          padding={"8px"}
          borderRadius={"8px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ backgroundColor: "var(--mbLightGray)" }}
        >
          <Close style={{ width: "16px", height: "16px" }} onClick={(e) => setStep(1)} />
        </Box>
      </Box>
      <Box width={"100%"}>
        <Typography textAlign={"right"} fontSize={"16px"}>
          نوع ارتباط با پشتیبانی را انتخاب کنید
        </Typography>
      </Box>
      <Box display={"flex"} gap={"8px"} flexWrap={"wrap"} maxHeight={"450px"}>
        <MobileQueueCard title={"متنی"} icon={chatIcon} onClick={makeChat} />
        <MobileQueueCard title={"تصویری"} icon={camera} onClick={makeCall} />
      </Box>
    </Box>
  );
}

export default MobileDirection;