import { ApiUrl } from "../../config";
import {
  FIND_HISTORY_CLIENT_CALL_FAILED,
  FIND_HISTORY_CLIENT_CALL_REQUEST,
  FIND_HISTORY_CLIENT_CALL_SUCCESS,
  FIND_HISTORY_CLIENT_FAILED,
  FIND_HISTORY_CLIENT_REQUEST,
  FIND_HISTORY_CLIENT_SUCCESS,
} from "../type/user_type";
const axios = require("axios");

const GetHistoryClient = (id) => {

  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/find-client",
      method: "post",
      params: {},
      data: {
        id,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {

        dispatch(success(response));
      })
      .catch(function (error) {

        dispatch(failure(error));
      })
      .finally(function () {

      });

    function request() {
      return { type: FIND_HISTORY_CLIENT_REQUEST };
    }

    function success(result) {
      return { type: FIND_HISTORY_CLIENT_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: FIND_HISTORY_CLIENT_FAILED, payload: { error } };
    }
  };
};

const GetHistoryClientCall = (id) => {

  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/find-client-history-call",
      method: "post",
      params: {},
      data: {
        id,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {

        dispatch(success(response));
      })
      .catch(function (error) {

        dispatch(failure(error));
      })
      .finally(function () {

      });

    function request() {
      return { type: FIND_HISTORY_CLIENT_CALL_REQUEST };
    }

    function success(result) {
      return { type: FIND_HISTORY_CLIENT_CALL_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: FIND_HISTORY_CLIENT_CALL_FAILED, payload: { error } };
    }
  };
};

export default {
  GetHistoryClient,
  GetHistoryClientCall,
};
