import React, { useEffect, useRef, useState } from "react";
import {
  AttachFile,
  CallEndOutlined,
  ChatBubbleOutline,
  DeleteOutlined,
  Done,
  DoneAll,
  EditOutlined,
  MicOffOutlined,
  MicOutlined,
  MoreHorizOutlined,
  PauseOutlined,
  ScreenShareOutlined,
  Send,
  ShareOutlined,
  StopScreenShareOutlined,
  TimelapseOutlined,
  VideocamOffOutlined,
  VideocamOutlined,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@mui/material";
import { TabContext } from "@mui/lab";
import Box from "@material-ui/core/Box";

import TabPanel from "@mui/lab/TabPanel";

import TabList from "@mui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import { useRecoilState } from "recoil";
import { LinkMessage, Message, MessageDelivered, MessageList, MessageSended, Socketid } from "../../layouts/Recoil";
import Check from "@material-ui/icons/Check";
import TextField from "@material-ui/core/TextField";
import Close from "@material-ui/icons/Close";
import DetailCostomer from "../../components/Detail/DetailCostomer";
import DetailProblem from "../../components/Detail/DetailProblem";
import SweetAlert from "react-bootstrap-sweetalert";
import useWindowSize from "../../Sizes/UseWindowSize";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100vh",
    padding: "10px",
    direction: "rtl",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    gap: "10px",
  },
  chatContainer: {
    minHeight: "500px",
    overflow: "hidden",
    width: "400px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "20px",
    backgroundColor: "#d1d8e0",
  },
  chatHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    overflow: "hidden",
  },
  chatMessages: {
    width: "100%",
    height: "300px",
    maxHeight: "300px",
    display: "flex",
    alignItems: "flex-start",
    overflow: "hidden",
  },
  chatFooter: {
    width: "100%",
    height: "100px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },

  otherContainer: {
    minHeight: "300px",
    overflow: "hidden",
    position: "relative",
  },
  buttonsContainer: {
    width: "100%",
    padding: "10px",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    overflow: "hidden",
    position: "absolute",
    bottom: "10px",
  },
  otherIcons: {
    backgroundColor: "var(--swanWhite)",
    borderRadius: "50%",
    padding: "5px",
    fontSize: "35px",
    cursor: "pointer",
  },
  moreIcon: {
    padding: "5px",
    backgroundColor: "var(--black)",
    fontSize: "40px",
    width: "40px",
    color: "var(--white)",
    borderRadius: "50%",
    cursor: "pointer",
  },
  endIcon: {
    padding: "10px 15px",
    backgroundColor: "red",
    fontSize: "60px",
    color: "var(--white)",
    width: "70px",
    borderRadius: "20px",
    cursor: "pointer",
  },
  meVideo: {
    height: "100px",
    borderRadius: "20px",
    position: "absolute",
    top: "10px",
    right: "10px",
    objectFit: "fill",
    border: "2px solid var(--white)",
  },
  screenVideo: {
    height: "200px",
    borderRadius: "20px",
    position: "absolute",
    top: "10px",
    left: "10px",
    objectFit: "fill",
    border: "2px solid var(--white)",
  },
  otherVideo: {
    width: "100%",
    height: "100%",
    minHeight: "500px",
    borderRadius: "20px",
    position: "relative",
    objectFit: "fill",
  },
  popup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,.3)",
    position: "absolute",
    top: "0",
    marginLeft: "auto",
    marginRight: "auto",
  },
  textArea: {
    border: "none",
    resize: "none",
    width: "100%",
    direction: "rtl",
    textAlign: "right",
    padding: "10px",
  },
  input: {
    border: "1px solid var(--gray)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    borderRadius: "10px",
    margin: "0 10px",
    backgroundColor: "var(--white)",
    direction: "ltr",
  },
  icons: {
    margin: "0 5px",
    backgroundColor: "var(--darkGreen)",
    padding: "10px",
    borderRadius: "10px",
    fontSize: "40px",
    color: "var(--white)",
  },
  message: {
    backgroundColor: "var(--lightCeladon)",
    padding: "5px 10px",
    borderRadius: "10px",
    float: "right",
    margin: "5px",
    overflow: "hidden",
    wordBreak: "break-word",
    textAlign: "right",
    "&:before": {
      content: "",
      width: "0px",
      height: "0px",
      position: "absolute",
      borderLeft: "10px solid var(--white)",
      borderRight: "10px solid transparent",
      borderTop: "10px solid var(--white)",
      borderBottom: "10px solid transparent",
      right: "-19px",
      top: "0px",
    },
  },
  messageOperator: {
    backgroundColor: "var(--white)",
    padding: "5px 10px",
    borderRadius: "10px",
    float: "left",
    margin: "5px",
    "&:before": {
      content: "",
      width: "0px",
      height: "0px",
      position: "absolute",
      borderLeft: "10px solid transparent",
      borderRight: "10px solid var(--gray)",
      borderTop: "10px solid var(--gray)",
      borderBottom: "10px solid transparent",
      left: "-19px",
      top: "0px",
    },
  },
  tab: {
    width: "50px",
    minWidth: "unset",
  },
});

function VideoBoxCallingDesktop({
  isTyping,
  setIsTyping,
  otherTyping,
  onTypingMessage,
  uploadEvent,
  setUploadEvent,
  setFlagSend,
  localMedia,
  endCall,
  track,
  isShare,
  operator = false,
  children,
  sendMessage,
  shareScreen,
  local_screen_stream,
  setIsShare,
  roomId,
  stopDisplay,
  editMessage,
  setEditMessage,
  editMessageInput,
  setEditMessageInput,
  deleteMessage,
  setDeleteMessage,
  customer,
  confirmVip,
  userNationalCode,
  userMobile,
  loading,
  sbmitCustomerProblem,
  onKeyDown,

}) {
  const windowSize = useWindowSize()
  const [muted, setMuted] = useState(false);
  const [camOff, setCamOff] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const [message, setMessageForm] = useRecoilState(Message);
  const [messagList, setMessagList] = useRecoilState(MessageList);
  const [socktid, setSocktid] = useRecoilState(Socketid);
  const [linkMessag, setLinkMessag] = useRecoilState(LinkMessage);
  const [messageSended, setMessageSended] = useRecoilState(MessageSended);
  const [messageDelivered, setMessageDelivered] = useRecoilState(MessageDelivered);
  const [editOpen, setEditOpen] = useState(false);
  const [msgId, setMsgId] = useState("");
  const [msg, setMsg] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(null);

  const classes = useStyles();
  const srcMe = useRef();
  const srcScreen = useRef();
  const srcOther = useRef();
  const userId = localStorage.getItem("data_nationalcode");
  const operatorId = localStorage.getItem("_id");

  const checkOperatorUser = (item) => {
    if (userId && item.national_code === userId && !operator && item.type === "user") {
      return true;
    } else if (operatorId && item.operator_id === operatorId && operator && item.type !== "user") {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (localMedia) localMedia.getAudioTracks()[0].enabled = !muted;
    if (localMedia) localMedia.getVideoTracks()[0].enabled = !camOff;
  }, [camOff, muted]);

  const tabHandleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const deleteMessageHandler = (msg) => {
    const obj = {
      ...deleteOpen,
      is_delete: true,
    };
    setEditMessage(obj);
    setDeleteOpen(null);
  };
  const editMessageHandler = () => {
    const obj = {
      ...editMessageInput,
      msg_id: editMessageInput.msg_id,
      message: editMessageInput.message,
    };
    setEditMessage(obj);
    setEditOpen(false);
  };

  const chatRef = useRef(null);

  useEffect(() => {
    if (chatRef && chatRef.current) {
      chatRef.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
  }, [messagList]);
  const deliverHandler = (index, item) => {
    // eslint-disable-next-line no-prototype-builtins
    let deliveryIndex = messageDelivered.findIndex((value) => value.msg_id === item.msg_id);
    if (messageDelivered) {
      return <DoneAll className={classes.checkIcon} />;
      // eslint-disable-next-line no-prototype-builtins
    } else if (messageSended) {
      return <Check className={classes.checkIcon} />;
    } else {
      return <TimelapseOutlined className={classes.checkIcon} />;
    }
  };
  useEffect(() => {
    if (localMedia)(srcMe.current.srcObject = localMedia);
  }, [localMedia]);
  useEffect(() => {
    if (uploadEvent){
      setMessageForm(uploadEvent.name);
    }
  }, [uploadEvent]);
  useEffect(() => {
    if (local_screen_stream) {
      (srcScreen.current.srcObject = local_screen_stream);
    }
    if (track) {
      const stream = new MediaStream([track]);
      console.log(srcOther.current.srcObject)
      if (srcOther.current.srcObject) {
         srcOther.current.srcObject.addTrack(track);
      } else {
        srcOther.current.srcObject = stream;
        srcOther.current.play();
      }
    }
  }, [track,windowSize]);

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.chatContainer}>
          <div className={classes.chatHeader}>
            <TabContext value={tabValue}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "center",
                }}
                width={"100%"}
              >
                <TabList
                  variant={"fullWidth"}
                  onChange={tabHandleChange}
                  aria-label="lab API tabs example"
                  sx={{ overflow: "hidden", display: "flex", justifyContent: "center" }}
                >
                  <Tab
                    classes={{ root: classes.tab }}
                    style={{
                      margin: "0 5px",
                      color: "black",
                      borderRadius: "10px",
                    }}
                    label={"پیام"}
                    value={"1"}
                  />
                  {operator && (
                    <Tab
                      classes={{ root: classes.tab }}
                      style={{
                        margin: "0 5px",
                        color: "black",
                        borderRadius: "10px",
                      }}
                      label={"ثبت تماس"}
                      value={"2"}
                    />
                  )}
                  {operator && (
                    <Tab
                      classes={{ root: classes.tab }}
                      style={{
                        margin: "0 5px",
                        color: "black",
                        borderRadius: "10px",
                      }}
                      label={"مشترک"}
                      value={"3"}
                    />
                  )}
                </TabList>
              </Box>
            </TabContext>
          </div>
          <div className={classes.chatMessages}>
            <TabContext value={tabValue}>
              <TabPanel
                ref={chatRef}
                value={"1"}
                sx={{
                  width: "100%",
                  height: "300px",
                  display: "block",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {otherTyping && <p>در حال نوشتن...</p>}
                {messagList.length !== 0 &&
                  messagList.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        display: "inline-block",
                        verticalAlign: "top",
                        position: "relative",
                      }}
                    >
                      <div
                        className={checkOperatorUser(item) ? classes.message : classes.messageOperator}
                        style={{
                          float: checkOperatorUser(item) ? "right" : "left",
                          backgroundColor: !checkOperatorUser(item) ? "var(--white)" : "var(--lightCeladon)",
                        }}
                      >
                        {item.file ? (
                          <a target="_blank" href={item.message} rel="noopener noreferrer">
                            جهت دانلود فایل کلیک کنید
                          </a>
                        ) : (
                          <div>
                            <p>{item.message}</p>

                            {!editOpen && checkOperatorUser(item) && deliverHandler(index, item)}
                          </div>
                        )}
                        {operator && checkOperatorUser(item) && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >
                            {!editOpen && (
                              <EditOutlined
                                style={{
                                  color: "var(--green)",
                                  cursor: "pointer",
                                }}
                                fontSize={"small"}
                                onClick={(e) => {
                                  setEditOpen(true);
                                  setEditMessageInput({
                                    message: item.message,
                                    msg_id: item.msg_id,
                                  });
                                }}
                              />
                            )}

                            {!editOpen && (
                              <DeleteOutlined
                                fontSize={"small"}
                                style={{
                                  color: "var(--chiliRed)",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  setDeleteOpen(item);
                                }}
                              />
                            )}
                            {deleteOpen && (
                              <SweetAlert
                                title={"از حذف پیام اطمینان دارید؟"}
                                style={{ display: "block", marginTop: "-100px" }}
                                confirmBtnText={"بله"}
                                onConfirm={(e) => deleteMessageHandler(item)}
                                onCancel={(e) => setDeleteOpen(null)}
                                cancelBtnText={"خیر"}
                                showCancel
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </TabPanel>
              <TabPanel
                value={"2"}
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "block",
                }}
              >
                <DetailCostomer
                  customer={customer}
                  confirmVip={confirmVip}
                  userNationalCode={userNationalCode}
                  userMobile={userMobile}
                  loading={loading}
                />
              </TabPanel>
              <TabPanel
                value={"3"}
                sx={{
                  width: "100%",
                  display: "block",
                }}
              >
                <DetailProblem
                  sbmitCustomerProblem={sbmitCustomerProblem}
                  userNationalCode={userNationalCode}
                  userMobile={userMobile}
                />
              </TabPanel>
            </TabContext>
            {editOpen && (
              <SweetAlert
                style={{ display: "block", marginTop: "-100px" }}
                title="ویرایش پیام"
                confirmBtnText="تایید"
                onConfirm={(e) => editMessageHandler()}
                onCancel={(e) => setEditOpen(false)}
                cancelBtnText="انصراف"
                showCancel
              >
                <TextField
                  variant={"standard"}
                  value={editMessageInput.message}
                  onChange={(e) =>
                    setEditMessageInput((prev) => ({
                      ...prev,
                      message: e.target.value,
                    }))
                  }
                />
              </SweetAlert>
            )}
            {}
          </div>
          <div className={classes.chatFooter}>
            <div className={classes.input}>
              <Send className={classes.icons} onClick={sendMessage} style={{ transform: "rotate(180deg)" }} />
              <textarea
                onKeyDown={onKeyDown}
                rows={2}
                value={message}
                onChange={(e) => {
                  e.preventDefault();
                  setMessageForm(e.target.value);
                  onTypingMessage();
                }}
                placeholder={"پیام خود را وارد کنید..."}
                className={classes.textArea}
              ></textarea>
              <Button component={"label"} sx={{ backgroundColor: "transparent", marginRight: "5px" }}>
                <input
                  type="file"
                  hidden={true}
                  onChange={(e) => {
                    setFlagSend(false);
                    setUploadEvent(e.target.files[0]);
                  }}
                />
                <AttachFile className={classes.icons} />
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.otherContainer}>
          <video  ref={srcOther} className={classes.otherVideo} autoPlay={true} playsInline={true} />
          <video  ref={srcMe} className={classes.meVideo} autoPlay={true} playsInline={true} muted={true} />
          {/*{*/}
          {/*    local_screen_stream &&*/}
          {/*    <video ref={srcScreen} className={classes.screenVideo} autoPlay={true} playsInline={true}/>*/}
          {/*}*/}
          <div className={classes.buttonsContainer}>
            {/*{*/}
            {/*    !operator &&*/}
            {/*    <PauseOutlined className={classes.otherIcons}/>*/}
            {/*}*/}
            {muted ? (
              <MicOffOutlined
                className={classes.otherIcons}
                style={{ backgroundColor: "var(--chiliRed)" }}
                onClick={(e) => setMuted(!muted)}
              />
            ) : (
              <MicOutlined className={classes.otherIcons} onClick={(e) => setMuted(!muted)} />
            )}
            <CallEndOutlined className={classes.endIcon} onClick={endCall} />
            {camOff ? (
              <VideocamOffOutlined
                className={classes.otherIcons}
                style={{ backgroundColor: "var(--chiliRed)" }}
                onClick={(e) => setCamOff(!camOff)}
              />
            ) : (
              <VideocamOutlined className={classes.otherIcons} onClick={(e) => setCamOff(!camOff)} />
            )}

            {operator &&
              (!isShare ? (
                <ScreenShareOutlined className={classes.otherIcons} onClick={(e) => shareScreen(roomId)} />
              ) : (
                <StopScreenShareOutlined
                  className={classes.otherIcons}
                  style={{ backgroundColor: "var(--chiliRed)" }}
                  onClick={(e) => stopDisplay(local_screen_stream)}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoBoxCallingDesktop;
