// @material-ui/core components
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle, roseColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { ApiUrl, Url } from "config";
import moment from "moment-jalaali";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux/";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import UserAction from "../../js/actions/user_action";

const token = localStorage.getItem("token");

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem",
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999",
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
  h5: {
    color: "#999999",
  },
  button: {
    background: "#8e24aa",
    padding: "5px 10px",
    borderRadius: "5px",
    color: "white",
    border: "1px solid #8e24aa",
    cursor: "pointer",
    transform: "scale(90%)",
    transition: ".3s",
    fontSize: "1rem",
    "&:hover": {
      transform: "scale(100%)",
      transition: ".3s",
    },
    "&:active": {
      opacity: ".8",
      transition: ".3s",
    },
    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
  },
  cardRadioButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  commentInput: {
    padding: "5px 10px",
    border: "1px solid black",
    borderRadius: "5px",
    fontSize: "1rem",
    width: "100%",
  },
};

const useStyles = makeStyles(styles);

function Histories(props) {
  const [history, setHistory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [videoBox, setVideoBox] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [comment, setComment] = useState("");

  useEffect(() => {
    fetchMore();
  }, []);

  const fetchMore = async () => {
    try {
      setLoading(true);
      let id = props.match.params.id;
      const res = await axios.get(ApiUrl + `/get-history/${id}`, { headers: { Authorization: token } });

      setHistory(res.data.data.history);
      const isVideoBox = "videobox" in res.data.data.history;

      if (isVideoBox) {
        setVideoBox(res.data.data.history.videobox);
        setComment(res.data.data.history.videobox.comment);
        if (res.data.data.history.videobox.is_checked) {
          setIsChecked(true);
        } else {
          setIsChecked(false);
        }
      }
      setLoading(false);
    } catch (e) {
      toast.error(e.message);
    }
  };

  const classes = useStyles();
  if (loading) return "loading";

  const isCheckedHandler = async () => {
    try {
      setLoadingButton(true);
      const res = await axios.post(
        `${ApiUrl} /video-box-check`,
        {
          id: videoBox._id,
          check: isChecked,
        },
        { headers: { Authorization: token } }
      );
      setLoadingButton(false);
      res.status === 200 && toast.success("تغییر وضعیت با موفقیت انجام شد");
    } catch (e) {
      toast.error(e.message);
    }
  };
  const commentHandler = async () => {
    try {
      setLoadingButton(true);
      const res = await axios.post(
        `${ApiUrl}/video-box-comment`,
        {
          comment,
          id: videoBox._id,
        },
        { headers: { Authorization: token } }
      );
      setLoadingButton(false);
      res.status === 200 && toast.success("توضیحات با موفقیت ثبت شد");
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <div dir="rtl">
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 style={{ color: "#424242" }}>ویدئو های صندوق تصویری</h4>
            </CardHeader>
            <CardBody>
              {history &&
                history.videos.map((e, i) => {
                  return <video style={{ margin: 20 }} controls src={Url + `/videos/${history._id}/${e}`} key={i} />;
                })}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={6}>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              {history && history.operator ? <h4 style={{ color: "#424242" }}>مشخصات اپراتور</h4> : ""}
            </CardHeader>
            <CardBody>
              <div className={"history"}>
                {history && history.operator ? (
                  <>
                    <label>
                      <span> نام اپراتور:</span>

                      <span>{history && " " + history.operator.first_name + " " + history.operator.last_name}</span>
                    </label>
                    <br />
                    <label>
                      <span> ایمیل:</span>

                      <span>{history && " " + history.operator.email}</span>
                    </label>
                    <br />

                    <label>
                      <span> همراه:</span>

                      <span>{history && " " + history.operator.mobile}</span>
                    </label>
                    <br />

                    <label>
                      <span> کد ملی:</span>

                      <span>{history && " " + history.operator.national_code}</span>
                    </label>
                  </>
                ) : (
                  ""
                )}

                <br />

                <label>------------------------</label>
                <br />

                <label>
                  <span> کد ملی مشتری :</span>

                  <span>{history && " " + history.user_national_code}</span>
                </label>
                <label>
                  <span> همراه مشتری :</span>

                  <span>{history && history.mobile && " " + history.mobile}</span>
                </label>
                {/*<label>*/}
                {/*    <span> تاریخ پاسخ :</span>*/}

                {/*    <span>{history && history.answer && " " + moment(history.answer,"YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD HH:mm:ss")}</span>*/}
                {/*</label>*/}
                <label>
                  <span> کد رهگیری :</span>

                  <span>{history && history.tracking_number && " " + history.tracking_number}</span>
                </label>
              </div>
            </CardBody>
          </Card>
          {videoBox && (
            <Card>
              <CardHeader color={"primary"} icon>
                <CardIcon color={"primary"}>
                  <Assignment />
                </CardIcon>
                <h4>تغییر وضعیت</h4>
              </CardHeader>
              <CardBody>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="change-isChecked"
                    value={isChecked}
                    aria-checked={true}
                    name="is-checked-button"
                    onChange={(e, value) => {
                      value === "true" ? setIsChecked(true) : setIsChecked(false);
                    }}
                  >
                    <FormControlLabel value={false} control={<Radio />} label="بررسی نشده" />
                    <FormControlLabel value={true} control={<Radio />} label="بررسی شده" />
                  </RadioGroup>
                </FormControl>
                <CardFooter>
                  <button disabled={loadingButton} onClick={isCheckedHandler} className={classes.button}>
                    ثبت وضعیت
                  </button>
                </CardFooter>
              </CardBody>
            </Card>
          )}
        </GridItem>

        <GridItem xs={6}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 style={{ color: "#424242" }}>پیام ها </h4>
            </CardHeader>
            <CardBody>
              {history &&
                history.messages.map((e, i) => {
                  return (
                    <div id={"messages"} className={"messages"} key={i}>
                      <div className={e.type == "operator" ? "me" : "other"}>
                        {e.message}

                        <br />

                        <span
                          style={{
                            padding: 5,
                            margin: 5,
                            float: "left",
                            fontSize: 10,
                          }}
                        >
                          {moment(e.date, "YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD HH:mm:ss")}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </CardBody>
          </Card>
          {/* if video Box available*/}
          <>
            {videoBox && (
              <Card>
                <CardHeader color={"primary"} icon>
                  <CardIcon color={"primary"}>
                    <Assignment />
                  </CardIcon>
                  <h4>جزئیات تماس</h4>
                </CardHeader>
                <CardBody>
                  <h5 className={classes.h5}>
                    {" "}
                    تاریخ: {moment(videoBox.date, "YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD HH:mm:ss")}
                  </h5>
                  <h5 className={classes.h5}> وضعیت: {videoBox.is_checked ? "بررسی شد" : "بررسی نشده"}</h5>
                  <h5 className={classes.h5}> موبایل: {videoBox.mobile}</h5>
                </CardBody>
              </Card>
            )}
            {videoBox && (
              <Card>
                <CardHeader color={"primary"} icon>
                  <CardIcon color={"primary"}>
                    <Assignment />
                  </CardIcon>
                  <h4>توضیحات</h4>
                </CardHeader>
                <CardBody>
                  <Card>
                    <CardBody>
                      <textarea
                        className={classes.commentInput}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </CardBody>
                  </Card>
                  <CardFooter>
                    <button disabled={loadingButton} className={classes.button} onClick={commentHandler}>
                      ثبت توضیحات
                    </button>
                  </CardFooter>
                </CardBody>
              </Card>
            )}
          </>
        </GridItem>
      </GridContainer>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  history: state.user._history,
  loading: state.user.loading,
  error: state.user.error,
});

const mapDispatchToProps = (dispatch) => ({
  UserAction: bindActionCreators({ UserAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Histories);
