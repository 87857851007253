import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import Email from "@material-ui/icons/Email";
import Password from "@material-ui/icons/Lock";
// import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import LoginAction from "../../js/actions/user_action";
import groovyWalkAnimation from "../../assets/animation2.json";
import Lottie from "lottie-react";
import styles from "assets/css/Loginstyle.css";
import logoHamichat from "../../assets/img/login/hami.png";

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(stylesAlert);

function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 400);

  const [alert, setAlert] = useState(null);
  const classes = useStyles();
  const classesAlert = useStylesAlert();
  const loginOrRegister = (e) => {
    e.preventDefault();

    const fd = new FormData();
    fd.append("mobile", e.target[0].value);
    fd.append("password", e.target[1].value);
    return props.store.dispatch(LoginAction.Login(fd));
  };

  useEffect(() => {
    if (props.data) {
      if (props.data === "isLogin") {
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="یک نفر با مشخصات این فرد قبلا ورود کرده است"
            onConfirm={() => setAlert(false)}
            confirmBtnCssclassName={classesAlert.button + " " + classesAlert.danger}
            confirmBtnText="بازگشت"
          ></SweetAlert>
        );
      } else {
        setAlert(null);
        localStorage.setItem("token", "Token " + props.data.token);
        localStorage.setItem("_id", props.data._id);
        window.location.href = "/admin";
      }
    } else if (props.error) {
      setAlert(
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="خطای ورود"
          onConfirm={() => setAlert(false)}
          confirmBtnCssclassName={classesAlert.button + " " + classesAlert.danger}
          confirmBtnText="بازگشت"
        ></SweetAlert>
      );
    }
  }, [props.loading]);
  return (
    <div className="container" style={{ maxWidth: "2000px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100vh"
        viewBox="0 0 1924.099 1083.9"
        preserveAspectRatio={"none"}
      >
        <path
          className={"svg-path-background"}
          id="Path_1"
          data-name="Path 1"
          d="M0,1080S113.112,748.278,591.662,794.683s234.924-174.018,600.362-449.547S1484.955-2.9,1484.955-2.9H1922.9V1080Z"
          transform="translate(0.699 3.4)"
          fill="var(--blue)"
        />
      </svg>
      <div className={"admin-background-animation"}>
        <Lottie animationData={groovyWalkAnimation} />
      </div>
      {alert}
      <div className={"admin-form-container"}>
        <img src={logoHamichat} alt="logo" className={classes.img} style={{ width: "100px", borderRadius: "5px" }} />
        <form action="#" className="sign-in-form" onSubmit={(e) => loginOrRegister(e)}>
          <h3 className="title">حامی چت</h3>

          <div className={"input-container-admin"}>
            <input type={"tel"} className="input-field" id="mobile" placeholder={"تلفن همراه"} />
          </div>

          <div className={"input-container-admin"}>
            <input type={"password"} className="input-field" id="password" placeholder={"رمز عبور"} />
          </div>
          <button className="submit-admin" type="submit">
            ورود
          </button>
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.user.login,
  loading: state.user.loading,
  error: state.user.error,
});

const mapDispatchToProps = (dispatch) => ({
  LoginAction: bindActionCreators({ LoginAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
