import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import mobileLogo from "../../assets/img/icons/mobileLogo.svg";
import { Box, Typography } from "@mui/material";
import search from "../../assets/img/icons/search.svg";
import Slider from "react-slick";
import slide1 from "../../assets/img/slide1.png";
import MobileQueueCard from "../../components/MobileQueueCard/MobileQueueCard";
import { ApiUrl, Url } from "../../config";
import axios from "axios";
import { useHistory } from "react-router-dom";
import arrowLeft from "../../assets/img/icons/arrowLeft.svg";
import ActiveChats from "../../components/ActiveChats/ActiveChats";
import FAQ from "../../components/FAQ/FAQ";
import Tickets from "../../components/Tickets/Tickets";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    padding: "40px 16px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    overflow: "scroll",
    backgroundColor: "var(--mbBgMono)",
  },
  search: {
    border: "none",
    width: "100%",
    textAlign: "right",
  },
  dots: {
    backgroundColor: "red",
    width: "10px",
    height: "10px",
    borderRadius: "40%",
  },
  slider: {},
});

function MainPage(props) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(1);
  const [id, setId] = useState(1);
  const [nationalCode, setNationalCode] = useState("");
  const [mobile, setMobile] = useState();
  const history = useHistory();
  const [faqData, setFaqData] = useState([]);
  const getFaqs = async () => {
    await axios
      .get(ApiUrl + "/faqs", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        setFaqData(res.data.data.faqs);
      })
      .catch((res) => {});
  };
  useEffect(() => {
    getFaqs();
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true,
    arrows: false,
    appendDots: (dots) => <ul className={classes.dots}>{dots}</ul>,
    customPaging: (i) => (
      <div className="ft-slick__dots--custom">
        <div className="custom-dot" />
      </div>
    ),
  };
  const getUsers = () => {
    setLoading(true);

    axios
      .get(ApiUrl + "/menu-items?status=true", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        setLoading(false);

        setData(response.data.data.menu_items);
      })
      .catch(function (error) {
        setLoading(false);
      })
      .finally(function () {
        setLoading(false);
      });
  };
  useEffect(() => {
    getUsers();
  }, []);
  useEffect(() => {
    let userData = localStorage.getItem("data_nationalcode");
    let userDataMobile = localStorage.getItem("data_mobile");

    if (userData != null && userDataMobile != null) {
      setNationalCode(userData);
      setMobile(userDataMobile);
    } else {
      if (history.location.state == undefined) {
        window.location.href = "/";
        return null;
      }
      if (history.location.state.nationalCode === null || history.location.state.nationalCode === undefined) {
        window.location.href = "/";
      }
      localStorage.setItem("data_nationalcode", history.location.state.nationalCode);
      localStorage.setItem("data_mobile", history.location.state.mobile);
      setNationalCode(history.location.state.nationalCode);
      setMobile(history.location.state.mobile);
    }
  }, []);
  return (
    <div className={classes.container}>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={"2px"} alignSelf={"flex-end"}>
        <Typography fontSize={"14px"} color={"var(--mbOrange)"} fontWeight={700}>
          چت
        </Typography>
        <Typography fontSize={"14px"} fontWeight={700}>
          حامی
        </Typography>

        <img src={mobileLogo} alt="" />
      </Box>
      <Box
        sx={{ borderRadius: "24px", backgroundColor: "var(--white)" }}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        padding={"12px 8px"}
        justifyContent={"space-between"}
        marginTop={"20px"}
      >
        <Box
          width={"20px"}
          height={"20px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          padding={"3px"}
        >
          <img src={search} alt="" width={"14px"} height={"14px"} />
        </Box>
        <input type="text" className={classes.search} />
      </Box>
      <Box width={"100%"} height={"112px"} marginTop={"40px"}>
        <Slider {...settings}>
          <img width={"100%"} height={"112px"} src={slide1} style={{ borderRadius: "8px" }} alt="" />
          <img width={"100%"} height={"112px"} src={slide1} style={{ borderRadius: "8px" }} alt="" />
          <img width={"100%"} height={"112px"} src={slide1} style={{ borderRadius: "8px" }} alt="" />
        </Slider>
      </Box>
      <Box width={"100%"} marginTop={"50px"}>
        <Typography fontSize={"14px"} fontWeight={500} textAlign={"right"}>
          سرویس ها
        </Typography>
      </Box>
      <Box display={"flex"} gap={"8px"} flexWrap={"wrap"} maxHeight={"450px"} marginTop={"8px"}>
        {data.map((item, index) => {
          return (
            <MobileQueueCard
              title={item.name}
              key={index}
              icon={Url + item.icon}
              onClick={(e) => {
                setName(item.name);
                setId(item._id);
              }}
            />
          );
        })}
      </Box>
      <Box width={"100%"} marginTop={"50px"}>
        <Typography fontSize={"16px"} textAlign={"right"} fontWeight={500}>
          پشتیبانی های فعال
        </Typography>
      </Box>
      <Box marginTop={"24px"} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={"4px"}>
          <Box width={"20px"} height={"20px"}>
            <img src={arrowLeft} />
          </Box>
          <Typography fontSize={"12px"} color={"var(--mbOrange)"} fontWeight={500}>
            آرشیو تیکت ها
          </Typography>
        </Box>
        <Typography fontSize={"12px"} fontWeight={600}>
          تیکت فعال
        </Typography>
      </Box>
      <Tickets />
      <Box width={"100%"} marginTop={"50px"}>
        <Typography fontSize={"16px"} textAlign={"right"} fontWeight={500}>
          سوالات متداول
        </Typography>
      </Box>
      <FAQ answer={faqData.answer} question={faqData.question} menu={faqData.menu} admin={false}/>
    </div>
  );
}

export default MainPage;
