import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useForm } from "react-hook-form";
import { connect } from "react-redux/";
import StarRatingComponent from "react-star-rating-component";
import io from "socket.io-client";
// import $ from "jquery"
import { ApiUrl, Url } from "../../config";
import logo from "./files/logo.jpeg";
import { AttachFile, CallEnd, CloseOutlined, DoneAll, Send, SendRounded, TimelapseOutlined } from "@material-ui/icons";
import { Btn } from "../../components/share/Btn/Btn";
import { Box, Button } from "@mui/material";
import { TabContext } from "@mui/lab";
import TabList from "@mui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { useRecoilState } from "recoil";
import { LinkMessage, Message, MessageDelivered, MessageList, MessageSended, Socketid } from "../../layouts/Recoil";
import Check from "@material-ui/icons/Check";
import MobileChat from "../../components/MobileChat/MobileChat";
import MobileChatWaiting from "../../components/MobileChatWaiting/MobileChatWaiting";
import MobileChatBot from "../../components/MobileChatBot/MobileChatBot";
import {createCollectionIndexed,clearTransaction,createTransaction,deleteIndexedDb,readDataIndexedDb,updateIndexedDb} from 'indexedDB/clientDB'

require("./style.css");
const useStyles = makeStyles(styles);
const useStyles3 = makeStyles({
  container: {
    width: "100%",
    height: "100vh",
    direction: "rtl",
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
  },
  meContainer: {
    height: "150px",
    minHeight: "100px",
    padding: "10px",
    overflow: "hidden",
  },
  otherContainer: {
    height: "300px",
    minHeight: "300px",
    padding: "15px",
    overflow: "hidden",
  },
  buttonsContainer: {
    width: "100%",
    padding: "10px",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    overflow: "hidden",
  },
  otherIcons: {
    backgroundColor: "var(--swanWhite)",
    borderRadius: "50%",
    padding: "5px",
    fontSize: "35px",
    cursor: "pointer",
  },
  moreIcon: {
    padding: "5px",
    backgroundColor: "var(--black)",
    fontSize: "40px",
    width: "40px",
    color: "var(--white)",
    borderRadius: "50%",
    cursor: "pointer",
  },
  endIcon: {
    padding: "5px 15px",
    backgroundColor: "red",
    fontSize: "40px",
    color: "var(--white)",
    width: "70px",
    borderRadius: "20px",
    cursor: "pointer",
  },
  meVideo: {
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    objectFit: "fill",
  },
  otherVideo: {
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    objectFit: "fill",
  },
  popup: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,.3)",
    position: "absolute",
    top: "0",
    marginLeft: "auto",
    marginRight: "auto",
  },

  chatContainer: {
    minHeight: "500px",
    overflow: "hidden",
    width: "400px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "20px",
    backgroundColor: "#d1d8e0",
  },
  chatHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    overflow: "hidden",
    position: "relative",
  },
  chatMessages: {
    width: "100%",
    height: "300px",
    maxHeight: "300px",
    display: "flex",
    alignItems: "flex-start",
    overflow: "hidden",
  },
  chatFooter: {
    width: "100%",
    height: "100px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },

  screenVideo: {
    height: "200px",
    borderRadius: "20px",
    position: "absolute",
    top: "10px",
    left: "10px",
    objectFit: "fill",
    border: "2px solid var(--white)",
  },
  textArea: {
    border: "none",
    resize: "none",
    width: "100%",
    direction: "rtl",
    textAlign: "right",
    padding: "10px",
  },
  input: {
    border: "1px solid var(--gray)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    borderRadius: "10px",
    margin: "0 10px",
    backgroundColor: "var(--white)",
    direction: "ltr",
  },
  icons: {
    margin: "0 5px",
    backgroundColor: "var(--darkGreen)",
    padding: "10px",
    borderRadius: "10px",
    fontSize: "40px",
    color: "var(--white)",
  },
  message: {
    backgroundColor: "var(--lightCeladon)",
    padding: "5px 10px",
    borderRadius: "10px",
    float: "right",
    margin: "5px",
    overflow: "hidden",
    wordBreak: "break-word",
    textAlign: "right",
    "&:before": {
      content: "",
      width: "0px",
      height: "0px",
      position: "absolute",
      borderLeft: "10px solid var(--white)",
      borderRight: "10px solid transparent",
      borderTop: "10px solid var(--white)",
      borderBottom: "10px solid transparent",
      right: "-19px",
      top: "0px",
    },
  },
  messageOperator: {
    backgroundColor: "var(--white)",
    padding: "5px 10px",
    borderRadius: "10px",
    float: "left",
    margin: "5px",
    "&:before": {
      content: "",
      width: "0px",
      height: "0px",
      position: "absolute",
      borderLeft: "10px solid transparent",
      borderRight: "10px solid var(--gray)",
      borderTop: "10px solid var(--gray)",
      borderBottom: "10px solid transparent",
      left: "-19px",
      top: "0px",
    },
  },
  notify: (props) => ({
    position: "absolute",
    top: "-5px",
    right: "-5px",
    color: "var(--white)",
    backgroundColor: "var(--chiliRed)",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    animation: props.newMessage != 0 ? `$bounce2 1s infinite` : "unset",
  }),
  "@keyframes bounce2": {
    "0%, 20%, 50%, 80%, 100%": { transform: "translateY(0)" },
    "40%": { transform: " translateY(-10px)" },
    "60%": { transform: "translateY(-5px)" },
  },
});
const useStyles2 = makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "var(--white)",
    borderRadius: "10px",
    border: "1px solid var(--gray)",
    margin: "5px 0",
    direction: "ltr",
  },
  textArea: {
    borderRadius: "10px",
    padding: "10px",
    border: "none",
    backgroundColor: "transparent",
    resize: "none",
    width: "100%",
  },
  messages: {
    backgroundColor: "white !important",
    borderRadius: "10px !important",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
  },
  messageTextMe: {
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: "var(--green)",
    float: "right",
  },
  messageTextOther: {
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: "var(--lightGray)",
    float: "left",
  },
});

function Index(props) {
  useEffect(() => {
    createCollectionIndexed();
    readDataIndexedDb(setMessagList);
  }, []);
  useEffect(() => {
    clearTransaction();
  }, []);
  let TimeWaiting;
  const classes = useStyles();
  const chatClasses = useStyles2();
  const [alert, setAlert] = React.useState(null);

  let { nationalCode, mobile, menu, name, idClient } = props.location.state;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmittxt = (data) => {
    let file = new FormData();
    file.append("file", data.file[0]);
    setfullname(data.fullname);
    // setMessage(data.message);
    if (localStorage.getItem("data_nationalcode")) {
      axios({
        url: ApiUrl + "/text-box",
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
        data: {
          fullname,
          message,
          menu,
          nationalCode,
          mobile,
          idClient,
          file,
          date: moment().format("YYYY-MM-DDTHH:mm:ss.sTZD"),
          date_time: moment().format("HH:mm:ss.s"),
        },
      })
        .then(function (response) {
          //
          setAlertSend(true);
          setFlagTextBox(false);
          setOut(false);
          setBox(false);
        })
        .catch(function (error) {
          // setAlertSend("ناموفق")
          //
        })
        .finally(function () {});
    } else {
      window.location.href = `/client-bo/direction/${mobile}`;
    }
  };
  const [isFree, setIsFree] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [fullname, setfullname] = useState("");
  const [files, setFiles] = useState("");
  const [flagSend, setFlagSend] = useState(false);
  const [uploadEvent, setUploadEvent] = useState("");
  const [out, setOut] = useState(false);
  const [alertSend, setAlertSend] = useState(null);
  const [flagTextBox, setFlagTextBox] = useState(false);
  const [waitingTime, setWaitingTime] = useState(false);
  const [box, setBox] = useState(false);
  const [times, setTime] = useState({ start: 0, end: 0 });
  const [operator, setOperator] = useState("");
  const [socketIo, setSocket] = useState(null);
  const [userCount, setUserCount] = useState(0);
  const [isSent, setIsSent] = useState(false);
  const [isEnded, setEnded] = useState(false);
  const [connected, isConnected] = useState(false);
  const [star, setStar] = useState(5);
  const [loading, isLoading] = useState(false);
  const [isHold, setIdHold] = useState(false);
  const [video, setVideo] = useState("");

  // const [message, setMessage] = useState("");
  const [messageList, setMessagList] = useRecoilState(MessageList);
  const [socktid, setSocktid] = useRecoilState(Socketid);
  const [linkMessag, setLinkMessag] = useRecoilState(LinkMessage);
  const [isTyping, setIsTyping] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [otherTyping, setOtherTyping] = useState(false);
  const [messageSended, setMessageSended] = useRecoilState(MessageSended);
  const [messageDelivered, setMessageDelivered] = useRecoilState(MessageDelivered);
  const [textArea, setTextArea] = useState("");
  const [chats, setChats] = useState([]);
  const [message, setMessageForm] = useRecoilState(Message);
  const [qsOp, setQsOpr] = useState([]);

  //indexedDb
  
  //

  const ICE_SERVERS = [
    {
      urls: "turn:185.60.32.129:3478",
      credential: "HmCht_3432",
      username: "hamichat",
    },
  ];
  let local_media_stream = null;
  let peerConnection = null;
  let sharePeerConnection = null;
  let operator_id = 0;

  const classes3 = useStyles3();
  const userId = localStorage.getItem("data_nationalcode");
  const operatorId = localStorage.getItem("_id");
  const checkOperatorUser = (item) => {
    if ((userId && item.national_code === userId) || item.type === "user") {
      return true;
    } else if ((operatorId && item.operator_id === operatorId) || item.type !== "user") {
      return false;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (!isSent) {
      setIsSent(true);
      connection();
      getAvr();
    }
  }, null);

  const getAvr = () => {
    axios
      .get(ApiUrl + "/get-avr", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        isLoading(false);

        setVideo(response.data.data.video);
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };
  const init = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function (stream) {
        local_media_stream = stream;
        let self = document.getElementById("self");
        self.srcObject = stream;
        self.setAttribute("autoplay", "autoplay");
        self.setAttribute("playsinline", "playsinline");
      })
      .catch((e) => {
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="سیستم شما می بایست به دوربین مجهز باشد"
            onConfirm={() => confirm()}
            confirmBtnCssClass={classes.button + " " + classes.success}
            confirmBtnText="بازگشت"
          ></SweetAlert>
        );
      });
  };

  const confirm = () => {
    window.location.href = `/client-bo/direction/${mobile}`;
  };
  const submit = (e) => {
    e.preventDefault();

    var bodyFormData = new FormData(e.target);
    bodyFormData.append("customer", mobile);
    bodyFormData.append("operator", operator);
    bodyFormData.append("user_national_code", nationalCode);
    bodyFormData.append("menu", menu);
    bodyFormData.append("status", "chat");
    bodyFormData.append("date", moment().format("YYYY-MM-DDTHH:mm:ss.sTZD"));
    bodyFormData.append("date_time", moment().format("HH:mm:ss.s"));
    axios({
      url: ApiUrl + "/save-poll",
      method: "post",
      headers: {},
      data: bodyFormData,
    })
      .then(function (response) {})
      .catch(function (error) {})
      .finally(function () {
        window.location.href = `/client-bo/direction/${mobile}`;
      });
  };
  useEffect(() => {
    clearTransaction();
  }, []);
  useEffect(() => {
    socketIo && socketIo.emit("is_typing", isTyping);
  }, [isTyping]);

  useEffect(() => {
    socketIo &&
      socketIo.on("other_typing", (otherTyping) => {
        setOtherTyping(!!otherTyping);
      });
  }, [socketIo]);
  const connection = () => {
    if (!connected) {
      isConnected(true);
      let socket = io.connect(Url);
      setSocket(socket);
      let date_select_menu = localStorage.getItem("data_timeMenu");
      let time_select_menu = moment(date_select_menu, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss.s");
      let date_login = localStorage.getItem("data_timeLogin");
      let time_login = moment(date_login, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss.s");
      socket.on("connect", function () {
        socket.emit("joinChat", {
          type: "client",
          code: nationalCode,
          menu: menu,
          mobile,
          idClient,
          date_select_menu,
          time_select_menu,
          date_login,
          time_login,
          socket_id: socket.id,
        });
      });
      socket.on("out_of_wait", function () {
        localStorage.setItem("name", name);
        localStorage.setItem("idClient", idClient);
        localStorage.setItem("menu", menu);
        window.location.href = "/client/message";
      });
      socket.on("online_users", function (users, option) {
        if (option) {
          setWaitingTime(option.waiting);
          let waitingMili = moment(option.waiting, "HH:mm:ss").diff(moment().startOf("day"), "seconds") * 1000;
          TimeWaiting = setTimeout(() => {
            setBox(true);
            socket.disconnect();
          }, waitingMili);
        }
        let users_items = [];
        if (users) {
          for (var i = 0; i < users.length; i++) {
            if (users[i].socket !== socket.id) {
              users_items.push(users[i]);
            } else {
              break;
            }
          }

          setUserCount(users_items.length);
        }
      });
      socket.on("free", function (id, _id, id_call_history) {
        operator_id = id;
        setIsFree(true);
        setOperator(_id);
        clearTimeout(TimeWaiting);
        //
        let temp = [];

        if (localStorage.getItem("data_idCall") !== null) {
          //
          let local = JSON.parse(localStorage.getItem("data_idCall"));
          //
          //
          temp = local;
        }
        //
        temp.push(id_call_history);
        localStorage.setItem("data_idCall", JSON.stringify(temp));
      });

      socket.on("hold_call", function (status) {
        setIdHold(status);

        let video = document.getElementById("client");
        if (status) {
          video.setAttribute("style", "display:none");
        } else {
          video.setAttribute("style", "display:unset");
        }
      });
      socket.on("free_share", function (id) {
        socket.emit("watcher_screen", id);
      });
      socket.on("cancel_display", function () {
        sharePeerConnection && sharePeerConnection.close();
        sharePeerConnection = null;
        setIsShare(false);
      });

      socket.on("offer", function (id, description, _id) {
        setOperator(_id);

        peerConnection = new RTCPeerConnection({ iceServers: ICE_SERVERS });

        let stream = local_media_stream;
        if (stream) stream.getTracks().forEach((track) => peerConnection.addTrack(track, stream));
        peerConnection
          .setRemoteDescription(description)
          .then(() => peerConnection.createAnswer())
          .then((sdp) => peerConnection.setLocalDescription(sdp))
          .then(function () {
            socket.emit("answer", id, peerConnection.localDescription);
          });
        peerConnection.ontrack = async function (event) {
          let client = await document.getElementById("client");
          client.setAttribute("autoplay", "autoplay");
          client.setAttribute("playsinline", "playsinline");
          client.srcObject = await event.streams[0];
        };
        peerConnection.onicecandidate = function (event) {
          if (event.candidate) {
            socket.emit("candidate", id, event.candidate);
          }
        };
      });
      socket.on("offer_screen", function (id, description) {
        setIsShare(true);

        sharePeerConnection = new RTCPeerConnection({
          iceServers: ICE_SERVERS,
        });

        sharePeerConnection
          .setRemoteDescription(description)
          .then(() => sharePeerConnection.createAnswer())
          .then((sdp) => sharePeerConnection.setLocalDescription(sdp))
          .then(function () {
            socket.emit("answer_screen", id, sharePeerConnection.localDescription);
          });
        sharePeerConnection.ontrack = async function (event) {
          let client = await document.getElementById("client_screen");
          client.setAttribute("autoplay", "autoplay");
          client.setAttribute("playsinline", "playsinline");
          client.srcObject = await event.streams[0];
        };
        sharePeerConnection.onicecandidate = function (event) {
          if (event.candidate) {
            socket.emit("candidate_screen", id, event.candidate);
          }
        };
      });

      socket.on("candidate", function (id, candidate) {
        peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      });
      socket.on("candidate_screen", function (id, candidate) {
        sharePeerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      });
      socket.on("out_of_time", function (start, end, waiting) {
        setOut(true);
        setBox(true);
        setTime({
          start,
          end,
        });
      });
      socket.on("message_edit", (msg) => {
        if (msg.is_delete === true) {
          deleteIndexedDb(msg._id, setMessagList);
        }
        updateIndexedDb(msg, setMessagList);
      });

      socket.on("chat_message", function (msg) {
        createTransaction(msg);
        readDataIndexedDb(setMessagList);
        //                 let message = ` <div class="${msg.id == socket.id ? "me" : "other"}">${msg.message}</div>
        // `;
        //
        //                 var messages = document.getElementById("messages");
        //                 messages.innerHTML = messages.innerHTML + message;
        //                 messages.scrollTop = messages.scrollHeight;
      });
      socket.on("chat_message_file", function (msg) {
        const obj = {
          ...msg,
          file: true,
        };

        createTransaction(obj);
        readDataIndexedDb(setMessagList);
        //                 let message = ` <div class="${msg.id == socket.id ? "me" : "other"}">
        //
        //
        //
        //  <a   href="${msg.message}">جهت دانلود فایل کلیک کنید</a>
        //
        //
        //
        // </div>
        // `;
        //
        //                 var messages = document.getElementById("messages");
        //                 messages.innerHTML = messages.innerHTML + message;
        //                 messages.scrollTop = messages.scrollHeight;
      });

      socket.on("disconnect", function () {
        clearTransaction();
        setEnded(true);
      });
      socket.on("end_call", function () {
        clearTransaction();
        setEnded(true);
      });
      socket.on("active_chats", (msg) => {
        setChats(msg);
      });
      socket.on("online_operators", function (users) {
        //
        let items = [];
        console.log(users);
        //
        if (users) {
          let users_items = users.filter((e) => {
            if (e) {
              e.menu.map((i) => {
                if (!items[i]) items[i] = [];
                items[i].push(e);
              });
            }
          });

          setQsOpr(users);
        }
      });
    }
  };
  const cancel = () => {
    window.location.href = `/client-bo/direction/${mobile}/${nationalCode}`;
  };

  const endCall = () => {
    socketIo.disconnect();
  };

  const sendMessage = function () {
    if (flagSend === false) {
      setFlagSend(true);
      uploadFile();
    }

    // let message = document.getElementById("message");
    if (!message == "") {
      //
      let items = {
        message: message,
        id: socketIo.id,
        national_code: nationalCode,
        mobile: mobile,
        operator_id: operator_id,
      };
      socketIo.emit("chat_message", items);
      // message = "";
      setMessageForm("");
    }
  };
  const onKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    } else if (event.key === "Enter" && event.shiftKey) {
    }
  };
  const mailBox = () => {
    props.history.push({
      pathname: "/client/box",
      state: {
        nationalCode,
        menu,
        name,
        mobile,
        idClient,
      },
    });
  };
  const uploadFile = function (e) {
    let event = uploadEvent;

    let items = {
      message: " ... در حال ارسال فایل",
      id: socketIo.id,
    };

    //

    socketIo.emit("chat_message", items);
    var fd = new FormData();
    fd.append("file", event);

    axios({
      url: ApiUrl + "/upload-chat-file",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: fd,
    })
      .then(function (response) {
        let message = document.getElementById("selectFile");
        let message2 = document.getElementById("message");

        let items = {
          message: Url + response.data.data.path,
          id: socketIo.id,
        };

        socketIo.emit("chat_message_file", items);
        message.value = "";
        message2.value = "";
        setUploadEvent("");
      })
      .catch(function (error) {})
      .finally(function () {});
  };
  //
  const sendToTextBox = function (e) {
    e.preventDefault();
    // let file = new FormData();
    // file.append("file", files.files[0]);
    if (localStorage.getItem("data_nationalcode")) {
      axios({
        url: ApiUrl + "/text-box",
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
        data: {
          fullname,
          message,
          menu,
          nationalCode,
          mobile,
          idClient,
          // file,
          date: moment().format("YYYY-MM-DDTHH:mm:ss.sTZD"),
          date_time: moment().format("HH:mm:ss.s"),
        },
      })
        .then(function (response) {
          //
          setAlertSend(true);
          setFlagTextBox(false);
          setOut(false);
          setBox(false);
        })
        .catch(function (error) {
          // setAlertSend("ناموفق")
          //
        })
        .finally(function () {});
    } else {
      window.location.href = `/client-bo/direction/${mobile}`;
    }
  };
  const deliverHandler = (index, item) => {
    // eslint-disable-next-line no-prototype-builtins
    let deliveryIndex = messageDelivered.findIndex((value) => value.msg_id === item.msg_id);
    if (messageDelivered) {
      return <DoneAll className={classes.checkIcon} />;
      // eslint-disable-next-line no-prototype-builtins
    } else if (messageSended) {
      return <Check className={classes.checkIcon} />;
    } else {
      return <TimelapseOutlined className={classes.checkIcon} />;
    }
  };
  const onTypingMessage = () => {
    //
    if (timeoutId !== null) clearTimeout(timeoutId);
    if (!isTyping) setIsTyping(true);

    const timeout = setTimeout(() => {
      setIsTyping(false);
    }, 2000);

    setTimeoutId(timeout);
  };

  const renderCalling = () => {
    return (
      <MobileChatBot
        chats={chats}
        cancel={cancel}
        endCall={endCall}
        sendMessage={sendMessage}
        loading={loading}
        setFlagSend={setFlagSend}
        setUploadEvent={setUploadEvent}
        uploadEvent={uploadEvent}
        otherTyping={otherTyping}
        onTypingMessage={onTypingMessage}
      />
    );
  };

  const renderWaiting = () => {
    return (
      <MobileChatWaiting cancel={cancel} onlineOperators={qsOp} userCount={userCount} />
      // <div className={"waiting"}>
      //   <div className="header">
      //     <div className="logo"></div>
      //     <h3> {name === "کیپاد" ? "پی پاد" : name}</h3>
      //   </div>
      //   <div className="waiters">
      //     <b> {userCount} نفر در صف انتظار</b>
      //     <br />
      //     <span> زمان تقریبی انتظار {userCount * 2} دقیقه</span>
      //   </div>
      //   <div className="video-waitning">
      //     <video
      //       src={
      //         video.waiting && video.waiting.length > 0
      //           ? Url + video.waiting
      //           : "https://pep.arvanvod.ir/axXzYxdY2p/e70LK7LROr/origin_PgM2qetvmttUJyWeDeeG5pyw7rwC6f4XN4sSKUNG.mp4"
      //       }
      //       loop
      //       controls
      //       autoPlay
      //       playsInline
      //     />
      //   </div>
      //
      //   {/*<div className="icon-container">*/}
      //   {/*    <div className="wainting-icon-chat"></div>*/}
      //   {/*</div>*/}
      //   <h4>در حال برقراری ارتباط با اولین اپراتور...</h4>
      //
      //   <div className="cancel-footer-bottem">
      //     {/*<input onClick={(e) => cancel()} className={"cancel-call"} type={"button"}/>*/}
      //     <CallEnd className={"cancel-call"} onClick={(e) => cancel()} fontSize={"large"} />
      //   </div>
      //   {/*<div className="header">*/}
      //   {/*    <div className="logo"></div>*/}
      //   {/*    <h3> {name === "کیپاد" ? "پی پاد" : name}</h3>*/}
      //   {/*</div>*/}
      //   {/*<div className="waiters">*/}
      //   {/*    <b> {userCount} نفر در صف انتظار</b>*/}
      //   {/*    <br/>*/}
      //   {/*    <span> زمان تقریبی انتظار {userCount * 2} دقیقه</span>*/}
      //   {/*</div>*/}
      //   {/*{video.waiting != "" && (*/}
      //   {/*    <div className="video-waitning">*/}
      //   {/*        <video src={Url + video.waiting} loop controls autoPlay playsInline/>*/}
      //   {/*    </div>*/}
      //   {/*)}*/}
      //   {/*<div className="icon-container">*/}
      //   {/*    <div className="wainting-icon-chat"></div>*/}
      //   {/*</div>*/}
      //   {/*<h4>در حال برقراری ارتباط با اولین اپراتور...</h4>*/}
      //
      //   {/*<div className="cancel-footer-bottem">*/}
      //   {/*    <input onClick={(e) => cancel()} className={"cancel-call"} type={"button"}/>*/}
      //   {/*</div>*/}
      // </div>
    );
  };

  const renderEnd = () => {
    return (
      <div className={"ended"}>
        <div className={"welcome-input"}>
          <div className="header">
            <div className="logo"></div>
          </div>

          <h2>از تماس شما متشکریم</h2>
          <form onSubmit={(e) => submit(e)}>
            <h4 className="infos">لطفا با تکمیل نظر سنجی ما را در بهبود خدمات یاری کنید</h4>
            <h6>میزان رضایت شما از ارتباط</h6>

            <StarRatingComponent name="rate" value={star} onStarClick={(e) => setStar(e)} starCount={5} />
            <textarea placeholder="لطفا نظرات خود را با ما در میان بگذارید" className={"message_area"} name="message" />
            <br />
            <input className={"cancel"} value={"ثبت نظر و بازگشت"} type={"submit"} />
          </form>
        </div>
      </div>
    );
  };
  const renderOutOfTime = () => {
    return (
      <div className={"ended"}>
        <div className={"welcome-input"}>
          <img src={logo} />
          <h2>با تشکر از تماس شما</h2>
          <hr />
          <form onSubmit={(e) => submit(e)}>
            <h4>لطفا به این گفت و گو امتیاز دهید</h4>
            <StarRatingComponent name="rate" value={star} onStarClick={(e) => setStar(e)} starCount={5} />
            <h4>اگر نظری در مورد این گفتگو دارید بیان کنید.</h4>
            <textarea className={"message_area"} name="message" />
            <br />
            <input className={"cancel"} value={"ثبت نظر و بازگشت"} type={"submit"} />
          </form>
        </div>
      </div>
    );
  };

  //flagTextBox is for sandogh_matni
  if (box === true && flagTextBox === false) {
    return (
      <div dir="rtl">
        <div style={{ marginBottom: 50 }} className={"video-container"}>
          <div className={"welcome-input"}>
            <div className="header">
              <div className="logo"></div>
            </div>
            {video.outtime != "" && (
              <div className="video-waitning">
                <video src={Url + video.outtime} playsInline controls loop autoPlay />
              </div>
            )}
            <h2>با تشکر از تماس شما</h2>
            <hr />
            {out ? (
              <h4>
                ساعات کاری از {times.start} تا {times.end} می باشد.
              </h4>
            ) : (
              <h4> همکاران ما مشغول هستند لطفا پیام خود را بگذارید تا در اسرع وقت با شما تماس بگیریم</h4>
            )}
            <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
            <input
              onClick={(e) => {
                setFlagTextBox(true);
              }}
              className={"cancel"}
              style={{ width: "200px" }}
              value={"ایجاد پیام در صندوق متنی"}
              type={"button"}
            />
            <br />{" "}
            <input
              onClick={(e) => {
                mailBox();
              }}
              className={"cancel"}
              style={{ width: "200px" }}
              value={"ایجاد پیام در صندوق تصویری"}
              type={"button"}
            />
            <br />
          </div>
        </div>
      </div>
    );
  }

  if (flagTextBox === true) {
    return (
      // <div dir="rtl">
      //   <form
      //     onSubmit={handleSubmit(onSubmittxt)}
      //     style={{ marginBottom: 50 }}
      //     className={"video-container"}
      //   >
      //     <div className={"welcome-input"}>
      //       <div className="header">
      //         <div className="logo"></div>
      //       </div>
      //       <form className={classes.root} noValidate autoComplete="off">
      //         <label className="label">نام و نام خانوادگی</label>
      //         <input
      //           {...register("fullname")}
      //           className={"national-code-input"}
      //           placeholder={" نام خود را وارد کنید"}
      //           onFocus={(e) => (e.target.placeholder = "")}
      //           onBlur={(e) => (e.target.placeholder = "نام خود را وارد کنید")}
      //         />
      //         {/* <input
      //           value={fullname}
      //           onChange={(e) => setfullname(e.target.value)}
      //           placeholder={" نام خود را وارد کنید"}
      //           className={"national-code-input"}
      //         /> */}
      //         <label className="label">متن پیام</label>
      //         <input
      //           {...register("message")}
      //           className={"national-code-input"}
      //           placeholder={" پیام خود را وارد کنید"}
      //           onFocus={(e) => (e.target.placeholder = "")}
      //           onBlur={(e) => (e.target.placeholder = "پیام خود را وارد کنید")}
      //         />
      //         {/* <input
      //           value={message}
      //           onChange={(e) => setMessage(e.target.value)}
      //           placeholder={"پیام خود را وارد کنید"}
      //           className={"national-code-input"}
      //         /> */}
      //         <input {...register("file", { required: false })} />

      //         {/* <input
      //           type="file"
      //           // value={files}
      //           onChange={(e) => setFiles(e.target.files)}
      //         /> */}
      //         <br />
      //         <hr />

      //         <input
      //           // onClick={(e) => sendToTextBox(e)}
      //           className={"cancel"}
      //           value={"ارسال"}
      //           type={"submit"}
      //         />
      //         <input
      //           onClick={(e) => cancel()}
      //           className={"cancel"}
      //           value={"بازگشت"}
      //           type={"button"}
      //         />
      //       </form>
      //     </div>
      //   </form>
      // </div>
      <div dir="rtl">
        <div style={{ marginBottom: 50 }} className={"video-container"}>
          <div className={"welcome-input"}>
            <div className="header">
              <div className="logo"></div>
            </div>
            <form className={classes.root} noValidate autoComplete="off">
              <label className="label">نام و نام خانوادگی</label>
              <input
                value={fullname}
                onChange={(e) => setfullname(e.target.value)}
                placeholder={" نام خود را وارد کنید"}
                className={"national-code-input"}
              />
              <label className="label">متن پیام</label>
              <input
                value={message}
                onChange={(e) => setMessageForm(e.target.value)}
                placeholder={"پیام خود را وارد کنید"}
                className={"national-code-input"}
              />
              <br />
              <hr />

              <input onClick={(e) => sendToTextBox(e)} className={"cancel"} value={"ارسال"} type={"submit"} />
              <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
            </form>
          </div>
        </div>
      </div>
    );
  }

  if (alertSend === true) {
    return (
      <div dir="rtl">
        <div style={{ marginBottom: 50 }} className={"video-container"}>
          <div className={"welcome-input"}>
            <div className="header">
              <div className="logo"></div>
            </div>
            <h2>با تشکر از تماس شما</h2>
            <h2>تماس شما در صندوق متنی ذخیره شده به زودی با شما تماس خواهیم گرفت</h2>
            <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div dir="rtl" className="directionpage">
      {alert}

      <div style={{ marginBottom: 50 }} className={"video-container"}>
        {isEnded ? renderEnd() : isFree ? renderCalling() : renderWaiting()}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  // UserAction: bindActionCreators({UserAction}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
