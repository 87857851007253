// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment-jalaali";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { connect } from "react-redux/";
import ReactTable from "react-table";
import ClientAction from "../../js/actions/client_action";

// 

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function Histories(props) {
  const { loading } = props;

  useEffect(() => {
    let id = props.match.params.id;
    props.store.dispatch(ClientAction.GetHistoryClient(id));
  }, loading);

  const [dataTable, setDataTable] = React.useState([]);

  useEffect(() => {
    // 
    let client = props.client;
    let dateTableV = [];
    if (client) {
      let enterArray = client.enter;
      let exitArray = client.exit;
      enterArray.forEach((item, index) => {
        let objTemp = {};
        objTemp.enter = moment(item, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD HH:mm:ss");
        if (exitArray[index]) {
          objTemp.exit = moment(exitArray[index], "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD HH:mm:ss");
        }
        dateTableV.push(objTemp);
      });
      // 
      setDataTable(dateTableV);
    }
  }, props.client);

  const classes = useStyles();
  if (loading) return "loading";

  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>گزارش ورود و خروج مشتری ها - حامی چت</title>
      </Helmet>
      <GridContainer>
        <GridItem md={6} xs={12}>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>گزارش ورود و خروج</h4>
            </CardHeader>

            <CardBody>
              <ReactTable
                rtl
                getTableProps={() => ({ style: { display: "block", textAlign: "right" } })}
                data={dataTable}
                loading={props.loading}
                pagination={false}
                showPagination={false}
                filterable
                manual
                columns={[
                  {
                    Header: "زمان ورود",
                    accessor: "enter",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "زمان خروج",
                    accessor: "exit",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                className="-striped -highlight"
                showPaginationTop={false}
                showPaginationBottom={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = (state) => {
  // 
  return {
    loading: state.client.loading,
    error: state.client.error,
    client: state.client.client,
    operators: state.user.operators,
  };
};

export default connect(mapStateToProps)(Histories);
