import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@mui/material";
import backspace from "../../assets/img/icons/backspace.svg";
import { Line } from "rc-progress";
import "react-step-progress-bar/styles.css";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import waitingGif from "../../assets/img/icons/waitingGit.gif";
import axios from "axios";
import { ApiUrl } from "../../config";
import profile from "../../assets/img/faces/avatar.png";
import ellipse1 from "../../assets/img/icons/Ellipse 440.svg";
import ellipse2 from "../../assets/img/icons/Ellipse 441.svg";
import ellipse3 from "../../assets/img/icons/Ellipse 442.svg";
import ellipse4 from "../../assets/img/icons/Ellipse 443.svg";
import endCallIcon from "../../assets/img/icons/endCall.svg";

const useStyles = makeStyles({
  container: {
    background: "linear-gradient(139deg, #FF9C67 0%, #FF751A 58.3%)",
    width: "100%",
    height: "100dvh",
    display: "flex",
    flexDirection: "column",
    padding: "48px 24px",
  },
  backspace: {
    backgroundColor: "rgba(255, 255, 255, 0.10)",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    padding: "8px",
    width: "40px",
    height: "40px",
    borderRadius: "8px",
  },
  ellipse1: {
    animation: "$fade 2s infinite",
    opacity: "0.4",
  },
  ellipse2: {
    animation: "$fade 2s infinite",
    transform: "rotate(-45deg)",
    opacity: "0.4",
  },
  ellipse3: {
    animation: "$fade 3s infinite",
    opacity: "0.4",
  },
  ellipse4: {
    animation: "$fade 4s infinite",
    transform: "rotate(-45deg)",
    opacity: "0.4",
  },
  "@keyframes fade-in": {
    from: {
      opacity: 0.4,
    },
    to: {
      opacity: 1,
    },
  },
  "@keyframes fade-out": {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0.4,
    },
  },
  "@keyframes fade": {
    " 0%": {
      opacity: 0.4,
    },
    "50%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0.4,
    },
  },
});

function MobileCallWaiting({ cancel, endCall, chats }) {
  const classes = useStyles();
  const [operators, setOperators] = useState([]);

  const getOperators = async () => {
    await axios
      .get(ApiUrl + "/get-users", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        setOperators(res.data.data.users);
      })
      .catch((res) => {});
  };

  return (
    <div className={classes.container}>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
        <Box className={classes.backspace}>
          <img src={backspace} alt="icon" style={{ width: "14px", height: "10px" }} onClick={cancel} />
        </Box>
        <Box>
          <Typography fontSize={"12px"} color={"var(--white)"}>
            حامی چت
          </Typography>
          <Typography fontSize={"8px"} color={"var(--mbText)"}>
            1.0.1
          </Typography>
        </Box>
        <Box width={"40px"} height={"40px"}></Box>
      </Box>
      <Box marginTop={"32px"}>
        <Typography fontSize={"16px"} color={"var(--white)"} textAlign={"right"}>
          برقراری تماس تصویری
        </Typography>
        <Typography fontWeight={400} marginTop={"8px"} fontSize={"12px"} color={"var(--white)"} textAlign={"right"}>
          لطفا شکیبا باشید
        </Typography>
      </Box>
      <Box
        width={"260px"}
        height={"260px"}
        margin={"auto"}
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Box width={"122.565px"} height={"122.565px"} flex-shrink={0} position={"absolute"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="128"
            height="128"
            viewBox="0 0 128 128"
            fill="none"
            className={classes.ellipse1}
          >
            <path
              d="M125.283 64.0001C125.283 97.8455 97.8458 125.283 64.0003 125.283C30.1549 125.283 2.71777 97.8455 2.71777 64.0001C2.71777 30.1547 30.1549 2.71753 64.0003 2.71753C97.8458 2.71753 125.283 30.1547 125.283 64.0001Z"
              stroke="white"
              strokeWidth="4.46852"
              strokeLinecap="round"
              strokeDasharray="0.13 20.43"
            />
          </svg>
        </Box>
        <Box width={"142.993px"} height={"142.993px"} flex-shrink={0} position={"absolute"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="146"
            height="146"
            viewBox="0 0 146 146"
            fill="none"
            className={classes.ellipse2}
          >
            <path
              d="M123.556 22.4445C151.477 50.3655 151.477 95.6345 123.556 123.556C95.6347 151.477 50.3657 151.477 22.4447 123.556C-5.47636 95.6345 -5.47636 50.3655 22.4447 22.4445C50.3657 -5.47658 95.6347 -5.47658 123.556 22.4445Z"
              stroke="white"
              strokeWidth="3.83016"
              strokeMiterlimit="3.99933"
              strokeLinecap="round"
              strokeDasharray="0.13 21.7"
            />
          </svg>
        </Box>
        <Box width={"163.42px"} height={"163.42px"} flex-shrink={0} position={"absolute"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="168"
            height="168"
            viewBox="0 0 168 168"
            fill="none"
            className={classes.ellipse3}
          >
            <path
              d="M165.711 84.0001C165.711 129.127 129.128 165.71 84.0006 165.71C38.8734 165.71 2.29053 129.127 2.29053 84.0001C2.29053 38.8729 38.8734 2.29004 84.0006 2.29004C129.128 2.29004 165.711 38.8729 165.711 84.0001Z"
              stroke="white"
              strokeWidth="3.83016"
              strokeLinecap="round"
              strokeDasharray="0.13 25.53"
            />
          </svg>
        </Box>
        <Box width={"183.848px"} height={"183.848px"} flex-shrink={0} position={"absolute"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="186"
            height="186"
            viewBox="0 0 186 186"
            fill="none"
            className={classes.ellipse4}
          >
            <path
              d="M158 28C193.898 63.8985 193.898 122.101 158 158C122.101 193.898 63.8985 193.898 28 158C-7.89852 122.101 -7.89852 63.8985 28 28C63.8985 -7.89846 122.101 -7.89846 158 28Z"
              stroke="white"
              strokeWidth="3.83016"
              strokeLinecap="round"
              strokeDasharray="0.13 30.64"
            />
          </svg>
        </Box>
        <img width={"88px"} height={"88px"} style={{ borderRadius: "50%" }} src={profile} alt="profile" />
        <Box
          position={"absolute"}
          bottom={"-30px"}
          margin={"auto"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Typography color={"var(--white)"} fontSize={"12px"} fontWeight={400}>
            پشتیبان :
          </Typography>
          {chats && (
            <Typography color={"var(--white)"} fontSize={"24px"}>
              {chats[0]?.operator ? chats[0].operator.last_name : ""}
            </Typography>
          )}
        </Box>
      </Box>

      <Box
        margin={"auto"}
        display={"flex"}
        padding={"16px"}
        gap={"8px"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ backgroundColor: "var(--mbError)", borderRadius: "50%" }}
        onClick={(e) => endCall()}
      >
        <img width={"32px"} height={"32px"} src={endCallIcon} alt="end call" />
      </Box>
    </div>
  );
}

export default MobileCallWaiting;
