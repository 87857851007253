import React, { useState } from "react";
import { ClockLoader } from "react-spinners";
import { dangerColor } from "assets/jss/material-dashboard-pro-react";

function Loading({ loading }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        flexDirection: "column",
        direction: "rtl",
      }}
    >
      <ClockLoader
        loading={loading}
        cssOverride={{
          display: "block",
          margin: "0 auto",
          borderColor: dangerColor[0],
        }}
        color={"red"}
      />
      <h3>در حال دریافت اطلاعات...</h3>
    </div>
  );
}

export default Loading;
