import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import React, { useEffect } from "react";
import { connect } from "react-redux/";

require("./style.css");
const useStyles = makeStyles(styles);

function Index(props) {
  useEffect(() => {
    let data = props.match.params.data;
    if (data) {
      var buf = new Buffer(data, "base64").toString();
      let json = JSON.parse(buf);
      props.history.push({
        pathname: "/client/call",
        state: {
          nationalCode: json.nationalCode,
          menu: json.menu,
          name: json.name,
          mobile: json.mobile,
        },
      });
    }
  }, null);

  return (
    <div dir="rtl" className="directionpage">
      <div className={"video-container"}></div>
      <div dir="rtl">
        <div className={"video-container"}>
          <div className={"welcome-input"}>
            <div className="header">
              <div className="logo"></div>
            </div>

            <h2>لطفا منتظر بمانید</h2>
            <br />
            <h4>در حال انتقال به صف انتظار</h4>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  // UserAction: bindActionCreators({UserAction}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
