import FAQ from "../../components/FAQ/FAQ";
import { Box, TextField, Typography } from "@mui/material";
import axios from "axios";
import { Url } from "config";
import { ApiUrl } from "config";
import React, { useEffect, useState } from "react";
function AdminSlider(props) {
    const [faqData, setFaqData] = useState([]);
  const [menu, setMenu] = useState([]);
  const [queue, setQueue] = useState([]);
  const [word, setWord] = useState("");
  const getSlides = async () => {
    await axios
      .get(ApiUrl + "/blog", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        const filteredData = res.data.data.blog.filter((el) => {
          if (word === "") {
            return el;
          } else {
            return el.title.match(word) || el.text.match(word);
          }
        });

        setFaqData(filteredData);
      })
      .catch((res) => {});
  };
  const getQueues = async () => {
    await axios
      .get(ApiUrl + "/get-queues", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        res.data.data.forEach(async (item) => {
          await axios
            .get(ApiUrl + "/menu-slide/" + item._id, {
              headers: { Authorization: localStorage.getItem("token") },
            })
            .then((res) => {
              console.log(res.data.data);
              if (res.data.data.slider.length > 0) {
                let obj = {
                  menu: item._id,
                  name: item.name,
                  slides: res.data.data.slider,
                };
                setMenu((prev) => [...prev, obj]);
              }
            });
        });
        setQueue(res.data.data);
      })
      .catch((res) => {});
  };
  useEffect(() => {
    // getNews();
    getQueues();
  }, []);
  useEffect(() => {
    // getFaqs();
  }, [word]);
  return (
    <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"24px"} sx={{ direction: "ltr" }}>
      <Box width={"100%"}>
        <TextField
          variant="outlined"
          placeholder="جست و جو"
          onChange={(e) => setWord(e.target.value)}
          style={{ width: "100%" }}
        />
      </Box>
      <Box width={"100%"} padding={"16px"} maxHeight={"400px"} overflow={"auto"}>
        {menu.map((item) => {
          return (
            <Box>
              <Typography fontSize={"16px"} fontWeight={700} margin={"10px 0"}>
                {item.name}
              </Typography>
              <Box padding={"0 10px"}>
                {item.slides.map((p) => {
                  return <img src={Url+p.image} alt={p.title} width={"100px"} height={"100px"}/>;
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}


export default AdminSlider;