import { atom } from "recoil";

export const textState = atom({
  key: "textState", // unique ID (with respect to other atoms/selectors)
  default: 0,
});
export const Message = atom({
  key: "Message", // unique ID (with respect to other atoms/selectors)
  default: "",
});
export const MessageList = atom({
  key: "MessageList", // unique ID (with respect to other atoms/selectors)
  default: [],
});
export const Socketid = atom({
  key: "Socketid", // unique ID (with respect to other atoms/selectors)
  default: [],
});
export const LinkMessage = atom({
  key: "LinkMessage", // unique ID (with respect to other atoms/selectors)
  default: [],
});
export const textState1 = atom({
  key: "textState1", // unique ID (with respect to other atoms/selectors)
  default: "",
});
export const MessageSended = atom({
  key: "MessageSended",
  default: [],
});
export const MessageDelivered = atom({
  key: "MessageDelivered",
  default: [],
});
export const MsgId = atom({
  key: "MsgId",
  default: "",
});
export const SocketIo = atom({
  key: "SocketIo",
  default: null,
});

export const EditMessage = atom({
  key: "EditMessage",
  default: {},
});
