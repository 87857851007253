// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import stylesa from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Helmet } from "react-helmet";
import ReactTable from "react-table";
import { ApiUrl, ConfUrl } from "../../config";

const styles = {
  ...stylesa,
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function Users(props) {
  const [data, setData] = useState([]);
  const [dataid, setDataid] = useState([]);
  const [loading, isLoading] = useState(false);
  const [alert, setAlert] = React.useState(null);

  const confirm = (id) => {
    hideAlert();
    axios
      .get(ApiUrl + "/delete- confrences/" + id, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        getUsers();
      })
      .catch(function (error) {})
      .finally(function () {});
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const deleteUser = (id) => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="آیا حذف اتاق اطمینان دارید؟"
        onConfirm={() => confirm(id)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="حذف"
        cancelBtnText="لغو"
        showCancel
      ></SweetAlert>
    );
  };

  const getUsers = () => {
    isLoading(true);
    axios
      .get(ApiUrl + "/get-confrences", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        isLoading(false);

        setData(response.data.data.confrences);
        // setDataid(response.data.data. confrences);
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };
  const changestatus = (id, status) => {
    isLoading(true);

    axios
      .get(ApiUrl + "/change-menu-item/" + id + "?status=" + status, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        isLoading(false);

        getUsers();
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };

  useEffect(() => {
    getUsers();
  }, loading);
  const [loader, setLoader] = useState(false);

  const handleOpen = (rommid) => {
    setLoader(true);
    axios({
      url: ApiUrl + "/set-login-logout",
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
      data: {
        type: "login",
        date: moment().format("YYYY-MM-DDTHH:mm:ss.sTZD"),
        id: rommid,
      },
    })
      .then(function (response) {
        setLoader(false);
        window.location.href = `${ConfUrl}/${rommid}`;
      })
      .catch(function (error) {
        setLoader(false);
        window.location.href = "/admin";
      })
      .finally(function () {});
  };
  const classes = useStyles();
  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title> - حامی چت</title>
      </Helmet>
      {alert}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>React Table</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                rtl
                getTableProps={() => ({
                  style: { display: "block", textAlign: "right" },
                })}
                data={data ? data : []}
                loading={loading}
                filterable
                columns={[
                  {
                    Header: "نام اتاق",
                    accessor: "name",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "عملیات",
                    accessor: "_id",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div className="actions-right">
                        <a href={ConfUrl + row.value} rel="noopener noreferrer">
                          <button className={"status-btn active"}>ورود</button>
                        </a>

                        <a href={"javascript:void(0);"} onClick={(e) => deleteUser(row.value)}>
                          حذف{" "}
                        </a>
                      </div>
                    ),
                  },
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default Users;
