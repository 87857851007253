import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import backspace from "../../assets/img/icons/backspace.svg";
import { makeStyles } from "@material-ui/core/styles";
import camera from "../../assets/img/icons/camera.svg";
import mic from "../../assets/img/icons/mic.svg";
import TextField from "@material-ui/core/TextField";
import profile from "../../assets/img/faces/avatar.png";
import cameraPhoto from "../../assets/img/icons/cameraPhoto.svg";
import emoji from "../../assets/img/icons/imojie.svg";
import attach from "../../assets/img/icons/attach.svg";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { LinkMessage, Message, MessageDelivered, MessageList, MessageSended, Socketid } from "../../layouts/Recoil";
import { DoneAll, TimelapseOutlined } from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import { Url } from "../../config";
import RecordVoice from "../../components/RecordVoice/RecordVoice";
import EmojiPicker, { Categories } from "emoji-picker-react";
import IsTyping from "../../components/IsTyping/IsTyping";
import send from "../../assets/img/icons/send.svg";
import cameraOff from "../../assets/img/icons/cameraOff.svg";

const useStyles = makeStyles({
  container: {
    background: "linear-gradient(139deg, #FF9C67 0%, #FF751A 58.3%)",
    width: "100%",
    height: "100dvh",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  backspace: {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    padding: "8px",
    width: "40px",
    height: "40px",
    borderRadius: "8px",
  },
  chatContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: "var(--mbBgMono)",
    flexDirection: "column",
    borderRadius: "24px 24px 0 0",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    position: "relative",
    overflow: "hidden",
    gap: "24px",
  },
  lineTop: {
    marginTop: "24px",
    backgroundColor: "var(--white)",
    borderRadius: "24px 24px 0 0",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
  },
  inputContainer: {
    display: "flex",
    padding: "8px 16px 16px 16px",
    gap: "8px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "var(--mbBgMono)",
    position: "relative",
  },
  inputText: {
    border: "none",
    outline: "none",
    width: "100%",
    fontSize: "12px",
  },
  operatorMessageContainer: {
    display: "flex",
    minWidth: "64px",
    maxWidth: "294px",
    padding: "8px 8px 4px 8px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    float: "left",
    gap: "4px",
    backgroundColor: "var(--white)",
    borderRadius: "16px 16px 16px 4px",
    overflow: "hidden",
    wordBreak: "break-word",
    textAlign: "right",
  },
  meMessageContainer: {
    display: "flex",
    minWidth: "64px",
    maxWidth: "294px",
    padding: "8px 8px 4px 8px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    float: "right",
    gap: "4px",
    backgroundColor: "var(--mbLightOrange2)",
    borderRadius: "16px 16px 4px 16px",
    overflow: "hidden",
    wordBreak: "break-word",
    textAlign: "right",
  },
});

function MobileChatBot({
  chats,
  cancel,
  onTypingMessage,
  otherTyping,
  sendMessage,
  setFlagSend,
  setUploadEvent,
  uploadEvent,
  joinToCall,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [messagList, setMessagList] = useRecoilState(MessageList);
  const [socktid, setSocktid] = useRecoilState(Socketid);
  const [linkMessag, setLinkMessag] = useRecoilState(LinkMessage);
  const [messageSended, setMessageSended] = useRecoilState(MessageSended);
  const [messageDelivered, setMessageDelivered] = useRecoilState(MessageDelivered);
  const [message, setMessageForm] = useRecoilState(Message);
  const [recorder, setRecorder] = useState(false);
  const [emojiShow, setEmojiShow] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState("");

  let nationalCode = localStorage.getItem("data_nationalcode");
  let mobile = localStorage.getItem("data_mobile");
  let id = localStorage.getItem("data_idClient");
  let name = localStorage.getItem("name");

  const getTypeUrl = (url) => {
    return url.split("?")[0].split(".").pop();
  };
  const deliverHandler = (index, item) => {
    // eslint-disable-next-line no-prototype-builtins
    let deliveryIndex = messageDelivered.findIndex((value) => value.msg_id === item.msg_id);
    if (messageDelivered) {
      return <DoneAll className={classes.checkIcon} />;
      // eslint-disable-next-line no-prototype-builtins
    } else if (messageSended) {
      return <Check className={classes.checkIcon} />;
    } else {
      return <TimelapseOutlined className={classes.checkIcon} />;
    }
  };
  const chatRef = useRef(null);
  useEffect(() => {
    if (chatRef && chatRef.current) {
      chatRef.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
  }, [messagList]);

  function makeCall() {
    // joinToCall();
    // endCall();
    // history.push({
    //   pathname: "/client/call",
    //   state: {
    //     nationalCode,
    //     menu: id,
    //     name: name,
    //     mobile,
    //     activeChats:chats,
    //     chatToVideo:true
    //   },
    // });
  }

  function emojiHandler(emojiData, event) {
    setMessageForm((inputValue) => inputValue + (emojiData.isCustom ? emojiData.unified : emojiData.emoji));
    setSelectedEmoji(emojiData.unified);
  }

  const onKeyDown = (event) => {
    setEmojiShow(false);
    setFlagSend(true);
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    } else if (event.key === "Enter" && event.shiftKey) {
    }
  };

  return (
    <div className={classes.container}>
      <Box
        padding={"24px"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        <Box className={classes.backspace}  onClick={cancel}>
          <img src={backspace} alt="icon" style={{ width: "14px", height: "10px" }} />
        </Box>
        <Box display={"flex"} alignItems="center" gap="8px">
          <img
            style={{ width: "24px", height: "24px", borderRadius: "50%" }}
            src={chats && chats[0].operator.avatar ? Url + chats[0].operator.avatar : profile}
          />
          <Typography fontSize={"16px"} color={"var(--white)"}>
            {chats && chats[0].operator.last_name}
          </Typography>
        </Box>
        <Box width={"24px"} height={"24px"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <img src={cameraOff} alt="icon" width={"24px"} height={"24px"} />
        </Box>
        <Box width={"24px"} height={"24px"} display={"flex"} alignItems={"center"} justifyContent={"center"}></Box>
      </Box>
      <Box className={classes.chatContainer}>
        <Box
          sx={{
            backgroundColor: "var(--mbLineGray)",
            width: "106px",
            height: "6px",
            borderRadius: "12px",
            justifySelf: "flex-start",
            margin: "0 auto auto auto",
          }}
        />
        <Box width={"100%"} overflow={"auto"} display={"flex"} flexDirection={"column"} gap={"24px"} ref={chatRef}>
          {messagList.map((item, index) => {
            return (
              <Box width={"100%"} key={index}>
                <div className={item.type === "user" ? classes.meMessageContainer : classes.operatorMessageContainer}>
                  {item.file && getTypeUrl(item.message) === "webm" ? (
                    <Box>
                      <audio src={item.message} controls />
                    </Box>
                  ) : item.file && item.type !== "user" ? (
                    <a target="_blank" href={item.message} rel="noopener noreferrer">
                      جهت دانلود فایل کلیک کنید
                    </a>
                  ) : (
                    <Box>{item.message}</Box>
                  )}
                  <Box
                    display={"flex"}
                    justifyContent={item.type === "user" ? "space-between" : "flex-end"}
                    alignItems={"center"}
                    width={"100%"}
                  >
                    <Typography textAlign={"left"} fontSize={"8px"} color={"var(--mbGray2)"}>
                      {new Date(item.date).toLocaleTimeString("fa-IR", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </Typography>
                    {item.type === "user" && (
                      <DoneAll style={{ width: "12px", height: "12px", color: "var(--mbBlue)" }} />
                    )}
                  </Box>
                </div>
              </Box>
            );
          })}
          {otherTyping && (
            <Box width={"100%"}>
              <div style={{ float: "left" }}>
                <IsTyping />
              </div>
            </Box>
          )}
        </Box>
      </Box>

      <Box className={classes.inputContainer}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"8px"}
          width={"32px"}
          height={"32px"}
          padding={"4px"}
        >
          <RecordVoice
            sendMessage={sendMessage}
            setFlagSend={setFlagSend}
            setUploadEvent={setUploadEvent}
            uploadEvent={uploadEvent}
          />
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          padding={"12px 14px 12px 12px"}
          alignItems={"center"}
          gap={"8px"}
          width={"100%"}
          sx={{
            backgroundColor: "var(--white)",
            borderRadius: "34px",
          }}
        >
          <Box width={"100%"}>
            <input
              type="text"
              className={classes.inputText}
              placeholder={"تایپ کنید"}
              value={message}
              onKeyDown={onKeyDown}
              onClick={(e) => setEmojiShow(false)}
              onChange={(e) => {
                setMessageForm(e.target.value);
                onTypingMessage();
                // sendMessageDelivery()
              }}
            />
          </Box>
          <Box display={"flex"} gap={"8px"} justifyContent={"flex-end"} alignItems={"center"}>
            <Button variant={"text"} component={"label"} sx={{ width: "24px", height: "24px", minWidth: "24px" }}>
              <input
                type="file"
                hidden
                onChange={(e) => {
                  setFlagSend(false);
                  setUploadEvent(e.target.files[0]);
                  setMessageForm(e.target.files[0].name);
                }}
              />
              <img src={attach} alt="attach" width={"24px"} height={"24px"} />
            </Button>
            {/*<img src={cameraPhoto} alt="camera" width={"24px"} height={"24px"} />*/}
            <Box>
              {emojiShow && (
                <Box position={"absolute"} bottom={"65px"} left={0}>
                  <EmojiPicker
                    onEmojiClick={emojiHandler}
                    searchDisabled={true}
                    lazyLoadEmojis={true}
                    suggestedEmojisMode={"recent"}
                    width={"250px"}
                    height={"400px"}
                    categories={[
                      {
                        name: "Smiles & Emotions",
                        category: Categories.SMILEYS_PEOPLE,
                      },
                    ]}
                  />
                </Box>
              )}
              <img src={emoji} alt="emoji" width={"24px"} height={"24px"} onClick={(e) => setEmojiShow(!emojiShow)} />
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"8px"}
          width={"32px"}
          height={"32px"}
          padding={"4px"}
        >
          <img
            src={send}
            alt=""
            onClick={(e) => {
              setFlagSend(true);
              sendMessage();
              setEmojiShow(false);
            }}
            style={{ transform: "rotate(240deg)" }}
          />
        </Box>
      </Box>
    </div>
  );
}

export default MobileChatBot;
