import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Close } from "@material-ui/icons";
import MobileQueueCard from "../../components/MobileQueueCard/MobileQueueCard";
import axios from "axios";
import { ApiUrl, Url } from "../../config";
import { useHistory } from "react-router-dom";

function MobileQueue({setStep}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(1);
  const [id, setId] = useState(1);
  const [nationalCode, setNationalCode] = useState("");
  const [mobile, setMobile] = useState();
  const [alerts, setAlerts] = useState("");
  const [welcomeData, setWelcomeData] = useState("");
  const history = useHistory();
  const getUsers = () => {
    setLoading(true);

    axios
      .get(ApiUrl + "/menu-items?status=true", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        setLoading(false);

        setData(response.data.data.menu_items);
      })
      .catch(function (error) {
        setLoading(false);
      })
      .finally(function () {
        setLoading(false);
      });
  };
  useEffect(() => {
    getUsers();
  }, []);
  useEffect(() => {
    let userData = localStorage.getItem("data_nationalcode");
    let userDataMobile = localStorage.getItem("data_mobile");

    if (userData != null && userDataMobile != null) {
      setNationalCode(userData);
      setMobile(userDataMobile);
    } else {
      if (history.location.state == undefined) {
        window.location.href = "/";
        return null;
      }
      if (history.location.state.nationalCode === null || history.location.state.nationalCode === undefined) {
        window.location.href = "/";
      }
      localStorage.setItem("data_nationalcode", history.location.state.nationalCode);
      localStorage.setItem("data_mobile", history.location.state.mobile);
      setNationalCode(history.location.state.nationalCode);
      setMobile(history.location.state.mobile);
    }
  }, []);
  function makeCall() {
    setStep(2)
    // history.push({
    //   pathname: "/client/chat",
    //   state: {
    //     nationalCode,
    //     menu: id,
    //     name: name,
    //     mobile,
    //   },
    // });
  }
  useEffect(() => {
    if (name && id && mobile) {
      makeCall();
    }
  }, [name, id]);
  return (
    <Box
      display={"flex"}
      padding={"16px"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      flexDirection={"column"}
      backgroundColor={"var(--white)"}
      borderRadius={"16px"}
      gap={"24px"}
    >
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
        <Box
          padding={"8px"}
          borderRadius={"8px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ backgroundColor: "var(--mbLightGray)" }}
        >
          <Close style={{ width: "16px", height: "16px" }} onClick={(e) => history.replace("/client")} />
        </Box>
      </Box>
      <Box width={"100%"}>
        <Typography textAlign={"right"} fontSize={"16px"}>
          پشتیبانی محصول را انتخاب نمایید
        </Typography>
      </Box>
      <Box display={"flex"} gap={"8px"} flexWrap={"wrap"} maxHeight={"450px"}>
        {data.map((item, index) => {
          return (
            <MobileQueueCard
              title={item.name}
              key={index}
              icon={Url + item.icon}
              onClick={(e) => {
                window.localStorage.setItem("name",item.name)
                window.localStorage.setItem("id",item._id)
                setName(item.name);
                setId(item._id);
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default MobileQueue;
