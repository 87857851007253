import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import plus from "../../assets/img/icons/plus.svg";

import { TextField, makeStyles } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { EditOutlined } from "@material-ui/icons";
import CreatableSelect from "react-select/creatable";
import { ApiUrl } from "config";
import axios from "axios";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  faq: {
    animation: "$open 1s",
    transition: "1s",
    overflow: "hidden",
  },
  "@keyframes open": {
    from: { height: 0 },
    to: {
      height: "100%",
    },
  },
});

function FAQ({ admin = false, data ,setFaqData}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [edit, setEdit] = useState({
    _id: "",
    answer: "",
    question: "",
    menu: "",
    rate: 1,
    status: false,
  });
  const [queue, setQueue] = useState({});
  const [dataSelectQueue, setDataSelectQueue] = useState([]);
  const [alert, setAlert] = useState(null);
  const openHandle = () => {
    setShowAnswer(!showAnswer)
  };
  let handleChangeQueue = (newValue, actionMeta) => {
    setQueue(newValue);
    setEdit({ ...edit, menu: newValue.value });
  };
  const customStyles = {
    valueContainer: () => ({
      padding: 10,
      display: "flex",
      alignItems: "center",
    }),
    control: (base) => ({
      ...base,
      height: "100%",
      width: "100%",
      border: "1px solid black",
    }),
    menu: (base) => ({
      ...base,
      position: "relative",
    }),
  };
  const getQueues = async () => {
    await axios
      .get(ApiUrl + "/get-queues", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        const options = [];
        res.data.data.forEach((item) => {
          options.push({
            value: item._id,
            label: item.name === "کیپاد" ? "پی پاد" : item.name,
          });
        });
        setDataSelectQueue(options);
        const dataIndex = options.findIndex((x) => x.value === data.menu._id);
        setQueue(options[dataIndex]);
      })
      .catch((res) => {});
  };
  const updateFaq = async () => {
    await axios
      .post(ApiUrl + "/update-faq", edit, { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        toast.success("با موفقیت ویرایش شد")
        window.location.reload()
      })
      .catch((res) => {
        toast.error("خطا در ویرایش")
      });
  };
  useEffect(() => {
    getQueues();
    setEdit({
      _id: data._id,
      answer: data.answer,
      question: data.question,
      menu: data.menu,
      rate: data.rate,
      status: data.status,
    });
  }, []);

  const confirm = async () => {
    updateFaq();
    setOpen(false)
  };
  const editItem = (e) => {
    const { name, value } = e.target;
    setEdit({ ...edit, [name]: value });
  };
  const deleteHandler = async () => {
    await axios
      .delete(
        ApiUrl + "/faq/"+edit._id,
        { headers: { Authorization: localStorage.getItem("token") } }
      )
      .then((res) => {
        toast.success("با موفقیت حذف شد")
        window.location.reload()
      })
      .catch((res) => {
        toast.error("خطا در حذف")
      });
  };

  const editHandler = (e) => {
    setOpen(!open)
    
  };
  const showEditInput = ()=>{
    return (
      <SweetAlert
        style={{ display: "block" }}
        onConfirm={() => confirm()}
        confirmBtnText="تایید"
        cancelBtnText="انصراف"
        showCancel={true}
        onCancel={() => setOpen(false)}
      >
        <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"10px"} sx={{ direction: "rtl" }}>
          <TextField
            variant="standard"
            style={{ fontSize: "12px" }}
            name="question"
            placeholder="سوال"
            value={edit.question}
            onChange={editItem}
          />
          <textarea
            name="answer"
            value={edit.answer}
            placeholder="جواب"
            onChange={editItem}
            style={{ padding: "10px", outline: "none", resize: "none", borderRadius: "4px", fontSize: "12px" }}
          />
          <Box>
            <CreatableSelect
              closeMenuOnSelect={false}
              isClearable
              onChange={handleChangeQueue}
              options={dataSelectQueue}
              styles={customStyles}
              defaultValue={queue}
            />
          </Box>
          <TextField
            name="rate"
            type="number"
            variant="outlined"
            placeholder="اولویت"
            style={{ width: "100%" }}
            onChange={editItem}
            value={edit.rate}
            label="اولویت"
          />
          <Button color="error" onClick={deleteHandler}>
            حذف
          </Button>
        </Box>
      </SweetAlert>
    );
  }
  return (
    <Box width={"100%"} display={"inline-flex"} flexDirection={"column"} gap={"32px"} marginTop={"24px"}>
      {open && showEditInput()}
      <Box display={"flex"} flexDirection={"column"} gap={"10px"} onClick={openHandle}>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} padding={"12px 0"}>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <svg
              style={{ transform: showAnswer ? "rotate(-90deg)" : "unset", transition: ".5s" }}
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 13L1 7L7 1"
                stroke={open ? "var(--mbOrange)" : "#BBBBBB"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Box>
          <Box display={"flex"} gap={"8px"} justifyContent={"center"} alignItems={"center"}>
            <Typography fontSize={"14px"} fontWeight={"500"}>
              {edit.question}
            </Typography>
            {admin ? (
              <EditOutlined style={{ fill: "var(--mbOrange)" }} onClick={editHandler} />
            ) : (
              <img src={plus} alt="" width={"16px"} height={"16px"} />
            )}
          </Box>
        </Box>
        {showAnswer && (
          <Box className={classes.faq} padding={"10px"} sx={{ backgroundColor: "var(--white)", borderRadius: "8px" }}>
            <Typography>{edit.answer}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default FAQ;
