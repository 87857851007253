import Call from "views/Client/Call";
import CallMobile from "views/Client/Call-Mobile";
import CallBot from "views/Client/CallBot";
import CallBotSaniar from "views/Client/CallBotSaniar";
import CallBotAvand from "views/Client/CallBotAvand";
import Chat from "views/Client/Chat";
import ChatBot from "views/Client/ChatBot";
import ChatBotSaniar from "views/Client/ChatBotSaniar";
import ChatBotAvand from "views/Client/ChatBotAvand";
import Client from "views/Client/Client";
import ClientBot from "views/Client/ClientBot";
import Direction from "views/Client/direction";
import DirectionBot from "views/Client/directionBot";
import directionBotsaniar from "views/Client/directionBotsaniar";
import directionBotAvand from "views/Client/directionBotAvand";
import MailBox from "views/Client/mailbox";
import MailBoxBot from "views/Client/mailBoxBot";
import MessageBox from "views/Client/MessageBox";
import OutofTime from "views/Client/OutofTime";
import ReportClientCall from "views/Client/ReportClientCall";
import ClientCreateConfrence from "views/Confrence/ClientCreateConfrence";
import ClientRoomConfrence from "views/Confrence/ClientRoomConfrence";
import Ticket from "./layouts/Ticket";
import Terms from "./views/Client/Terms";
import ChatBotAvandIframe from "./views/Pages/ChatBotAvand-iframe";
import MainPage from "./views/Client/MainPage";
import CircularButton from "shared/Buttons/CircularButton/CircularButton";

var dashRoutes = [
  {
    path: "/welcome",
    component: Client,
    layout: "/client",
  },

  {
    path: "/box",
    component: MailBox,
    layout: "/client",
  },
  {
    path: "/message",
    component: OutofTime,
    layout: "/client",
  },
  {
    path: "/box-bot",
    component: MailBoxBot,
    layout: "/client-bot",
  },
  {
    path: "/terms",
    component: Terms,
    layout: "/client",
  },
  {
    path: "/direction",
    component: Direction,
    layout: "/client",
  },
  {
    path: "/ticket",
    component: Ticket,
    layout: "/client",
  },
  {
    path: "/call",
    component: Call,
    layout: "/client",
  },
  {
    path: "/chat",
    component: Chat,
    layout: "/client",
  },
  {
    path: "/call-mobile/:data",
    component: CallMobile,
    layout: "/client",
  },
  {
    path: "/hamichat",
    component: ClientBot,
    layout: "/client-bot",
  },
  {
    path: "/direction-bot",
    component: DirectionBot,
    layout: "/client-bot",
  },
  {
    path: "/direction/:mobileid/:nationalid",
    component: directionBotsaniar,
    layout: "/client-bo",
  },
  {
    path: "/direction-avand/:mobileid/:nationalid",
    component: directionBotAvand,
    layout: "/client-bo",
  },
  {
    path: "/clint/room/:id",
    component: ClientRoomConfrence,
    layout: "/api",
  },
  {
    path: "/api/client/conf/:uid",
    component: ClientCreateConfrence,
    layout: "/api",
  },
  {
    path: "/chat-bot",
    component: ChatBot,
    layout: "/client-bot",
  },
  {
    path: "/chat-bot-avand",
    component: ChatBotAvand,
    layout: "/client-bo",
  },
  {
    path: "/",
  },
  {
    path: "/chat-bot",
    component: ChatBotSaniar,
    layout: "/client-bo",
  },
  {
    path: "/call-bot",
    component: CallBot,
    layout: "/client-bot",
  },
  {
    path: "/call-bot-avand",
    component: CallBotAvand,
    layout: "/client-bo",
  },
  {
    path: "/call-bot",
    component: CallBotSaniar,
    layout: "/client-bo",
  },
  {
    path: "/message-box",
    component: MessageBox,
    layout: "/client-bot",
  },
  {
    path: "/callHistory",
    component: ReportClientCall,
    layout: "/client",
  },
  {
    path: "/chat-bot-avand-iframe",
    component: ChatBotAvandIframe,
    layout: "/client",
  },
  {
    path: "/main-page",
    component: MainPage,
    layout: "/client",
  },
  {
    path:"/circular",
    component:CircularButton,
    layout:"/client"
  }
];
export default dashRoutes;
