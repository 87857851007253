import { Avatar, Grid } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { grayColor, infoColor } from "assets/jss/material-dashboard-pro-react";
import React from "react";
import vipIcon from "../../assets/img/pic/vip.jpeg";

const useStyles = makeStyles((theme) => ({
  root: {
    direction: "rtl",
    width: "100%",
    // maxWidth: 360,
    height: "220px",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const FixedList = ({ operatorData = null, clientData = null }) => {
  const classes = useStyles();
  try {
    return (
      <div className={classes.root}>
        <List component="nav" aria-label="main mailbox folders" style={{ direction: "rtl", borderRadius: "8px" }}>
          {operatorData
            ? operatorData.map((person) => {
                return (
                  <ListItem key={person.id} button style={{ direction: "rtl", overflow: "auto" }}>
                    <Grid container>
                      <Grid item xs={1}>
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                      </Grid>
                      <Grid item xs={3}>
                        <ListItemText
                          style={{ textAlign: "center" }}
                          primary={person.first_name + ` ` + person.last_name}
                        />
                      </Grid>
                      <Grid item xs={8} style={{ display: "block" }}>
                        {person.menu_list.map((queue) => {
                          return (
                            <Chip
                              key={queue["_id"]}
                              style={{
                                margin: "0 1px",
                                color: infoColor[0],
                                borderColor: infoColor[0],
                              }}
                              // key={queue.id}
                              label={queue.name}
                              color="secondary"
                              variant="outlined"
                            />
                          );
                        })}
                        <ListItemText style={{ textAlign: "center" }} primary={person.nationalCode} />
                      </Grid>
                    </Grid>
                  </ListItem>
                  // <Divider />
                );
              })
            : clientData.map((person) => {
                return (
                  <ListItem key={person.id} button style={{ direction: "rtl" }}>
                    <Grid container>
                      <Grid item xs={1}>
                        <ListItemIcon>
                          <PersonOutlineIcon />
                        </ListItemIcon>
                      </Grid>
                      <Grid item xs={5}>
                        <ListItemText style={{ textAlign: "center" }} primary={person.mobile} />
                      </Grid>
                      <Grid item xs={4}>
                        {person.type == "chat" ? (
                          <ListItemText style={{ textAlign: "center" }} primary={"متنی"} />
                        ) : (
                          <ListItemText style={{ textAlign: "center" }} primary={"تصویری"} />
                        )}
                      </Grid>
                      <Grid item xs={2} style={{ overflow: "auto" }}>
                        {person.vip != 0 ? (
                          <Avatar style={{ height: "20px" }} src={vipIcon} />
                        ) : person.menu_name ? (
                          <Chip
                            style={{
                              margin: "0 1px",
                              color: infoColor[0],
                              borderColor: infoColor[0],
                            }}
                            label={person.menu_name}
                            color="secondary"
                            variant="outlined"
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  </ListItem>
                  // <Divider />
                );
              })}
        </List>
      </div>
    );
  } catch (error) {
    return (
      <div
        className={classes.root}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "10px",
          color: grayColor[9],
        }}
      >
        <p>با عرض پوزش، خطایی از سمت سرور رخ داده است</p>
      </div>
    );
  }
};

export default FixedList;

//<ListItemText style={{textAlign: 'center'}}  primary={"VIP"} />
