import { ApiUrl } from "../../config";
import { API_URL } from "../../contacs";
import {
  CREATE_CUSTOMER_FAILED,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_FAILED,
  EDIT_CUSTOMER_REQUEST,
  EDIT_CUSTOMER_SUCCESS,
  GET_CUSTOMERS_FAILED,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER_FAILED,
  GET_CUSTOMER_PROBLEMS_FAILED,
  GET_CUSTOMER_PROBLEMS_REQUEST,
  GET_CUSTOMER_PROBLEMS_SUCCESS,
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_SUCCESS,
  GET_PROBLEMS1_FAILED,
  GET_PROBLEMS1_REQUEST,
  GET_PROBLEMS1_SUCCESS,
  GET_PROBLEMS_FAILED,
  GET_PROBLEMS_REQUEST,
  GET_PROBLEMS_SUCCESS,
} from "../type/customer_type";

const axios = require("axios");

const getProblems = (pagination) => {
  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + `/get-problems?size=${pagination.per_page}&page=${pagination.page}`,
      method: "get",
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",

        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        dispatch(success(response));
      })
      .catch(function (error) {
        dispatch(failure(error));
      })
      .finally(function () {

      });

    function request() {
      return { type: GET_PROBLEMS_REQUEST };
    }

    function success(result) {
      return { type: GET_PROBLEMS_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: GET_PROBLEMS_FAILED, payload: { error } };
    }
  };
};
const getProblemslogic = (data) => {
  return (dispatch) => {
    dispatch(request());
    return axios({
      url: `${API_URL}/v1/ticket-hamichat/admin`,
      method: "get",
      headers: {

        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        dispatch(success(response));
      })
      .catch(function (error) {
        dispatch(failure(error));
      })
      .finally(function () {

      });

    function request() {
      return { type: GET_PROBLEMS1_REQUEST };
    }

    function success(result) {
      return { type: GET_PROBLEMS1_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: GET_PROBLEMS1_FAILED, payload: { error } };
    }
  };
};

const getCustomer = (national_code) => {

  return (dispatch) => {
    dispatch(request());
    return axios
      .get(ApiUrl + "/get-customer/" + national_code, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        //
        dispatch(success(response));
      })
      .catch(function (error) {
        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });

    function request() {
      return { type: GET_CUSTOMER_REQUEST };
    }

    function success(result) {
      return { type: GET_CUSTOMER_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: GET_CUSTOMER_FAILED, payload: { error } };
    }
  };
};

const createCustomer = (data, groupLabels = []) => {
  //
  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/create-customer",
      method: "post",
      params: {},
      data: {
        customer: {
          first_name: data.first_name,
          last_name: data.last_name,
          national_code: data.national_code,
          mobile: data.mobile,
          email: data.email,
          vip: data.vip,
          group: groupLabels,
        },
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        //
        dispatch(success(response));
      })
      .catch(function (error) {
        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });

    function request() {
      return { type: CREATE_CUSTOMER_REQUEST };
    }

    function success(result) {
      return { type: CREATE_CUSTOMER_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: CREATE_CUSTOMER_FAILED, payload: { error } };
    }
  };
};

const sendProblem = (data) => {
  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/create-problem",
      method: "post",
      params: {},
      data: {
        problem: {
          problem: data.get("problem"),
          user_national_code: data.get("national_code"),
          mobile: data.get("mobile"),
        },
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        dispatch(success(response));
        //
      })
      .catch(function (error) {
        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });

    function request() {
      return { type: CREATE_CUSTOMER_REQUEST };
    }

    function success(result) {
      return { type: CREATE_CUSTOMER_SUCCESS, payload: result.data };
    }

    function failure(error) {
      return { type: CREATE_CUSTOMER_FAILED, payload: { error } };
    }
  };
};

const GetCustomersAll = (pagination) => {
  //
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(ApiUrl + "/reports-customers" + `/?size=${pagination.per_page}&page=${pagination.page}`, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        //
        dispatch(success(response));
      })
      .catch(function (error) {
        //
        dispatch(failure(error));
      })
      .finally(function () {
        //
        // always executed
      });
    function request() {
      return { type: GET_CUSTOMERS_REQUEST };
    }
    function success(result) {
      return { type: GET_CUSTOMERS_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_CUSTOMERS_FAILED, payload: { error } };
    }
  };
};

const GetCustomersFilter = (pagination, typeCustomer, nationalCodeArray, mobileArray, groupLabels) => {
  //
  //   pagination,
  //   typeCustomer,
  //   mobile,
  //   nationalCode,
  //   "pagination , typeCustomer ,mobile,nationalCode action Get customer "
  // );
  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/reports-customers" + `/?size=${pagination.per_page}&page=${pagination.page}`,
      method: "post",
      data: {
        typeCustomer,
        nationalCodeArray,
        mobileArray,
        groupLabels,
      },
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        //
        dispatch(success(response));
      })
      .catch(function (error) {
        //
        dispatch(failure(error));
      })
      .finally(function () {
        //
        // always executed
      });
    function request() {
      return { type: GET_CUSTOMERS_REQUEST };
    }
    function success(result) {
      return { type: GET_CUSTOMERS_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_CUSTOMERS_FAILED, payload: { error } };
    }
  };
};

const GetCustomerReport = (customerId) => {
  //
  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/get-customer-problem" + `/${customerId}`,
      method: "get",
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        //
        dispatch(success(response));
      })
      .catch(function (error) {
        //
        dispatch(failure(error));
      })
      .finally(function () {
        //
        // always executed
      });
    function request() {
      return { type: GET_CUSTOMER_PROBLEMS_REQUEST };
    }
    function success(result) {
      return { type: GET_CUSTOMER_PROBLEMS_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_CUSTOMER_PROBLEMS_FAILED, payload: { error } };
    }
  };
};

const editCustomer = (data, customerId, vip, groupLabels) => {
  //
  data.vip = vip;
  data.group = groupLabels;
  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/edit-customer" + `/${customerId}`,
      method: "post",
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
      data: {
        data,
      },
    })
      .then(function (response) {
        //
        dispatch(success(response));
      })
      .catch(function (error) {
        //
        dispatch(failure(error));
      })
      .finally(function () {
        //
        // always executed
      });
    function request() {
      return { type: EDIT_CUSTOMER_REQUEST };
    }
    function success(result) {
      return { type: EDIT_CUSTOMER_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: EDIT_CUSTOMER_FAILED, payload: { error } };
    }
  };
};

export default {
  getCustomer,
  editCustomer,
  createCustomer,
  sendProblem,
  getProblems,
  GetCustomersFilter,
  GetCustomersAll,
  GetCustomerReport,
  getProblemslogic,
};
