import {
  CREATE_OPERATOR_SUCCESS,
  CREATE_USER_FAILED,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  GET_HISTORY_FAILED,
  GET_HISTORY_LIST_FAILED,
  GET_HISTORY_LIST_REQUEST,
  GET_HISTORY_LIST_SUCCESS,
  GET_HISTORY_REQUEST,
  GET_HISTORY_SUCCESS,
  GET_INPUT_CALL_REQUEST,
  GET_INPUT_CALL_SUCCESS,
  GET_USER_LIST_FAILED,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_SFAILED,
  LOGIN_SUCCESS,
} from "../type/user_type";

const initialState = {
  data: null,
  history: null,
  inputHistory: null,
  _history: null,
  user: null,
  pagination_call: { per_page: 20, page: 1, total: 1 },
  pagination_users: { per_page: 20, page: 1, total: 1 },
  login: null,
  loading: false,
  error: null,
  message: null,
  code: null,
  operators: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_HISTORY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_HISTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        _history: action.payload.data.history,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
      };
    }
    case GET_HISTORY_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    }
    case GET_HISTORY_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_HISTORY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        history: action.payload.data.histories,
        pagination_call: action.payload.data.pagination,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
      };
    }
    case GET_HISTORY_LIST_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    }
    case LOGIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        login: action.payload.data.user,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
      };
    }
    case LOGIN_SFAILED: {
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    }
    case CREATE_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
        message: null,
        code: null,
      };
    }
    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: action.payload.data,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
      };
    }
    case CREATE_OPERATOR_SUCCESS: {
      return {
        ...state,
        loading: false,
        operators: action.payload.data,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
      };
    }
    //input call
    case GET_INPUT_CALL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_INPUT_CALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        inputHistory: action.payload.data.histories,
        pagination_call: action.payload.data.pagination,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
      };
    }
    case CREATE_USER_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_USER_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
        message: null,
        code: null,
      };
    }
    case GET_USER_LIST_SUCCESS: {
      //
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
        pagination_users: action.payload.data.pagination,
      };
    }
    case GET_USER_LIST_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
