import { Button, Divider } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { useState } from "react";
import { ApiUrl } from "../../../config";
import styles from "./UploadProfile.module.scss";
import {
  grayColor,
  infoColor,
  roseColor,
  successColor,
  whiteColor,
} from "../../../assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles({
  root: {
    width: "360px",
    height: "180px",
    direction: "rtl",
    margin: "auto auto",
  },
  content: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // padding : '0.5rem',
  },
  title: {
    fontSize: 12,
    color: grayColor[0],
    textAlign: "start",
  },

  mainContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  cancell: {
    display: "flex",
    justifyContent: "end",
    textAlign: "end",
  },

  formContainer: {
    height: "100%",
  },
});

const UploadProfile = (props) => {
  const classes = useStyles();

  const [flagSend, setFlagSend] = useState(false);
  const [uploadEvent, setUploadEvent] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  const handleInputChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setUploadEvent(event.target.files[0]);
  };

  const submit = () => {
    const data = new FormData();
    data.append("avatar", selectedFile);
    data.append("id", localStorage.getItem("_id"));
    let url = ApiUrl + "/update-users-avatar";
    axios
      .post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.status == 200 || res.status == 204) {
          props.closeModal();
          window.location.href = "/admin/dashboard";
        }
      });
    // window.location.href = "/admin/dashboard"
  };

  const onSubmittxt = (data) => {
    // 
    let file = new FormData();
    // file.append("file", data.file[0]);
    file.append("file", data.file[0]);
    // setfullname(data.fullname);
    // setMessageForm(data.message);
    // if (localStorage.getItem("data_nationalcode")) {
    axios({
      url: ApiUrl + "/text-box",
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
      data: {
        id: localStorage.getItem("_id"),
        avatar: file,
      },
    })
      .then(function (response) {
        // 
        // setAlertSend(true);
        // setFlagTextBox(false);
        // setOut(false);
        // setBox(false);
      })
      .catch(function (error) {
        // setAlertSend("ناموفق")
        // 
      })
      .finally(function () {});
    // } else {
    //   window.location.href = "/";
    // }
  };

  return (
    <div className={styles.modal}>
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <div>
            <h6 className={classes.title} color="textSecondary" gutterBottom>
              آپلود عکس
            </h6>
            <Divider />
          </div>
          <div className={classes.mainContent}>
            <div className={classes.formContainer}>
              <form style={{ overflow: "unset" }}>
                <div className={styles.attachContainer}>
                  <input
                    className={styles.attachInput}
                    type={"file"}
                    // onChange={(e) => {
                    //   setFlagSend(false);
                    //   setUploadEvent(e.target.files[0]);
                    // }}
                    onChange={handleInputChange}
                  />
                  {/*<div className={styles.attachBtn}>*/}
                  {/*  <Button variant="contained" style={{ backgroundColor: infoColor[0], color: whiteColor,width:"100%" }}>*/}
                  {/*    انتخاب فایل*/}
                  {/*  </Button>*/}
                  {/*</div>*/}
                </div>
              </form>
            </div>
            <div
              className={uploadEvent ? styles["btnGrp--upload"] : styles["btnGrp--empty"]}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className={classes.cancell}>
                <Button
                  onClick={props.closeModal}
                  variant="contained"
                  style={{
                    backgroundColor: roseColor[0],
                    color: whiteColor,
                    justifySelf: "end",
                    display: "inline",
                  }}
                >
                  انصراف
                </Button>
              </div>
              <div className={styles.attachBtn}>
                {uploadEvent ? (
                  <Button
                    onClick={submit}
                    variant="contained"
                    style={{
                      backgroundColor: successColor[0],
                      color: whiteColor,
                      justifySelf: "end",
                      display: "inline",
                    }}
                  >
                    ارسال
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    component={"label"}
                    style={{
                      backgroundColor: infoColor[0],
                      color: whiteColor,
                      width: "100%",
                    }}
                  >
                    انتخاب فایل
                    <input
                      className={styles.attachInput}
                      type={"file"}
                      // onChange={(e) => {
                      //   setFlagSend(false);
                      //   setUploadEvent(e.target.files[0]);
                      // }}
                      hidden
                      onChange={handleInputChange}
                    />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default UploadProfile;
