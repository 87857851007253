import FormLabel from "@material-ui/core/FormLabel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle, roseColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import Button from "components/CustomButtons/Button";
// import Button from "@mui/material/Button";
import CustomInput from "components/CustomInput/CustomInput";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux/";
import { bindActionCreators } from "redux";
import { ApiUrl } from "../../config";
import UserAction from "../../js/actions/user_action";
import { toast, ToastContainer } from "react-toastify";
import TextField from "@material-ui/core/TextField";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem",
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999",
  },
  "rt-resizable-header-content": {
    textAlign: "right",
  },
};

const useStyles = makeStyles(styles);

function Users(props) {
  const [loading, isLoading] = useState(false);
  const [link, setLink] = useState(null);
  const [file, setFile] = useState(null);
  const [menu, setMenu] = useState(null);
  const [type, setType] = useState(null);

  // const sendVideo = async () => {
  //     const fd = new FormData()
  //     try {
  //         const res = axios.post(ApiUrl+'/upload-avr',{})
  //     }catch (e) {
  //
  //     }
  // }

  const addAvrFile = (e) => {
    // var bodyFormData = new FormData(e.target);

    isLoading(true);
    // e.target.reset();
    axios({
      url: ApiUrl + "/upload-avr",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("token"),
      },
      data: {
        menu,
        type,
        file,
      },
    })
      .then(function (response) {
        isLoading(false);
        toast.success("لینک با موفقیت اضافه شد");
        setFile(null);
      })
      .catch(function (error) {
        isLoading(false);
        toast.error("خطا");
      })
      .finally(function () {
        isLoading(false);
      });
  };

  const addAvrLink = (e) => {

    // var bodyFormData = new FormData(e.target);
    isLoading(true);
    // e.target.reset();
    axios({
      url: ApiUrl + "/add-avr-link",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      data: {
        menu,
        type,
        link,
      },
    })
      .then(function (response) {
        isLoading(false);
        toast.success("لینک با موفقیت اضافه شد");
        setLink(null);
      })
      .catch(function (error) {
        isLoading(false);
        toast.error("خطا");
      })
      .finally(function () {
        isLoading(false);
      });
  };
  function onSubmit(e) {
    e.preventDefault();
    if (file) {
      addAvrFile(e);
    } else {
      addAvrLink(e);
    }
  }

  const [menus, setMenus] = useState([]);

  useEffect(() => {
    axios
      .get(ApiUrl + "/menu-items", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        setMenus(response.data.data.menu_items);
      })
      .catch(function (error) {})
      .finally(function () {});
  }, [null]);

  const classes = useStyles();

  if (props.loading) return <div>در حال ارسال...</div>;
  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>افزودن ویدئو - حامی چت</title>
      </Helmet>
      <div id={"from"}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>افزودن وِیدئو جدید</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>نوع فایل ویدئویی</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <select className={"select-parent"} name="type" onChange={(e) => setType(e.target.value)}>
                        <option value={"holding"}>پخش هنگام نگه داشتن تماس</option>
                        <option value={"waiting"}>پخش هنگام تماس</option>
                        <option value={"outtime"}>پخش خارج از ساعت اداری</option>
                      </select>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>صف انتخابی</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <select className={"select-parent"} name="menu" onChange={(e) => setMenu(e.target.value)}>
                        <option value={"null"}>همه منو ها</option>

                        {menus.map((e, i) => {
                          return (
                            <option value={e._id} key={i}>
                              {e.name}
                            </option>
                          );
                        })}
                      </select>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>فایل ویدئویی</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "file",
                          name: "file",
                          accept: "video/mp4",
                        }}
                        disabled={link ? true : false}
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>لینک ویدئویی</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      {/*<CustomInput*/}
                      {/*    formControlProps={{fullWidth: true}}*/}
                      {/*    inputProps={{*/}
                      {/*        type: 'text',*/}
                      {/*        name: 'link'*/}
                      {/*    }}*/}
                      {/*    onChange={e =>
                      {/*    disabled={file ? true : false}*/}
                      {/*/>*/}
                      <TextField
                        disabled={file ? true : false}
                        variant={"standard"}
                        onChange={(e) => setLink(e.target.value)}
                        style={{ width: "100%" }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button color="rose" onClick={onSubmit}>
                    {loading ? "در حال ذخیره کردن" : "ذخیره"}
                  </Button>
                </CardFooter>
              </Card>
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.user.data,
  loading: state.user.loading,
  error: state.user.error,
});

const mapDispatchToProps = (dispatch) => ({
  UserAction: bindActionCreators({ UserAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
