import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux/";
import { ApiUrl, Url } from "../../config";

const useStyles = makeStyles(styles);

require("./style.css");

function Index(props) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [loading, isLoading] = useState(false);
  const [check, setCheck] = useState(true);
  const [logedin, isLogedin] = useState(false);
  const [step, setStep] = useState(1);
  const [name, setName] = useState(1);
  const [id, setId] = useState(1);
  const [nationalCode, setNationalCode] = useState("");
  const [mobile, setMobile] = useState();
  const [alerts, setAlerts] = useState("");

  useEffect(() => {
    let userData = localStorage.getItem("data_nationalcode");
    let userDataMobile = localStorage.getItem("data_mobile");

    if (userData != null && userDataMobile != null) {
      setNationalCode(userData);
      setMobile(userDataMobile);
    } else {
      if (!props.location.state == undefined) {
        window.location.href = "/client-bot";
        return null;
      }

      if (props.location.state) {
        localStorage.setItem("data_nationalcode", props.location.state.nationalCode);
        localStorage.setItem("data_mobile", props.location.state.mobile);
        setNationalCode(props.location.state.nationalCode);
        setMobile(props.location.state.mobile);
      }
    }
  }, [logedin]);

  function saveExitClient(nationalCode, mobile) {
    // 
    let data = {
      nationalCode,
      mobile,
      exit_date: moment().format("YYYY-MM-DDTHH:mm:ss.sTZD"),
    };
    return axios({
      url: ApiUrl + "/save-client",
      method: "post",
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        // 
      })
      .catch(function (error) {
        // 
      })
      .finally(function () {
        // always executed
        // 
      });
  }

  const exit = () => {
    localStorage.removeItem("data_nationalcode");
    localStorage.removeItem("data_mobile");
    let nationalCode;
    let mobile;
    if (props.history.location.state) {
      nationalCode = props.history.location.state.nationalCode;
      mobile = props.history.location.state.mobile;
    }
    // 
    saveExitClient(nationalCode, mobile);
    window.location.href = "/client-bot";
  };

  function handleCall(e, name) {
    setName(name);
    setId(e);
    setStep(2);
  }

  const confirm = () => {
    window.location.href = "/client-bot";
  };
  function MeasureConnectionSpeed() {
    var imageAddr = "https://hamichat.pep.co.ir/tester.jpg";
    var downloadSize = 2996096; //bytes

    var startTime, endTime;
    var download = new Image();
    download.onload = function () {
      endTime = new Date().getTime();
      showResults();
    };

    download.onerror = function (err, msg) {
      setAlerts(
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="خطا در بارگزاری اطلاعات"
          onConfirm={() => confirm()}
          confirmBtnCssClass={classes.button + " " + classes.success}
          confirmBtnText="بازگشت"
        ></SweetAlert>
      );
    };

    startTime = new Date().getTime();
    var cacheBuster = "?nnn=" + startTime;
    download.src = imageAddr + cacheBuster;

    function showResults() {
      var duration = (endTime - startTime) / 1000;
      var bitsLoaded = downloadSize * 8;
      var speedBps = (bitsLoaded / duration).toFixed(2);
      var speedKbps = (speedBps / 1024).toFixed(2);
      var speedMbps = (speedKbps / 1024).toFixed(2);

      let minSpeed = 0.5;

      if (speedMbps > minSpeed) {
        makeCall();
      } else {
        setAlerts(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="اینترنت نامناسب"
            onConfirm={() => setAlerts("")}
            confirmBtnCssClass={classes.button + " " + classes.success}
            confirmBtnText="بازگشت"
          >
            <p>
              متاسفانه سرعت اینترنت شما جهت برقراری تماس صوتی و تصویری مناسب نمی باشد. لطفا از گزینه پیام متنی استفاده
              کنید.
            </p>
          </SweetAlert>
        );
      }
      // ShowProgressMessage([
      //     "Your connection speed is:",
      //     speedBps + " bps",
      //     speedKbps + " kbps",
      //     speedMbps + " Mbps"
      // ]);
    }
  }

  function handleCalling() {
    window.setTimeout(MeasureConnectionSpeed, 1);

    setAlerts(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="در حال ارزیابی سرعت اینترنت"
        onConfirm={() => {}}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText=" لطفا منتظر بمانید..."
      ></SweetAlert>
    );
  }

  function makeCall() {
    props.history.push({
      pathname: "/client-bot/call-bot",
      state: {
        nationalCode,
        menu: id,
        name: name,
        mobile,
      },
    });
  }
  function handleChat() {
    props.history.push({
      pathname: "/client-bot/chat-bot",
      state: {
        nationalCode,
        menu: id,
        name: name,
        mobile,
      },
    });
  }

  const getUsers = () => {
    isLoading(true);

    axios
      .get(ApiUrl + "/menu-items?status=true", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        isLoading(false);

        setData(response.data.data.menu_items);
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };

  useEffect(() => {
    getUsers();
  }, loading);

  return (
    <div dir="rtl">
      {alerts}

      <div>
        {step == 2 ? (
          <div>
            <div className={"header"}>
              <div className={"logo"}></div>
            </div>
            <div className="waiters">
              <br />
              <b style={{ marginRight: "30px" }}> {name === "کیپاد" ? "پی پاد" : name}</b>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h5 className="text">نوع ارتباط با پشتیبانی را انتخاب کنید</h5>
            </div>

            <div onClick={(e) => handleChat()} className="selector">
              <div className="chatmessage"></div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h4>پیام متنی</h4>
              </div>
            </div>

            <div onClick={(e) => handleCalling()} className="selector">
              <div className="videocall"></div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h4>تماس تصویری</h4>
              </div>
            </div>
          </div>
        ) : (
          <div className={"direction-bot"}>
            <div className={"header"}>
              <div className={"logo"}></div>
              <button onClick={(e) => exit()}>خروج</button>
            </div>
            <h5 className="text">به پشتیبانی از کدام خدمات نیاز دارید؟</h5>
            <div className={"options"}>
              {data.map((e, i) => {
                return (
                  <div className={"option"} key={i}>
                    <div className={"option-item"} onClick={(j) => handleCall(e._id, e.name)}>
                      <div
                        style={{
                          background: `url("${Url + e.icon}") no-repeat`,
                        }}
                        className={"logo "}
                      ></div>
                      <div className={"text"}>{e.name}</div>

                      <div className={"arrow"}></div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  // UserAction: bindActionCreators({UserAction}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
