import {
  GET_CALL_STATUS_FAILED,
  GET_CALL_STATUS_REQUEST,
  GET_CALL_STATUS_SUCCESS,
  GET_OUT_CALL_FAILED,
  GET_OUT_CALL_REQUEST,
  GET_OUT_CALL_SUCCESS,
  GET_POLL_LIST_FAILED,
  GET_POLL_LIST_REQUEST,
  GET_POLL_LIST_SUCCESS,
  GET_QUEUE_ENTER_EXIT_FAILED,
  GET_QUEUE_ENTER_EXIT_REQUEST,
  GET_QUEUE_ENTER_EXIT_SUCCESS,
  GET_REPORT_LIST_FAILED,
  GET_REPORT_LIST_REQUEST,
  GET_REPORT_LIST_SUCCESS,
  GET_RESPONSE_CALL_LIST_FAILED,
  GET_RESPONSE_CALL_LIST_REQUEST,
  GET_RESPONSE_CALL_LIST_SUCCESS,
  GET_TRANSFER_CALL_FAILED,
  GET_TRANSFER_CALL_REQUEST,
  GET_TRANSFER_CALL_SUCCESS,
} from "../type/user_type";

const initialState = {
  data: null,
  reports: null,
  loading: false,
  error: null,
  message: null,
  code: null,
  responseList: null,
  callStatus: null,
  history_history: null,
  polls: null,
  pagination: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_REPORT_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
        message: null,
        code: null,
      };
    }
    case GET_REPORT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        reports: action.payload.data,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
      };
    }
    case GET_REPORT_LIST_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_RESPONSE_CALL_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
        message: null,
        code: null,
      };
    }
    case GET_RESPONSE_CALL_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        responseList: action.payload.data,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
      };
    }
    case GET_RESPONSE_CALL_LIST_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_CALL_STATUS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CALL_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
        callStatus: action.payload.data.histories,
        ////////////falseeeeeeee
      };
    }
    case GET_CALL_STATUS_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_POLL_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_POLL_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
        polls: action.payload.data.polls,
        pagination: action.payload.data.pagination,
      };
    }
    case GET_POLL_LIST_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_TRANSFER_CALL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_TRANSFER_CALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
        polls: action.payload.data.polls,
        pagination: action.payload.data.pagination,
      };
    }
    case GET_TRANSFER_CALL_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_OUT_CALL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_OUT_CALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
        inputHistory: action.payload.data.histories,
      };
    }
    case GET_OUT_CALL_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_QUEUE_ENTER_EXIT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_QUEUE_ENTER_EXIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
        polls: action.payload.data.polls,
        pagination: action.payload.data.pagination,
      };
    }
    case GET_QUEUE_ENTER_EXIT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
