import DashboardIcon from "@material-ui/icons/Dashboard";
import Login from "views/Pages/LoginPage";

var Routes = [
  {
    path: "/login-page",
    name: "ورود",
    rtlName: "ورود",
    icon: DashboardIcon,
    component: Login,
    layout: "/auth",
  },
];
export default Routes;
