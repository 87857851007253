import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { TimePicker } from "antd";
import "antd/dist/antd.css";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { default as moment, default as momentj } from "moment-jalaali";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { connect } from "react-redux/";
//import ChartistGraph from "react-chartist";
import CreatableSelect from "react-select/creatable";
import { toast, ToastContainer } from "react-toastify";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { bindActionCreators } from "redux";
import { ApiUrl } from "../../config";
import ReportAction from "../../js/actions/report_action";
import UserAction from "../../js/actions/user_action";
import allCallLogo from "./../../assets/img/calls/allCall.png";
import allChatLogo from "./../../assets/img/calls/allChat.png";
import answerCallLogo from "./../../assets/img/calls/answerCall.png";
import answerChatLogo from "./../../assets/img/calls/answerChat.png";
import callingCallLogo from "./../../assets/img/calls/callingCall.png";
import callingChatLogo from "./../../assets/img/calls/callingChat.png";
import midCallingLogo from "./../../assets/img/calls/deadline.png";
import maxLogo from "./../../assets/img/calls/hourglass.png";
import minCallingLogo from "./../../assets/img/calls/mincalling.png";
import rejectCallLogo from "./../../assets/img/calls/rejectCall.png";
import rejectChatLogo from "./../../assets/img/calls/rejectChat.png";
import midLogo from "./../../assets/img/calls/timer.png";
import minLogo from "./../../assets/img/calls/waiting.png";
import maxCallingLogo from "./../../assets/img/calls/wall-clock.png";
import Reset from "./../../assets/img/reset.png";
import CallsCount from "../../components/Recharts/CallsCount";
import CallsResponseAvg from "../../components/Recharts/CallsResponseAvg";
import CallsStatus from "../../components/Recharts/CallsStatus";
import WaitingAvg from "../../components/Recharts/WaitingAvg";

import MissedCalls from "components/Recharts/MissedCallsChart";
import MissedCalls24Hours from "components/Recharts/MissedCalls24HoursChart";
//var Chartist = require("chartist");

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
  },
  numberReportCard: {
    width: "300px",
    height: "140px",
    display: "flex",
    flexDirection: "row",
    margin: "8px",
  },
  numberReportCardMath: {
    width: "400px",
    height: "140px",
    display: "flex",
    flexDirection: "row",
    margin: "8px",
  },
  numberReportImg: {
    width: "100px",
    height: "100px",
    margin: "20px",
  },
  numberReport: {
    margin: "8px",
  },
  headNumberReport: {
    margin: "20px",
  },
};

const useStyles = makeStyles(styles);

function Users(props) {
  const [loading, isLoading] = useState(false);
  const handleSimple = (event) => {
    setSimpleSelect(event.target.value);
  };

  const classes = useStyles();
  useEffect(() => {
    //getUsers()
    //getStatus()
    let today = new Date();
    let jDayToday = moment(today).format("jD");
    let jYearToday = moment(today).format("jYYYY");
    let jMonthToday = moment(today).format("jM");
    // 
    let PM = new Date();
    let newMonth = PM.getMonth() - 2;
    if (newMonth < 0) {
      newMonth += 12;
      PM.setYear(PM.getYear() - 2);
    }
    PM.setMonth(newMonth);
    let jDayPM = moment(PM).format("jD");
    let jYearPM = moment(PM).format("jYYYY");
    let jMonthPM = moment(PM).format("jM");
    // 

    let firstRange = {
      from: { day: jDayPM, month: jMonthPM, year: jYearPM },
      to: { day: jDayToday, month: jMonthToday, year: jYearToday },
    };
    // 
    // props.store.dispatch(ReportAction.GetCallStatusReports(firstRange, [], []));
  }, loading);

  useEffect(() => {
    // props.store.dispatch(UserAction.GetOperators());
    // props.store.dispatch(ReportAction.GetAllQueues());
    // getStatus();
  }, []);
  useEffect(() => {
    // let data = props.operators;
    // let options = [];
    // if (data) {
    //   data.forEach((item) => {
    //     options.push({
    //       value: item._id,
    //       label: item.first_name + " " + item.last_name,
    //     });
    //   });
    //   setDataSelectOperator(options);
    // }
    // 
  }, props.operators);

  const getStatus = () => {
    return props.store.dispatch(ReportAction.GetALLCallStatus());
  };

  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [operator, setOperator] = React.useState("");
  // const [type, setType] = React.useState("");
  const [callStatus, setCallStatus] = React.useState("");
  const [allCall, setAllCall] = React.useState(0);
  const [allAnsweredCall, setAllAnsweredCall] = React.useState(0);
  const [allRejectedCall, setAllRejectedCall] = React.useState(0);
  const [allCallingCall, setAllCallingCall] = React.useState(0);
  const [allChat, setAllChat] = React.useState(0);
  const [allAnsweredChat, setAllAnsweredChat] = React.useState(0);
  const [allRejectedChat, setAllRejectedChat] = React.useState(0);
  const [allCallingChat, setAllCallingChat] = React.useState(0);
  ///staticmath
  const [midWaitingCall, setMidWaitingCall] = React.useState(0);
  const [minWaitingCall, setMinWaitingCall] = React.useState(0);
  const [maxWaitingCall, setMaxWaitingCall] = React.useState(0);

  const [maxCallingTime, setMaxCallingTime] = React.useState(0);
  const [minCallingTime, setMinCallingTime] = React.useState(0);
  const [midCallingTime, setMidCallingTime] = React.useState(0);

  const [dataSelectOperator, setDataSelectOperator] = React.useState([]);

  const [allCallCount, setAllCallCount] = React.useState(0);
  const [allChatCount, setAllChatCount] = React.useState(0);
  const [callHistory, setCallHistory] = React.useState([]);

  // 
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });
  // 

  let handleChangeOperator = (newValue, actionMeta) => {
    setOperator(newValue);
  };
  let handleChangeCallStatus = (newValue, actionMeta) => {
    setCallStatus(newValue);
  };
  // let handleChangeCallType = (newValue, actionMeta) => {
  //     setType(newValue)
  // };

  let callStatusoptions = [
    { value: "answer", label: "تماس های تصویری موفق" },
    { value: "calling", label: "تماس های تصویری بی پاسخ یا لغو شده" },
    { value: "rejected", label: "تماس های تصویری رد شده" },
    { value: "answer-chat", label: "تماس های متنی موفق" },
    { value: "calling-chat", label: "تماس های متنی بی پاسخ یا لغو شده" },
    { value: "rejected-chat", label: "تماس های متنی رد شده" },
  ];

  const customStyles = {
    control: (base) => ({
      ...base,
      height: "auto",
      minHeight: 55,
      border: "1px solid black",
      width: "auto",
      //minWidth: "600px"
    }),
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length > 0) {
      // 

      return (
        <div
          style={{
            zIndex: 1000,
            backgroundColor: "#f9f9f9",
            padding: "5px",
            borderRadius: "8px",
            opacity: "0.7",
          }}
        >
          <p className="label" style={{ textAlign: "center" }}>
            {" "}
            تاریخ: <span>{label}</span>
          </p>
          {payload.map((item, index) => {
            return (
              <div key={index}>
                {item.name == "answer" ? (
                  <p className="label">
                    {" "}
                    تماس های تصویری موفق: <span>{item.value}</span>
                  </p>
                ) : null}
                {item.name == "rejected" ? (
                  <p className="label">
                    {" "}
                    تماس های تصویری رد شده توسط اپراتور: <span>{item.value}</span>
                  </p>
                ) : null}
                {item.name == "calling" ? (
                  <p className="label">
                    {" "}
                    تماس تصویری بی پاسخ یا لغو شده توسط کاربر: <span>{item.value}</span>
                  </p>
                ) : null}
                {item.name == "answerChat" ? (
                  <p className="label">
                    {" "}
                    تماس های متنی موفق: <span>{item.value}</span>
                  </p>
                ) : null}
                {item.name == "rejectedChat" ? (
                  <p className="label">
                    {" "}
                    تماس های متنی رد شده توسط اپراتور: <span>{item.value}</span>
                  </p>
                ) : null}
                {item.name == "callingChat" ? (
                  <p className="label">
                    {" "}
                    تماس های متنی بی پاسخ یا لغو شده توسط کاربر: <span>{item.value}</span>
                  </p>
                ) : null}
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const renderCustomInput = ({ ref }) => (
    <>
      <input
        readOnly
        ref={ref} // necessary
        placeholder="زمان گزارش را انتخاب کنید"
        value={
          selectedDayRange.from && selectedDayRange.to
            ? `${selectedDayRange.from.year}` +
              "/" +
              `${selectedDayRange.from.month}` +
              "/" +
              `${selectedDayRange.from.day}` +
              " تا " +
              `${selectedDayRange.to.year}` +
              "/" +
              `${selectedDayRange.to.month}` +
              "/" +
              `${selectedDayRange.to.day}`
            : ""
        }
        style={{
          textAlign: "center",
          padding: "8px",
          fontSize: "1rem",
          border: "1px solid ",
          borderRadius: "5px",
          outline: "none",
          height: 55,
          minHeight: 35,
          width: 500,
        }}
        className="my-custom-input-class" // a styling class
      />
      <a>
        <img
          src={Reset}
          style={{ width: "30px", height: "30px" }}
          onClick={() => {
            setSelectedDayRange({
              from: null,
              to: null,
            });
          }}
        />
      </a>
    </>
  );

  //////////RangePicker
  const { RangePicker } = TimePicker;
  const [timeStart, settimeStart] = useState("");
  const [timeEnd, settimeEnd] = useState("");

  function onChange(time, timeString) {
    // 
    settimeStart(timeString[0]);
    settimeEnd(timeString[1]);
  }

  ////////////RangePicker
  useEffect(() => {
    getCallHistory();
  }, []);
  ////
  useEffect(() => {
    let data = props.queues;
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.name === "کیپاد" ? "پی پاد" : item.name,
        });
      });
      setDataSelectQueue(options);
    }
  }, [props.queues]);
  const [queue, setQueue] = React.useState("");
  const [dataSelectQueue, setDataSelectQueue] = React.useState([]);
  let handleChangeQueue = (newValue, actionMeta) => {
    setQueue(newValue);
  };
  ////

  const dataRenderChart = () => {
    let dataResponse = props.callStatus;
    let dataChart = [];

    if (dataResponse) {
      dataResponse.forEach((item, index) => {
        ///////////////
        let tempObj = {
          name: "",
          calling: 0,
          answer: 0,
          rejected: 0,
          callingChat: 0,
          answerChat: 0,
          rejectedChat: 0,
        };
        //let time = moment(item.start, 'YYYY-M-D HH:mm:ss').format('jYYYY/jM/jD')
        let time =
          moment(item.start, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD HH:mm:ss") === "Invalid date"
            ? moment(item.start, "YYYY-MM-DD HH:mm:ss").format("jYYYY/jM/jD")
            : moment(item.start, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD");
        if (
          dataChart.find((miniItem) => {
            if (miniItem.name === time) {
              if (item.status === "calling") {
                miniItem.calling = miniItem.calling + 1;
              }
              if (item.status === "answer") {
                miniItem.answer = miniItem.answer + 1;
              }
              if (item.status === "rejected") {
                miniItem.rejected = miniItem.rejected + 1;
              }
              if (item.status === "calling-chat") {
                miniItem.callingChat = miniItem.callingChat + 1;
              }
              if (item.status === "answer-chat") {
                miniItem.answerChat = miniItem.answerChat + 1;
              }
              if (item.status === "rejected-chat") {
                miniItem.rejectedChat = miniItem.rejectedChat + 1;
              }

              return true;
            }
          })
        ) {
          //return;
        } else {
          tempObj.name = time;
          if (item.status === "calling") {
            tempObj.calling = tempObj.calling + 1;
          }
          if (item.status === "answer") {
            tempObj.answer = tempObj.answer + 1;
          }
          if (item.status === "rejected") {
            tempObj.rejected = tempObj.rejected + 1;
          }
          if (item.status === "calling-chat") {
            tempObj.callingChat = tempObj.callingChat + 1;
          }
          if (item.status === "answer-chat") {
            tempObj.answerChat = tempObj.answerChat + 1;
          }
          if (item.status === "rejected-chat") {
            tempObj.rejectedChat = tempObj.rejectedChat + 1;
          }
          // 
          dataChart.push(tempObj);
        }
      });
    }
    // 

    return dataChart;
  };

  useEffect(() => {
    let allCalls = 0;
    let allAnsweredCalls = 0;
    let allCallingCalls = 0;
    let allRejectedCalls = 0;
    let allChats = 0;
    let allAnsweredChats = 0;
    let allCallingChats = 0;
    let allRejectedChats = 0;
    ///math
    //WC=waitingCall
    let sumWC = 0;
    let minWC = 0;
    let maxWC = 0;
    let arrayWc = [];
    //CT=CallingTime
    let sumCT = 0;
    let minCT = 0;
    let maxCT = 0;
    let arrayCT = [];
    let dataResponse = props.callStatus;
    if (dataResponse) {
      dataResponse.forEach((item, index) => {
        // if( index >50 ){
        //     // 
        //     return true
        // }
        // 
        if (item.status === "calling") {
          // 

          allCallingCalls = allCallingCalls + 1;
          allCalls = allCalls + 1;
        } else if (item.status === "answer") {
          // 

          allAnsweredCalls = allAnsweredCalls + 1;
          allCalls = allCalls + 1;
        } else if (item.status === "rejected") {
          // 
          allRejectedCalls = allRejectedCalls + 1;
          allCalls = allCalls + 1;
        } else if (item.status === "answer-chat") {
          // 

          allAnsweredChats = allAnsweredChats + 1;
          allChats = allChats + 1;
        } else if (item.status === "rejected-chat") {
          // 

          allRejectedChats = allRejectedChats + 1;
          allChats = allChats + 1;
        } else if (item.status === "calling-chat") {
          // 

          allCallingChats = allCallingChats + 1;
          allChats = allChats + 1;
        } else {
          // 
        }
        ///////////////callingTime
        let answerField = item.answer;
        let endField = item.end;
        let callingTime = 0;

        if (answerField && endField) {
          let end;
          let answer;
          let answerFormat = moment(answerField, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD HH:mm:ss");
          let endFormat = moment(endField, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD HH:mm:ss");
          answer = new Date(answerFormat).getTime(); //return milliseconds
          end = new Date(endFormat).getTime();
          let seconds_course = Math.abs(end - answer) / 1000;
          let seconds_course_Float = parseFloat(seconds_course);
          arrayCT.push(seconds_course_Float);
          if (seconds_course_Float === "NaN") {
            callingTime = 0;
          } else {
            callingTime = seconds_course_Float;
          }
          sumCT = sumCT + callingTime;
        }

        /////////////////////waitingTime
        let startField = item.start;
        let waitingTime = 0;
        if (answerField && endField) {
          let start;
          let answer;
          let answerFormat = moment(answerField, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD HH:mm:ss");
          let startFormat = moment(startField, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD HH:mm:ss");
          answer = new Date(answerFormat).getTime();
          start = new Date(startFormat).getTime();
          let seconds = Math.abs(answer - start) / 1000;
          let secondsFloat = parseFloat(seconds);
          arrayWc.push(secondsFloat);
          if (secondsFloat === "NaN") {
            waitingTime = 0;
          } else {
            waitingTime = secondsFloat;
          }
          sumWC = sumWC + waitingTime;
        }
      });
      let numberAll = arrayCT.length;
      ///////////////callingTime
      let midCT = sumCT / numberAll;
      minCT = Math.min(...arrayCT);
      maxCT = Math.max(...arrayCT);
      let minCT_hms = secondsToHms(minCT, "min ct");
      let maxCT_hms = secondsToHms(maxCT, "max ct");
      let midCT_hms = secondsToHms(midCT, "mid ct");

      // 
      setMidCallingTime(midCT_hms);
      setMinCallingTime(minCT_hms);
      setMaxCallingTime(maxCT_hms);
      /////////////waitingTime
      let midWC = sumWC / numberAll;
      // 
      // 
      minWC = Math.min(...arrayWc);
      maxWC = Math.max(...arrayWc);
      let minhms = secondsToHms(minWC, "min wt");
      // 
      let maxhms = secondsToHms(maxWC, "max wt");
      let midhms = secondsToHms(midWC, "mid wt");
      setMidWaitingCall(midhms);
      setMinWaitingCall(minhms);
      setMaxWaitingCall(maxhms);
    }
    setAllAnsweredCall(allAnsweredCalls);
    setAllCall(allCalls);
    setAllRejectedCall(allRejectedCalls);
    setAllCallingCall(allCallingCalls);
    setAllChat(allChats);
    setAllAnsweredChat(allAnsweredChats);
    setAllCallingChat(allCallingChats);
    setAllRejectedChat(allRejectedChats);

    // 
  }, [dataRenderChart]);

  function secondsToHms(d, a) {
    d = Number(d);
    if (d < 1) {
      return 1 + " ثانیه ";
    }
    if (d === 0 || d == "Infinity" || d == "-Infinity" || isNaN(d)) {
      return 0 + " ثانیه ";
    }
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    if (h == 0 && m == 0 && s == 0) return 0;
    var hDisplay = h > 0 ? h + (h == 1 ? " ساعت " : " ساعت ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " دقیقه " : " دقیقه ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " ثانیه " : " ثانیه ") : "";
    return hDisplay + " " + mDisplay + " " + sDisplay;
  }

  const getFileExcell = () => {
    let arrayOperatorsId = [];
    if (operator) {
      operator.forEach((item) => {
        arrayOperatorsId.push(item.value);
      });
    }
    let callStatusValues = [];
    if (callStatus) {
      callStatus.forEach((item) => {
        callStatusValues.push(item.value);
      });
    }
    let queuesId = [];
    if (queue) {
      queue.forEach((item) => {
        queuesId.push(item.value);
      });
    }

    let dateG_from;
    let dateG_to;
    if (selectedDayRange.to) {
      let dateFromString =
        selectedDayRange.from.year + "-" + selectedDayRange.from.month + "-" + selectedDayRange.from.day;
      let dateToString = selectedDayRange.to.year + "-" + selectedDayRange.to.month + "-" + selectedDayRange.to.day;
      dateG_from = momentj(dateFromString, "jYYYY-jM-jD").format("YYYY-MM-DD");
      dateG_to = momentj(dateToString, "jYYYY-jM-jD").format("YYYY-MM-DD");
      // 
    }
    axios({
      url: ApiUrl + "/reports-call-status-excell",
      method: "post",
      params: {},
      data: {
        dateTo: dateG_to,
        dateFrom: dateG_from,
        operators: arrayOperatorsId,
        status: callStatusValues,
        queuesId: queuesId,
        timeStart,
        timeEnd,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
      responseType: "arraybuffer",
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.xlsx");
        link.click();
      })
      .catch(() => {
        toast.error("دانلود فایل با خطا مواجه شد", { position: toast.POSITION.TOP_CENTER });
      });
  };

  const getCallHistory = function (e) {
    axios({
      url: ApiUrl + "/get-count-call-history",
      method: "get",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        setCallHistory(response.data.data.data);
        // 

        let total_chat = 0;
        let total_call = 0;
        response.data.data.data.map((item) => {
          if (item._id.status == "calling-chat") {
            total_chat = total_chat + item.count;
          }
          if (item._id.status == "rejected-chat") {
            total_chat = total_chat + item.count;
          }
          if (item._id.status == "answer-chat") {
            total_chat = total_chat + item.count;
          }

          if (item._id.status == "calling") {
            total_call = total_call + item.count;
          }
          if (item._id.status == "rejected") {
            total_call = total_call + item.count;
          }
          if (item._id.status == "answer") {
            total_call = total_call + item.count;
          }
        });
        setAllCallCount(total_call);
        setAllChatCount(total_chat);
      })
      .catch(function (error) {
        // 
      });
  };

  if (props.loading) return <div>در حال ارسال...</div>;
  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>نمودار تماس ها - حامی چت</title>
      </Helmet>
      <GridContainer>
        <Card></Card>

        <Grid item style={{ width: "300px", margin: "0 5px" }}>
          <Card className={classes.numberReportCard}>
            <Box p={1}>
              <img src={allCallLogo} className={classes.numberReportImg} />
            </Box>
            <Box>
              <h2 className={classes.numberReport}>{allCallCount}</h2>
              <Box fontSize={{ xs: 16, xl: 18 }}>تعداد کل تماس های تصویری</Box>
            </Box>
          </Card>
        </Grid>
        <Grid item style={{ width: "300px", margin: "0 5px" }}>
          {callHistory.map((item) =>
            item._id.status == "answer" ? (
              <Card className={classes.numberReportCard}>
                <Box p={1}>
                  <img src={answerCallLogo} className={classes.numberReportImg} />
                </Box>
                <Box>
                  <h2 className={classes.numberReport}>{item.count}</h2>
                  <Box fontSize={{ xs: 16, xl: 18 }}>تماس های تصویری پاسخ داده شده</Box>
                </Box>
              </Card>
            ) : null
          )}
        </Grid>
        <Grid item style={{ width: "300px", margin: "0 5px" }}>
          {callHistory.map((item) =>
            item._id.status == "rejected" ? (
              <Card className={classes.numberReportCard}>
                <Box p={1}>
                  <img src={rejectCallLogo} className={classes.numberReportImg} />
                </Box>
                <Box>
                  <h2 className={classes.numberReport}>{item.count}</h2>
                  <Box fontSize={{ xs: 16, xl: 18 }}>تماس های تصویری ردشده</Box>
                </Box>
              </Card>
            ) : null
          )}
        </Grid>
        <Grid item style={{ width: "300px", margin: "0 5px" }}>
          {callHistory.map((item) =>
            item._id.status == "calling" ? (
              <Card className={classes.numberReportCard}>
                <Box p={1}>
                  <img src={callingCallLogo} className={classes.numberReportImg} />
                </Box>
                <Box>
                  <h2 className={classes.numberReport}>{item.count}</h2>
                  <Box fontSize={{ xs: 16, xl: 18 }}>تماس های تصویری بی پاسخ</Box>
                </Box>
              </Card>
            ) : null
          )}
        </Grid>

        <Grid item style={{ width: "300px", margin: "0 5px" }}>
          <Card className={classes.numberReportCard}>
            <Box p={1}>
              <img src={allChatLogo} className={classes.numberReportImg} />
            </Box>
            <Box>
              <h2 className={classes.numberReport}>{allChatCount}</h2>
              <Box fontSize={{ xs: 16, xl: 18 }}>تعداد کل تماس های متنی</Box>
            </Box>
          </Card>
        </Grid>
        <Grid item style={{ width: "300px", margin: "0 5px" }}>
          {callHistory.map((item) =>
            item._id.status == "answer-chat" ? (
              <Card className={classes.numberReportCard}>
                <Box p={1}>
                  <img src={answerChatLogo} className={classes.numberReportImg} />
                </Box>
                <Box>
                  <h2 className={classes.numberReport}>{item.count}</h2>
                  <Box fontSize={{ xs: 16, xl: 18 }}>تماس های متنی پاسخ داده شده</Box>
                </Box>
              </Card>
            ) : null
          )}
        </Grid>
        <Grid style={{ width: "300px", margin: "0 5px" }}>
          {callHistory.map((item) =>
            item._id.status == "calling-chat" ? (
              <Card className={classes.numberReportCard}>
                <Box p={1}>
                  <img src={rejectChatLogo} className={classes.numberReportImg} />
                </Box>
                <Box>
                  <h2 className={classes.numberReport}>{item.count}</h2>
                  <Box fontSize={{ xs: 16, xl: 18 }}>تماس های متنی رد شده</Box>
                </Box>
              </Card>
            ) : null
          )}
        </Grid>
        <Grid item style={{ width: "300px", margin: "0 5px" }}>
          {callHistory.map((item) =>
            item._id.status == "rejected-chat" ? (
              <Card className={classes.numberReportCard}>
                <Box p={1}>
                  <img src={callingChatLogo} className={classes.numberReportImg} />
                </Box>
                <Box>
                  <h2 className={classes.numberReport}>{item.count}</h2>
                  <Box fontSize={{ xs: 16, xl: 18 }}>تماس های متنی بی پاسخ</Box>
                </Box>
              </Card>
            ) : null
          )}
        </Grid>

        <MissedCalls />
        <MissedCalls24Hours />

        <CallsCount />
        <CallsResponseAvg />
        <CallsStatus />
        <WaitingAvg />
      </GridContainer>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.reports.data,
  loading: state.reports.loading,
  error: state.reports.error,
  operators: state.user.operators,
  callStatus: state.reports.callStatus,
  queues: state.reports_2.queues,
});

const mapDispatchToProps = (dispatch) => ({
  UserAction: bindActionCreators({ UserAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
