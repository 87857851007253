import React, { Fragment } from "react";

function DetailCostomer({ customer, confirmVip, userMobile, userNationalCode, loading }) {
  return (
    <Fragment>
      {customer ? (
        <div dir={"rtl"}>
          <div className={"customer-info"}>
            <span>نام:</span>

            <span>{customer.first_name}</span>
          </div>
          <div className={"customer-info"}>
            <span>نام خانوادگی:</span>

            <span>{customer.last_name}</span>
          </div>
          <div className={"customer-info"}>
            <span>کد ملی:</span>

            <span>{customer.national_code}</span>
          </div>

          <div className={"customer-info"}>
            <span>تلفن همراه:</span>

            <span>{customer.mobile}</span>
          </div>

          <div className={"customer-info"}>
            <span>مشتری VIP:</span>

            <span>{customer.vip ? "بله" : "خیر"}</span>
          </div>
        </div>
      ) : loading ? (
        "در حال بارگذاری..."
      ) : (
        <div>
          <form
            style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}
            onSubmit={(event) => confirmVip(event)}
          >
            <div className={"customer_input"}>
              <label>نام</label>
              <input name="first_name" />
            </div>

            <div className={"customer_input"}>
              <label>نام خانوادگی</label>
              <input name="last_name" />
            </div>

            <div className={"customer_input"}>
              <label>کد ملی</label>
              <input value={userNationalCode} name="national_code" />
            </div>
            <div className={"customer_input"}>
              <label>تلفن همراه</label>
              <input value={userMobile} name="mobile" />
            </div>

            <div style={{ display: "flex", direction: "rtl" }}>
              <label htmlFor={"vip"} style={{ paddingRight: "1rem", paddingLeft: "1rem" }}>
                مشتری VIP
              </label>
              <input id={"vip"} type="checkbox" name="vip" />
            </div>
            <div>
              <input value={"ثبت"} type={"submit"} className={"submit"} name="mobile" />
            </div>
          </form>
        </div>
      )}
    </Fragment>
  );
}

export default DetailCostomer;
