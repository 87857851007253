import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

function WaitingBarChart({ data, menu }) {
  const [chartData, setChartData] = useState([]);
  const convertedData = (data) => {
    let arr = [];
    data.watingTime.forEach((p) => {
      let obj = {
        Hour: 0,
        Menu: "",
        _id: "",
        Duration: 0,
      };
      obj.Menu = p.menu.name;
      obj._id = p._id.menu;
      obj.Hour = p.hour;
      obj.Duration = parseFloat(p.watingTime);
      arr.push(obj);
    });

    const queue = arr.filter((value) => value.Menu === menu);
    const sorted = queue.sort((a, b) => {
      if (a.Hour < b.Hour) {
        return -1;
      }
      if (a.Hour > b.Hour) {
        return 1;
      }
      return 0;
    });
    setChartData(sorted);
  };

  useEffect(() => {
    convertedData(data);
  }, [data]);

  const LabelCustom = ({ active, label, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            flexDirection: "column",
            backgroundColor: "white",
          }}
        >
          <h4>{`ساعت : ${label}`}</h4>
          <h4 style={{ color: payload[0].fill }}>{` میانگین زمان انتظار : ${payload[0].value}`}</h4>
        </div>
      );
    }
    return <></>;
  };

  return (
    <Box>
      <h5 style={{ backgroundColor: "var(--blueSky)", padding: "10px", boxShadow: "0 0 2px rgba(0,0,0,.5)" }}>
        میانگین زمان انتظار
      </h5>
      <Box
        width={"350px"}
        height={"200px"}
        dir={"ltr"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ResponsiveContainer>
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="Hour"
              orientation="bottom"
              allowDuplicatedCategory={false}
              type="category"
              alignmentBaseline={"auto"}
            />
            <YAxis dataKey={"Duration"} />
            <Tooltip content={<LabelCustom />} />
            {/*<Legend/>*/}
            <Bar dataKey="Duration" fill="#8884d8" barSize={8} radius={10} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
}

export default WaitingBarChart;
