import {
  LinkMessage,
  MessageList,
  Socketid,
  MessageSended,
  MessageDelivered,
  MsgId,
  SocketIo,
  EditMessage,
} from "layouts/Recoil";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import {
  CheckBoxOutlineBlankOutlined,
  DeleteOutlined,
  DoneAll,
  EditOutlined,
  TimelapseOutlined,
} from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";
import TextField from "@material-ui/core/TextField";

const styles = {
  checkIcon: {
    fontSize: ".9rem",
    color: "grey",
    alignSelf: "flex-start",
  },
  msgContainer: {
    display: "flex",
    flexDirection: "column",
  },
};
const useStyles = makeStyles(styles);

function DetailBody({ otherTyping, user, setEditMessage }) {
  const [messagList, setMessagList] = useRecoilState(MessageList);
  const [socktid, setSocktid] = useRecoilState(Socketid);
  const [linkMessag, setLinkMessag] = useRecoilState(LinkMessage);
  const [messageSended, setMessageSended] = useRecoilState(MessageSended);
  const [messageDelivered, setMessageDelivered] = useRecoilState(MessageDelivered);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(null);
  const [editMessageInput, setEditMessageInput] = useState({
    message: "",
    msg_id: "",
    is_delete: false,
  });

  const deleteMessageHandler = (msg) => {
    const obj = {
      ...deleteOpen,
      is_delete: true,
    };
    setEditMessage(obj);
    setDeleteOpen(null);
  };
  useEffect(() => {}, []);
  const editMessageHandler = (msg) => {
    const obj = {
      ...editMessageInput,
      msg_id: editMessageInput.msg_id,
      message: editMessageInput.message,
    };
    setEditMessage(obj);
    setEditOpen(false);
  };
  const chatRef = useRef(null);

  const classes = useStyles();
  useEffect(() => {
    if (chatRef && chatRef.current) chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [messagList]);
  const deliverHandler = (index, item) => {
    // eslint-disable-next-line no-prototype-builtins
    let deliveryIndex = messageDelivered.findIndex((value) => value.msg_id === item.msg_id);
    if (messageDelivered) {
      return <DoneAll className={classes.checkIcon} />;
      // eslint-disable-next-line no-prototype-builtins
    } else if (messageSended) {
      return <Check className={classes.checkIcon} />;
    } else {
      return <TimelapseOutlined className={classes.checkIcon} />;
    }
  };
  const userId = localStorage.getItem("data_nationalcode");
  const operatorId = localStorage.getItem("_id");

  const checkOperatorUser = (item) => {
    if (user && item.type === "user") {
      return true;
    } else if (!user && item.type === "operator") {
      return true;
    } else {
      return false;
    }
  };
  const getTypeUrl = (url) => {
    return url.split("?")[0].split(".").pop();
  };
  return (
    <div className="DetailBody custom-scrollbars" ref={chatRef} style={{ width: "100%" }}>
      {messagList.length > 0 &&
        messagList.map((item, index) => (
          <div
            className={`DetailBody__Message DetailBody__Message--${!checkOperatorUser(item) ? "costomer" : "operator"}`}
            key={index}
          >
            {item.file && getTypeUrl(item.message) === "webm" ? (
              <audio src={item.message} controls />
            ) : item.file ? (
              <a target="_blank" href={item.message} rel="noopener noreferrer">
                جهت دانلود فایل کلیک کنید
              </a>
            ) : (
              <div className={classes.msgContainer}>
                {item.message}
                {checkOperatorUser(item) && deliverHandler(index, item)}
                {item.operator_id === operatorId && (
                  <div>
                    <EditOutlined
                      onClick={(e) => {
                        setEditOpen(true);
                        setEditMessageInput({
                          message: item.message,
                          msg_id: item.msg_id,
                        });
                      }}
                    />
                    <DeleteOutlined
                      onClick={(e) => {
                        setDeleteOpen(item);
                      }}
                    />
                  </div>
                )}

                {deleteOpen && (
                  <SweetAlert
                    title={"از حذف پیام اطمینان دارید؟"}
                    style={{ display: "block", marginTop: "-100px" }}
                    confirmBtnText={"بله"}
                    onConfirm={(e) => deleteMessageHandler(item)}
                    onCancel={(e) => setDeleteOpen(null)}
                    cancelBtnText={"خیر"}
                    showCancel
                  />
                )}
              </div>
            )}
            <Typography textAlign={"left"} fontSize={"8px"} color={"var(--black)"}>
              {new Date(item.date).toLocaleTimeString("fa-IR", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Typography>
          </div>
        ))}
      <div
        dir="rtl"
        style={{
          textAlign: "center",
          display: `${otherTyping ? "block" : "none"}`,
          marginBottom: "1rem",
        }}
      >
        در حال نوشتن...
      </div>
      {editOpen && (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="ویرایش پیام"
          confirmBtnText="تایید"
          onConfirm={(e) => editMessageHandler()}
          onCancel={(e) => setEditOpen(false)}
          cancelBtnText="انصراف"
          showCancel
        >
          <TextField
            variant={"standard"}
            value={editMessageInput.message}
            onChange={(e) =>
              setEditMessageInput((prev) => ({
                ...prev,
                message: e.target.value,
              }))
            }
          />
        </SweetAlert>
      )}
    </div>
  );
}

export default DetailBody;
