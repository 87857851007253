import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  typing: {
    width: "50px",
    height: "8px",
    position: "relative",
    padding: "10px",
    background: "transparent",
  },
  dot1: {
    float: "left",
    width: "8px",
    height: "8px",
    margin: "0 1px",
    background: "#8d8c91",
    borderRadius: "50%",
    opacity: " 0",
    animation: "$fade 1s infinite",
    "&:nth-child(1)": {
      animationDelay: "0s",
    },
    "&:nth-child(2)": {
      animationDelay: "0.2s",
    },
    "&:nth-child(3)": {
      animationDelay: "0.4s",
    },
  },
  dot2: {},
  dot3: {},
  "@keyframes fade": {
    " 0%": {
      opacity: 0,
    },
    "50%": {
      opacity: 0.8,
    },
    "100%": {
      opacity: 0,
    },
  },
});

function IsTyping(props) {
  const classes = useStyles();
  return (
    <div className={classes.typing}>
      <div className={classes.dot1}></div>
      <div className={classes.dot1}></div>
      <div className={classes.dot1}></div>
    </div>
  );
}

export default IsTyping;
