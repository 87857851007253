import { Message, MsgId, SocketIo } from "layouts/Recoil";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { ReactComponent as Arroe } from "../../assets/img/icons/Frame 28.svg";
import attachPic from "../../assets/img/pic/attach.png";
import { AttachFile, Send } from "@material-ui/icons";

function DetailChat({ sendMessage, setFlagSend, setUploadEvent, uploadEvent, onTypingMessage, user }) {
  const [message, setMessageForm] = useRecoilState(Message);
  const [msgId, setMsgId] = useRecoilState(MsgId);

  // const onKeyDown = (event) => {
  //     if (event.key === "Enter") {
  //         sendMessage();
  //     }
  // };
  const onKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    } else if (event.key === "Enter" && event.shiftKey) {
    }
  };
  useEffect(() => {
    // document.getElementById("usermsg").addEventListener("keypress", submitOnEnter);
  }, []);

  function submitOnEnter(event) {
    if (event.which === 13 && !event.shiftKey) {
      event.target.form.dispatchEvent(new Event("submit", { cancelable: true }));
      event.preventDefault(); // Prevents the addition of a new line in the text field (not needed in a lot of cases)
    }
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    //
    sendMessage();
  };

  useEffect(() => {
    if (uploadEvent !== "") {
      return setMessageForm(uploadEvent.name);
    }
  }, [uploadEvent]);

  return (
    <div className="Detail__DetailChat ">
      <div className="Detail__attachIcon" style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
        <label htmlFor="selectFile">
          <AttachFile style={{ cursor: "pointer" }} />
        </label>
        <input
          style={{ display: "none" }}
          accept=".jpg,.jpeg,.doc,.docx,.xls,.xlsx,.pdf"
          className=" Detail__attach "
          id={"selectFile"}
          type={"file"}
          onChange={(e) => {
            setFlagSend(false);
            setUploadEvent(e.target.files[0]);
          }}
        />
      </div>
      {/*<div className="Detail__attachIcon">*/}
      {/*    <input*/}
      {/*        className=" Detail__attach "*/}
      {/*        // id={"selectFile"}*/}
      {/*        type={"file"}*/}
      {/*        onChange={(e) => {*/}
      {/*            setFlagSend(false);*/}
      {/*            setUploadEvent(e.target.files[0]);*/}
      {/*        }}*/}
      {/*    />*/}
      {/*    <div className="Detail__Img">*/}
      {/*        <img src={attachPic} style={{width: "2rem", height: "2rem"}}/>*/}
      {/*    </div>*/}
      {/*    /!* <i className="fas fa-paperclip  Detail__attach--icon"></i> *!/*/}
      {/*</div>*/}

      {/*{uploadEvent !== "" && <p className="Detail__attach--name">{uploadEvent.name}</p>}*/}

      <form onSubmit={handleSubmit(onSubmit)} className="  Detail__form" style={{ padding: 0 }} onKeyDown={onKeyDown}>
        <div className="Detail__input">
          <textarea
            id={"usermsg"}
            rows={2}
            {...register("text", {
              required: false,
            })}
            // className={classNames("input__field")}
            className={"Detail__textarea"}
            value={message}
            style={{ margin: "15px" }}
            onChange={(e) => {
              setMessageForm(e.target.value);
              onTypingMessage();
              // sendMessageDelivery()
            }}
            placeholder="پیام خود را بنویسید..."
          />
        </div>
        <div>
          <Send onClick={handleSubmit(onSubmit)} className="cursor-pointer Detail__icon" />
        </div>
      </form>
    </div>
  );
}

export default DetailChat;
