import { Hidden } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import PersonIcon from "@material-ui/icons/Person";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux/";
import { useHistory } from "react-router-dom";
import DeafaultOperator from "../../assets/img/pic/operator_default.png";
import { ApiUrl, Url } from "../../config";
import UploadProfile from "./UploadProfile/UploadProfile";
import styles from "./UserProfile.module.scss";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    ...props,
    // minWidth: "275px",
    width: "100%",
    height: "100%",

    overflow: "visible",
    // marginTop : '5rem',
    borderRadius: "4px",
    border: "1px solid var(--lightGray)",
    // padding: "0 1rem 1rem 1rem ",
    position: "relative",
  }),

  title: {
    fontSize: 20,
    marginBottom: "2rem",
  },

  text: {
    marginBottom: "1rem",
  },

  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    // position: "relative",
    // right: "35%",
    // top: "-4rem",
    backgroundColor: "rgba($color: #00acc1, $alpha: 1)",
    zIndex: "5",
    width: theme.spacing(12),
    height: theme.spacing(12),
    boxShadow: "0px 0px 15px 4px rgba(200,200,200,0.4)",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const UserProfile = (props) => {
  const classes = useStyles(props.style);
  let history = useHistory();

  const [data, setData] = useState({
    email: "",
    username: "",
    first_name: "",
    last_name: "",
    national_code: "",
    mobile: "",
  });

  const [operatorData, setOperatorData] = useState();

  const [showModal, setShowModal] = useState(false);
  const [showProfileChange, setShowProfileChange] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    getUser();
  }, []);
  let avatarPic = localStorage.getItem("profile") ? (
    <Avatar
      alt="Profile"
      src={localStorage.getItem("profile")}
      className={[classes.large, styles.avatar].join(" ")}
      onMouseOver={() => setShowProfileChange(true)}
    >
      <PersonIcon />
    </Avatar>
  ) : (
    <Avatar
      alt="Profile"
      src={DeafaultOperator}
      className={[classes.large, styles.avatar].join(" ")}
      onMouseOver={() => setShowProfileChange(true)}
    >
      <PersonIcon />
    </Avatar>
  );
  const getUser = () => {
    let id = localStorage.getItem("_id");
    axios
      .get(ApiUrl + "/get-user/" + id, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        // setOperatorData(response.data.data);
        setData({
          email: response.data.data.user.email,
          first_name: response.data.data.user.first_name,
          last_name: response.data.data.user.last_name,
          national_code: response.data.data.user.national_code,
          mobile: response.data.data.user.mobile,
          username: response.data.data.user.username,
        });
        return response.data.data.user;
      })
      .then((res) => {
        localStorage.setItem("profile", Url + res.avatar);
        // if(res &&  res.avatar){
        //
        //   avatarPic =

        // }
      })
      .catch(function (error) {});
    // .finally(function () {});
  };

  return (
    <>
      {showModal ? <UploadProfile closeModal={closeModal} /> : null}
      <Card className={classes.root}>
        <div style={{ display: "flex", width: "100%", position: "absolute", top: "-80px" }}>
          {showProfileChange ? (
            <Avatar
              style={{ marginLeft: "auto", marginRight: "auto" }}
              className={[classes.large].join(" ")}
              onClick={() => setShowModal(true)}
              onMouseLeave={() => setShowProfileChange(false)}
            >
              <div className={styles.profileChange}>
                <div className={styles.profileContainer}>
                  <span className={styles.profileIcon}>
                    <AddIcon />
                  </span>
                  <p className={styles.profileText}>افزودن تصویر</p>
                </div>
                {/* <div className={styles.profileContainer} >
                        <span className={styles.profileIcon} ><EditIcon /></span>
                        <p className={styles.profileText} >ویرایش تصویر</p>   
                    </div> */}
              </div>
            </Avatar>
          ) : (
            avatarPic
          )}
        </div>
        {/* <CardContent style={{position : 'relative',top : '-4rem'}}> */}
        <div>
          <CardContent>
            <Grid container spacing={1} justifyContent={"center"} alignContent={"center"}>
              <Grid item xs={12}>
                <h5 style={{ textAlign: "center" }} className={classes.title} color="textSecondary">
                  اپراتور
                </h5>
              </Grid>

              <Grid container xs={12} className={styles.profile} justifyContent={"start"} alignContent={"center"}>
                <Grid item xs={6} className={styles.profile} justifyContent={"center"} alignContent={"center"}>
                  <Hidden only={["lg", "xl", "xs"]}>
                    <p color="textSecondary" className={classes.text} style={{ textAlign: "start", fontSize: "12px" }}>
                      نام
                    </p>
                  </Hidden>

                  <Hidden only={["sm", "md"]}>
                    <p color="textSecondary" className={classes.text} style={{ textAlign: "start" }}>
                      نام
                    </p>
                  </Hidden>
                </Grid>
                <Grid item xs={6} className={styles.name} justifyContent={"center"} alignContent={"center"}>
                  <Hidden only={["lg", "xl", "xs"]}>
                    <p
                      color="textSecondary"
                      className={classes.text}
                      style={{
                        textAlign: "end",
                        height: "22px",
                        overflow: "auto",
                        fontSize: "12px",
                      }}
                    >
                      {data.first_name + " " + data.last_name}
                    </p>
                  </Hidden>

                  <Hidden only={["sm", "md"]}>
                    <p
                      color="textSecondary"
                      className={classes.text}
                      style={{ textAlign: "end", height: "22px", overflow: "auto" }}
                    >
                      {data.first_name + " " + data.last_name}
                    </p>
                  </Hidden>
                </Grid>
              </Grid>

              <Grid container xs={12} className={styles.profile} justifyContent={"center"} alignContent={"center"}>
                <Grid item xs={6} className={styles.profile} justifyContent={"center"} alignContent={"center"}>
                  <Hidden only={["lg", "xl", "xs"]}>
                    <p color="textSecondary" className={classes.text} style={{ textAlign: "start", fontSize: "12px" }}>
                      ایمیل
                    </p>
                  </Hidden>

                  <Hidden only={["sm", "md"]}>
                    <p color="textSecondary" className={classes.text} style={{ textAlign: "start" }}>
                      ایمیل
                    </p>
                  </Hidden>
                </Grid>
                <Grid item xs={6} className={styles.email} justifyContent={"center"} alignContent={"center"}>
                  <Hidden only={["lg", "xl", "xs"]}>
                    <p color="textSecondary" className={classes.text} style={{ textAlign: "end", fontSize: "12px" }}>
                      {data.email}
                    </p>
                  </Hidden>

                  <Hidden only={["sm", "md"]}>
                    <p color="textSecondary" className={classes.text} style={{ textAlign: "end" }}>
                      {data.email}
                    </p>
                  </Hidden>
                </Grid>
              </Grid>

              <Grid container xs={12} className={styles.profile} justifyContent={"center"} alignContent={"center"}>
                <Grid item xs={6} className={styles.profile} justifyContent={"center"} alignContent={"center"}>
                  <Hidden only={["lg", "xl", "xs"]}>
                    <p color="textSecondary" className={classes.text} style={{ textAlign: "start", fontSize: "12px" }}>
                      وضعیت
                    </p>
                  </Hidden>

                  <Hidden only={["sm", "md"]}>
                    <p color="textSecondary" className={classes.text} style={{ textAlign: "start" }}>
                      وضعیت
                    </p>
                  </Hidden>
                </Grid>
                <Grid item xs={6} className={styles.profile} justifyContent={"center"} alignContent={"center"}>
                  <Hidden only={["lg", "xl", "xs"]}>
                    <p color="textSecondary" className={classes.text} style={{ textAlign: "end", fontSize: "12px" }}>
                      {props.userStatus === "on" ? (
                        <p style={{ color: "limegreen" }}>فعال</p>
                      ) : (
                        <p style={{ color: "#f50057" }}>غیر فعال</p>
                      )}
                    </p>
                  </Hidden>

                  <Hidden only={["sm", "md"]}>
                    <p color="textSecondary" className={classes.text} style={{ textAlign: "end" }}>
                      {props.userStatus === "on" ? (
                        <p style={{ color: "limegreen" }}>فعال</p>
                      ) : (
                        <p style={{ color: "#f50057" }}>غیر فعال</p>
                      )}
                    </p>
                  </Hidden>
                </Grid>
              </Grid>

              <Grid container xs={12} className={styles.profile} justifyContent={"center"} alignContent={"center"}>
                <Grid item xs={6} className={styles.profile} justifyContent={"center"} alignContent={"center"}>
                  <Hidden only={["lg", "xl", "xs"]}>
                    <p color="textSecondary" className={classes.text} style={{ textAlign: "start", fontSize: "12px" }}>
                      صف
                    </p>
                  </Hidden>

                  <Hidden only={["sm", "md"]}>
                    <p color="textSecondary" className={classes.text} style={{ textAlign: "start" }}>
                      صف
                    </p>
                  </Hidden>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={styles.queues}
                  justifyContent={"center"}
                  alignContent={"center"}
                  style={{
                    textAlign: "end",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  {props.userQueues.length > 0 ? (
                    props.userQueues.map((queue) => {
                      return (
                        <Chip
                          key={queue}
                          style={{ margin: "0 1px", color: "#fff", backgroundColor: "#00acc1" }}
                          label={queue}
                          color="secondary"
                          variant="default"
                        />
                      );
                    })
                  ) : props.userStatus === "on" ? (
                    alert("تب غیر اصلی")
                  ) : (
                    <>
                      <Hidden only={["lg", "xl", "xs"]}>
                        <p
                          color="textSecondary"
                          className={classes.text}
                          style={{
                            textAlign: "end",
                            display: "inline",
                            color: "#9d9d9d",
                            height: "22px",
                            overflow: "auto",
                            fontSize: "12px",
                          }}
                        >
                          بدون صف
                        </p>
                      </Hidden>

                      <Hidden only={["sm", "md"]}>
                        <p
                          color="textSecondary"
                          className={classes.text}
                          style={{
                            textAlign: "end",
                            display: "inline",
                            color: "#9d9d9d",
                            height: "22px",
                            overflow: "auto",
                          }}
                        >
                          بدون صف
                        </p>
                      </Hidden>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions style={{ width: "100%" }}>
            <Button
              style={{ width: "100%", backgroundColor: "#00acc1", color: "#fff" }}
              variant="contained"
              // color="secondary"
              size="large"
              onClick={() => window.open("/admin/profile", "_blank")}
            >
              بیشتر
            </Button>
          </CardActions>
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.user.data,
  loading: state.user.loading,
  error: state.user.error,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
