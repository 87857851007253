import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import moment from "moment-jalaali";
import clsx from "clsx";

const styles = {
  container: {
    width: "80%",
    height: "80%",
    position: "absolute",
    top: 0,
    margin: "auto",
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: "9999",
    background: "white",
    boxShadow: "0 0 10px",
    borderRadius: "10px",
    padding: "10px",
    overflow: "hidden",
    maxWidth: "1000px",
    animation: "$open-box .5s",
  },
  containerExit: {
    animation: "$close-box .5s",
    opacity: 0,
  },
  iconContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: "10px",
  },
  icon: {
    fontSize: "2rem",
    cursor: "pointer",
  },
  wrapper: {
    width: "100%",
    height: "100%",
    padding: "25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto",
    gap: "5px",
  },
  operator: {
    padding: "5px 10px",
    background: "var(--lightCeladon)",
    float: "right",
    alignSelf: "flex-start",
    borderRadius: "5px",
  },
  user: {
    padding: "5px 10px",
    background: "var(--lightGray)",
    float: "left",
    alignSelf: "flex-end",
    borderRadius: "5px",
  },
  "@keyframes open-box": {
    from: {
      transform: "translateX(-50%) scale(0%)",
      opacity: 0,
    },
    to: {
      transform: "translateX(0) scale(100%)",
      opacity: 1,
    },
  },
  "@keyframes close-box": {
    from: {
      transform: "translateX(0%) scale(100%)",
    },
    to: {
      transform: "translateX(-100%) scale(0)",
    },
  },
};
const useStyle = makeStyles(styles);

function MessageDetailPopUp(props) {
  const classes = useStyle();
  const [exit, setExit] = useState(false);
  const { showMsg, setShowMsg, messages } = props;
  return (
    <div className={clsx(classes.container, { [classes.containerExit]: !showMsg })}>
      <div className={classes.iconContainer}>
        <CloseRounded
          className={classes.icon}
          onClick={(e) => {
            setExit(true);
            setShowMsg(false);
          }}
        />
      </div>
      <div className={classes.wrapper}>
        {messages.length > 0
          ? messages.map((p) => {
              return (
                <div key={p._id} className={p.type == "operator" ? classes.operator : classes.user}>
                  {p.message}

                  <br />

                  <span
                    style={{
                      padding: 5,
                      margin: 5,
                      float: "left",
                      fontSize: 10,
                    }}
                  >
                    {moment(p.date, "YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD HH:mm:ss")}
                  </span>
                </div>
              );
            })
          : "پیامی وجود ندارد"}
      </div>
    </div>
  );
}

export default MessageDetailPopUp;
