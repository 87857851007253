import {
  GET_CLIENTS_FAILED,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_INPUT_CALL_FAILED,
  GET_INPUT_CALL_REQUEST,
  GET_INPUT_CALL_SUCCESS,
  GET_QUEUES_FAILED,
  GET_QUEUES_REQUEST,
  GET_QUEUES_SUCCESS,
  GET_QUEUE_ENTER_EXIT_FAILED,
  GET_QUEUE_ENTER_EXIT_REQUEST,
  GET_QUEUE_ENTER_EXIT_SUCCESS,
  GET_TRANSFER_CALL_FAILED,
  GET_TRANSFER_CALL_REQUEST,
  GET_TRANSFER_CALL_SUCCESS,
} from "../type/user_type";

const initialState = {
  data: null,
  loading: false,
  error: null,
  message: null,
  code: null,
  pagination: null,
  pagination_inputCall: null,
  enterExitQueue: null,
  transferCall: null,
  clients: null,
  queues: null,
  inputCall: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSFER_CALL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_TRANSFER_CALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
        transferCall: action.payload.data.transferCalls,
        pagination: action.payload.data.pagination,
      };
    }
    case GET_TRANSFER_CALL_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_QUEUE_ENTER_EXIT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_QUEUE_ENTER_EXIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
        enterExitQueue: action.payload.data.enterExitQueue,
        pagination: action.payload.data.pagination,
      };
    }
    case GET_QUEUE_ENTER_EXIT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_CLIENTS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CLIENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
        clients: action.payload.data.clients,
        pagination: action.payload.data.pagination,
      };
    }
    case GET_CLIENTS_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_QUEUES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_QUEUES_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
        queues: action.payload.data,
      };
    }
    case GET_QUEUES_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_INPUT_CALL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_INPUT_CALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
        inputCall: action.payload.data.histories,
        pagination_inputCall: action.payload.data.pagination,
      };
    }
    case GET_INPUT_CALL_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
