import React, { useEffect, useState } from "react";
import CardHeader from "components/Card/CardHeader";
import styles from "../../style/Recharts/MissedCalls.module.scss";
import Select from "react-select";
import ButtonXlsx from "components/Recharts/ButtonChartXls/ButtonXlsx";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Loading from "components/Loading";
import Card from "components/Card/Card";
import axios from "axios";
import { ApiUrl } from "../../config";
import { toast, ToastContainer } from "react-toastify";

function MissedCalls(props) {
  const [days, setDays] = useState(16);
  const [chartData, setChartData] = useState([]);
  const [filterMode, setFilterMode] = useState("0");
  const [loading, setLoading] = useState(false);
  const [tickDate, setTickDate] = useState({});
  const [apiRequest, setApiRequest] = useState("inputcall-past");

  const token = localStorage.getItem("token");
  const daysOption = [
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 15, label: 15 },
    { value: 30, label: 30 },
    { value: 60, label: 60 },
    { value: 90, label: 90 },
  ];
  const getMissedCall = async () => {
    try {
      setLoading(true);
      const res = await axios.get(ApiUrl + `/inputcall-past-day`, { headers: { Authorization: token } });
      convertData(res.data.data);
      setLoading(false);
    } catch (e) {
      toast.error(e.message);
      setLoading(false);
    }
  };
  const convertData = (data) => {
    const arr = [];
    data.forEach((item, index) => {
      if (item.missed == false) {
        index++;
      } else {
        arr.push(item);
      }
    });
    const reduce = arr.reduce((total, current) => {
      let index = total.findIndex((value) => value.date === current.date);
      index === -1 ? total.push(current) : (total[index].date = total[index].date);
      return total;
    }, []);
    const sorted = reduce.sort((a, b) => {
      if (a.date < b.date) {
        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }
      return 0;
    });
    //
    setChartData(sorted);
  };
  useEffect(() => {
    getMissedCall();
  }, [days]);

  const selectDayHandler = (e) => {
    setDays(e.value);
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: "1px solid #9c27b0",
      transition: ".5s",
    }),
    option: (provided, state) => ({
      fontSize: "1rem",
      borderBottom: "none",
      color: state.isSelected ? "black" : "blue",
      fontWeight: state.isSelected && "bold",
      padding: 20,
      borderRadius: "5px",
      cursor: "pointer",
      "&:hover": {
        color: "white",
        background: "#9c27b0",
        transition: ".3s",
      },
    }),
    control: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      width: 200,
      fontSize: "1rem",
      border: "1px solid black",
      borderRadius: "5px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const LabelCustom = ({ active, label, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.customLabel}>
          <h4>{`تاریخ : ${new Date(label).toLocaleDateString("fa-IR")}`}</h4>
          <h4>{` تعداد تماس از دست رفته :${payload[0].value} `}</h4>
        </div>
      );
    }
    return <></>;
  };

  return (
    <Card>
      <CardHeader color="info">
        <h4>تعداد تماس های از دست رفته</h4>
      </CardHeader>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/*<div className={styles.filters}>*/}
          {/*    <div className={styles.filterWrapper}>*/}
          {/*        <div className={styles.selectContainer}>*/}
          {/*            /!*<BsSortDown className={styles.icons}/>*!/*/}

          {/*            <Select*/}
          {/*                styles={customStyles}*/}
          {/*                defaultValue={days}*/}
          {/*                options={daysOption}*/}
          {/*                onChange={selectDayHandler}*/}
          {/*                placeholder={"تعداد روز های گذشته"}*/}
          {/*                className={styles.SelectBox}*/}
          {/*            />*/}
          {/*        </div>*/}
          {/*        /!* <ButtonXlsx apiRequest={apiRequest} days={days}/> *!/*/}
          {/*    </div>*/}
          {/*</div>*/}
          <div className={styles.chart}>
            <ResponsiveContainer className={styles.rechartResponsiveContainer}>
              {!loading ? (
                <LineChart
                  width={300}
                  height={300}
                  data={chartData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis
                    dataKey={"date"}
                    tickFormatter={(tick) => new Date(tick).toLocaleDateString("fa-IR")}
                    orientation="bottom"
                    allowDuplicatedCategory={false}
                    type="category"
                    alignmentBaseline={"auto"}
                  />
                  <YAxis dataKey={"count"} orientation="left" />
                  <Tooltip cursor={{ stroke: "grey", strokeWidth: 2 }} content={<LabelCustom />} />
                  <Line
                    type="monotone"
                    dataKey="count"
                    stroke="#9c27b0"
                    dot={{ stroke: "#9c27b0", strokeWidth: 1, r: 4, strokeDasharray: "" }}
                  />
                </LineChart>
              ) : (
                <Loading loading={loading} />
              )}
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Card>
  );
}

export default MissedCalls;
