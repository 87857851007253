const prefix = "USER/";

export const CREATE_OPERATOR_SUCCESS = prefix + "CREATE_OPERATOR_SUCCESS";

export const GET_REPORT_LIST_REQUEST = prefix + "GET_REPORT_LIST_REQUEST";
export const GET_REPORT_LIST_SUCCESS = prefix + "GET_REPORT_LIST_SUCCESS";
export const GET_REPORT_LIST_FAILED = prefix + "GET_REPORT_LIST_FAILED";

export const GET_QUEUES_REQUEST = prefix + "GET_QUEUES_REQUEST";
export const GET_QUEUES_SUCCESS = prefix + "GET_QUEUES_SUCCESS";
export const GET_QUEUES_FAILED = prefix + "GET_QUEUES_FAILED";

export const GET_INPUT_CALL_REQUEST = prefix + "GET_INPUT_CALL_REQUEST";
export const GET_INPUT_CALL_SUCCESS = prefix + "GET_INPUT_CALL_SUCCESS";
export const GET_INPUT_CALL_FAILED = prefix + "GET_INPUT_CALL_FAILED";

export const GET_OPERATOR_REQUEST = prefix + "GET_OPERATOR_REQUEST";
export const GET_OPERATOR_SUCCESS = prefix + "GET_OPERATOR_SUCCESS";
export const GET_OPERATOR_FAILED = prefix + "GET_OPERATOR_FAILED";

export const FIND_HISTORY_CLIENT_REQUEST = prefix + "FIND_HISTORY_CLIENT_REQUEST";
export const FIND_HISTORY_CLIENT_SUCCESS = prefix + "FIND_HISTORY_CLIENT_SUCCESS";
export const FIND_HISTORY_CLIENT_FAILED = prefix + "FIND_HISTORY_CLIENT_FAILED";

export const FIND_HISTORY_CLIENT_CALL_REQUEST = prefix + "FIND_HISTORY_CLIENT_CALL_REQUEST";
export const FIND_HISTORY_CLIENT_CALL_SUCCESS = prefix + "FIND_HISTORY_CLIENT_CALL_SUCCESS";
export const FIND_HISTORY_CLIENT_CALL_FAILED = prefix + "FIND_HISTORY_CLIENT_CALL_FAILED";

export const GET_MID_CALLS_REQUEST = prefix + "GET_MID_CALLS_REQUEST";
export const GET_MID_CALLS_SUCCESS = prefix + "GET_MID_CALLS_SUCCESS";
export const GET_MID_CALLS_FAILED = prefix + "GET_MID_CALLS_FAILED";

export const GET_OUT_CALL_REQUEST = prefix + "GET_OUT_CALL_REQUEST";
export const GET_OUT_CALL_SUCCESS = prefix + "GET_OUT_CALL_SUCCESS";
export const GET_OUT_CALL_FAILED = prefix + "GET_OUT_CALL_FAILED";

export const GET_TRANSFER_CALL_REQUEST = prefix + "GET_TRANSFER_CALL_REQUEST";
export const GET_TRANSFER_CALL_SUCCESS = prefix + "GET_TRANSFER_CALL_SUCCESS";
export const GET_TRANSFER_CALL_FAILED = prefix + "GET_TRANSFER_CALL_FAILED";

export const GET_CLIENTS_REQUEST = prefix + "GET_CLIENTS_REQUEST";
export const GET_CLIENTS_SUCCESS = prefix + "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAILED = prefix + "GET_CLIENTS_FAILED";

export const GET_TEXT_BOX_REQUEST = prefix + "GET_TEXT_BOX_REQUEST";
export const GET_TEXT_BOX_SUCCESS = prefix + "GET_TEXT_BOX_SUCCESS";
export const GET_TEXT_BOX_FAILED = prefix + "GET_TEXT_BOX_FAILED";

export const GET_TEXT_BOX_MESSAGE_REQUEST = prefix + "GET_TEXT_BOX_MESSAGE_REQUEST";
export const GET_TEXT_BOX_MESSAGE_SUCCESS = prefix + "GET_TEXT_BOX_MESSAGE_SUCCESS";
export const GET_TEXT_BOX_MESSAGE_FAILED = prefix + "GET_TEXT_BOX_MESSAGE_FAILED";

export const GET_MEDIA_BOX_REQUEST = prefix + "GET_MEDIA_BOX_REQUEST";
export const GET_MEDIA_BOX_SUCCESS = prefix + "GET_MEDIA_BOX_SUCCESS";
export const GET_MEDIA_BOX_FAILED = prefix + "GET_MEDIA_BOX_FAILED";

export const GET_POLL_LIST_REQUEST = prefix + "GET_POLL_LIST_REQUEST";
export const GET_POLL_LIST_SUCCESS = prefix + "GET_POLL_LIST_SUCCESS";
export const GET_POLL_LIST_FAILED = prefix + "GET_POLL_LIST_FAILED";

export const GET_QUEUE_ENTER_EXIT_REQUEST = prefix + "GET_QUEUE_ENTER_EXIT_REQUEST";
export const GET_QUEUE_ENTER_EXIT_SUCCESS = prefix + "GET_QUEUE_ENTER_EXIT_SUCCESS";
export const GET_QUEUE_ENTER_EXIT_FAILED = prefix + "GET_QUEUE_ENTER_EXIT_FAILED";

export const GET_USER_LIST_REQUEST = prefix + "GET_USER_LIST_REQUEST";
export const GET_USER_LIST_SUCCESS = prefix + "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILED = prefix + "GET_USER_LIST_FAILED";

export const GET_HISTORY_LIST_REQUEST = prefix + "GET_HISTORY_LIST_REQUEST";
export const GET_HISTORY_LIST_SUCCESS = prefix + "GET_HISTORY_LIST_SUCCESS";
export const GET_HISTORY_LIST_FAILED = prefix + "GET_HISTORY_LIST_FAILED";

export const GET_HISTORY_REQUEST = prefix + "GET_HISTORY_REQUEST";
export const GET_HISTORY_SUCCESS = prefix + "GET_HISTORY_SUCCESS";
export const GET_HISTORY_FAILED = prefix + "GET_HISTORY_FAILED";

export const CREATE_USER_REQUEST = prefix + "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = prefix + "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILED = prefix + "CREATE_USER_FAILED";

export const LOGIN_REQUEST = prefix + "LOGIN_REQUEST";
export const LOGIN_SUCCESS = prefix + "LOGIN_SUCCESS";
export const LOGIN_SFAILED = prefix + "LOGIN_SFAILED";

export const CREATE_REQUEST = prefix + "CREATE_REQUEST";
export const CREATE_SUCCESS = prefix + "CREATE_SUCCESS";
export const CREATE_FAILED = prefix + "CREATE_FAILED";

export const GET_RESPONSE_CALL_LIST_REQUEST = prefix + "GET_RESPONSE_CALL_LIST_REQUEST";
export const GET_RESPONSE_CALL_LIST_SUCCESS = prefix + "GET_RESPONSE_CALL_LIST_SUCCESS";
export const GET_RESPONSE_CALL_LIST_FAILED = prefix + "GET_RESPONSE_CALL_LIST_FAILED";

export const GET_CALL_STATUS_REQUEST = prefix + "GET_CALL_STATUS_REQUEST";
export const GET_CALL_STATUS_SUCCESS = prefix + "GET_CALL_STATUS_SUCCESS";
export const GET_CALL_STATUS_FAILED = prefix + "GET_CALL_STATUS_FAILED";
