import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@mui/material";
import backspace from "../../assets/img/icons/backspace.svg";
import { Line } from "rc-progress";
import "react-step-progress-bar/styles.css";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import waitingGif from "../../assets/img/icons/waitingGit.gif";
import axios from "axios";
import { ApiUrl, Url } from "../../config";
import profile from "../../assets/img/faces/avatar.png";

const useStyles = makeStyles({
  container: {
    background: "linear-gradient(139deg, #FF9C67 0%, #FF751A 58.3%)",
    width: "100%",
    height: "100dvh",
    display: "flex",
    flexDirection: "column",
    padding: "48px 24px",
    overflow: "hidden",
  },
  backspace: {
    backgroundColor: "rgba(255, 255, 255, 0.10)",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    padding: "8px",
    width: "40px",
    height: "40px",
    borderRadius: "8px",
  },
});

function MobileChatWaiting({ userCount, cancel, onlineOperators }) {
  const classes = useStyles();
  const [operators, setOperators] = useState([]);
  const getOperators = async () => {
    await axios
      .get(ApiUrl + "/get-users", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        setOperators(res.data.data.users);
      })
      .catch((res) => {});
  };

  useEffect(() => {}, [operators]);
  return (
    <div className={classes.container}>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
        <Box className={classes.backspace}>
          <img src={backspace} alt="icon" style={{ width: "14px", height: "10px" }} onClick={cancel} />
        </Box>
        <Box>
          <Typography fontSize={"12px"} color={"var(--white)"}>
            حامی چت
          </Typography>
          <Typography fontSize={"8px"} color={"var(--mbText)"}>
            1.0.1
          </Typography>
        </Box>
        <Box width={"40px"} height={"40px"}></Box>
      </Box>
      <Box marginTop={"40px"}>
        <Box margin={"8px 0"} width={"100%"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Box display={"flex"}>
            {onlineOperators.slice(0, 4).map((item, index) => {
              const right = index.toString().padEnd(2, "0");
              return (
                <img
                  key={index}
                  src={item.avatar ? Url + item.avatar : profile}
                  style={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "50%",
                    position: "relative",
                    right: "-0" + Number(right) + "px",
                  }}
                />
              );
            })}
          </Box>
          <Box>
            <Typography fontSize={"10px"} color={"var(--white)"}>
              {userCount} نفر تا اتصال به اپراتورها
            </Typography>
          </Box>
        </Box>
        <Box width={"100%"}>
          <ProgressBar cancel={cancel} userCount={userCount} />
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        marginTop={"162px"}
        gap={"24px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography fontSize={"16px"} color={"var(--white)"}>
          لطفا شکیبا باشید
        </Typography>
        <Typography color={"var(--white)"} fontSize={"16px"}>
          در حال اتصال به اولین اپراتور آنلاین...
        </Typography>
        <img width={"104px"} height={"104px"} src={waitingGif} alt="gif" />
      </Box>
    </div>
  );
}

export default MobileChatWaiting;
