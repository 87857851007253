/* eslint-disable no-console */
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import Button from "components/CustomButtons/Button";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment-jalaali";
import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import { Helmet } from "react-helmet";
import { connect } from "react-redux/";
import { bindActionCreators } from "redux";
import { ApiUrl, ApiUrlConference } from "../../config";
import UserAction from "../../js/actions/user_action";
import { toast } from "react-toastify";

import deactiveAnimation from "../../assets/deactive.json";
import activeAnimation from "../../assets/active.json";
import Lottie from "lottie-react";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
  inputContainer: {
    border: "1px solid black",
    borderRadius: "10px",
    overflow: "hidden",
  },
  welcomeInput: {
    width: "100%",
    height: "100%",
    border: "none",
    padding: "10px",
  },
  welcomeSaveBtn: {
    padding: "10px 15px",
    borderRadius: "10px",
    border: "none",
    background: roseColor,
  },
};

const useStyles = makeStyles(styles);

function Users(props) {
  const [loading, isLoading] = useState(false);
  const [startTimeAt, setStarTimetAt] = useState(moment());
  const [endTimeAt, setEndTimetAt] = useState(moment());
  const [waitingTimeAt, setwaitingTimetAt] = useState(moment().format("HH:mm"));
  const [option, setOption] = useState("");
  const [startTime, setStart] = useState("");
  const [endTime, setEnd] = useState("");
  const [watingTime, setWating] = useState("");
  const [welcomeData, setWelcomeData] = useState("");
  const [textArea, setTextArea] = useState("");
  const token = localStorage.getItem("token");
  const [conference, setConference] = useState(false);

  const checkConferenceRoom = async () => {
    // await axios
    //   .get(ApiUrlConference)
    //   .then((res) => (res.status === 200 ? setConference(true) : setConference(false)))
    //   .catch((res) => (res.status === 200 ? setConference(true) : setConference(false)));
  };

  useEffect(() => {
    checkConferenceRoom();
  }, []);
  const submitWelcomeMsg = async () => {
    try {
      let res = await axios.post(
        ApiUrl + "/save-option",
        {
          title: "wellcom_msg",
          value: textArea,
          status: "1",
        },
        { headers: { Authorization: token } }
      );
      if (res.status == 200) {
        toast.success("متن خوش آمد گویی با موفقیت ذخیره شد");
      }
      
    } catch (e) {
      toast.error(e.message);
    }
  };
  const changeStatus = async () => {
    try {
      let res = await axios.post(
        ApiUrl + "/save-option",
        {
          title: "wellcom_msg",
          status: "0",
        },
        { headers: { Authorization: token } }
      );
      
      if (res.status == 200) {
        toast.success("وضعیت با موفقیت تغییر کرد");
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  function onSubmit(e) {
    e.preventDefault();

    isLoading(true);

    axios({
      url: ApiUrl + "/save-option",
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        title: "working_hours",
        value: JSON.stringify({
          start: moment(startTimeAt).format("HH:mm"),
          end: moment(endTimeAt).format("HH:mm"),
          waiting: moment(waitingTimeAt).format("HH:mm:ss"),
        }),
      },
    })
      .then(function (response) {
        isLoading(false);
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  }

  const getWelcomeMsg = async () => {
    await axios
      .get(ApiUrl + "/get-wellcom-msg", { headers: { Authorization: token } })
      .then((res) => {
        setWelcomeData(res.data.data.option);
      })
      .catch((res) => {
        
      });
  };
  useEffect(() => {
    getWelcomeMsg();
  }, []);
  useEffect(() => {
    axios
      .get(ApiUrl + "/get-working-time", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        // 
        // 
        let workink_time = JSON.parse(response.data.data.option.value);
        // 
        setOption(workink_time);
      })
      .catch(function (error) {})
      .finally(function () {});
  }, [null]);

  const classes = useStyles();

  if (props.loading) return <div>در حال ارسال...</div>;
  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>تنظیمات - حامی چت</title>
      </Helmet>
      <form id={"from"} onSubmit={(e) => onSubmit(e)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <form>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>اختصاص زمان کاری</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={5} lg={3}>
                      <InputLabel className={classes.label}>ساعت شروع</InputLabel>
                      <FormControl fullWidth>
                        <Datetime
                          dateFormat={false}
                          timeFormat={"HH:mm"}
                          onChange={(value) => setStarTimetAt(value)}
                          value={startTimeAt}
                          inputProps={{ placeholder: "انتخاب ساعت" }}
                        />
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={5} lg={3}>
                      <InputLabel className={classes.label}>ساعت پایان</InputLabel>
                      <FormControl fullWidth>
                        <Datetime
                          dateFormat={false}
                          timeFormat={"HH:mm"}
                          onChange={(value) => setEndTimetAt(value)}
                          value={endTimeAt}
                          inputProps={{ placeholder: "انتخاب ساعت" }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={5} lg={3}>
                      <InputLabel className={classes.label}>زمان انتظار سانیار و آوند</InputLabel>
                      <FormControl fullWidth>
                        <Datetime
                          dateFormat={false}
                          timeFormat="HH:mm:ss"
                          timeConstraints={{
                            hours: { min: 0, max: 23 },
                            minutes: { min: 0, max: 59 },
                            seconds: { min: 0, max: 59 },
                          }}
                          onChange={(value) => setwaitingTimetAt(value)}
                          inputProps={{ placeholder: "انتخاب ساعت" }}
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button type={"submit"} color="rose">
                    {loading ? "در حال ذخیره کردن" : "ذخیره"}
                  </Button>
                </CardFooter>
              </Card>
            </form>

            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>زمان کاری فعلی</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={5} lg={3}>
                    <InputLabel className={classes.label}>ساعت شروع</InputLabel>
                    {option.start}
                  </GridItem>

                  <GridItem xs={12} sm={6} md={5} lg={3}>
                    <InputLabel className={classes.label}>ساعت پایان</InputLabel>
                    {option.end}
                  </GridItem>
                  <GridItem xs={12} sm={6} md={5} lg={3}>
                    <InputLabel className={classes.label}>زمان انتظار</InputLabel>
                    {option.waiting}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardText color={"rose"}>
                  <h4>متن خوش آمدگویی</h4>
                </CardText>
              </CardHeader>
              {
                welcomeData &&
                <CardBody>
                <div className={classes.inputContainer}>
                  <textarea
                    rows={4}
                    className={classes.welcomeInput}
                    placeholder={welcomeData ? welcomeData.value : ""}
                    onChange={(e) => setTextArea(e.target.value)}
                  />
                </div>
                <div>
                  <h5 style={{ margin: "15px 0" }}>
                    وضعیت :{" "}
                    <span style={{ color: welcomeData ? (welcomeData.status === "1" ? "green" : "red") :"red"}}>
                      {welcomeData ? (welcomeData.status === "1" ? "فعال" : "غیرفعال"):"غیرفعال"}
                    </span>
                  </h5>
                </div>
              </CardBody>
              }
              <CardFooter>
                <Button color="rose" onClick={submitWelcomeMsg}>
                  {loading ? "در حال ذخیره کردن" : "ذخیره"}
                </Button>
                <Button color={"info"} onClick={changeStatus}>
                  {welcomeData ? (welcomeData.status === "1" ? "غیرفعال کردن" : "فعال کردن"):"غیرفعال"}
                </Button>
              </CardFooter>
            </Card>

            <Card>
              <CardHeader>
                <CardText color={"rose"}>
                  <h4>وضعیت اتاق کنفرانس</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h4>{conference ? "فعال" : "غیرفعال"}</h4>
                  <div style={{ width: "50px", height: "50px" }}>
                    {conference ? (
                      <Lottie animationData={activeAnimation} />
                    ) : (
                      <Lottie animationData={deactiveAnimation} />
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.user.data,
  loading: state.user.loading,
  error: state.user.error,
});

const mapDispatchToProps = (dispatch) => ({
  UserAction: bindActionCreators({ UserAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
