import React from "react";
import "../../scss/main.scss";

function PrimaryBtn(props) {
  return (
    <button className={`PrimaryBtn ${props.className}`} type="submit" onClick={props.onClick} disabled={props.disabled}>
      {props.children}
    </button>
  );
}

export { PrimaryBtn };
export { PrimaryBtntypebtn };

function PrimaryBtntypebtn(props) {
  return (
    <button className={`PrimaryBtn ${props.className}`} onClick={props.onClick} disabled={props.disabled}>
      {props.children}
    </button>
  );
}
