import React, { useEffect, useState } from "react";
import CardHeader from "components/Card/CardHeader";
import styles from "../../style/Recharts/CallsResponseAvg.module.scss";
import { BsSortDown } from "react-icons/bs";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Card from "components/Card/Card";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "components/Loading";
import ButtonXlsx from "components/Recharts/ButtonChartXls/ButtonXlsx";
import Select from "react-select";
import { ApiUrl } from "../../config";

const token = localStorage.getItem("token");

function CallsResponseAvg(props) {
  const [days, setDays] = useState(10);
  const [chartData, setChartData] = useState([]);
  const [filterMode, setFilterMode] = useState("0");
  const [apiRequest, setApiRequest] = useState("daily-call-response-avg-time");
  const [loading, setLoading] = useState(true);
  const [tickDate, setTickDate] = useState({});
  const filterOptions = [
    { value: "0", label: "تعداد" },
    { value: "1", label: "اپراتور" },
    { value: "2", label: "صف" },
  ];
  const daysOption = [
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 15, label: 15 },
    { value: 30, label: 30 },
    { value: 60, label: 60 },
    { value: 90, label: 90 },
  ];

  const data = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${ApiUrl}/${apiRequest}/${days}`, {
        headers: {
          Authorization: token,
        },
      });
      convertData(res.data.data);
    } catch (e) {
      toast.error(e.message);
    }
  };
  const changeApiRequest = () => {
    if (filterMode === "0") {
      return setApiRequest("daily-call-response-avg-time");
    } else if (filterMode === "1") {
      return setApiRequest("daily-operator-response-avg-time");
    } else if (filterMode === "2") {
      return setApiRequest("daily-menu-response-avg-time");
    }
  };
  useEffect(() => {
    changeApiRequest();
  }, [filterMode]);

  useEffect(() => {
    data();
  }, [days, apiRequest]);

  const convertData = (data) => {
    let arr = [];
    let dateTemp = {
      data: [],
    };
    data.forEach((item) => {
      dateTemp.data.push({
        date: new Date(item._id.startDay).toLocaleDateString("fa-IR", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        }),
      });
      //   dateArr.push(obj);
    });
    dateTemp.data.sort((a, b) => {
      if (a.date < b.date) {
        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }
      return 0;
    });

    setTickDate(dateTemp);
    if (filterMode === "1") {
      data.forEach((item, index) => {
        if (!item.operator) {
          return index++;
        }
        let obj = {
          name: item.operator.first_name + " " + item.operator.last_name,
          _id: item._id.operator,
          data: [
            {
              date: new Date(item._id.startDay).toLocaleDateString("fa-IR", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }),
              time: Math.round(item.time),
              count: item.count,
            },
          ],
        };
        arr.push(obj);
      });
      let result = arr.reduce((acc, curr) => {
        let index = acc.findIndex((x) => x._id === curr._id);
        // eslint-disable-next-line no-unused-expressions
        index === -1 ? acc.push(curr) : (acc[index].data = acc[index].data.concat(curr.data));
        return acc;
      }, []);
      result.forEach((item) => {
        return item.data.sort((a, b) => {
          if (a.date < b.date) {
            return -1;
          }
          if (a.date > b.date) {
            return 1;
          }
          return 0;
        });
      });
      setChartData(result);
      return setLoading(false);
    }

    if (filterMode === "2") {
      data.forEach((item, index) => {
        if (!item.menu) {
          return index++;
        }
        let obj = {
          name: item.menu.name,
          _id: item._id.menu,
          data: [
            {
              date: new Date(item.startDay).toLocaleDateString("fa-IR", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }),
              time: Math.round(item.time),
            },
          ],
        };

        arr.push(obj);
      });
      const result = arr
        .reduce((acc, curr) => {
          let index = acc.findIndex((x) => x._id === curr._id);
          // eslint-disable-next-line no-unused-expressions
          index === -1 ? acc.push(curr) : (acc[index].data = acc[index].data.concat(curr.data));
          return acc;
        }, [])
        .sort((a, b) => {
          return a.data[0] - b.data[0];
        });
      result.forEach((item) => {
        return item.data.sort((a, b) => {
          if (a.date < b.date) {
            return -1;
          }
          if (a.date > b.date) {
            return 1;
          }
          return 0;
        });
      });
      setChartData(result);
      return setLoading(false);
    } else {
      data.forEach((item) => {
        let obj = {
          date: new Date(item._id.startDay).toLocaleDateString("fa-IR", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }),
          time: Math.round(item.count),
        };
        arr.push(obj);
      });

      arr.sort((a, b) => {
        if (a.date < b.date) {
          return -1;
        }
        if (a.date > b.date) {
          return 1;
        } else {
          return 0;
        }
      });
      setChartData(arr);
      return setLoading(false);
    }
  };

  const selectDayHandler = (e) => {
    setDays(e.value);
  };
  const selectFilterHandler = (e) => {
    setFilterMode(e.value);
  };
  const LabelCustom = ({ active, label, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.customLabel}>
          <h4>{`تاریخ : ${label}`}</h4>
          {filterMode === "0" && <h4>{`  زمان پاسخ :${payload[0].value}s `}</h4>}
          {payload.map((item, index) => {
            return (
              filterMode === "1" && (
                <h5
                  key={index}
                  style={{ color: `${item.fill}` }}
                >{` اپراتور : ${item.name} , زمان پاسخ : ${item.value}s`}</h5>
              )
            );
          })}
          {payload.map((item, index) => {
            return (
              filterMode === "2" && (
                <h5
                  key={index}
                  style={{ color: `${item.fill}` }}
                >{` صف : ${item.name} , زمان پاسخ : ${item.value}s`}</h5>
              )
            );
          })}
        </div>
      );
    }
    return <></>;
  };
  const LineMapData = () => {
    if (filterMode === "1") {
      return chartData.map((item, index) => {
        let arrayOfColors = ["1", "1", "2", "3", "4", "5", "6", "7", "8", "9", "b", "b", "c", "d", "e", "f"];
        let color = "#";
        for (let i = 0; i < 6; i++) {
          let index = Math.floor(Math.random() * 16);
          let value = arrayOfColors[index];
          color += value;
        }
        return (
          <Line
            key={index}
            type="monotone"
            name={item.name}
            data={item.data}
            dataKey={`time`}
            stroke={color}
            fill={color}
            dot={{ strokeWidth: 2, r: 4 }}
          />
        );
      });
    } else if (filterMode === "2") {
      return chartData.map((item, index) => {
        let arrayOfColors = ["1", "1", "2", "3", "4", "5", "6", "7", "8", "9", "b", "b", "c", "d", "e", "f"];
        let color = "#";
        for (let i = 0; i < 6; i++) {
          let index = Math.floor(Math.random() * 16);
          let value = arrayOfColors[index];
          color += value;
        }
        return (
          <Line
            key={index}
            type="monotone"
            name={item.name}
            data={item.data}
            dataKey={`time`}
            stroke={color}
            fill={color}
            dot={{ width: 2, r: 4 }}
          />
        );
      });
    }
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: "1px solid #9c27b0",
      transition: ".5s",
    }),
    option: (provided, state) => ({
      fontSize: "1rem",
      borderBottom: "none",
      color: state.isSelected ? "black" : "blue",
      fontWeight: state.isSelected && "bold",
      padding: 20,
      borderRadius: "5px",
      cursor: "pointer",
      "&:hover": {
        color: "white",
        background: "#9c27b0",
        transition: ".3s",
      },
    }),
    control: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      width: 200,
      fontSize: "1rem",
      border: "1px solid black",
      borderRadius: "5px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };
  return (
    <Card>
      <CardHeader color="info">
        <h4>میانگین زمان پاسخ تماس ها</h4>
      </CardHeader>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.filters}>
            <div className={styles.filterWrapper}>
              <div className={styles.selectContainer}>
                <Select
                  styles={customStyles}
                  defaultValue={filterMode}
                  options={filterOptions}
                  onChange={selectFilterHandler}
                  placeholder={"مرتب سازی بر اساس"}
                  className={styles.SelectBox}
                />
              </div>
            </div>
            <div className={styles.filterWrapper}>
              <div className={styles.selectContainer}>
                <Select
                  styles={customStyles}
                  defaultValue={days}
                  options={daysOption}
                  onChange={selectDayHandler}
                  placeholder={"تعداد روز های گذشته"}
                  className={styles.SelectBox}
                />
              </div>
              <ButtonXlsx apiRequest={apiRequest} days={days} />
            </div>
          </div>
          <div className={styles.chart}>
            <ResponsiveContainer className={styles.rechartResponsiveContainer}>
              {!loading ? (
                <LineChart
                  width={300}
                  height={300}
                  data={chartData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey={"date"} orientation="bottom" allowDuplicatedCategory={false} />
                  <YAxis dataKey={"time"} orientation="left" unit={filterMode !== "0" && "s"} />
                  <Tooltip cursor={{ stroke: "grey", strokeWidth: 1 }} content={<LabelCustom />} />
                  {filterMode === "0" && (
                    <Line
                      type="monotone"
                      dataKey="time"
                      stroke="#9c27b0"
                      dot={{ stroke: "#9c27b0", strokeWidth: 1, r: 4, strokeDasharray: "" }}
                    />
                  )}
                  {filterMode === "1" && <Line data={tickDate.data} />}
                  {filterMode === "2" && <Line data={tickDate.data} />}
                  {filterMode === "1" && LineMapData()}
                  {filterMode === "2" && LineMapData()}
                </LineChart>
              ) : (
                <Loading loading={loading} />
              )}
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default CallsResponseAvg;
