import { css } from "@emotion/react";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import { API_URL } from "../../contacs";
import "../../scss/main.scss";
import { PrimaryBtn } from "../commen/Btn";
import ModalBox from "../commen/ModalBox";

function UpdateTicketModal(props) {
  const handleclose = () => {
    props.closeModal();
  };
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState("");
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setInfo(data);
    handleUpdate();
  };
  const handleUpdate = () => {
    axios
      .post(`${API_URL}/v1/ticket-hamichat/admin/${props.updateId}`, info, {
        headers: {
          // "content-type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        handleclose();
      })
      .catch((error) => {
        // 
      });
  };
  return (
    <ModalBox closemodal={props.closeModal}>
      <div className="ModalBox_timeFilter">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="UpdateTicket__form">
            <div className="MakeTicket__inputBox">
              <label>وضعیت</label>
              <select defaultValue="NEW" {...register("status")}>
                <option value="NEW">جدید</option>
                <option value="OPEN">باز</option>
                <option value="CLOSE">بسته</option>
              </select>
            </div>

            <div className="MakeTicket__inputBox UpdateTicket__textarea">
              <label>متن</label>
              <textarea
                {...register("text", { required: false })}
                className="MakeTicket__textarea"
                placeholder=" توضیحات را وارد نمایید"
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => (e.target.placeholder = " توضیحات را وارد نمایید")}
              />
            </div>
          </div>
          <div
            className="d-flex"
            style={{
              marginTop: "20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrimaryBtn className="MakeTicket__Btn">
              {loading ? <ClipLoader color="#ffffff" loading={loading} css={override} size={15} /> : "پاسخ"}
            </PrimaryBtn>
          </div>
        </form>
      </div>
    </ModalBox>
  );
}

export default UpdateTicketModal;
