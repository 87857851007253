import { openDB } from "idb";
const idb = window.indexedDB;
const dbName = "messages-db-admin";
export const createCollectionIndexed = async () => {
    await openDB(dbName, 2, {
      upgrade(db) {
        
        if (!db.objectStoreNames.contains("messages")) {
          db.createObjectStore("messages", { autoIncrement: true });
        }
      },
    });
  };

export const createTransaction = (data) => {
  const dbPromise = idb.open(dbName);
  dbPromise.onsuccess = () => {
    const db = dbPromise.result;
    if (!db.objectStoreNames.contains("messages")) return;
    const tx = db.transaction("messages", "readwrite");
    const messages = tx.objectStore("messages");
    const message = messages.put(data);

    message.onsuccess = (event) => {
      tx.oncomplete = () => {
        db.close();
      };
    };
  };
};

export const clearTransaction = () => {
  const dbPromise = idb.open(dbName);
  dbPromise.onsuccess = () => {
    const db = dbPromise.result;
    if (!db.objectStoreNames.contains("messages")) return;
    const tx = db.transaction("messages", "readwrite");
    const messages = tx.objectStore("messages");
    const clear = messages.clear();
    clear.onsuccess = () => {};
  };
};

export const updateIndexedDb = (data,setMessagList) => {
  const dbPromise = idb.open(dbName);
  dbPromise.onsuccess = () => {
    const db = dbPromise.result;
    if (!db.objectStoreNames.contains("messages")) return;
    const tx = db.transaction("messages", "readwrite");
    const messages = tx.objectStore("messages");
    const req = messages.openCursor();

    req.onsuccess = (event) => {
      let cursor = event.target.result;

      if (cursor) {
        if (cursor.value.msg_id === data._id) {
          cursor.value.message = data.message;
          let res = cursor.update(cursor.value);
          res.onerror = function (e) {};
          readDataIndexedDb(setMessagList);
        }
        cursor.continue();
      }
    };
  };
};
export const deleteIndexedDb = (msgId) => {
  const dbPromise = idb.open(dbName);
  dbPromise.onsuccess = () => {
    const db = dbPromise.result;
    if (!db.objectStoreNames.contains("messages")) return;
    const tx = db.transaction("messages", "readwrite");
    const messages = tx.objectStore("messages");
    const req = messages.openCursor();

    req.onsuccess = (event) => {
      let cursor = event.target.result;

      if (cursor) {
        if (cursor.value.msg_id === msgId) {
          let res = cursor.delete();
          res.onerror = function (e) {
            console.log(e);
          };
          readDataIndexedDb();
        }
        cursor.continue();
      }
    };
  };
};
export const readDataIndexedDb = (setMessagList) => {
  const dbPromise = idb.open(dbName);
  dbPromise.onsuccess = () => {
    const db = dbPromise.result;
    if (!db.objectStoreNames.contains("messages")) return;
    const tx = db.transaction("messages", "readonly");
    const messages = tx.objectStore("messages");
    const message = messages.getAll();
    message.onsuccess = (query) => {
      setMessagList(query.srcElement.result);
    };
    tx.oncomplete = () => {
      db.close();
    };
  };
};
