import { GET_MID_CALLS_FAILED, GET_MID_CALLS_REQUEST, GET_MID_CALLS_SUCCESS } from "../type/user_type";

const initialState = {
  data: null,
  loading: false,
  error: null,
  message: null,
  code: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_MID_CALLS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_MID_CALLS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
      };
    }
    case GET_MID_CALLS_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    }

    default:
      return state;
  }
}
