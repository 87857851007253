import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux/";
import { useHistory, useParams } from "react-router-dom";
import { ApiUrl, Url, saniarMenuId } from "../../config";
import textMessageImg from "../../assets/img/chat/textMessage2.svg";
import { VideocamOutlined } from "@material-ui/icons";
import MobileDirection from "../../components/MobileDirection/MobileDirection";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(styles);

require("./style.css");

function Index(props) {
  const classes = useStyles();
  let { mobileid, nationalid } = useParams();
  //
  const [data, setData] = useState([]);
  const [loading, isLoading] = useState(false);
  const [check, setCheck] = useState(true);
  const [logedin, isLogedin] = useState(false);
  const [step, setStep] = useState(2);
  const [name, setName] = useState("سانیار");
  const [id, setId] = useState(saniarMenuId);
  const [nationalCode, setNationalCode] = useState("");
  const [mobile, setMobile] = useState();
  const [alerts, setAlerts] = useState("");
  let history = useHistory();
  useEffect(() => {
    let userData = localStorage.getItem("data_nationalcode");
    let userDataMobile = localStorage.getItem("data_mobile");

    if (userData != null && userDataMobile != null) {
      setNationalCode(userData);
      setMobile(userDataMobile);
    } else {
      if (mobileid == undefined || nationalid === undefined) {
        window.location.href = "/client-bo";
        return null;
      } else {
        localStorage.setItem("data_mobile", mobileid);
        localStorage.setItem("data_nationalcode", nationalid);
        getUsers();
      }
    }
  }, [logedin]);

  function saveExitClient(nationalCode, mobile) {
    //
    let data = {
      nationalCode,
      mobile,
      exit_date: moment().format("YYYY-MM-DDTHH:mm:ss.sTZD"),
    };
    return axios({
      url: ApiUrl + "/save-client",
      method: "post",
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        //
      })
      .catch(function (error) {
        //
      })
      .finally(function () {
        // always executed
        //
      });
  }

  const exit = () => {
    // localStorage.removeItem("data_nationalcode");
    localStorage.removeItem("data_mobile");
    localStorage.removeItem("data_nationalcode");
    let nationalCode;
    let mobile;
    // if (props.history.location.state) {
    //   nationalCode = props.history.location.state.nationalCode;
    //   mobile = props.history.location.state.mobile;
    // }
    //
    saveExitClient(nationalCode, mobile);
    window.location.href = "/client-bo";
  };

  function handleCall(e) {
    // setName(name);
    setId(e);
    setStep(2);
  }

  const confirm = () => {
    window.location.href = "/client-bo";
  };

  function MeasureConnectionSpeed() {
    var imageAddr = "https://hamichat.pep.co.ir/tester.jpg";
    var downloadSize = 2996096; //bytes

    var startTime, endTime;
    var download = new Image();
    download.onload = function () {
      endTime = new Date().getTime();
      showResults();
    };

    download.onerror = function (err, msg) {
      setAlerts(
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="خطا در بارگزاری اطلاعات"
          onConfirm={() => confirm()}
          confirmBtnCssClass={classes.button + " " + classes.success}
          confirmBtnText="بازگشت"
        ></SweetAlert>
      );
    };

    startTime = new Date().getTime();
    var cacheBuster = "?nnn=" + startTime;
    download.src = imageAddr + cacheBuster;

    function showResults() {
      var duration = (endTime - startTime) / 1000;
      var bitsLoaded = downloadSize * 8;
      var speedBps = (bitsLoaded / duration).toFixed(2);
      var speedKbps = (speedBps / 1024).toFixed(2);
      var speedMbps = (speedKbps / 1024).toFixed(2);

      let minSpeed = 0.5;

      if (speedMbps > minSpeed) {
        makeCall();
      } else {
        setAlerts(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="اینترنت نامناسب"
            onConfirm={() => setAlerts("")}
            confirmBtnCssClass={classes.button + " " + classes.success}
            confirmBtnText="بازگشت"
          >
            <p>
              متاسفانه سرعت اینترنت شما جهت برقراری تماس صوتی و تصویری مناسب نمی باشد. لطفا از گزینه پیام متنی استفاده
              کنید.
            </p>
          </SweetAlert>
        );
      }
      // ShowProgressMessage([
      //     "Your connection speed is:",
      //     speedBps + " bps",
      //     speedKbps + " kbps",
      //     speedMbps + " Mbps"
      // ]);
    }
  }

  function handleCalling() {
    window.setTimeout(MeasureConnectionSpeed, 1);

    setAlerts(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="در حال ارزیابی سرعت اینترنت"
        onConfirm={() => {}}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText=" لطفا منتظر بمانید..."
      ></SweetAlert>
    );
  }

  function makeCall() {
    history.push({
      pathname: "/client-bo/call-bot",
      state: {
        nationalCode: localStorage.getItem("data_nationalcode"),
        menu: id,
        name: name,
        mobile: localStorage.getItem("data_mobile"),
      },
    });
  }

  function handleChat() {
    history.push({
      pathname: "/client-bo/chat-bot",
      state: {
        nationalCode: localStorage.getItem("data_nationalcode"),
        menu: id,
        name: name,
        mobile: localStorage.getItem("data_mobile"),
      },
    });
  }

  const getUsers = () => {
    isLoading(true);

    axios
      .get(ApiUrl + "/menu-items?status=true", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        isLoading(false);

        setData(response.data.data.menu_items);
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };

  useEffect(() => {
    getUsers();
  }, loading);

  return (
    <Box
      padding="138px 23px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      sx={{backgroundColor:"var(--mbGray)"}}
      height="100vh"
    >
      <MobileDirection  setStep={setStep} chatPath="/client-bo/chat-bot" callPath="/client-bo/call-bot" menuId={id} menuName={name} />
    </Box>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  // UserAction: bindActionCreators({UserAction}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
