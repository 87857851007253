import React from "react";

function Btn(props) {
  return (
    <button className={`Btn cursor-pointer ${props.className}`} onClick={props.onClick} disabled={props.disabled}>
      {props.children}
    </button>
  );
}

export { Btn };
