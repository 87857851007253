import { css } from "@emotion/react";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import moment from "jalali-moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRecoilState } from "recoil";
import { API_URL } from "../../contacs";
import { textState } from "../../layouts/Recoil";
import { PrimaryBtn } from "../commen/Btn";
import DefaultBox from "../commen/DefaultBox";
import PrimaryTable, { StyledTableCell } from "../commen/Table";
import { TicketContext } from "./TicketContext";

function UserTicketList({ setTicket, personalid, phone }) {
  const [info, setInfo] = useState("");
  const [infodelet, setInfodelet] = useState("");
  const [load, setLoad] = useState(true);
  const [userid, setUserid] = useRecoilState(textState);
  const [datas, setDatas] = useState("");
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: #f6881b;
  `;
  useEffect(() => {
    setInfo({
      n_id: localStorage.getItem("data_nationalcode"),
      phone_number: localStorage.getItem("data_mobile"),
      verify_code: "",
    });
  }, []);
  const [ticketid, setTicketid] = useState("");
  const { saveTicketInfo } = useContext(TicketContext);
  const firstUpdate = useRef(true);
  // useLayoutEffect(() => {
  //   if (firstUpdate.current) {
  //     firstUpdate.current = false;
  //     return;
  //   }
  //   // 
  //   getuser();
  // }, [info]);
  useEffect(() => {
    if (info !== "") {
      getuser();
    }
  }, [info]);
  const getuser = () => {
    axios({
      url: `${API_URL}/v1/ticket-hamichat/user`,
      method: "POST",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: info,
    })
      .then(function (response) {
        setDatas(response.data.data);
        // 
        setLoad(false);
      })
      .catch(function (error) {
        setLoad(false);
      });
  };
  const Deletticket = () => {
    axios({
      url: `${API_URL}/v1/ticket-hamichat/customer/${ticketid}`,
      method: "POST",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: infodelet,
    })
      .then(function (response) {
        setDatas(response.data.data);
        setLoad(false);
      })
      .catch(function (error) {
        setLoad(false);
      });
  };

  const roleNotify = () => {
    toast.error("امکان پاسخ گویی به این تیکت وجود ندارد", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const handleClick = (ticketid, infos) => {
    setUserid(ticketid);
    setTicket(3);
    saveTicketInfo(infos);
  };
  const handleupdate = (ticketid, infos) => {
    setUserid(ticketid);
    setTicket(4);
    saveTicketInfo(infos);
  };
  const handleDelet = (ticketid) => {
    setInfodelet({ status: "CLOSE" });
    setTicketid(ticketid);
    Deletticket();
    setLoad(true);
  };
  return (
    <div className="Ticket">
      <DefaultBox className="Ticket__DefaultBox" title="لیست تیکت ها" main={false} setTicket={setTicket}>
        {datas !== "" ? (
          <>
            {datas.length !== 0 ? (
              <PrimaryTable theadTitle={header}>
                {datas.length !== 0 &&
                  datas.map(
                    (index, arr) =>
                      index.status !== "CLOSE" && (
                        <TableRow key={index.id}>
                          {/* <StyledTableCell align="right">{index.id}</StyledTableCell> */}
                          <StyledTableCell align="right">
                            {moment(index.create_at, "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD")}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {moment(index.update_at, "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD")}
                          </StyledTableCell>
                          <StyledTableCell align="right">{index.ticket_id}</StyledTableCell>
                          <StyledTableCell align="right">{index.user_id}</StyledTableCell>
                          <StyledTableCell align="right">{index.title}</StyledTableCell>
                          <StyledTableCell align="right">{JSON.parse(index.text)[0].message}</StyledTableCell>
                          <StyledTableCell align="right">{JSON.parse(index.text)[0].role}</StyledTableCell>
                          <StyledTableCell align="right">{JSON.parse(index.text)[0].sender_id}</StyledTableCell>
                          <StyledTableCell align="right">
                            {moment(JSON.parse(index.text)[0].time, "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD")}
                          </StyledTableCell>
                          <StyledTableCell align="right">{index.attach}</StyledTableCell>
                          <StyledTableCell align="right">{index.priority}</StyledTableCell>
                          <StyledTableCell align="right">{index.status}</StyledTableCell>
                          <StyledTableCell align="right">
                            <PrimaryBtn
                              className="MakeTicket__Btn-small"
                              onClick={() => handleClick(index.id, datas[arr])}
                            >
                              پاسخ
                            </PrimaryBtn>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <div className="d-flex UserTicketList__EditIcon">
                              <i className="far fa-edit" onClick={() => handleupdate(index.id, datas[arr])}></i>
                              <i className="fas fa-trash" onClick={() => handleDelet(index.id)}></i>
                            </div>
                          </StyledTableCell>
                        </TableRow>
                      )
                  )}
              </PrimaryTable>
            ) : (
              <p>برای این یوزر اطلاعاتی موجود نیست</p>
            )}
          </>
        ) : load ? (
          <ClipLoader color="#f6881b" loading={true} css={override} size={30} />
        ) : (
          <p>برای این یوزر اطلاعاتی موجود نیست</p>
        )}
      </DefaultBox>
      <ToastContainer />
    </div>
  );
}

export default UserTicketList;
const header = [
  "ایجاد",

  "ویرایش",

  "آی دی تیکت",

  "کاربر آی دی",

  "عنوان",

  "توضیحات",
  "نقش",
  "آی دی فرستنده",
  "زمان ارسال",

  "فایل اتچ شده",

  "اولویت",

  "وضعیت",
  "پاسخ",
  "ویرایش/حذف",
];
