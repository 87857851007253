import React from "react";

const ModalBox = (props) => {
  return (
    <div className="modalbox-background" onClick={props.closemodal}>
      <div onClick={(event) => event.stopPropagation()} className="modalbox">
        {props.children}
        <button onClick={props.closemodal} className="modal-box-close">
          <i className="fas fa-times"></i>
        </button>
      </div>
    </div>
  );
};

export default ModalBox;
