import routes from "client_routes";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

const getRoutes = (routes) => {
  return routes.map((prop, key) => {
    if (prop.collapse) {
      return getRoutes(prop.views);
    }
    if (prop.layout === "/client-bo") {
      return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
    } else {
      return null;
    }
  });
};

export default function Client(props) {
  return (
    <div>
      <Switch>
        {getRoutes(routes)}
        <Redirect
          from="/client-bo"
          to={`/client-bo/direction/${localStorage.getItem("data_mobile")}/${localStorage.getItem(
            "data_nationalcode"
          )}`}
        />
      </Switch>
    </div>
  );
}
