import {
  CREATE_CUSTOMER_FAILED,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_FAILED,
  EDIT_CUSTOMER_REQUEST,
  EDIT_CUSTOMER_SUCCESS,
  GET_CUSTOMERS_FAILED,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER_FAILED,
  GET_CUSTOMER_PROBLEMS_FAILED,
  GET_CUSTOMER_PROBLEMS_REQUEST,
  GET_CUSTOMER_PROBLEMS_SUCCESS,
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_SUCCESS,
  GET_PROBLEMS1_FAILED,
  GET_PROBLEMS1_REQUEST,
  GET_PROBLEMS1_SUCCESS,
  GET_PROBLEMS_FAILED,
  GET_PROBLEMS_REQUEST,
  GET_PROBLEMS_SUCCESS,
} from "../type/customer_type";

const initialState = {
  data: null,
  customer: null,
  customerHistory: null,
  customers: null,
  login: null,
  pagination: { per_page: 20, page: 1, total: 1 },
  paginationCustomers: { per_page: 20, page: 1, total: 1 },
  problems: [],
  problems1: [],
  loading: false,
  error: null,
  message: null,
  code: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROBLEMS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_PROBLEMS1_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_PROBLEMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        problems: action.payload.data.problems,
        pagination: action.payload.data.pagination,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
      };
    }
    case GET_PROBLEMS1_SUCCESS: {
      return {
        ...state,
        loading: false,
        problems1: action.payload.data,
        pagination: action.payload.data.pagination,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
      };
    }
    case GET_PROBLEMS_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    }
    case GET_PROBLEMS1_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    }
    case CREATE_CUSTOMER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_CUSTOMER_SUCCESS: {
      return {
        loading: false,
        customer: action.payload.data.customer,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
      };
    }
    case CREATE_CUSTOMER_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    }
    case GET_CUSTOMER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CUSTOMER_SUCCESS: {
      return {
        loading: false,
        customer: action.payload.data.customer,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
      };
    }
    case GET_CUSTOMER_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    }

    case GET_CUSTOMERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CUSTOMERS_SUCCESS: {
      return {
        loading: false,
        customers: action.payload.data.customers,
        error: action.payload.error,
        message: action.payload.message,
        paginationCustomers: action.payload.data.pagination,
        code: action.payload.code,
      };
    }
    case GET_CUSTOMERS_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    }

    case GET_CUSTOMER_PROBLEMS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CUSTOMER_PROBLEMS_SUCCESS: {
      return {
        loading: false,
        customerHistory: action.payload.data.customer,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
      };
    }
    case GET_CUSTOMER_PROBLEMS_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    }
    case EDIT_CUSTOMER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case EDIT_CUSTOMER_SUCCESS: {
      return {
        loading: false,
        customer: action.payload.data.customer,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
      };
    }
    case EDIT_CUSTOMER_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    }

    default:
      return state;
  }
}
