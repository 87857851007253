
import moment from "moment-jalaali";
import { ApiUrl } from "../../config";
import {
  GET_CALL_STATUS_FAILED,
  GET_CALL_STATUS_REQUEST,
  GET_CALL_STATUS_SUCCESS,
  GET_CLIENTS_FAILED,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_MID_CALLS_FAILED,
  GET_MID_CALLS_REQUEST,
  GET_MID_CALLS_SUCCESS,
  GET_OUT_CALL_FAILED,
  GET_OUT_CALL_REQUEST,
  GET_OUT_CALL_SUCCESS,
  GET_POLL_LIST_FAILED,
  GET_POLL_LIST_REQUEST,
  GET_POLL_LIST_SUCCESS,
  GET_QUEUES_FAILED,
  GET_QUEUES_REQUEST,
  GET_QUEUES_SUCCESS,
  GET_QUEUE_ENTER_EXIT_FAILED,
  GET_QUEUE_ENTER_EXIT_REQUEST,
  GET_QUEUE_ENTER_EXIT_SUCCESS,
  GET_RESPONSE_CALL_LIST_FAILED,
  GET_RESPONSE_CALL_LIST_REQUEST,
  GET_RESPONSE_CALL_LIST_SUCCESS,
  GET_TEXT_BOX_FAILED,
  GET_TEXT_BOX_MESSAGE_FAILED,
  GET_TEXT_BOX_MESSAGE_REQUEST,
  GET_TEXT_BOX_MESSAGE_SUCCESS,
  GET_TEXT_BOX_REQUEST,
  GET_TEXT_BOX_SUCCESS,
  GET_TRANSFER_CALL_FAILED,
  GET_TRANSFER_CALL_REQUEST,
  GET_TRANSFER_CALL_SUCCESS,
} from "../type/user_type";

const axios = require("axios");

const GetPollReports = (
  operatorsId,
  pagination,
  queuesId,
  dateG_from,
  dateG_to,
  nationalCodeArray,
  mobileArray,
  points,
  statuslist,
  timeStart,
  timeEnd,
  dayOfWeek
) => {
  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/reports-poll" + `/?size=${pagination.per_page}&page=${pagination.page}`,
      method: "POST",
      params: {},
      data: {
        dayOfWeek,
        operatorsId,
        queuesId,
        dateG_from,
        dateG_to,
        nationalCodeArray,
        mobileArray,
        points,
        statuslist,
        timeStart,
        timeEnd,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {

        dispatch(success(response));
      })
      .catch(function (error) {

        dispatch(failure(error));
      })
      .finally(function () {

      });
    function request() {
      return { type: GET_POLL_LIST_REQUEST };
    }
    function success(result) {

      return { type: GET_POLL_LIST_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_POLL_LIST_FAILED, payload: { error } };
    }
  };
};

const GetPollReportsALL = (pagination) => {
  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/reports-poll" + `/?size=${pagination.per_page}&page=${pagination.page}`,
      method: "get",
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {

        dispatch(success(response));
      })
      .catch(function (error) {

        dispatch(failure(error));
      })
      .finally(function () {

      });
    function request() {
      return { type: GET_POLL_LIST_REQUEST };
    }
    function success(result) {

      return { type: GET_POLL_LIST_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_POLL_LIST_FAILED, payload: { error } };
    }
  };
};

const GetCallResponseReports = (dateJalali, queuesId, callTypeValues) => {

  let dateG_to;
  let dateG_from;
  if (dateJalali.to) {
    let dateFromString = dateJalali.from.year + "-" + dateJalali.from.month + "-" + dateJalali.from.day;
    let dateToString = dateJalali.to.year + "-" + dateJalali.to.month + "-" + dateJalali.to.day;

    dateG_from = moment(dateFromString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    dateG_to = moment(dateToString, "jYYYY-jM-jD").format("YYYY-MM-DD");
  }
  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/reports-call-response",
      method: "post",
      data: {
        dateTo: dateG_to,
        dateFrom: dateG_from,
        queuesId: queuesId,
        callTypeValues: callTypeValues,
      },
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {

        dispatch(success(response));
      })
      .catch(function (error) {

        dispatch(failure(error));
      })
      .finally(function () {

      });
    function request() {

      return { type: GET_RESPONSE_CALL_LIST_REQUEST };
    }
    function success(result) {
      return { type: GET_RESPONSE_CALL_LIST_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_RESPONSE_CALL_LIST_FAILED, payload: { error } };
    }
  };
};

const GetCallResponseReportsAll = () => {
  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/reports-call-response",
      method: "get",
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {

        dispatch(success(response));
      })
      .catch(function (error) {

        dispatch(failure(error));
      })
      .finally(function () {

      });
    function request() {
      return { type: GET_RESPONSE_CALL_LIST_REQUEST };
    }
    function success(result) {
      return { type: GET_RESPONSE_CALL_LIST_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_RESPONSE_CALL_LIST_FAILED, payload: { error } };
    }
  };
};

const GetDirectCallReport = (operatorsId, dateJalali, timeStart, timeEnd) => {

  let dateG_from;
  let dateG_to;
  if (dateJalali.to) {
    let dateFromString = dateJalali.from.year + "-" + dateJalali.from.month + "-" + dateJalali.from.day;
    let dateToString = dateJalali.to.year + "-" + dateJalali.to.month + "-" + dateJalali.to.day;
    dateG_from = moment(dateFromString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    dateG_to = moment(dateToString, "jYYYY-jM-jD").format("YYYY-MM-DD");
  }
  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/reports-direct-call",
      method: "post",
      data: {
        dateTo: dateG_to,
        dateFrom: dateG_from,
        operators: operatorsId,
        timeStart,
        timeEnd,
      },
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {

        dispatch(success(response));
      })
      .catch(function (error) {

        dispatch(failure(error));
      })
      .finally(function () {

      });
    function request() {

      return { type: GET_OUT_CALL_REQUEST };
    }
    function success(result) {
      return { type: GET_OUT_CALL_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_OUT_CALL_FAILED, payload: { error } };
    }
  };
};
const GetAllReportDirectCall = (pagination) => {
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(ApiUrl + "/reports-direct-call" + `/?size=${pagination.per_page}&page=${pagination.page}`, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {

        dispatch(success(response));
      })
      .catch(function (error) {
        
        dispatch(failure(error));
      })
      .finally(function () {

      });
    function request() {
      return { type: GET_OUT_CALL_REQUEST };
    }
    function success(result) {
      
      return { type: GET_OUT_CALL_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_OUT_CALL_FAILED, payload: { error } };
    }
  };
};
//transfer call
const GetTransferCallReport = (
  // callStatusValues,
  operatorsId,
  second_operatorId,
  dateJalali,
  queuesId,
  timeStart,
  timeEnd
) => {
  let dateG_from;
  let dateG_to;
  if (dateJalali.to) {
    let dateFromString = dateJalali.from.year + "-" + dateJalali.from.month + "-" + dateJalali.from.day;
    let dateToString = dateJalali.to.year + "-" + dateJalali.to.month + "-" + dateJalali.to.day;
    dateG_from = moment(dateFromString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    dateG_to = moment(dateToString, "jYYYY-jM-jD").format("YYYY-MM-DD");
  }
  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/reports-transfer-call",
      method: "post",
      data: {
        dateTo: dateG_to,
        dateFrom: dateG_from,
        queuesId,
        operators: operatorsId,
        timeStart,
        timeEnd,
        second_operators: second_operatorId,
        // status: callStatusValues,
      },
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        dispatch(success(response));
      })
      .catch(function (error) {

        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });
    function request() {

      return { type: GET_TRANSFER_CALL_REQUEST };
    }
    function success(result) {
      
      return { type: GET_TRANSFER_CALL_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_TRANSFER_CALL_FAILED, payload: { error } };
    }
  };
};
const GetAllTransferCall = (pagination) => {
  
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(ApiUrl + "/reports-transfer-call" + `/?size=${pagination.per_page}&page=${pagination.page}`, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {

        dispatch(success(response));
      })
      .catch(function (error) {

        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });
    function request() {
      return { type: GET_TRANSFER_CALL_REQUEST };
    }
    function success(result) {
      
      return { type: GET_TRANSFER_CALL_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_TRANSFER_CALL_FAILED, payload: { error } };
    }
  };
};

const GetAllQueueEnterExit = (pagination) => {
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(ApiUrl + "/reports-queue-enter-exit" + `/?size=${pagination.per_page}&page=${pagination.page}`, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        
        dispatch(success(response));
      })
      .catch(function (error) {
        //
        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });
    function request() {
      return { type: GET_QUEUE_ENTER_EXIT_REQUEST };
    }
    function success(result) {
      return { type: GET_QUEUE_ENTER_EXIT_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_QUEUE_ENTER_EXIT_FAILED, payload: { error } };
    }
  };
};

const GetEnterEXitQueueReports = (
  pagination,
  operatorsId,
  typeEnterEXitArray,
  dateJalali,
  timeStart,
  timeEnd,
  reasonArray,
  queuesId
) => {
  // 
  let dateG_from;
  let dateG_to;
  if (dateJalali.to) {
    let dateFromString = dateJalali.from.year + "-" + dateJalali.from.month + "-" + dateJalali.from.day;
    let dateToString = dateJalali.to.year + "-" + dateJalali.to.month + "-" + dateJalali.to.day;
    dateG_from = moment(dateFromString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    dateG_to = moment(dateToString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    // 
  }

  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/reports-queue-enter-exit" + `/?size=${pagination.per_page}&page=${pagination.page}`,
      method: "post",
      data: {
        dateTo: dateG_to,
        dateFrom: dateG_from,
        operatorsId: operatorsId,
        typeEnterExit: typeEnterEXitArray,
        timeStart,
        timeEnd,
        reasonArray,
        queuesId,
      },
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        // 
        dispatch(success(response));
      })
      .catch(function (error) {
        // 
        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
        //
      });
    function request() {
      return { type: GET_QUEUE_ENTER_EXIT_REQUEST };
    }
    function success(result) {
      return { type: GET_QUEUE_ENTER_EXIT_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_QUEUE_ENTER_EXIT_FAILED, payload: { error } };
    }
  };
};

const GetClients = (pagination) => {
  //
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(ApiUrl + "/report-clients" + `/?size=${pagination.per_page}&page=${pagination.page}`, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        // 
        dispatch(success(response));
      })
      .catch(function (error) {
        //
        dispatch(failure(error));
      })
      .finally(function () {
        //
        // always executed
      });
    function request() {
      return { type: GET_CLIENTS_REQUEST };
    }
    function success(result) {
      return { type: GET_CLIENTS_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_CLIENTS_FAILED, payload: { error } };
    }
  };
};
const GetClientsFilter = (pagination, arrayTypeCall, nationalCodeArray, mobileArray) => {
  // 
  //   pagination,
  //   arrayTypeCall,
  //   nationalCodeArray,
  //   mobileArray,
  //   "pagination, arrayTypeCall,nationalCodeArray,mobileArray action GetClientsFilter "
  // );

  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/report-clients" + `/?size=${pagination.per_page}&page=${pagination.page}`,
      method: "post",
      data: {
        arrayTypeCall,
        nationalCodeArray,
        mobileArray,
      },
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        // 
        dispatch(success(response));
      })
      .catch(function (error) {
        //
        dispatch(failure(error));
      })
      .finally(function () {
        //
        // always executed
      });
    function request() {
      return { type: GET_CLIENTS_REQUEST };
    }
    function success(result) {
      return { type: GET_CLIENTS_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_CLIENTS_FAILED, payload: { error } };
    }
  };
};

const GetAllQueues = () => {
  //
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(ApiUrl + "/get-queues", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        //
        dispatch(success(response));
      })
      .catch(function (error) {
        //
        dispatch(failure(error));
      })
      .finally(function () {
        //
        // always executed
      });
    function request() {
      return { type: GET_QUEUES_REQUEST };
    }
    function success(result) {
      return { type: GET_QUEUES_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_QUEUES_FAILED, payload: { error } };
    }
  };
};

const GetALLCallStatus = () => {
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(ApiUrl + "/reports-call-status", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        // 
        dispatch(success(response));
      })
      .catch(function (error) {
        //
        dispatch(failure(error));
      })
      .finally(function () {
        //
        // always executed
      });
    function request() {
      return { type: GET_CALL_STATUS_REQUEST };
    }
    function success(result) {
      return { type: GET_CALL_STATUS_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_CALL_STATUS_FAILED, payload: { error } };
    }
  };
};

const GetCallStatusReports = (dateJalali, operatorsId, statusValue, queuesId, timeStart, timeEnd) => {
  // 
  let dateG_from;
  let dateG_to;
  if (dateJalali.to) {
    let dateFromString = dateJalali.from.year + "-" + dateJalali.from.month + "-" + dateJalali.from.day;
    let dateToString = dateJalali.to.year + "-" + dateJalali.to.month + "-" + dateJalali.to.day;
    dateG_from = moment(dateFromString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    dateG_to = moment(dateToString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    //
  }

  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/reports-call-status",
      method: "post",
      data: {
        dateTo: dateG_to,
        dateFrom: dateG_from,
        operators: operatorsId,
        status: statusValue,
        queuesId: queuesId,
        timeStart,
        timeEnd,
      },
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        //
        dispatch(success(response));
      })
      .catch(function (error) {
        //
        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });
    function request() {
      return { type: GET_CALL_STATUS_REQUEST };
    }
    function success(result) {
      return { type: GET_CALL_STATUS_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_CALL_STATUS_FAILED, payload: { error } };
    }
  };
};

const getMidCalls = (dateJalali, queuesId, timeStart, timeEnd, operatorsId, typeList) => {
  //
  let dateG_from;
  let dateG_to;
  if (dateJalali && dateJalali.to) {
    let dateFromString = dateJalali.from.year + "-" + dateJalali.from.month + "-" + dateJalali.from.day;
    let dateToString = dateJalali.to.year + "-" + dateJalali.to.month + "-" + dateJalali.to.day;
    dateG_from = moment(dateFromString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    dateG_to = moment(dateToString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    //
  }

  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/reports-mid-calls",
      method: "post",
      data: {
        dateTo: dateG_to,
        dateFrom: dateG_from,
        timeStart,
        timeEnd,
        queuesId,
        operatorsId,
        typeList,
      },
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        // 
        dispatch(success(response));
      })
      .catch(function (error) {
        //
        dispatch(failure(error));
      })
      .finally(function () {
        // always executed
      });
    function request() {
      return { type: GET_MID_CALLS_REQUEST };
    }
    function success(result) {
      return { type: GET_MID_CALLS_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_MID_CALLS_FAILED, payload: { error } };
    }
  };
};

const getTextBoxReportAll = (pagination) => {
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(ApiUrl + "/text-box-reports" + `/?size=${pagination.per_page}&page=${pagination.page}`, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        // 
        dispatch(success(response));
      })
      .catch(function (error) {
        //
        dispatch(failure(error));
      })
      .finally(function () {
        //
        // always executed
      });
    function request() {
      return { type: GET_TEXT_BOX_REQUEST };
    }
    function success(result) {
      return { type: GET_TEXT_BOX_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_TEXT_BOX_FAILED, payload: { error } };
    }
  };
};

const getTextBoxReportFilter = (
  pagination,
  dateJalali,
  queuesId,
  mobileArray,
  nationalCodeArray,
  timeStart,
  timeEnd
) => {
  // 
  let dateG_from;
  let dateG_to;
  if (dateJalali.to) {
    let dateFromString = dateJalali.from.year + "-" + dateJalali.from.month + "-" + dateJalali.from.day;
    let dateToString = dateJalali.to.year + "-" + dateJalali.to.month + "-" + dateJalali.to.day;
    dateG_from = moment(dateFromString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    dateG_to = moment(dateToString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    //
  }

  return (dispatch) => {
    dispatch(request());
    return axios({
      url: ApiUrl + "/text-box-reports" + `/?size=${pagination.per_page}&page=${pagination.page}`,
      method: "post",
      data: {
        dateTo: dateG_to,
        dateFrom: dateG_from,
        queuesId,
        mobileArray,
        nationalCodeArray,
        timeStart,
        timeEnd,
      },
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        // 
        dispatch(success(response));
      })
      .catch(function (error) {
        //
        dispatch(failure(error));
      })
      .finally(function () {
        //
        // always executed
      });
    function request() {
      return { type: GET_TEXT_BOX_REQUEST };
    }
    function success(result) {
      return { type: GET_TEXT_BOX_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_TEXT_BOX_FAILED, payload: { error } };
    }
  };
};

const getTextBoxMessage = (id) => {
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(ApiUrl + "/text-box-message" + `/${id}`, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        // 
        dispatch(success(response));
      })
      .catch(function (error) {
        //
        dispatch(failure(error));
      })
      .finally(function () {
        //
        // always executed
      });
    function request() {
      return { type: GET_TEXT_BOX_MESSAGE_REQUEST };
    }
    function success(result) {
      return { type: GET_TEXT_BOX_MESSAGE_SUCCESS, payload: result.data };
    }
    function failure(error) {
      return { type: GET_TEXT_BOX_MESSAGE_FAILED, payload: { error } };
    }
  };
};

export default {
  GetPollReports,
  GetCallResponseReports,
  GetCallResponseReportsAll,
  GetALLCallStatus,
  GetCallStatusReports,
  GetAllTransferCall,
  GetAllQueueEnterExit,
  GetEnterEXitQueueReports,
  GetClients,
  GetClientsFilter,
  GetAllQueues,
  getMidCalls,
  getTextBoxReportFilter,
  getTextBoxReportAll,
  getTextBoxMessage,
  GetPollReportsALL,
  GetAllReportDirectCall,
  GetDirectCallReport,
  GetTransferCallReport,
};
