// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/layouts/rtlStyle";
import axios from "axios";
import cx from "classnames";
import Footer from "components/Footer/Footer";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes";
import { ApiUrl } from "../config";

var ps;

const useStyles = makeStyles(styles);

export default function RTL(props) {
  const { ...rest } = props;
  const { store } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isGetUser, setIsGetUSer] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image, setImage] = React.useState(require("assets/img/sidebar-2.jpg"));
  const [user, setUser] = React.useState({});
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("white");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(require("assets/img/logo.jpeg"));
  const [loading, setLoading] = React.useState(true);
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  // functions for changeing the states from components
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.svg"));
        break;
      default:
        setLogo(require("assets/img/logo-white.svg"));
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "پرداخت الکترونیک پاسارگاد";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      return (
        <Route
          path={prop.layout + prop.path}
          render={(props) => <prop.component {...props} adminUser={user} store={store} />}
          key={key}
        />
      );
    });
  };

  localStorage.getItem("token") === null &&
    props.history.push({
      pathname: "/auth",
    });

  if (props.LoginError) {
    localStorage.removeItem("token");
    localStorage.removeItem("_id");
    props.history.push({
      pathname: "/auth",
    });
  }

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const getUser = () => {
    axios({
      url: ApiUrl + "/get-user/" + localStorage.getItem("_id"),
      method: "get",
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.data && response.data.data && response.data.data.user) setUser(response.data.data.user);
      })
      .catch(function (error) {
        localStorage.removeItem("token");
        localStorage.removeItem("_id");
        props.history.push({
          pathname: "/auth",
        });
      })
      .finally(function () {
        // always executed
      });
  };
  React.useEffect(() => {
    if (!isGetUser) {
      getUser();

      setIsGetUSer(true);
    }
  });

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"حامی چت"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        user={user}
        bgColor={bgColor}
        miniActive={miniActive}
        rtlActive
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          rtlActive
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          handleDrawerToggle={handleDrawerToggle}
          brandText={getActiveRoute(routes)}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              {getRoutes(routes)}

              {user.type &&
                (user.type === "admin" ? (
                  <Redirect from="/admin" to="/admin/admin-dashboard" />
                ) : (
                  <Redirect from="/admin" to="/admin/dashboard" />
                ))}
            </Switch>
          </div>
        </div>
        <Footer fluid rtlActive />
      </div>
    </div>
  );
}
