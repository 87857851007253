import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useForm } from "react-hook-form";
import { connect } from "react-redux/";
import StarRatingComponent from "react-star-rating-component";
import io from "socket.io-client";
// import $ from "jquery"
import { ApiUrl, Url } from "../../config";
import logo from "./files/logo.jpeg";
import TextareaAutosize from "react-autosize-textarea";
import { AttachFile, CallEnd, SendRounded } from "@material-ui/icons";
import { Btn } from "../../components/share/Btn/Btn";
import { Button } from "@mui/material";
import { useRecoilState } from "recoil";
import { LinkMessage, MessageList, Message } from "layouts/Recoil";
import MobileChat from "../../components/MobileChat/MobileChat";
import MobileChatWaiting from "../../components/MobileChatWaiting/MobileChatWaiting";
import MobileChatBot from "../../components/MobileChatBot/MobileChatBot";
import {createCollectionIndexed,clearTransaction,createTransaction,deleteIndexedDb,readDataIndexedDb,updateIndexedDb} from 'indexedDB/clientDB'

require("./style.css");
const useStyles = makeStyles(styles);
const useStyles2 = makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "var(--white)",
    borderRadius: "10px",
    border: "1px solid var(--gray)",
    margin: "5px 0",
    direction: "ltr",
  },
  textArea: {
    borderRadius: "10px",
    padding: "10px",
    border: "none",
    backgroundColor: "transparent",
    resize: "none",
    width: "100%",
  },
  messages: {
    backgroundColor: "white !important",
    borderRadius: "10px !important",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
  },
  messageTextMe: {
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: "var(--green)",
    float: "right",
  },
  messageTextOther: {
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: "var(--lightGray)",
    float: "left",
  },
});

function Index(props) {
  let TimeWaiting;
  const classes = useStyles();
  const chatClasses = useStyles2();
  const [alert, setAlert] = React.useState(null);

  let { nationalCode, mobile, menu, name, idClient } = props.location.state;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmittxt = (data) => {
    let file = new FormData();
    file.append("file", data.file[0]);
    setfullname(data.fullname);
    // setMessage(data.message);
    if (localStorage.getItem("data_nationalcode")) {
      axios({
        url: ApiUrl + "/text-box",
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
        data: {
          fullname,
          message,
          menu,
          nationalCode,
          mobile,
          idClient,
          file,
          date: moment().format("YYYY-MM-DDTHH:mm:ss.sTZD"),
          date_time: moment().format("HH:mm:ss.s"),
        },
      })
        .then(function (response) {
          //
          setAlertSend(true);
          setFlagTextBox(false);
          setOut(false);
          setBox(false);
        })
        .catch(function (error) {
          // setAlertSend("ناموفق")
          //
        })
        .finally(function () {});
    } else {
      window.location.href = `/client-bo/direction-avand/${mobile}`;
    }
  };
  const [isFree, setIsFree] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [fullname, setfullname] = useState("");
  const [files, setFiles] = useState("");
  // const [message, setMessage] = useState("");
  const [flagSend, setFlagSend] = useState("");
  const [uploadEvent, setUploadEvent] = useState("");
  const [out, setOut] = useState(false);
  const [alertSend, setAlertSend] = useState(null);
  const [flagTextBox, setFlagTextBox] = useState(false);
  const [waitingTime, setWaitingTime] = useState(false);
  const [box, setBox] = useState(false);
  const [times, setTime] = useState({ start: 0, end: 0 });
  const [operator, setOperator] = useState("");
  const [socketIo, setSocket] = useState(null);
  const [userCount, setUserCount] = useState(0);
  const [isSent, setIsSent] = useState(false);
  const [isEnded, setEnded] = useState(false);
  const [connected, isConnected] = useState(false);
  const [star, setStar] = useState(5);
  const [loading, isLoading] = useState(false);
  const [isHold, setIdHold] = useState(false);
  const [messageList, setMessagList] = useRecoilState(MessageList);
  const [linkMessag, setLinkMessag] = useRecoilState(LinkMessage);
  const [video, setVideo] = useState("");
  const [textArea, setTextArea] = useState("");
  const [chats, setChats] = useState([]);
  const [message, setMessageForm] = useRecoilState(Message);
  const [qsOp, setQsOpr] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [otherTyping, setOtherTyping] = useState(false);
  const ICE_SERVERS = [
    {
      urls: "turn:185.60.32.129:3478",
      credential: "HmCht_3432",
      username: "hamichat",
    },
  ];
  let local_media_stream = null;
  let peerConnection = null;
  let sharePeerConnection = null;
  let operator_id = 0;
  const userId = localStorage.getItem("data_nationalcode");
  const operatorId = localStorage.getItem("_id");

  //indexedDb
  
  //
  useEffect(() => {
    createCollectionIndexed();
    readDataIndexedDb(setMessagList);
  }, []);
  useEffect(() => {
    if (!isSent) {
      setIsSent(true);
      connection();
      getAvr();
    }
  }, null);
  useEffect(() => {
    clearTransaction();
  }, []);
  const getAvr = () => {
    axios
      .get(ApiUrl + "/get-avr", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        isLoading(false);

        setVideo(response.data.data.video);
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };
  const init = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function (stream) {
        local_media_stream = stream;
        let self = document.getElementById("self");
        self.srcObject = stream;
        self.setAttribute("autoplay", "autoplay");
        self.setAttribute("playsinline", "playsinline");
      })
      .catch((e) => {
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="سیستم شما می بایست به دوربین مجهز باشد"
            onConfirm={() => confirm()}
            confirmBtnCssClass={classes.button + " " + classes.success}
            confirmBtnText="بازگشت"
          ></SweetAlert>
        );
      });
  };

  const confirm = () => {
    window.location.href = `/client-bo/direction-avand/${mobile}`;
  };
  const submit = (e) => {
    e.preventDefault();

    var bodyFormData = new FormData(e.target);
    bodyFormData.append("customer", mobile);
    bodyFormData.append("operator", operator);
    bodyFormData.append("user_national_code", nationalCode);
    bodyFormData.append("menu", menu);
    bodyFormData.append("status", "chat");
    bodyFormData.append("date", moment().format("YYYY-MM-DDTHH:mm:ss.sTZD"));
    bodyFormData.append("date_time", moment().format("HH:mm:ss.s"));
    axios({
      url: ApiUrl + "/save-poll",
      method: "post",
      headers: {},
      data: bodyFormData,
    })
      .then(function (response) {})
      .catch(function (error) {})
      .finally(function () {
        window.location.href = `/client-bo/direction-avand/${mobile}/${nationalCode}`;
      });
  };

  const connection = () => {
    if (!connected) {
      isConnected(true);
      let socket = io.connect(Url);
      setSocket(socket);
      let date_select_menu = localStorage.getItem("data_timeMenu");
      let time_select_menu = moment(date_select_menu, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss.s");
      let date_login = localStorage.getItem("data_timeLogin");
      let time_login = moment(date_login, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss.s");
      socket.on("connect", function () {
        socket.emit("joinChat", {
          type: "client",
          code: nationalCode,
          menu: menu,
          mobile,
          idClient,
          date_select_menu,
          time_select_menu,
          date_login,
          time_login,
          socket_id: socket.id,
        });
      });
      socket.on("out_of_wait", function () {
        localStorage.setItem("name", name);
        localStorage.setItem("idClient", idClient);
        localStorage.setItem("menu", menu);
        window.location.href = "/client/message";
      });
      socket.on("online_users", function (users, option) {
        if (option) {
          setWaitingTime(option.waiting);
          let waitingMili = moment(option.waiting, "HH:mm:ss").diff(moment().startOf("day"), "seconds") * 1000;
          TimeWaiting = setTimeout(() => {
            setBox(true);
            socket.disconnect();
          }, waitingMili);
        }
        let users_items = [];
        if (users) {
          for (var i = 0; i < users.length; i++) {
            if (users[i].socket !== socket.id) {
              users_items.push(users[i]);
            } else {
              break;
            }
          }

          setUserCount(users_items.length);
        }
      });
      socket.on("free", function (id, _id, id_call_history) {
        operator_id = id;
        setIsFree(true);
        setOperator(_id);
        clearTimeout(TimeWaiting);
        //
        let temp = [];

        if (localStorage.getItem("data_idCall") !== null) {
          //
          let local = JSON.parse(localStorage.getItem("data_idCall"));
          //
          //
          temp = local;
        }
        //
        temp.push(id_call_history);
        localStorage.setItem("data_idCall", JSON.stringify(temp));
      });

      socket.on("hold_call", function (status) {
        setIdHold(status);

        let video = document.getElementById("client");
        if (status) {
          video.setAttribute("style", "display:none");
        } else {
          video.setAttribute("style", "display:unset");
        }
      });
      socket.on("free_share", function (id) {
        socket.emit("watcher_screen", id);
      });
      socket.on("cancel_display", function () {
        sharePeerConnection && sharePeerConnection.close();
        sharePeerConnection = null;
        setIsShare(false);
      });

      socket.on("offer", function (id, description, _id) {
        setOperator(_id);

        peerConnection = new RTCPeerConnection({ iceServers: ICE_SERVERS });

        let stream = local_media_stream;
        if (stream) stream.getTracks().forEach((track) => peerConnection.addTrack(track, stream));
        peerConnection
          .setRemoteDescription(description)
          .then(() => peerConnection.createAnswer())
          .then((sdp) => peerConnection.setLocalDescription(sdp))
          .then(function () {
            socket.emit("answer", id, peerConnection.localDescription);
          });
        peerConnection.ontrack = async function (event) {
          let client = await document.getElementById("client");
          client.setAttribute("autoplay", "autoplay");
          client.setAttribute("playsinline", "playsinline");
          client.srcObject = await event.streams[0];
        };
        peerConnection.onicecandidate = function (event) {
          if (event.candidate) {
            socket.emit("candidate", id, event.candidate);
          }
        };
      });
      socket.on("offer_screen", function (id, description) {
        setIsShare(true);

        sharePeerConnection = new RTCPeerConnection({
          iceServers: ICE_SERVERS,
        });

        sharePeerConnection
          .setRemoteDescription(description)
          .then(() => sharePeerConnection.createAnswer())
          .then((sdp) => sharePeerConnection.setLocalDescription(sdp))
          .then(function () {
            socket.emit("answer_screen", id, sharePeerConnection.localDescription);
          });
        sharePeerConnection.ontrack = async function (event) {
          let client = await document.getElementById("client_screen");
          client.setAttribute("autoplay", "autoplay");
          client.setAttribute("playsinline", "playsinline");
          client.srcObject = await event.streams[0];
        };
        sharePeerConnection.onicecandidate = function (event) {
          if (event.candidate) {
            socket.emit("candidate_screen", id, event.candidate);
          }
        };
      });

      socket.on("candidate", function (id, candidate) {
        peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      });
      socket.on("candidate_screen", function (id, candidate) {
        sharePeerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      });
      socket.on("out_of_time", function (start, end, waiting) {
        setOut(true);
        setBox(true);
        setTime({
          start,
          end,
        });
      });
      socket.on("message_edit", (msg) => {
        if (msg.is_delete === true) {
          deleteIndexedDb(msg, setMessagList);
        }
        updateIndexedDb(msg);
      });

      socket.on("chat_message", function (msg) {
        createTransaction(msg);
        readDataIndexedDb(setMessagList);
        //                 let message = ` <div class="${msg.id == socket.id ? "me" : "other"}">${msg.message}</div>
        // `;
        //
        //                 var messages = document.getElementById("messages");
        //                 messages.innerHTML = messages.innerHTML + message;
        //                 messages.scrollTop = messages.scrollHeight;
      });
      socket.on("chat_message_file", function (msg) {
        const obj = {
          ...msg,
          file: true,
        };

        createTransaction(obj);
        readDataIndexedDb(setMessagList);
        //                 let message = ` <div class="${msg.id == socket.id ? chatClasses.messageTextMe : chatClasses.messageTextOther}">
        //
        //
        //
        //  <a   href="${msg.message}">جهت دانلود فایل کلیک کنید</a>
        //
        //
        //
        // </div>
        // `;
        //
        //                 var messages = document.getElementById("messages");
        //                 messages.innerHTML = messages.innerHTML + message;
        //                 messages.scrollTop = messages.scrollHeight;
      });

      socket.on("disconnect", function () {
        clearTransaction();
        setEnded(true);
      });
      socket.on("end_call", function () {
        clearTransaction();
        setEnded(true);
      });
      socket.on("message_edit", (msg) => {
        if (msg.is_delete === true) {
          deleteIndexedDb(msg._id, setMessagList);
        }
        updateIndexedDb(msg, setMessagList);
      });
      socket.on("active_chats", (msg) => {
        setChats(msg);
      });
      socket.on("online_operators", function (users) {
        //
        let items = [];
        console.log(users);
        //
        if (users) {
          let users_items = users.filter((e) => {
            if (e) {
              e.menu.map((i) => {
                if (!items[i]) items[i] = [];
                items[i].push(e);
              });
            }
          });

          setQsOpr(users);
        }
      });
    }
  };
  const cancel = () => {
    window.location.href = `/client-bo/direction-avand/${mobile}/${nationalCode}`;
  };

  const endCall = () => {
    socketIo.disconnect();
  };

  const sendMessage = function () {
    if (flagSend === false) {
      setFlagSend(true);
      uploadFile();
    }

    // let message = document.getElementById("message");
    if (!message == "") {
      let items = {
        message: message,
        id: socketIo.id,
        national_code: nationalCode,
        mobile: mobile,
        operator_id: operator_id,
      };

      socketIo.emit("chat_message", items);
      // message.value = "";
      setMessageForm("");
    }
  };
  const onKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    } else if (event.key === "Enter" && event.shiftKey) {
    }
  };
  const mailBox = () => {
    props.history.push({
      pathname: "/client/box",
      state: {
        nationalCode,
        menu,
        name,
        mobile,
        idClient,
      },
    });
  };
  const uploadFile = function (e) {
    let event = uploadEvent;

    let items = {
      message: " ... در حال ارسال فایل",
      id: socketIo.id,
    };

    //

    socketIo.emit("chat_message", items);
    var fd = new FormData();
    fd.append("file", event);

    axios({
      url: ApiUrl + "/upload-chat-file",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: fd,
    })
      .then(function (response) {
        let message = document.getElementById("selectFile");
        let message2 = document.getElementById("message");
        let items = {
          message: Url + response.data.data.path,
          id: socketIo.id,
        };

        socketIo.emit("chat_message_file", items);
        message.value = "";
        message2.value = "";
        setUploadEvent("");
      })
      .catch(function (error) {})
      .finally(function () {});
  };
  const onTypingMessage = () => {
    //
    if (timeoutId !== null) clearTimeout(timeoutId);
    if (!isTyping) setIsTyping(true);

    const timeout = setTimeout(() => {
      setIsTyping(false);
    }, 2000);

    setTimeoutId(timeout);
  };
  useEffect(() => {
    socketIo &&
      socketIo.on("other_typing", (otherTyping) => {
        setOtherTyping(!!otherTyping);
      });
  }, [socketIo]);
  //
  const sendToTextBox = function (e) {
    e.preventDefault();
    // let file = new FormData();
    // file.append("file", files.files[0]);
    if (localStorage.getItem("data_nationalcode")) {
      axios({
        url: ApiUrl + "/text-box",
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
        data: {
          fullname,
          message,
          menu,
          nationalCode,
          mobile,
          idClient,
          // file,
          date: moment().format("YYYY-MM-DDTHH:mm:ss.sTZD"),
          date_time: moment().format("HH:mm:ss.s"),
        },
      })
        .then(function (response) {
          //
          setAlertSend(true);
          setFlagTextBox(false);
          setOut(false);
          setBox(false);
        })
        .catch(function (error) {
          // setAlertSend("ناموفق")
          //
        })
        .finally(function () {});
    } else {
      window.location.href = `/client-bo/direction-avand/${mobile}`;
    }
  };
  const checkOperatorUser = (item) => {
    if ((userId && item.national_code === userId) || item.type === "user") {
      return true;
    } else if ((operatorId && item.operator_id === operatorId) || item.type !== "user") {
      return false;
    } else {
      return false;
    }
  };
  const renderCalling = () => {
    return (
      <MobileChatBot
        chats={chats}
        cancel={cancel}
        endCall={endCall}
        sendMessage={sendMessage}
        loading={loading}
        setFlagSend={setFlagSend}
        setUploadEvent={setUploadEvent}
        uploadEvent={uploadEvent}
        otherTyping={otherTyping}
        onTypingMessage={onTypingMessage}
      />
      // <div className={"calling"}>
      //   <div className="header">
      //     <div className="logo"></div>
      //     <h3> {name === "کیپاد" ? "پی پاد" : name}</h3>
      //   </div>

      //   <div style={!isShare ? { display: "none" } : {}} className="video">
      //     {/* <video src={Url + video.waiting} loop controls autoPlay playsInline /> */}
      //     <video
      //       src={
      //         video.waiting
      //           ? video.waiting
      //           : "https://pep.arvanvod.ir/axXzYxdY2p/e70LK7LROr/origin_PgM2qetvmttUJyWeDeeG5pyw7rwC6f4XN4sSKUNG.mp4"
      //       }
      //       loop
      //       controls
      //       autoPlay
      //       playsInline
      //     />
      //   </div>

      //   <div
      //     className="btn-container"
      //     style={
      //       !isShare
      //         ? {
      //             maxWidth: "95%",
      //             flex: "0 0 95%",
      //             margin: "auto auto 80px auto",
      //           }
      //         : {}
      //     }
      //   >
      //     <div className="chat" dir="rtl" style={{ height: "81vh" }}>
      //       <div id={"messages"} className={`messages ${chatClasses.messages}`} style={{ maxHeight: "74vh" }}>
      //         {messageList.map((item, index) => {
      //           return (
      //             <div key={index} className={checkOperatorUser(item) ? " me" : " other"}>
      //               {item.file ? (
      //                 <a target="_blank" href={item.message} rel="noopener noreferrer">
      //                   جهت دانلود فایل کلیک کنید
      //                 </a>
      //               ) : (
      //                 item.message
      //               )}
      //             </div>
      //           );
      //         })}
      //       </div>
      //       <div className={chatClasses.container}>
      //         {/*<TextareaAutosize*/}
      //         {/*  autoComplete="off"*/}
      //         {/*  dir="rtl"*/}
      //         {/*  onKeyDown={onKeyDown}*/}
      //         {/*  id={"message"}*/}
      //         {/*  placeholder={"پیام خود را وارد کنید..."}*/}
      //         {/*  type={"text"}*/}
      //         {/*  className="TextareaAutosize"*/}
      //         {/*/>*/}
      //         <SendRounded style={{ cursor: "pointer", margin: "0 5px" }} onClick={sendMessage} />
      //         <textarea
      //           autoComplete="off"
      //           dir="rtl"
      //           onKeyDown={onKeyDown}
      //           id={"message"}
      //           placeholder={"پیام خود را وارد کنید..."}
      //           type={"text"}
      //           className={chatClasses.textArea}
      //           onChange={(e) => setTextArea(e.target.value)}
      //           value={textArea}
      //         />
      //         <label htmlFor={"selectFile"}>
      //           <AttachFile style={{ cursor: "pointer", margin: "0 5px" }} />
      //         </label>
      //         <input
      //           id={"selectFile"}
      //           type={"file"}
      //           placeholder={"تصویر خود برای ارسال را انتخاب کنید.."}
      //           onChange={(e) => {
      //             setFlagSend(false);
      //             setUploadEvent(e.target.files[0]);
      //             setTextArea(e.target.files[0].name);
      //           }}
      //           style={{ display: "none" }}
      //         />
      //       </div>
      //     </div>

      //     <div className="cancel-footer">
      //       {/*<CallEnd style={{color: "var(--chiliRed)", fontSize: "40px", cursor: "pointer"}}*/}
      //       {/*         onClick={(event) => endCall()}/>*/}
      //       <Button
      //         sx={{ backgroundColor: "var(--chiliRed)", padding: "10px", color: "var(--black)" }}
      //         onClick={() => endCall()}
      //       >
      //         پایان تماس
      //       </Button>
      //     </div>
      //   </div>
      // </div>
    );
  };

  const renderWaiting = () => {
    return (
      <MobileChatWaiting cancel={cancel} onlineOperators={qsOp} userCount={userCount} />
      // <div className={"waiting"} style={{ width: "100%" }}>
      //   <div className="header">
      //     <div className="logo"></div>
      //     <h3> {name === "کیپاد" ? "پی پاد" : name}</h3>
      //   </div>
      //   <div className="waiters">
      //     <b> {userCount} نفر در صف انتظار</b>
      //     <br />
      //     <span> زمان تقریبی انتظار {userCount * 2} دقیقه</span>
      //   </div>
      //   {video.waiting != "" && (
      //     <div className="video-waitning">
      //       <video src={Url + video.waiting} loop controls autoPlay playsInline />
      //     </div>
      //   )}
      //   {/*<div className="icon-container">*/}
      //   {/*    <div className="wainting-icon-chat"></div>*/}
      //   {/*</div>*/}
      //   <h4>در حال برقراری ارتباط با اولین اپراتور...</h4>

      //   <div className="cancel-footer-bottem">
      //     {/*<input onClick={(e) => cancel()} className={"cancel-call"} type={"button"}/>*/}
      //     <CallEnd onClick={(e) => cancel()} className={"cancel-call"} />
      //   </div>
      // </div>
    );
  };

  const renderEnd = () => {
    return (
      <div className={"ended"}>
        <div className={"welcome-input"}>
          <div className="header">
            <div className="logo"></div>
          </div>

          <h2>از تماس شما متشکریم</h2>
          <form onSubmit={(e) => submit(e)}>
            <h4 className="infos">لطفا با تکمیل نظر سنجی ما را در بهبود خدمات یاری کنید</h4>
            <h6>میزان رضایت شما از ارتباط</h6>

            <StarRatingComponent name="rate" value={star} onStarClick={(e) => setStar(e)} starCount={5} />
            <textarea placeholder="لطفا نظرات خود را با ما در میان بگذارید" className={"message_area"} name="message" />
            <br />
            <input className={"cancel"} value={"ثبت نظر و بازگشت"} type={"submit"} />
          </form>
        </div>
      </div>
    );
  };
  const renderOutOfTime = () => {
    return (
      <div className={"ended"}>
        <div className={"welcome-input"}>
          <img src={logo} />
          <h2>با تشکر از تماس شما</h2>
          <hr />
          <form onSubmit={(e) => submit(e)}>
            <h4>لطفا به این گفت و گو امتیاز دهید</h4>
            <StarRatingComponent name="rate" value={star} onStarClick={(e) => setStar(e)} starCount={5} />
            <h4>اگر نظری در مورد این گفتگو دارید بیان کنید.</h4>
            <textarea className={"message_area"} name="message" />
            <br />
            <input className={"cancel"} value={"ثبت نظر و بازگشت"} type={"submit"} />
          </form>
        </div>
      </div>
    );
  };

  //flagTextBox is for sandogh_matni
  if (box === true && flagTextBox === false) {
    return (
      <div dir="rtl">
        <div style={{ marginBottom: 50 }} className={"video-container"}>
          <div className={"welcome-input"}>
            <div className="header">
              <div className="logo"></div>
            </div>
            {video.outtime != "" && (
              <div className="video-waitning">
                <video src={Url + video.outtime} playsInline controls loop autoPlay />
              </div>
            )}
            <h2>با تشکر از تماس شما</h2>
            <hr />
            {out ? (
              <h4>
                ساعات کاری از {times.start} تا {times.end} می باشد.
              </h4>
            ) : (
              <h4> همکاران ما مشغول هستند لطفا پیام خود را بگذارید تا در اسرع وقت با شما تماس بگیریم</h4>
            )}
            <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
            <input
              onClick={(e) => {
                setFlagTextBox(true);
              }}
              className={"cancel"}
              style={{ width: "200px" }}
              value={"ایجاد پیام در صندوق متنی"}
              type={"button"}
            />
            <br />{" "}
            <input
              onClick={(e) => {
                mailBox();
              }}
              className={"cancel"}
              style={{ width: "200px" }}
              value={"ایجاد پیام در صندوق تصویری"}
              type={"button"}
            />
            <br />
          </div>
        </div>
      </div>
    );
  }

  if (flagTextBox === true) {
    return (
      // <div dir="rtl">
      //   <form
      //     onSubmit={handleSubmit(onSubmittxt)}
      //     style={{ marginBottom: 50 }}
      //     className={"video-container"}
      //   >
      //     <div className={"welcome-input"}>
      //       <div className="header">
      //         <div className="logo"></div>
      //       </div>
      //       <form className={classes.root} noValidate autoComplete="off">
      //         <label className="label">نام و نام خانوادگی</label>
      //         <input
      //           {...register("fullname")}
      //           className={"national-code-input"}
      //           placeholder={" نام خود را وارد کنید"}
      //           onFocus={(e) => (e.target.placeholder = "")}
      //           onBlur={(e) => (e.target.placeholder = "نام خود را وارد کنید")}
      //         />
      //         {/* <input
      //           value={fullname}
      //           onChange={(e) => setfullname(e.target.value)}
      //           placeholder={" نام خود را وارد کنید"}
      //           className={"national-code-input"}
      //         /> */}
      //         <label className="label">متن پیام</label>
      //         <input
      //           {...register("message")}
      //           className={"national-code-input"}
      //           placeholder={" پیام خود را وارد کنید"}
      //           onFocus={(e) => (e.target.placeholder = "")}
      //           onBlur={(e) => (e.target.placeholder = "پیام خود را وارد کنید")}
      //         />
      //         {/* <input
      //           value={message}
      //           onChange={(e) => setMessage(e.target.value)}
      //           placeholder={"پیام خود را وارد کنید"}
      //           className={"national-code-input"}
      //         /> */}
      //         <input {...register("file", { required: false })} />

      //         {/* <input
      //           type="file"
      //           // value={files}
      //           onChange={(e) => setFiles(e.target.files)}
      //         /> */}
      //         <br />
      //         <hr />

      //         <input
      //           // onClick={(e) => sendToTextBox(e)}
      //           className={"cancel"}
      //           value={"ارسال"}
      //           type={"submit"}
      //         />
      //         <input
      //           onClick={(e) => cancel()}
      //           className={"cancel"}
      //           value={"بازگشت"}
      //           type={"button"}
      //         />
      //       </form>
      //     </div>
      //   </form>
      // </div>
      <div dir="rtl">
        <div style={{ marginBottom: 50 }} className={"video-container"}>
          <div className={"welcome-input"}>
            <div className="header">
              <div className="logo"></div>
            </div>
            <form className={classes.root} noValidate autoComplete="off">
              <label className="label">نام و نام خانوادگی</label>
              <input
                value={fullname}
                onChange={(e) => setfullname(e.target.value)}
                placeholder={" نام خود را وارد کنید"}
                className={"national-code-input"}
              />
              <label className="label">متن پیام</label>
              <input
                value={message}
                onChange={(e) => setMessageForm(e.target.value)}
                placeholder={"پیام خود را وارد کنید"}
                className={"national-code-input"}
              />
              <br />
              <hr />

              <input onClick={(e) => sendToTextBox(e)} className={"cancel"} value={"ارسال"} type={"submit"} />
              <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
            </form>
          </div>
        </div>
      </div>
    );
  }

  if (alertSend === true) {
    return (
      <div dir="rtl">
        <div style={{ marginBottom: 50 }} className={"video-container"}>
          <div className={"welcome-input"}>
            <div className="header">
              <div className="logo"></div>
            </div>
            <h2>با تشکر از تماس شما</h2>
            <h2>تماس شما در صندوق متنی ذخیره شده به زودی با شما تماس خواهیم گرفت</h2>
            <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div dir="rtl" className="directionpage">
      {alert}

      <div style={{ marginBottom: 50 }} className={"video-container"}>
        {isEnded ? renderEnd() : isFree ? renderCalling() : renderWaiting()}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  // UserAction: bindActionCreators({UserAction}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
