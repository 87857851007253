import styles from "../../../style/Recharts/ButtonXlsx.module.scss";
import React, { useState } from "react";
import { GrDocumentDownload } from "react-icons/gr";
import { FaFileDownload } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { BeatLoader, DotLoader } from "react-spinners";
import { ApiUrl } from "../../../config";

function ButtonXlsx({ apiRequest, days }) {
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");
  const getFileXlsx = async () => {
    try {
      setLoading(true);
      axios
        .get(`${ApiUrl}/${apiRequest}-xls/${days}`, {
          headers: {
            Authorization: token,
          },
          responseType: "arraybuffer",
        })
        .then(function (response) {
          toast.success("در حال بارگیری...");
          setLoading(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          // 
          link.href = url;
          link.setAttribute("download", "data.xlsx");
          link.click();
        })
        .catch(() => {
          toast.error("دانلود فایل با خطا مواجه شد", { position: toast.POSITION.TOP_CENTER });
          setLoading(true);
        });
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <div className={styles.container}>
      <button disabled={loading} onClick={getFileXlsx}>
        {!loading ? (
          <>
            <FaFileDownload className={styles.icons} />
            <h5>دریافت فایل</h5>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              flexDirection: "column",
              direction: "rtl",
            }}
          >
            <BeatLoader
              loading={loading}
              cssOverride={{
                display: "block",
                margin: "0 auto",
                borderColor: "#9c27b0",
              }}
              color={"#9c27b0"}
            />
          </div>
        )}
      </button>
    </div>
  );
}

export default ButtonXlsx;
