import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import axios from "axios";
import $ from "jquery";
import { Device } from "mediasoup-client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux/";
import StarRatingComponent from "react-star-rating-component";
import { useRecoilState } from "recoil";
import io from "socket.io-client";
import { ApiUrl, sendRequest, Url } from "../../config";
import UseWindowSize from "../../Sizes/UseWindowSize";
import logo from "./files/logo.jpeg";
import { textState1 } from "./StateRecoil";
import { CallEnd } from "@material-ui/icons";
import VideoBoxCalling from "../../components/VideoBoxCalling/VideoBoxCalling";
import { LinkMessage, Message, MessageList, Socketid } from "layouts/Recoil";
import notification from "../../assets/sounds/notification.mp3";
import MobileCall from "../../components/MobileCall/MobileCall";
import MobileCallWaiting from "../../components/MobileCallWaiting/MobileCallWaiting";
import {createCollectionIndexed,clearTransaction,createTransaction,deleteIndexedDb,readDataIndexedDb,updateIndexedDb} from 'indexedDB/clientDB'

require("./style.css");
const useStyles = makeStyles(styles);

function Index(props) {
  let TimeWaiting;

  useEffect(() => {
    createCollectionIndexed();
    readDataIndexedDb(setMessagList);
  }, []);
  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);
  const windowSize = UseWindowSize();

  let { nationalCode, mobile, menu, name, idClient } = props.location.state;
  const [isFree, setIsFree] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [flagSend, setFlagSend] = useState("");
  const [uploadEvent, setUploadEvent] = useState("");
  const [out, setOut] = useState(false);
  const [times, setTime] = useState({ start: 0, end: 0 });
  const [operator, setOperator] = useState("");
  const [socketIo, setSocket] = useState(null);
  const [userCount, setUserCount] = useState(0);
  const [isSent, setIsSent] = useState(false);
  const [isEnded, setEnded] = useState(false);
  const [connected, isConnected] = useState(false);
  const [star, setStar] = useState(5);
  const [loading, isLoading] = useState(false);
  const [isHold, setIdHold] = useState(false);
  const [outofwait, setOutofwait] = useState(false);
  const [message, setMessageForm] = useRecoilState(Message);
  const [linkMessag, setLinkMessag] = useRecoilState(LinkMessage);
  const [messagList, setMessagList] = useRecoilState(MessageList);
  const [socktid, setSocktid] = useRecoilState(Socketid);

  const [alertSend, setAlertSend] = useState(null);
  const [flagTextBox, setFlagTextBox] = useState(false);
  const [waitingTime, setWaitingTime] = useState(false);
  const [box, setBox] = useState(false);
  const [fullname, setfullname] = useState("");
  const [message2, setMessage] = useState("");
  const [startvid, setStartvid] = useState(false);
  const [state, setState] = useRecoilState(textState1);
  const [video, setVideo] = useState("");

  //new state
  const [localMedia, setLocalMedia] = useState(null);
  const [tracks, setTracks] = useState(null);
  const [chats, setChats] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [otherTyping, setOtherTyping] = useState(false);

  //indexedDb
  
  //

  const ICE_SERVERS = [
    {
      urls: "turn:185.60.32.129:3478",
      credential: "HmCht_3432",
      username: "hamichat",
    },
  ];
  let local_media_stream = null;
  let peerConnection = null;
  let sharePeerConnection = null;
  let operator_id = 0;

  let device = null;
  let producerTransport = null;
  let videoProducer = null;
  let audioProducer = null;
  let consumerTransport = null;
  let videoConsumers = {};
  let audioConsumers = {};
  useEffect(() => {
    if (!isSent) {
      setIsSent(true);
      init();
      getAvr();
    }
    // if (startvid && !isSent) {
    //   setIsSent(true);
    //   getAvr();
    // }
  }, null);
  useEffect(() => {
    clearTransaction();
  }, []);
  const onTypingMessage = () => {
    //
    if (timeoutId !== null) clearTimeout(timeoutId);
    if (!isTyping) setIsTyping(true);

    const timeout = setTimeout(() => {
      setIsTyping(false);
    }, 2000);

    setTimeoutId(timeout);
  };
  useEffect(() => {
    clearTransaction();
  }, []);
  useEffect(() => {
    socketIo && socketIo.emit("is_typing", isTyping);
  }, [isTyping]);

  useEffect(() => {
    socketIo &&
      socketIo.on("other_typing", (otherTyping) => {
        setOtherTyping(!!otherTyping);
      });
  }, [socketIo]);
  const mailBox = () => {
    props.history.push({
      pathname: "/client/box",
      state: {
        nationalCode,
        menu,
        name,
        mobile,
        idClient,
      },
    });
  };

  const getAvr = () => {
    axios
      .get(ApiUrl + "/get-avr?menu=" + menu, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        isLoading(false);

        setVideo(response.data.data.video);
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };
  const init = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function (stream) {
        local_media_stream = stream;
        setLocalMedia(stream);
        // let self = document.getElementById("self");
        // self.srcObject = stream;
        // self.setAttribute("autoplay", "autoplay");
        // self.setAttribute("playsinline", "playsinline");
        connection();
      })
      .catch((e) => {
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="سیستم شما می بایست به دوربین مجهز باشد"
            onConfirm={() => confirm()}
            confirmBtnCssClass={classes.button + " " + classes.success}
            confirmBtnText="بازگشت"
          ></SweetAlert>
        );
      });
  };
  //
  const confirm = () => {
    window.location.href = `/client-bo/direction/${mobile}`;
  };
  const submit = (e) => {
    e.preventDefault();

    var bodyFormData = new FormData(e.target);
    bodyFormData.append("customer", mobile);
    bodyFormData.append("operator", operator);
    bodyFormData.append("user_national_code", nationalCode);
    bodyFormData.append("menu", menu);
    bodyFormData.append("status", "call");
    bodyFormData.append("date", moment().format("YYYY-MM-DDTHH:mm:ss.sTZD"));
    bodyFormData.append("date_time", moment().format("HH:mm:ss.s"));
    axios({
      url: ApiUrl + "/save-poll",
      method: "post",
      headers: {},
      data: bodyFormData,
    })
      .then(function (response) {})
      .catch(function (error) {})
      .finally(function () {
        window.location.href = `/client-bo/direction/${mobile}`;
      });
  };

  async function subscribe(socketIo, course_id) {
    // --- prepare transport ---
    if (!consumerTransport) {
      const params = await sendRequest("createConsumerTransport", {}, socketIo);
      consumerTransport = await device.createRecvTransport(params);

      // --- join & start publish --
      consumerTransport.on("connect", async ({ dtlsParameters }, callback, errback) => {
        sendRequest("connectConsumerTransport", { dtlsParameters: dtlsParameters }, socketIo)
          .then(callback)
          .catch(errback);
      });

      consumerTransport.on("connectionstatechange", (state) => {
        switch (state) {
          case "connecting":
            break;

          case "connected":
            // consumeCurrentProducers(clientId, socketIo);
            break;

          case "failed":
            producerTransport.close();
            break;

          default:
            break;
        }
      });
      consumeCurrentProducers(operator_id, socketIo);
    }
  }

  async function consumeCurrentProducers(clientId, socketIo) {
    const remoteInfo = await sendRequest("getCurrentProducers", { localId: socketIo.id }, socketIo).catch((err) => {
      return;
    });

    consumeAll(consumerTransport, remoteInfo.remoteVideoIds, remoteInfo.remoteAudioIds, socketIo);
  }

  function removeConsumer(id, kind) {
    if (kind === "video") {
      delete videoConsumers[id];
      //
    } else if (kind === "audio") {
      delete audioConsumers[id];
      //
    } else {
      // console.warn("UNKNOWN consumer kind=" + kind);
    }
  }

  function consumeAll(transport, remoteVideoIds, remotAudioIds, socket) {
    remoteVideoIds.forEach((rId) => {
      consumeAdd(transport, rId.id, null, "video", socket, rId.name, rId.type);
    });
    remotAudioIds.forEach((rId) => {
      consumeAdd(transport, rId.id, null, "audio", socket, rId.name, rId.type);
    });
  }

  async function consumeAdd(transport, remoteSocketId, prdId, trackKind, socket, name = "", type = "student") {
    //

    const { rtpCapabilities } = device;
    const data = await sendRequest(
      "consumeAdd",
      {
        rtpCapabilities: rtpCapabilities,
        remoteId: remoteSocketId,
        kind: trackKind,
      },
      socket
    ).catch((err) => {
      //
    });

    const { producerId, id, kind, rtpParameters } = data;
    if (prdId && prdId !== producerId) {
      // console.warn("producerID NOT MATCH");
    }

    let codecOptions = {};
    const consumer = await transport.consume({
      id,
      producerId,
      kind,
      rtpParameters,
      codecOptions,
    });
    //
    const { track } = consumer;
    setTracks(track);
    addRemoteTrack(remoteSocketId, consumer.track, name, type, kind);
    addConsumer(remoteSocketId, consumer, kind);
    consumer.remoteId = remoteSocketId;
    consumer.on("transportclose", () => {
      //consumer.close();
      //removeConsumer(remoteId);
      //removeRemoteVideo(consumer.remoteId);
    });
    consumer.on("producerclose", () => {
      consumer.close();
      removeConsumer(consumer.remoteId, kind);
      removeRemoteVideo(consumer.remoteId);
    });
    consumer.on("trackended", () => {});

    //return stream;

    if (kind === "video") {
      await sendRequest("resumeAdd", { remoteId: remoteSocketId, kind: kind }, socket)
        .then(() => {})
        .catch((err) => {});
    }
  }

  function addConsumer(id, consumer, kind) {
    if (kind === "video") {
      videoConsumers[id] = consumer;
      //
    } else if (kind === "audio") {
      audioConsumers[id] = consumer;
      //
    } else {
      // console.warn("UNKNOWN consumer kind=" + kind);
    }
  }

  function findRemoteVideo(id, type, kind) {
    //

    let element;
    if (kind === "audio") element = document.querySelector(`#audio_remote video`);
    else {
      if (type == "screen") element = document.querySelector(`#screen video`);
      else element = document.querySelector(`#remote video`);
    }

    return element;
  }

  function addRemoteVideo(id, name = "", type = "", kind, track) {
    //

    let existElement = findRemoteVideo(id, type, kind);
    if (existElement) {
      return existElement;
    }

    // let element = document.createElement('video');
    // const remoteContainer = document.getElementById('remote_container');
    //
    //

    switch (type) {
      case "screen":
        var remote_media = document.createElement("video");
        var parent = document.createElement("div");

        setIsShare(true);

        remote_media.setAttribute("autoplay", "autoplay");
        remote_media.setAttribute("playsinline", "playsinline");

        parent.setAttribute("id", "client_screen");
        if (kind === "audio") parent.setAttribute("style", "display:none");
        else parent.setAttribute("style", "");
        parent.append(remote_media);
        $("#display-container").append(parent);

        return remote_media;

      case "student":
      case "teacher":
        var remote_media = document.createElement("video");
        var parent = document.createElement("div");
        var details = document.createElement("div");
        var username = document.createElement("h3");

        details.setAttribute("class", "details-container");

        username.setAttribute("class", "name");
        username.innerText = name;

        details.append(username);
        remote_media.setAttribute("autoplay", "autoplay");
        remote_media.setAttribute("playsinline", "playsinline");
        remote_media.setAttribute("class", isShare ? "floatclinet" : "");
        parent.setAttribute("class", "student-camera-container");

        if (kind === "audio") {
          parent.setAttribute("id", "audio_remote");
          parent.append(remote_media);
          $("#audio-container").append(parent);

          // let element = document.getElementById(`remote_${id}`);
          // if (element) {
          // var audiobar = document.createElement('div');
          // var elementParent = document.createElement('div');
          // const newStream = new MediaStream();
          // newStream.addTrack(track);
          // let _hark = hark(newStream, {play: false});
          // _hark.on('volume_change', (dBs, threshold) => {
          //     let audioVolume = Math.round(Math.pow(10, dBs / 85) * 10);

          //     if (audioVolume === 1)
          //         audioVolume = 0;

          //     changeVolume(audioVolume, audiobar)

          // });
          // elementParent.append(audiobar)
          // elementParent.setAttribute("class", "barContainer");
          // element.append(elementParent)
          // }
        } else {
          parent.setAttribute("id", "remote");
          parent.append(remote_media);
          // parent.append(details);
          $("#video-container").append(parent);
        }

        return remote_media;
    }

    //
    // remoteContainer.appendChild(element);
    // element.id = 'remote_' + id;
    // element.width = 240;
    // element.height = 180;
    // element.volume = 0;
    // //element.controls = true;
    // element.style = 'border: solid black 1px;';
  }

  function addRemoteTrack(id, track, name = "", type = "", kind) {
    //

    let video = findRemoteVideo(id, type, kind);

    if (!video) {
      video = addRemoteVideo(id, name, type, kind, track);
    }

    if (video.srcObject) {
      video.srcObject.addTrack(track);
      return;
    }

    const newStream = new MediaStream();
    newStream.addTrack(track);

    playVideo(video, newStream)
      .then(() => {
        video.volume = 1.0;
      })
      .catch((err) => {
        // console.error("media ERROR:", err);
      });
  }

  function playVideo(element, stream) {
    if (element.srcObject) {
      // console.warn("element ALREADY playing, so ignore");
      return;
    }
    element.srcObject = stream;
    element.volume = 0;
    return element.play();
  }

  function removeRemoteVideo(id) {
    $("#remote").remove();
    $("#audio_remote").remove();
    $("#client_screen").remove();
  }

  async function loadDevice(routerRtpCapabilities) {
    try {
      device = new Device();
    } catch (error) {
      if (error.name === "UnsupportedError") {
        // console.error("browser not supported");
      }
    }
    await device.load({ routerRtpCapabilities });
  }

  const connection = () => {
    if (!connected) {
      isConnected(true);
      let socket = io.connect(Url);
      setSocket(socket);
      let date_select_menu = localStorage.getItem("data_timeMenu");
      let time_select_menu = moment(date_select_menu, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss.s");
      let date_login = localStorage.getItem("data_timeLogin");
      let time_login = moment(date_login, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss.s");
      socket.on("connect", function () {
        socket.emit("join", {
          type: "client",
          code: nationalCode,
          menu: menu,
          mobile,
          idClient,
          date_select_menu,
          time_select_menu,
          date_login,
          time_login,
          socket_id: socket.id,
        });
      });
      // socket.on("out_of_wait", {
      //   setOutofwait(true)
      // });
      socket.on("out_of_wait", function () {
        localStorage.setItem("name", name);
        localStorage.setItem("idClient", idClient);
        localStorage.setItem("menu", menu);
        window.location.href = "/client/message";
      });
      socket.on("newProducer", function (message) {
        if (device) {
          const remoteId = message.socketId;
          const prdId = message.producerId;
          const kind = message.kind;
          const name = message.name;
          const type = message.type;
          const size = message.size;

          if (kind === "video") {
            consumeAdd(consumerTransport, remoteId, prdId, kind, socket, name, type);
          } else if (kind === "audio") {
            // console.warn('-- audio NOT SUPPORTED YET. skip remoteId=' + remoteId + ', prdId=' + prdId + ', kind=' + kind);

            consumeAdd(consumerTransport, remoteId, prdId, kind, socket, name, type);
          }
        }
      });
      socket.on("producerClosed", function (message) {
        const localId = message.localId;
        const remoteId = message.remoteId;
        const kind = message.kind;
        const size = message.size;
        const type = message.type;

        removeConsumer(remoteId, kind);
        removeRemoteVideo(remoteId);
      });
      socket.on("online_users", function (users, option) {
        if (option) {
          setWaitingTime(option.waiting);
          let waitingMili = moment(option.waiting, "HH:mm:ss").diff(moment().startOf("day"), "seconds") * 1000;
          TimeWaiting = setTimeout(() => {
            setBox(true);
            socket.disconnect();
          }, waitingMili);
        }

        let users_items = [];
        if (users) {
          for (var i = 0; i < users.length; i++) {
            if (users[i].socket !== socket.id) {
              users_items.push(users[i]);
            } else {
              break;
            }
          }

          setUserCount(users_items.length);
        }
      });
      socket.on("free", async function (id, roomId, _id, id_call_history) {
        operator_id = id;
        // socket.emit("watcher", id);
        let temp = [];

        if (localStorage.getItem("data_idCall") !== null) {
          let local = JSON.parse(localStorage.getItem("data_idCall"));
          //
          temp = local;
        }
        temp.push(id_call_history);
        localStorage.setItem("data_idCall", JSON.stringify(temp));
        clearTimeout(TimeWaiting);
        setIsFree(true);
        setOperator(_id);

        await sendRequest("prepare_room", { roomId: roomId }, socket);

        const data = await sendRequest("getRouterRtpCapabilities", { room_id: roomId }, socket);

        await loadDevice(data);

        if (!producerTransport) {
          const params = await sendRequest("createProducerTransport", {}, socket);

          producerTransport = device.createSendTransport(params);
        }

        //

        producerTransport.on("connect", async ({ dtlsParameters }, callback, errback) => {
          //

          sendRequest("connectProducerTransport", { dtlsParameters: dtlsParameters }, socket)
            .then(callback)
            .catch(errback);
        });
        producerTransport.on("produce", async ({ kind, rtpParameters }, callback, errback) => {
          try {
            const { id } = await sendRequest(
              "produce",
              {
                transportId: producerTransport.id,
                kind,
                rtpParameters,
                type: "student",
              },
              socket
            );

            callback({ id });
            //
          } catch (err) {
            //
            errback(err);
          }
        });

        producerTransport.on("connectionstatechange", (state) => {
          switch (state) {
            case "connecting":
              //

              break;

            case "connected":
              //

              break;

            case "failed":
              //

              producerTransport.close();
              break;

            default:
              break;
          }
        });

        const videoTrack = local_media_stream.getVideoTracks()[0];

        if (videoTrack) {
          const trackParams = {
            track: videoTrack,
            encodings: [{ maxBitrate: 50000 }],
          };
          videoProducer = await producerTransport.produce(trackParams);
        }
        const audioTrack = local_media_stream.getAudioTracks()[0];
        if (audioTrack) {
          const trackParams = {
            track: audioTrack,
            encodings: [{ maxBitrate: 100000 }],
          };
          audioProducer = await producerTransport.produce(trackParams);
        }

        subscribe(socket, roomId);
      });

      socket.on("hold_call", function (status) {
        setIdHold(status);

        let video = document.getElementById("remote");
        if (status) {
          video.setAttribute("style", "display:none");
        } else {
          video.setAttribute("style", "display:unset");
        }
      });
      socket.on("free_share", function (id) {
        socket.emit("watcher_screen", id);
      });
      socket.on("cancel_display", function () {
        // sharePeerConnection && sharePeerConnection.close();
        // sharePeerConnection = null
        setIsShare(false);
      });

      socket.on("offer", function (id, description, _id) {
        setOperator(_id);

        peerConnection = new RTCPeerConnection({ iceServers: ICE_SERVERS });

        let stream = local_media_stream;
        if (stream) stream.getTracks().forEach((track) => peerConnection.addTrack(track, stream));
        peerConnection
          .setRemoteDescription(description)
          .then(() => peerConnection.createAnswer())
          .then((sdp) => peerConnection.setLocalDescription(sdp))
          .then(function () {
            socket.emit("answer", id, peerConnection.localDescription);
          });
        peerConnection.ontrack = async function (event) {
          let client = await document.getElementById("client");
          client.setAttribute("autoplay", "autoplay");
          client.setAttribute("playsinline", "playsinline");
          client.srcObject = await event.streams[0];
        };
        peerConnection.onicecandidate = function (event) {
          if (event.candidate) {
            socket.emit("candidate", id, event.candidate);
          }
        };
      });
      socket.on("offer_screen", function (id, description) {
        setIsShare(true);

        sharePeerConnection = new RTCPeerConnection({
          iceServers: ICE_SERVERS,
        });

        sharePeerConnection
          .setRemoteDescription(description)
          .then(() => sharePeerConnection.createAnswer())
          .then((sdp) => sharePeerConnection.setLocalDescription(sdp))
          .then(function () {
            socket.emit("answer_screen", id, sharePeerConnection.localDescription);
          });
        sharePeerConnection.ontrack = async function (event) {
          let client = await document.getElementById("client_screen");
          client.setAttribute("autoplay", "autoplay");
          client.setAttribute("playsinline", "playsinline");
          client.srcObject = await event.streams[0];
        };
        sharePeerConnection.onicecandidate = function (event) {
          if (event.candidate) {
            socket.emit("candidate_screen", id, event.candidate);
          }
        };
      });

      socket.on("candidate", function (id, candidate) {
        peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      });
      socket.on("candidate_screen", function (id, candidate) {
        sharePeerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      });
      socket.on("out_of_time", function (start, end, waiting) {
        const tracks = local_media_stream.getTracks();

        tracks.forEach(function (track) {
          track.stop();
        });
        setBox(true);
        setOut(true);
        setTime({
          start,
          end,
        });
      });

      socket.on("chat_message", function (msg) {
        {
          msg.id === socket.id ? setSocktid((prev) => [...prev, true]) : setSocktid((prev) => [...prev, false]);
        }
        let msg_notification = new Audio(notification);
        msg_notification.loop = false;
        if (msg.operator_id != 0) {
          msg_notification.play();
        }
        createTransaction(msg);
        readDataIndexedDb(setMessagList);
        // setMessagList((prev) => [...prev, msg]);
        setLinkMessag((prev) => [...prev, false]);
        //                 let message = ` <div class="${msg.id == socket.id ? "me" : "other"}">${msg.message}</div>
        // `;
        //
        //                 var messages = document.getElementById("messages");
        //                 messages.innerHTML = messages.innerHTML + message;
        //                 messages.scrollTop = messages.scrollHeight;
      });
      socket.on("chat_message_file", function (msg) {
        {
          msg.id === socket.id ? setSocktid((prev) => [...prev, true]) : setSocktid((prev) => [...prev, false]);
        }
        const obj = {
          ...msg,
          file: true,
        };

        createTransaction(obj);
        readDataIndexedDb(setMessagList);
        // setMessagList((prev) => [...prev, msg]);
        // setLinkMessag((prev) => [...prev, true]);
        //                 let message = ` <div class="${msg.id == socket.id ? "me" : "other"}">
        //
        //
        //
        //  <a   href="${msg.message}">جهت دانلود فایل کلیک کنید</a>
        //
        //
        //
        // </div>
        // `;

        // var messages = document.getElementById("messages");
        // messages.innerHTML = messages.innerHTML + message;
        // messages.scrollTop = messages.scrollHeight;
      });

      socket.on("disconnect", function () {
        clearTransaction();
        //
        peerConnection && peerConnection.close();

        const tracks = local_media_stream.getTracks();

        tracks.forEach(function (track) {
          track.stop();
        });
        setEnded(true);
      });
      socket.on("end_call", function () {
        clearTransaction();
        //
        peerConnection && peerConnection.close();

        const tracks = local_media_stream.getTracks();

        tracks.forEach(function (track) {
          track.stop();
        });
        socket.disconnect();

        setEnded(true);
      });

      socket.on("divert", function () {
        device = null;
        producerTransport = null;
        videoProducer = null;
        audioProducer = null;
        consumerTransport = null;
        videoConsumers = {};
        audioConsumers = {};
        device = null;

        setIsFree(false);
      });
      socket.on("message_edit", (msg) => {
        if (msg.is_delete === true) {
          deleteIndexedDb(msg._id, setMessagList);
        }
        updateIndexedDb(msg, setMessagList);
      });
      socket.on("active_chats", (msg) => {
        setChats(msg);
      });
    }
  };
  const cancel = () => {
    window.location.href = `/client-bo/direction/${mobile}/${nationalCode}`;
  };

  const endCall = () => {
    socketIo.disconnect();
  };

  const sendMessage = function () {
    if (flagSend === false) {
      setFlagSend(true);
      uploadFile();
    }

    // let message = document.getElementById("message");
    if (!message == "") {
      let items = {
        message: message,
        id: socketIo.id,
        national_code: nationalCode,
        mobile: mobile,
        operator_id: operator_id,
      };
      socketIo.emit("chat_message", items);
      setMessageForm("");
      // message.value = "";
    }
  };
  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const uploadFile = function (e) {
    let event = uploadEvent;

    let items = {
      message: " ... در حال ارسال فایل",
      id: socketIo.id,
    };
    socketIo.emit("chat_message", items);

    var fd = new FormData();
    fd.append("file", event);

    axios({
      url: ApiUrl + "/upload-chat-file",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("token"),
      },
      data: fd,
    })
      .then(function (response) {
        let message = document.getElementById("selectFile");

        let items = {
          message: Url + response.data.data.path,
          id: socketIo.id,
        };

        socketIo.emit("chat_message_file", items);
        message.value = "";
      })
      .catch(function (error) {})
      .finally(function () {});
  };

  const sendToTextBox = function (e) {
    e.preventDefault();
    //
    if (localStorage.getItem("data_nationalcode")) {
      axios({
        url: ApiUrl + "/text-box",
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
        data: {
          fullname,
          message,
          menu,
          nationalCode,
          mobile,
          idClient,
          date: moment().format("YYYY-MM-DDTHH:mm:ss.sTZD"),
          date_time: moment().format("HH:mm:ss.s"),
        },
      })
        .then(function (response) {
          //
          setAlertSend(true);
          setFlagTextBox(false);
          setOut(false);
          setBox(false);
        })
        .catch(function (error) {
          // setAlertSend("ناموفق")
          //
        })
        .finally(function () {});
    } else {
      window.location.href = `/client-bo/direction/${mobile}`;
    }
  };
  const startvideo = () => {
    setStartvid(true);
    init();
  };
  const handleEnd = () => {
    renderEnd();
  };
  const renderCalling = () => {
    return (
      <div className={"calling"}>
        <div className="header">
          <div className="logo"></div>
          <h3> {name === "کیپاد" ? "پی پاد" : name}</h3>
        </div>

        <div className=" video ">
          {isShare && <div id="display-container"></div>}
          <div className={isShare ? " floatclinet" : "  "} id="video-container"></div>
          {video.holding != "" &&
            isHold &&
            (windowSize === "md-2" || windowSize === "sm" ? (
              <video
                src={Url + video.holding}
                playsInline
                controls
                loop
                autoPlay
                id="holding"
                width="300px"
                height="180px"
              />
            ) : (
              <video src={Url + video.holding} playsInline controls loop autoPlay id="holding" />
            ))}
          {/* <div className="VideoPreview__btnBox">
            <button onClick={startvideo}>شروع ضبط</button>
            <button onClick={handleEnd}>پایان ضبط</button>
          </div> */}
        </div>
        {
          <div
            style={{
              display: !isHold ? "block" : "none",
            }}
            className="btn-container"
          >
            <div className="chat" dir="rtl">
              <div id={"messages"} className={"messages"}></div>
              <div className={"message-input"}>
                <input
                  dir="rtl"
                  autoComplete="off"
                  onKeyDown={onKeyDown}
                  id={"message"}
                  placeholder={"پیام خود را وارد کنید..."}
                  type={"text"}
                />
                <input
                  id={"selectFile"}
                  type={"file"}
                  onChange={(e) => {
                    setFlagSend(false);
                    setUploadEvent(e.target.files[0]);
                  }}
                />

                <input onClick={sendMessage} type={"button"} />
              </div>
            </div>
          </div>
        }
        <div className="cancel-footer">
          <button className={"cancel-call"} onClick={(event) => endCall()}></button>
        </div>
      </div>
    );
  };

  const renderWaiting = () => {
    return <MobileCallWaiting endCall={endCall} cancel={cancel} chats={chats} />;
  };

  const renderEnd = () => {
    return (
      <div className={"ended"}>
        <div className={"welcome-input"}>
          <div className="header">
            <div className="logo"></div>
          </div>

          <h2>از تماس شما متشکریم</h2>
          <form onSubmit={(e) => submit(e)}>
            <h4 className="infos">لطفا با تکمیل نظر سنجی ما را در بهبود خدمات یاری کنید</h4>
            <h6>میزان رضایت شما از ارتباط</h6>

            <StarRatingComponent name="rate" value={star} onStarClick={(e) => setStar(e)} starCount={5} />
            <textarea placeholder="لطفا نظرات خود را با ما در میان بگذارید" className={"message_area"} name="message" />
            <br />
            <input className={"cancel"} value={"ثبت نظر و بازگشت"} type={"submit"} />
          </form>
        </div>
      </div>
    );
  };
  const renderOutOfTime = () => {
    return (
      <div className={"ended"}>
        <div className={"welcome-input"}>
          <img src={logo} />
          <h2>با تشکر از تماس شما</h2>
          <hr />
          <form onSubmit={(e) => submit(e)}>
            <h4>لطفا به این گفت و گو امتیاز دهید</h4>
            <StarRatingComponent name="rate" value={star} onStarClick={(e) => setStar(e)} starCount={5} />
            <h4>اگر نظری در مورد این گفتگو دارید بیان کنید.</h4>
            <textarea className={"message_area"} name="message" />
            <br />
            <input className={"cancel"} value={"ثبت نظر و بازگشت"} type={"submit"} />
          </form>
        </div>
      </div>
    );
  };

  //flagTextBox is for sandogh_matni
  if (box === true && flagTextBox === false) {
    return (
      <div dir="rtl">
        <div style={{ marginBottom: 50 }} className={"video-container"}>
          <div className={"welcome-input"}>
            <div className="header">
              <div className="logo"></div>
            </div>
            {video.outtime != "" && (
              <div className="video-waitning">
                <video src={Url + video.outtime} controls playsInline={true} loop autoPlay={true} />
              </div>
            )}
            <h2>با تشکر از تماس شما</h2>
            <hr />
            {out ? (
              <h4>
                ساعات کاری از {times.start} تا {times.end} می باشد.
              </h4>
            ) : (
              <h4> همکاران ما مشغول هستند لطفا پیام خود را بگذارید تا در اسرع وقت با شما تماس بگیریم</h4>
            )}
            <input
              onClick={(e) => {
                setFlagTextBox(true);
              }}
              className={"cancel"}
              style={{ width: "200px" }}
              value={"ایجاد پیام در صندوق متنی"}
              type={"button"}
            />
            <br />{" "}
            <input
              onClick={(e) => {
                mailBox();
              }}
              className={"cancel"}
              style={{ width: "200px" }}
              value={"ایجاد پیام در صندوق تصویری"}
              type={"button"}
            />
            <br /> <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
          </div>
        </div>
      </div>
    );
  }
  if (outofwait === true && flagTextBox === false) {
    return (
      <div dir="rtl">
        <div style={{ marginBottom: 50 }} className={"video-container"}>
          <div className={"welcome-input"}>
            <div className="header">
              <div className="logo"></div>
            </div>
            {video.outtime != "" && (
              <div className="video-waitning">
                <video src={Url + video.outtime} controls playsInline={true} loop autoPlay={true} />
              </div>
            )}
            <h2>با تشکر از تماس شما</h2>
            <hr />
            <h4> همکاران ما مشغول هستند لطفا پیام خود را بگذارید تا در اسرع وقت با شما تماس بگیریم</h4>
            <input
              onClick={(e) => {
                setFlagTextBox(true);
              }}
              className={"cancel"}
              style={{ width: "200px" }}
              value={"ایجاد پیام در صندوق متنی"}
              type={"button"}
            />
            <br />{" "}
            <input
              onClick={(e) => {
                mailBox();
              }}
              className={"cancel"}
              style={{ width: "200px" }}
              value={"ایجاد پیام در صندوق تصویری"}
              type={"button"}
            />
            <br /> <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
          </div>
        </div>
      </div>
    );
  }

  if (flagTextBox === true) {
    return (
      <div dir="rtl">
        <div style={{ marginBottom: 50 }} className={"video-container"}>
          <div className={"welcome-input"}>
            <div className="header">
              <div className="logo"></div>
            </div>
            <form className={classes.root} noValidate autoComplete="off">
              <label className="label">نام و نام خانوادگی</label>
              <input
                value={fullname}
                onChange={(e) => setfullname(e.target.value)}
                placeholder={" نام خود را وارد کنید"}
                className={"national-code-input"}
              />
              <label className="label">متن پیام</label>
              <input
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={"پیام خود را وارد کنید"}
                className={"national-code-input"}
              />
              <br />
              <hr />

              <input onClick={(e) => sendToTextBox(e)} className={"cancel"} value={"ارسال"} type={"submit"} />
              <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
            </form>
          </div>
        </div>
      </div>
    );
  }

  if (alertSend === true) {
    return (
      <div dir="rtl">
        <div style={{ marginBottom: 50 }} className={"video-container"}>
          <div className={"welcome-input"}>
            <div className="header">
              <div className="logo"></div>
            </div>
            <h2>با تشکر از تماس شما</h2>
            <h2>تماس شما در صندوق متنی ذخیره شده به زودی با شما تماس خواهیم گرفت</h2>
            <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div dir="rtl" className="directionpage">
      {alert}
      <div style={{ marginBottom: 50, height: "100%", overflow: "auto" }} className={"video-container"}>
        {isEnded ? (
          renderEnd()
        ) : isFree ? (
          <MobileCall
            chats={chats}
            track={tracks}
            localMedia={localMedia}
            endCall={endCall}
            isShare={isShare}
            sendMessage={sendMessage}
            setFlagSend={setFlagSend}
            setUploadEvent={setUploadEvent}
            uploadEvent={uploadEvent}
            otherTyping={otherTyping}
            onTypingMessage={onTypingMessage}
            onKeyDown={onKeyDown}
            windowSize={windowSize}
          />
        ) : (
          renderWaiting()
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  // UserAction: bindActionCreators({UserAction}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
