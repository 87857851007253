import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiUrl } from "../../config";

function Index({ title, data, id }) {
  const token = localStorage.getItem("token");
  const [tabData, setTabData] = useState(null);
  const convertedData = (data) => {
    const arr = [];
    data.forEach((p) => {
      const obj = {
        name: "",
        _id: "",
        calling: 0,
        answer: 0,
        rejected: 0,
        callingChat: 0,
        answerChat: 0,
        rejectedChat: 0,
        count: 0,
      };
      obj.name = p.menu.name;
      obj._id = p._id.menu;
      obj.count = parseInt(p.count);
      if (p._id.status === "calling") {
        obj.calling = parseInt(p.count);
      }
      if (p._id.status === "answer") {
        obj.answer = parseInt(p.count);
      }
      if (p._id.status === "rejected") {
        obj.rejected = parseInt(p.count);
      }
      if (p._id.status === "calling-chat") {
        obj.callingChat = parseInt(p.count);
      }
      if (p._id.status === "answer-chat") {
        obj.answerChat = parseInt(p.count);
      }
      if (p._id.status === "rejected-chat") {
        obj.rejectedChat = parseInt(p.count);
      }
      arr.push(obj);
    });
    const result = arr.reduce((total, current) => {
      let index = total.findIndex((x) => x._id === current._id);
      // eslint-disable-next-line no-unused-expressions
      index === -1
        ? total.push(current)
        : ((total[index].calling += current.calling),
          (total[index].answer += current.answer),
          (total[index].rejected += current.rejected),
          (total[index].callingChat += current.callingChat),
          (total[index].answerChat += current.answerChat),
          (total[index].rejectedChat += current.rejectedChat),
          (total[index].count += current.count));

      return total;
    }, []);
    const queueIndex = result.findIndex((x) => x.name === title);
    const result2 = result[queueIndex];
    setTabData(result2);
  };

  useEffect(() => {
    convertedData(data);
  }, [data]);

  return (
    <div style={{ width: "100%" }}>
      <h4>{title}</h4>
      <div style={{ textAlign: "right", width: "100%" }}>
        <p> تعداد تماس : {tabData ? tabData.count : 0}</p>
        <p> تعداد تماس موفق : {tabData ? tabData.answer + tabData.answerChat : 0}</p>
        <p>
          {" "}
          تعداد تماس ناموفق :{" "}
          {tabData ? tabData.calling + tabData.callingChat + tabData.rejectedChat + tabData.rejected : 0}
        </p>
        <p> متنی موفق : {tabData ? tabData.answerChat : 0}</p>
        <p> تصویری موفق : {tabData ? tabData.answer : 0}</p>
      </div>
    </div>
  );
}

export default Index;
