import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Close } from "@material-ui/icons";
import logo from "../../assets/img/login/hami2.png";
import TextField from "@material-ui/core/TextField";
import moment from "moment/moment";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ApiUrl } from "../../config";

function MobileLoginInput({ setStep, mobile, setMobile, nationalCode, message, setNationalCode, setMessage }) {
  const [mob, setMob] = useState("notValid");
  const [nation, setNation] = useState("notValid");
  const history = useHistory();
  const checkNationalId = (code) => {
    if (
      !/^\d{10}$/.test(code) ||
      code === "0000000000" ||
      code === "1111111111" ||
      code === "2222222222" ||
      code === "3333333333" ||
      code === "4444444444" ||
      code === "5555555555" ||
      code === "6666666666" ||
      code === "7777777777" ||
      code === "8888888888" ||
      code === "9999999999" ||
      code === "1234567891"
    ) {
      return false;
    } else {
      return true;
    }
  };
  const checkMobile = (value) => {
    let emailRex = /^(\+98|0)?9\d{9}$/g;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  async function verify() {
    if (checkNationalId(nationalCode)) setNation("checked");
    else {
      setMessage("لطفا یک کد ملی معتبر وارد کنید");
      setNation("checkFailed");
    }

    if (checkMobile(mobile)) setMob("checked");
    else {
      setMessage("لطفا یک شماره همراه معتبر وارد کنید");
      setMob("checkFailed");
    }
  }

  async function saveClient(nationalCode, mobile) {
    let now = moment().format("YYYY-MM-DDTHH:mm:ss.sTZD");
    let data = {
      nationalCode,
      mobile,
      enter_date: now,
    };
    return await axios({
      url: ApiUrl + "/save-client",
      method: "post",
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(function (response) {
        //
        let idClient = response.data.data._id;
        localStorage.setItem("data_timeLogin", now);
        localStorage.setItem("data_idClient", idClient);
        localStorage.setItem("data_mobile", response.data.data.mobile);
        localStorage.setItem("data_nationalcode", response.data.data.nationalCode);
        history.push({
          pathname: "/client/direction",
          state: {
            nationalCode,
            mobile,
            idClient,
          },
        });
      })
      .catch(function (error) {
        //
      })
      .finally(function () {
        // always executed
        //
      });
  }

  useEffect(() => {
    if (mob === "checked" && nation === "checked") {
      saveClient(nationalCode, mobile);
    }
  }, [mob, nation]);

  const checkValidateNation = (value) => {
    setNationalCode(value);
    value.length > 9 ? setNation("valid") : setNation("notValid");
    mobile.length > 10 ? setMob("valid") : setMob("notValid");
  };
  const checkValidateMobile = (value) => {
    setMobile(value);
    value.length > 10 ? setMob("valid") : setMob("notValid");
    nationalCode.length > 9 ? setNation("valid") : setNation("notValid");
  };

  return (
    <Box
      display={"flex"}
      padding={"16px"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      flexDirection={"column"}
      backgroundColor={"var(--white)"}
      borderRadius={"16px"}
      gap={"24px"}
    >
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
        <Box
          padding={"8px"}
          borderRadius={"8px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ backgroundColor: "var(--mbLightGray)" }}
        >
          <Close style={{ width: "16px", height: "16px" }} />
        </Box>
        <Typography fontSize={"16px"}>ورود</Typography>
      </Box>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} padding={"32px 0"}>
        <img src={logo} width={"100px"} />
      </Box>
      <Box width={"100%"} gap={"8px"} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
        <Typography textAlign={"right"} fontSize={"16px"}>
          کد ملی خود را وارد کنید
        </Typography>
        <TextField
          onChange={(e) => checkValidateNation(e.target.value)}
          variant={"outlined"}
          placeholder={"مثال:0012345678"}
          type={"number"}
          fullWidth
          value={nationalCode}
          sx={{ padding: "12px 8px", borderRadius: "8px" }}
          inputProps={{ style: { padding: "12px 8px", borderRadius: "8px" } }}
        />
      </Box>
      <Box width={"100%"} gap={"8px"} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
        <Typography textAlign={"right"} fontSize={"16px"}>
          شماره موبایل خود را وارد کنید
        </Typography>
        <TextField
          onChange={(e) => checkValidateMobile(e.target.value)}
          variant={"outlined"}
          placeholder={"09123456789"}
          type={"tel"}
          value={mobile}
          fullWidth
          sx={{ padding: "12px 8px", borderRadius: "8px" }}
          inputProps={{ style: { padding: "12px 8px", borderRadius: "8px" } }}
        />
      </Box>
      <Typography color={"var(--mbPink)"}>{message}</Typography>
      <Box width={"100%"}>
        <Button
          sx={{
            color: "var(--white)",
            backgroundColor: "var(--mbOrange)",
            height: "48px",
            fontSize: "16px",
            ":disabled": { backgroundColor: "var(--mbLightOrange)" },
          }}
          disabled={mob === "valid" && nation === "valid" ? false : true}
          fullWidth
          onClick={verify}
        >
          ثبت
        </Button>
      </Box>
    </Box>
  );
}

export default MobileLoginInput;
