import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import stylesa from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Helmet } from "react-helmet";
import { connect } from "react-redux/";
import CreatableSelect from "react-select/creatable";
import ReactTable from "react-table";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import { ApiUrl, Url } from "../../config";
import ReportAction from "../../js/actions/report_action";
import UserAction from "../../js/actions/user_action";

const styles = {
  cardTitle,
  ...stylesa,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function Users(props) {
  const [alert, setAlert] = React.useState(null);

  const confirm = (id) => {
    hideAlert();
    axios
      .get(ApiUrl + "/delete-user/" + id, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        getAllUsers();
      });
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 55,
      border: "1px solid black",
    }),
  };

  const [status, setStatus] = React.useState("");
  let handleChangeStatus = (newValue, actionMeta) => {
    setStatus(newValue);
  };

  const createOptionMobile = (label) => ({
    label,
    value: label,
  });

  const [stateMobile, setStateMobile] = React.useState([]);
  const [inputMobile, setInputMobile] = React.useState("");
  const handleChangeMobile = (newValue, actionMeta) => {
    setStateMobile(newValue);
  };

  const handleInputChangeMobile = (inputValueMobile) => {
    setInputMobile(inputValueMobile);
  };

  const handleKeyDownMobile = (event) => {
    if (!inputMobile) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        let stateV = stateMobile;
        let found;
        if (stateV && stateV.length) {
          found = stateV.find((element) => {
            return element.value == inputMobile;
          });
        }

        if (!found) {
          setInputMobile("");
          let stateB;
          if (stateV) {
            stateB = [...stateV, createOptionMobile(inputMobile)];
          } else {
            stateB = [createOptionMobile(inputMobile)];
          }
          setStateMobile(stateB);
        }
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const components = {
    DropdownIndicator: null,
  };

  const createOptionNationalCode = (label) => ({
    label,
    value: label,
  });

  const [stateNationalCode, setStateNationalCode] = React.useState([]);
  const [inputNationalCode, setInputNationalCode] = React.useState("");
  const handleChangeNationalCode = (value, actionMeta) => {
    setStateNationalCode(value);
  };

  const handleInputChangeNationalCode = (inputValue) => {
    setInputNationalCode(inputValue);
  };

  const handleKeyDownNationalCode = (event) => {
    if (!inputNationalCode) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        let stateV = stateNationalCode;
        let found;
        if (stateV && stateV.length) {
          found = stateV.find((element) => {
            return element.value == inputNationalCode;
          });
        }
        if (!found) {
          setInputNationalCode("");
          let stateB;
          if (stateV) {
            stateB = [...stateV, createOptionNationalCode(inputNationalCode)];
          } else {
            stateB = [createOptionNationalCode(inputNationalCode)];
          }
          setStateNationalCode(stateB);
        }
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let data = props.queues;
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.name === "کیپاد" ? "پی پاد" : item.name,
        });
      });
      setDataSelectQueue(options);
    }
  }, [props.queues]);

  const [queue, setQueue] = React.useState("");
  const [dataSelectQueue, setDataSelectQueue] = React.useState([]);
  let handleChangeQueue = (newValue, actionMeta) => {
    setQueue(newValue);
  };

  const deleteUser = (id) => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="آیا حذف کاربر اطمینان دارید؟"
        onConfirm={() => confirm(id)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="حذف"
        cancelBtnText="لغو"
        showCancel
      ></SweetAlert>
    );
  };

  const getAllUsers = (pagination = { per_page: 20, page: 1 }) => {
    return props.store.dispatch(UserAction.GetAllUsers(pagination));
  };

  const getFilterUsers = (pagination = { per_page: 20, page: 1 }) => {
    let queuesId = [];
    if (queue) {
      queue.forEach((item) => {
        queuesId.push(item.value);
      });
    }
    let mobileArray = [];
    if (stateMobile && stateMobile.length) {
      stateMobile.forEach((item) => {
        mobileArray.push(item.value);
      });
    }
    let nationalCodeArray = [];
    if (stateNationalCode && stateNationalCode.length) {
      stateNationalCode.forEach((item) => {
        nationalCodeArray.push(item.value);
      });
    }
    let typeArray = [];
    if (status && status.length) {
      status.forEach((item) => {
        typeArray.push(item.value);
      });
    }
    return props.store.dispatch(
      UserAction.GetFilterUsers(pagination, nationalCodeArray, mobileArray, queuesId, typeArray)
    );
  };

  const [flag, setFlag] = React.useState("ALL");

  const onPageChangeFunction = (pageIndex) => {
    if (flag === "ALL") {
      getAllUsers({ per_page: 20, page: pageIndex + 1 });
    }
    if (flag === "FILTER") {
      getFilterUsers({ per_page: 20, page: pageIndex + 1 });
    }
  };

  const onPageSizeChangeFunction = (pageSize, pageIndex) => {
    if (flag === "ALL") {
      getAllUsers({ per_page: pageSize, page: pageIndex + 1 });
    }
    if (flag === "FILTER") {
      getFilterUsers({ per_page: 20, page: pageIndex + 1 });
    }
  };

  const getExcell = () => {
    let queuesId = [];
    if (queue) {
      queue.forEach((item) => {
        queuesId.push(item.value);
      });
    }
    let mobileArray = [];
    if (stateMobile && stateMobile.length) {
      stateMobile.forEach((item) => {
        mobileArray.push(item.value);
      });
    }
    let nationalCodeArray = [];
    if (stateNationalCode && stateNationalCode.length) {
      stateNationalCode.forEach((item) => {
        nationalCodeArray.push(item.value);
      });
    }
    let typeArray = [];
    if (status && status.length) {
      status.forEach((item) => {
        typeArray.push(item.value);
      });
    }
    return axios({
      url: ApiUrl + `/get-users-excell`,
      method: "post",
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
      data: {
        nationalCodeArray,
        mobileArray,
        queuesId,
        typeArray,
      },
      responseType: "arraybuffer",
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.xlsx");
        link.click();
      })
      .catch(() => {
        toast.error("دانلود فایل با خطا مواجه شد", { position: toast.POSITION.TOP_CENTER });
      });
  };

  const { loading, data } = props;
  useEffect(() => {
    getAllUsers();
    props.store.dispatch(ReportAction.GetAllQueues());
  }, loading);

  const classes = useStyles();
  if (loading) return "loading";
  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>کاربران - حامی چت</title>
      </Helmet>
      <GridContainer>
        {alert}

        <GridItem xs={12}>
          <Card>
            <Grid container spacing={3}>
              <Grid item xs={5} style={{ margin: "24px", marginTop: "8px", marginLeft: "0px" }}>
                <div>
                  <CreatableSelect
                    isMulti
                    closeMenuOnSelect={false}
                    isClearable
                    onChange={handleChangeQueue}
                    options={dataSelectQueue}
                    styles={customStyles}
                    defaultValue={queue}
                    placeholder="صف مورد نظر خود را انتخاب کنید"
                  />
                </div>
              </Grid>

              <Grid
                item
                xs={5}
                style={{
                  margin: "24px",
                  marginTop: "8px",
                  marginBottom: "0px",
                }}
              >
                <CreatableSelect
                  isMulti
                  closeMenuOnSelect={false}
                  noOptionsMessage={() => null}
                  isClearable
                  onChange={handleChangeStatus}
                  options={[
                    { value: "operator", label: "اپراتور" },
                    { value: "admin", label: "مدیر سیستم" },
                  ]}
                  defaultValue={status}
                  styles={customStyles}
                  placeholder="نوع کاربر"
                />
              </Grid>
              <Grid
                item
                xs={5}
                style={{
                  marginRight: "24px",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                <CreatableSelect
                  components={components}
                  inputValue={inputMobile}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={handleChangeMobile}
                  onInputChange={handleInputChangeMobile}
                  onKeyDown={handleKeyDownMobile}
                  placeholder="شماره تلفن کاربر"
                  styles={customStyles}
                  value={stateMobile}
                />
              </Grid>
              <Grid
                item
                xs={5}
                style={{
                  margin: "24px",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                <CreatableSelect
                  components={components}
                  inputValue={inputNationalCode}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={handleChangeNationalCode}
                  onInputChange={handleInputChangeNationalCode}
                  onKeyDown={handleKeyDownNationalCode}
                  styles={customStyles}
                  placeholder="کدملی کاربر"
                  value={stateNationalCode}
                />
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  marginRight: "24px",
                  marginBottom: "24px",
                  marginTop: "0px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ height: 55 }}
                  onClick={() => {
                    setFlag("FILTER");
                    getFilterUsers({ per_page: 20, page: 1 });
                  }}
                >
                  اعمال
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ height: 55 }}
                  onClick={() => {
                    getAllUsers({ per_page: 20, page: 1 });
                    setFlag("ALL");
                  }}
                >
                  دریافت همه گزارشات
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ height: 55 }}
                  onClick={() => {
                    getExcell();
                  }}
                >
                  دریافت فایل
                </Button>
              </Grid>
            </Grid>
          </Card>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <ReactTable
                rtl
                pagination={true}
                getTableProps={() => ({
                  style: { display: "block", textAlign: "right" },
                })}
                data={data ? data.users : []}
                loading={loading}
                filterable
                manual
                columns={[
                  {
                    Header: "نام کاربری پارس لاجیک",
                    accessor: "username",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "نام",
                    accessor: "first_name",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div className="actions-right">{row.original.first_name + " " + row.original.last_name}</div>
                    ),
                  },
                  {
                    Header: "نقش",
                    accessor: "roles",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => <div className="actions-right">{row.original.roll}</div>,
                  },
                  {
                    Header: "ایمیل",
                    accessor: "email",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "کد ملی",
                    accessor: "national_code",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "تلفن همراه",
                    accessor: "mobile",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "بخش های مربوطه",
                    accessor: "expertList",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div className="actions-right">
                        {row.value.map((e, i) => {
                          return (
                            <div className={"parts"} key={i}>
                              <img style={{ width: 25 }} src={Url + e.icon} />
                              <span>{e.name}</span>
                            </div>
                          );
                        })}
                      </div>
                    ),
                  },
                  {
                    Header: "نوع کاربر",
                    accessor: "type",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div className="actions-right">{row.value !== "admin" ? "اپراتور" : "مدیر سیستم"}</div>
                    ),
                  },
                  {
                    Header: "عملیات",
                    accessor: "_id",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div className="actions-right">
                        <a href={"/admin/poll/" + row.value}>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{ height: 30, padding: "3px" }}
                          >
                            مشاهده نظرات/امتیاز
                          </Button>
                        </a>
                        <br />
                        {row.original.type !== "admin" && (
                          <a href={"/admin/edit-user/" + row.value}>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              style={{ height: 30, padding: "3px" }}
                            >
                              ویرایش
                            </Button>
                          </a>
                        )}
                        <br />
                        {row.original.type !== "admin" && (
                          <a href={"javascript:void(0);"} onClick={(e) => deleteUser(row.value)}>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              style={{ height: 30, padding: "3px" }}
                            >
                              حذف کاربر
                            </Button>
                          </a>
                        )}
                      </div>
                    ),
                  },
                ]}
                showPaginationTop={false}
                pageSize={props.pagination && props.pagination.size}
                page={props.pagination && props.pagination.page}
                pages={props.pagination && props.pagination.total}
                onPageChange={(pageIndex) => {
                  onPageChangeFunction(pageIndex);
                }}
                onPageSizeChange={(pageSize, pageIndex) => {
                  onPageSizeChangeFunction(pageSize, pageIndex);
                }}
                onFilteredChange={(column, value) => {}}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.user.data,
  pagination: state.user.pagination_users,
  loading: state.user.loading,
  error: state.user.error,
  queues: state.reports_2.queues,
});

const mapDispatchToProps = (dispatch) => ({
  UserAction: bindActionCreators({ UserAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
