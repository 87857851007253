import { Image, SearchOutlined } from "@material-ui/icons";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import Assessment from "@material-ui/icons/Assessment";
import ChatIcon from "@material-ui/icons/Chat";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DirectionsIcon from "@material-ui/icons/Directions";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import HistoryIcon from "@material-ui/icons/History";
import HowToVoteIcon from "@material-ui/icons/HowToVote";
import QueueIcon from "@material-ui/icons/List";
import Sessions from "@material-ui/icons/MeetingRoom";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import { default as PersonIcon, default as UsersIcon } from "@material-ui/icons/Person";
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";
import PhoneForwardedIcon from "@material-ui/icons/PhoneForwarded";
import Ticket from "@material-ui/icons/ReportProblem";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import TimelineIcon from "@material-ui/icons/Timeline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditCustomer from "views/Customers/EditCustomer";
import ProblemsCustomer from "views/Customers/ProblemsCustomer";
import Profile from "views/Dashboard/Profile";
import CallToClient from "views/operator/CallToClient";
import AddQueue from "views/Queue/AddQueue";
import Queues from "views/Queue/Queues";
import ClientHistoryCall from "views/Reports/ClientHistoryCall";
import ClientsHistoryEnterExit from "views/Reports/ClientsHistoryEnterExit";
import HIstory from "views/Reports/History";
import inputCall from "views/Reports/inputCall";
import MediaBoxReport from "views/Reports/MediaBoxReport";
import MidCalls from "views/Reports/MidCalls";
import Accessibility from "views/Reports/Modals/Accessibility";
import OperatorAccessibility from "views/Reports/Modals/OperatorAccessibility";
import Tickets from "views/Reports/Problems";
import SearchTrackingNumber from "views/Reports/SearchTrackingNumber";
import TextBoxReport from "views/Reports/TextBoxReport";
import AddUser from "views/Users/AddUser";
import Users from "views/Users/Users";
import AddVideos from "views/Videos/AddVideo";
import Videos from "views/Videos/Videos";
import OperatorDashboard from "./components/OperatorDashboard/OperatorDashboard";
import AddClientConfrence from "./views/Confrence/AddClientConfrence";
import AddRoom from "./views/Confrence/AddConfrence";
import Rooms from "./views/Confrence/Confrences";
import Room from "./views/Confrence/Room";
import RoomOperator from "./views/Confrence/RoomOperator";
import RoomTest from "./views/Confrence/RoomTest";
import AddCustomers from "./views/Customers/AddCustomer";
import Customers from "./views/Customers/Customers";
import DashboardAdmin from "./views/Dashboard/DashboardAdmin";
import SessionAdmin from "./views/Dashboard/Session";
import Options from "./views/Options/Options";
import TextBoxDetail from "./views/Reports/All/TextBoxDetail";
import CallDetails from "./views/Reports/CallDetails";
import ChartCalls from "./views/Reports/ChartCalls";
import Clients from "./views/Reports/Clients";
import ReportOperator from "./views/Reports/Modals/ReportOperator";
import ViewAccessibility from "./views/Reports/Modals/ViewAccessibility";
import OperatorsPoll from "./views/Reports/OperatorsPoll";
import OutCall from "./views/Reports/OutCall";
import ReportEnterExit from "./views/Reports/ReportEnterExit";
import TransferCall from "./views/Reports/transferCall";
import EditUser from "./views/Users/EditUser";
import Polls from "./views/Users/Polls";
import { FaQq } from "react-icons/fa";
import AdminFAQ from "./views/AdminFAQ/AdminFAQ";
import { MdFeed, MdQuiz } from "react-icons/md";
import AddFaq from "components/AddFaq/AddFaq";
import AdminNews from "components/AdminNews/AdminNews";
import AddNews from "components/AddNews/AddNews";
import AddSlide from "components/AddSlide/AddSlide";
import AdminSlider from "components/AdminSlider/AdminSlider";

var dashRoutes = [
  {
    operator: true,
    path: "/dashboard",
    name: "داشبورد",
    rtlName: "داشبورد",
    icon: DashboardIcon,
    component: OperatorDashboard,
    layout: "/admin",
  },

  {
    admin: true,
    path: "/admin-dashboard",
    name: "داشبورد مدیریت",
    rtlName: "داشبورد   مدیریت",
    cName: "AdminDashboard",
    icon: DashboardIcon,
    component: DashboardAdmin,
    layout: "/admin",
  },
  {
    admin: true,
    sub: true,
    path: "/check-session/:id",
    name: "داشبورد",
    rtlName: "داشبورد",
    cName: "SessionAdmin",
    icon: DashboardIcon,
    component: SessionAdmin,
    layout: "/admin",
  },
  {
    admin: true,
    path: "/profile",
    name: "پروفایل",
    rtlName: "پروفایل",
    cName: "Profile",
    icon: PermIdentityIcon,
    component: Profile,
    layout: "/admin",
  },
  {
    collapse: true,
    admin: true,
    name: "کنفرانس",
    rtlName: "کنفرانس",
    cName: "Confrences",
    icon: UsersIcon,
    state: "formsCollapse",
    views: [
      {
        admin: true,
        path: "/rooms",
        name: "لیست اتاق های اپراتورها",
        rtlName: "لیست اتاق های اپراتورها",
        cName: "Rooms",
        mini: "U",
        rtlMini: "ک",
        component: Rooms,
        layout: "/admin",
      },

      {
        admin: true,
        path: "/add-room",
        name: "افزودن اتاق اپراتور",
        rtlName: "افزودن اتاق اپراتور",
        cName: "AddRoom",
        mini: "U",
        rtlMini: "ا",
        component: AddRoom,
        layout: "/admin",
      },
    ],
  },

  {
    operator: true,
    path: "/operator-rooms",
    name: "اتاق ها",
    rtlName: "اتاق ها",
    icon: DashboardIcon,
    component: RoomOperator,
    layout: "/admin",
  },

  {
    collapse: true,
    admin: true,
    name: " سطح دسترسی",
    rtlName: " سطح دسترسی",
    icon: AccessibilityIcon,
    state: "accessibilityCollapse",
    views: [
      {
        admin: true,
        path: "/accessibility",
        name: "تعیین سطح دسترسی",
        rtlName: "تعیین سطح دسترسی",
        icon: AccessibilityIcon,
        component: Accessibility,
        layout: "/admin",
      },
      {
        admin: true,
        path: "/view-accessibility",
        name: "مشاهده ی سطح دسترسی",
        rtlName: "مشاهده ی سطح دسترسی",
        icon: VisibilityIcon,
        component: ViewAccessibility,
        layout: "/admin",
      },
      {
        admin: true,
        path: "/operator-accessibility",
        name: "تعیین سطح دسترسی اپراتور",
        rtlName: "  تعیین سطح دسترسی اپراتور",
        icon: AccessibilityIcon,
        component: OperatorAccessibility,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    // admin: true,
    name: "گزارشات",
    rtlName: "گزارشات",
    cName: "reports",
    icon: Assessment,
    state: "reportsCollapse",
    views: [
      {
        admin: true,
        path: "/histories",
        name: "تاریخچه تماس ها",
        rtlName: "تاریخچه تماس ها",
        cName: "callDetails",
        icon: HistoryIcon,
        mini: "U",
        rtlMini: "ت",
        component: CallDetails,
        layout: "/admin",
      },
      {
        path: "/search-tracking-number",
        name: "جست و جو شماره پیگیری",
        rtlName: "جستوجو کد پیگیری",
        icon: SearchOutlined,
        mini: "S",
        rtlMini: "ج",
        component: SearchTrackingNumber,
        layout: "/admin",
      },
      // {
      //   admin: true,
      //   path: "/input-call",
      //   name: "inputCall",
      //   rtlName: "تماس های ورودی",
      //   cName: "inputCall",
      //   icon: PhoneCallbackIcon,
      //   mini: "U",
      //   rtlMini: "ت",
      //   component: inputCall,
      //   layout: "/admin",
      // },
      {
        admin: true,
        path: "/ChartCalls",
        name: "نمودار تماس ها",
        rtlName: "نمودار تماس ها",
        cName: "chartCalls",
        icon: TimelineIcon,
        mini: "U",
        rtlMini: "‌ن",
        component: ChartCalls,
        layout: "/admin",
      },

      {
        admin: true,
        path: "/reports",
        name: "گزارشات نظرسنجی",
        rtlName: "گزارشات نظرسنجی",
        cName: "operatorsPoll",
        icon: HowToVoteIcon,
        mini: "U",
        rtlMini: "گ",
        component: OperatorsPoll,
        layout: "/admin",
      },
      {
        admin: true,
        path: "/enter-exit-reports",
        name: "گزارش ورود و خروج از صف",
        rtlName: "گزارش ورود و خروج از صف",
        cName: "reportEnterExit",
        icon: SwapHorizIcon,
        mini: "U",
        rtlMini: "گ",
        component: ReportEnterExit,
        layout: "/admin",
      },
      {
        admin: true,
        path: "/text-box-reports",
        name: "گزارش صندوق متنی",
        cName: "textBoxReport",
        rtlName: "گزارش صندوق متنی",
        icon: ChatIcon,
        mini: "U",
        rtlMini: "گ",
        component: TextBoxReport,
        layout: "/admin",
      },
      {
        admin: true,
        path: "/media-box-reports",
        name: "گزارش صندوق تصویری",
        cName: "mediaBoxReport",
        rtlName: "گزارش صندوق تصویری",
        icon: OndemandVideoIcon,
        mini: "U",
        rtlMini: "گ",
        component: MediaBoxReport,
        layout: "/admin",
      },
      // {
      //   admin: true,
      //   // sub: true,
      //   path: "/text-box-messages/:id",
      //   name: "پیام های ارسالی",
      //   rtlName: "پیام های ارسالی",
      //   cName: "textBoxMessages",
      //   icon: Sessions,
      //   component: TextBoxMessages,
      //   layout: "/admin",
      // },
      {
        admin: true,
        path: "/transfer-call-reports",
        name: "گزارش انتقال تماس",
        rtlName: "گزارش انتقال تماس",
        cName: "transferCall",
        icon: DirectionsIcon,
        mini: "U",
        rtlMini: "گ",
        component: TransferCall,
        layout: "/admin",
      },
      {
        admin: true,
        path: "/out-call-reports",
        name: "گزارش تماس خروجی",
        rtlName: "گزارش تماس خروجی",
        cName: "outCall",
        icon: PhoneForwardedIcon,
        mini: "U",
        rtlMini: "گ",
        component: OutCall,
        layout: "/admin",
      },
      {
        admin: true,
        path: "/report-operators",
        name: "گزارش اپراتور ها",
        rtlName: "گزارش اپراتور ها",
        cName: "reportMidCalls",
        icon: SupervisorAccountIcon,
        mini: "U",
        rtlMini: "گ",
        component: MidCalls,
        layout: "/admin",
      },
      {
        admin: true,
        path: "/report-clients",
        name: "گزارش مشتری ها",
        rtlName: "گزارش مشتری ها",
        cName: "clientsReport",
        icon: PersonIcon,
        mini: "U",
        rtlMini: "گ",
        component: Clients,
        layout: "/admin",
      },
    ],
  },

  // {
  //   admin: true,
  //   path: "/surveyQuestionnaire",
  //   name: "surveyQuestionnaire",
  //   rtlName: "فرم نظرسنجی",
  //   icon: QuestionAnswerIcon,
  //   component: SurveyQuestionnaire,
  //   layout: "/admin",
  // },

  {
    sub: true,
    path: "/report-clients-enter-exit/:id",
    name: "تاریخچه ورود و خروج ",
    rtlName: "تاریخچه ورود و خروج ",
    cName: "ClientsHistoryEnterExit",
    icon: Sessions,
    component: ClientsHistoryEnterExit,
    layout: "/admin",
  },
  {
    sub: true,
    path: "/report-clients-calls/:id",
    name: "گزارش تماس مشتری",
    rtlName: "گزارش تماس مشتری",
    cName: "ClientHistoryCall",
    icon: Sessions,
    component: ClientHistoryCall,
    layout: "/admin",
  },
  {
    sub: true,
    path: "/report_customers/:id",
    name: "تاریخچه تماس مشتری",
    rtlName: "تاریخچه تماس مشتری",
    cName: "ProblemsCustomer",
    icon: Sessions,
    component: ProblemsCustomer,
    layout: "/admin",
  },
  {
    sub: true,
    path: "/edit_customer/:id",
    name: "ویراش مشتری",
    rtlName: "ویراش مشتری",
    cName: "EditCustomer",
    icon: Sessions,
    component: EditCustomer,
    layout: "/admin",
  },

  {
    admin: true,
    path: "/options",
    name: "تنظیمات",
    rtlName: "تنظیمات",
    cName: "Options",
    icon: DashboardIcon,
    component: Options,
    layout: "/admin",
  },
  // {
  //   path: "/add-client-room",
  //   name: "تماس خروجی",
  //   rtlName: "تماس خروجی",
  //   cName: "CallToClient",
  //   icon: HeadsetMicIcon,
  //   component: CallToClient,
  //   layout: "/admin",
  // },
  {
    collapse: true,
    // admin: true,
    name: "تماس خروجی",
    rtlName: "تماس خروجی",
    cName: "CallToClient",
    icon: HeadsetMicIcon,
    component: CallToClient,
    state: "roomCollapse",
    views: [
      {
        admin: true,
        path: "/clientrooms",
        name: "لیست اتاق های مشتری ها",
        rtlName: "لیست اتاق های مشتری ها",
        cName: "RoomOperator",
        mini: "U",
        rtlMini: "ک",
        component: RoomOperator,
        layout: "/admin",
      },

      {
        // admin: true,
        path: "/add-clientroom",
        name: "افزودن تماس خروجی",
        rtlName: "افزودن تماس خروجی",
        cName: "AddClientConfrence",
        mini: "UI",
        rtlMini: "ا",
        component: AddClientConfrence,
        layout: "/admin",
      },
    ],
  },

  {
    path: "/operator-report",
    name: "سوابق تماس",
    rtlName: "سوابق تماس",
    cName: "ReportOperator",
    icon: PersonIcon,
    component: ReportOperator,
    layout: "/admin",
  },
  {
    operator: true,
    path: "/profile",
    name: "پروفایل",
    rtlName: "پروفایل",
    icon: PermIdentityIcon,
    component: Profile,
    layout: "/admin",
  },

  {
    admin: true,
    sub: true,
    path: "/room/:id",
    name: "گزینه ها",
    rtlName: "گزینه ها",
    cName: "Room",
    icon: DashboardIcon,
    component: Room,
    layout: "/admin",
  },
  {
    admin: true,
    sub: true,
    path: "/roomtest/:id",
    name: "گزینه ها",
    rtlName: "گزینه ها",
    cName: "RoomTest",
    icon: DashboardIcon,
    component: RoomTest,
    layout: "/admin",
  },

  {
    admin: true,
    sub: true,
    path: "/poll/:id",
    name: "داشبورد",
    rtlName: "داشبورد",
    icon: DashboardIcon,
    component: Polls,
    layout: "/admin",
  },
  {
    admin: true,
    sub: true,
    path: "/edit-user/:id",
    name: "داشبورد",
    rtlName: "داشبورد",
    icon: DashboardIcon,
    component: EditUser,
    layout: "/admin",
  },

  {
    path: "/problems",
    name: "تیکت ها",
    rtlName: "تیکت ها",
    icon: Ticket,
    component: Tickets,
    layout: "/admin",
  },

  {
    sub: true,
    path: "/history/:id",
    name: "تاریخچه تماس ",
    rtlName: "تاریخچه تماس ",
    cName: "HIstory",
    icon: Sessions,
    component: HIstory,
    layout: "/admin",
  },
  {
    sub: true,
    path: "/text-box-message/:id",
    name: " تاریخچه تماس متنی  ",
    rtlName: "تاریخچه تماس متنی  ",
    cName: "TextBoxDetail",
    icon: Sessions,
    component: TextBoxDetail,
    layout: "/admin",
  },

  {
    collapse: true,
    admin: true,
    name: "کاربران",
    rtlName: "کاربران",
    icon: UsersIcon,
    state: "usersCollapse",
    views: [
      {
        admin: true,
        path: "/users",
        name: "لیست کاربران",
        rtlName: "لیست کاربران",
        mini: "U",
        rtlMini: "ک",
        component: Users,
        layout: "/admin",
      },
      {
        admin: true,
        path: "/add-user",
        name: "افزودن کاربر",
        rtlName: "افزودن کاربر",
        mini: "U",
        rtlMini: "ا",
        component: AddUser,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    // admin: true,
    name: "مشتری ها",
    rtlName: "مشتری ها",
    cName: "customers",
    icon: UsersIcon,
    state: "customerCollapse",
    views: [
      {
        admin: true,
        path: "/customers",
        name: "لیست مشتری ها",
        rtlName: "لیست مشتری ها",
        cName: "customersList",
        mini: "C",
        rtlMini: "ل",
        component: Customers,
        layout: "/admin",
      },
      {
        admin: true,
        operator: false,
        path: "/add-customers",
        name: "افزودن مشتری",
        rtlName: "افزودن مشتری",
        cName: "addCustomers",
        mini: "C",
        rtlMini: "ا",
        component: AddCustomers,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    // admin: true,
    operator: false,
    name: "صف ها",
    rtlName: "صف ها",
    cName: "queue",
    icon: QueueIcon,
    state: "queueCollapse",
    views: [
      {
        admin: true,
        operator: false,
        path: "/queues",
        name: "لیست صف ها",
        rtlName: "لیست صف ها",
        cName: "queuesList",
        mini: "U",
        rtlMini: "ص",
        component: Queues,
        layout: "/admin",
      },
      {
        admin: true,
        operator: false,
        path: "/add-queue",
        name: "افزودن صف",
        rtlName: "افزودن صف",
        cName: "addQueue",
        mini: "U",
        rtlMini: "ا",
        component: AddQueue,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    admin: true,
    name: "ویدئو ها",
    rtlName: "ویدئو ها",
    icon: QueueIcon,
    state: "videoCollapse",
    views: [
      {
        admin: true,
        path: "/videos",
        name: "لیست ویدئو ها",
        rtlName: "لیست ویدئو ها",
        mini: "U",
        rtlMini: "و",
        component: Videos,
        layout: "/admin",
      },
      {
        admin: true,
        path: "/add-video",
        name: "افزودن ویدئو",
        rtlName: "افزودن ویدئو",
        mini: "U",
        rtlMini: "ا",
        component: AddVideos,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    admin: true,
    path: "/FAQ",
    name: "سوالات متداول",
    rtlName: "سوالات متداول",
    cName: "faq",
    icon: MdQuiz,
    layout: "/admin",
    views: [
      {
        admin: true,
        path: "/update-faq",
        name: "ویرایش",
        rtlName: "ویرایش",
        cName: "faq",
        mini: "U",
        rtlMini: "و",
        layout: "/admin",
        component: AdminFAQ,
      },
      {
        admin: true,
        path: "/add-faq",
        name: "افزودن",
        rtlName: "افزودن",
        cName: "faq",
        mini: "A",
        rtlMini: "ا",
        layout: "/admin",
        component: AddFaq,
      },
    ],
  },
  {
    collapse: true,
    admin: true,
    path: "/News",
    name: "خبرنامه",
    rtlName: "خبرنامه",
    cName: "News",
    icon: MdFeed,
    layout: "/admin",
    views: [
      // {
      //   admin: true,
      //   path: "/update-news",
      //   name: "ویرایش",
      //   rtlName: "ویرایش",
      //   cName: "faq",
      //   mini: "U",
      //   rtlMini: "و",
      //   layout: "/admin",
      //   component: AdminNews,
      // },
      {
        admin: true,
        path: "/add-news",
        name: "افزودن",
        rtlName: "افزودن",
        cName: "faq",
        mini: "A",
        rtlMini: "ا",
        layout: "/admin",
        component: AddNews,
      },
    ],
  },
  {
    collapse: true,
    admin: true,
    path: "/slider",
    name: "اسلایدر",
    rtlName: "اسلایدر",
    cName: "Slider",
    icon: Image,
    layout: "/admin",
    views: [
      // {
      //   admin: true,
      //   path: "/update-slide",
      //   name: "ویرایش",
      //   rtlName: "ویرایش",
      //   cName: "slides",
      //   mini: "U",
      //   rtlMini: "و",
      //   layout: "/admin",
      //   component: AdminSlider,
      // },
      {
        admin: true,
        path: "/add-slide",
        name: "افزودن",
        rtlName: "افزودن",
        cName: "slide",
        mini: "A",
        rtlMini: "ا",
        layout: "/admin",
        component: AddSlide,
      },
    ],
  },
];

export default dashRoutes;
