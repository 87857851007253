import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { ApiUrl } from "../../config";
import { toast, ToastContainer } from "react-toastify";
import { Delete, Edit, RemoveCircle } from "@material-ui/icons";

const useStyles = makeStyles({
  textArea: {
    resize: "none",
    width: "100%",
    borderRadius: "10px",
    border: "1px solid var(--lightGray)",
    padding: "10px",
  },
  "@keyframes openNote": {
    from: { height: "0" },
    to: { height: "100%" },
  },
  textContainer: {
    animation: "$openNote 1s !important",
  },
  notes: {
    color: "var(--white)",
    wordBreak: "break-word",
    wordWrap: "break-word",
    padding: "10px",
    backgroundColor: "var(--darkGreen)",
    borderRadius: "10px",
    textAlign: "right",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

function AddNotes(props) {
  const classes = useStyles();

  const [openNotes, setOpenNotes] = useState(false);
  const [openAddNote, setOpenAddNote] = useState(false);
  const [text, setText] = useState("");
  const [notes, setNotes] = useState([]);
  const [edit, setEdit] = useState("");
  const [update, setUpdate] = useState(false);
  const operatorId = localStorage.getItem("_id");

  const addNoteHandle = async () => {
    edit
      ? updateNote(edit)
      : await axios
          .post(
            ApiUrl + "/operator-add-notepad",
            {
              text,
              operator: operatorId,
            },
            { headers: { Authorization: localStorage.getItem("token") } }
          )
          .then((res) => {
            setText("");
            setUpdate(!update);
            toast.success("یادداشت با موفقیت ثبت شد");
          })
          .catch((res) => {
            
            toast.error("خطا در ثبت یادداشت");
          });
  };
  const getNotes = async () => {
    await axios
      .get(ApiUrl + "/operator-notepad/" + operatorId, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => {
        const sorted = res.data.data.notePad.sort((a, b) => {
          if (a.rate > b.rate) {
            return -1;
          }
          if (a.rate < b.rate) {
            return 1;
          }
          return 0;
        });
        setNotes(sorted);
      })
      .catch((res) => {
        
      });
  };

  const deleteNote = async (id) => {
    await axios
      .post(
        ApiUrl + "/operator-update-notepad",
        { is_active: false, id },
        {
          headers: { Authorization: localStorage.getItem("token") },
        }
      )
      .then((res) => {
        setUpdate(!update);
        toast.success("یادداشت با موفقیت حذف شد");
      })
      .catch((res) => {
        toast.error("خطا در حذف یادداشت");
      });
  };

  const updateNote = async (id) => {
    await axios
      .post(
        ApiUrl + "/operator-update-notepad",
        { id, text, is_active: true },
        {
          headers: { Authorization: localStorage.getItem("token") },
        }
      )
      .then((res) => {
        setText("");
        setUpdate(!update);
        toast.success("یادداشت با موفقیت ویرایش شد");
      })
      .catch((res) => {
        setText("");
        toast.error("خطا در ویرایش یادداشت");
      });
  };
  useEffect(() => {
    getNotes();
  }, [update]);
  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} width={"100%"}>
      <ToastContainer position={"top-center"} />
      <Box alignSelf={"flex-start"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Button
          sx={{ backgroundColor: "var(--primaryBlue)", color: "var(--black)" }}
          onClick={(e) => setOpenAddNote(!openAddNote)}
        >
          {openAddNote ? "انصراف" : "افزودن یادداشت"}
        </Button>
        <Button sx={{ margin: "0 5px" }} onClick={(e) => setOpenNotes(!openNotes)}>
          {openNotes ? "بستن یادداشت ها" : "مشاهده یادداشت ها"}
        </Button>
      </Box>
      {openNotes && (
        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          flexDirection={"column"}
          overflow={"auto"}
          width={"100%"}
          margin={2}
          maxHeight={"200px"}
        >
          {notes.map((item, index) => (
            <Box key={item._id} display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <p className={classes.notes}>{item.text}</p>
              <Edit
                onClick={(e) => (setText(item.text), setOpenAddNote(true), setEdit(item._id))}
                style={{ margin: "0 5px", cursor: "pointer" }}
              />
              <RemoveCircle
                onClick={(e) => deleteNote(item._id)}
                color={"error"}
                style={{ margin: "0 5px", cursor: "pointer" }}
              />
            </Box>
          ))}
        </Box>
      )}
      {openAddNote && (
        <Box
          width={"100%"}
          overflow={"hidden"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          margin={2}
          className={classes.textContainer}
        >
          <Box width={"100%"}>
            <textarea className={classes.textArea} rows={4} onChange={(e) => setText(e.target.value)} value={text} />
          </Box>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"} alignSelf={"flex-start"}>
            <Button
              onClick={addNoteHandle}
              disabled={!text && true}
              sx={{ backgroundColor: "var(--primaryBlue)", color: "var(--black)" }}
            >
              ثبت
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default AddNotes;
