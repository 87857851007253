import {
  GET_MEDIA_BOX_FAILED,
  GET_MEDIA_BOX_REQUEST,
  GET_MEDIA_BOX_SUCCESS,
  GET_TEXT_BOX_FAILED,
  GET_TEXT_BOX_MESSAGE_FAILED,
  GET_TEXT_BOX_MESSAGE_REQUEST,
  GET_TEXT_BOX_MESSAGE_SUCCESS,
  GET_TEXT_BOX_REQUEST,
  GET_TEXT_BOX_SUCCESS,
} from "../type/user_type";

const initialState = {
  data: null,
  loading: false,
  error: null,
  message: null,
  code: null,
  pagination: null,
  pagination_media: null,
  mediaBox: null,
  textBox: null,
  textBoxmessage: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TEXT_BOX_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_TEXT_BOX_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
        textBox: action.payload.data.textBox,
      };
    }
    case GET_TEXT_BOX_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_TEXT_BOX_MESSAGE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_TEXT_BOX_MESSAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
        textBoxmessage: action.payload.data.textBox,
      };
    }
    case GET_TEXT_BOX_MESSAGE_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_MEDIA_BOX_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_MEDIA_BOX_SUCCESS: {
      return {
        ...state,
        loading: false,
        mediaBox: action.payload.data.histories,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
      };
    }
    case GET_MEDIA_BOX_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    }

    default:
      return state;
  }
}
