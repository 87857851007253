import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
import { ApiUrl } from "config";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import connect from "react-redux/es/connect/connect";
import CreatableSelect from "react-select/creatable";
import { bindActionCreators } from "redux";
import UserAction from "../../../js/actions/user_action";

const useStyles = makeStyles((theme) => ({
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
  //check box style
  root: {
    display: "block",
    marginTop: "20px",
  },
  formControl: {
    width: "49%",
    color: "black",
  },
  textFeild: {
    width: "49%",
  },
}));
const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 55,
    // width: "50%",
    border: "1px solid black",
  }),
  menu: (base) => ({
    ...base,
    zIndex: "999999",
  }),
};

function OperatorAccessibility(props) {
  const classes = useStyles();
  //set level
  useEffect(() => {
    let data = [
      {
        name: "سطح 1",
        _id: "level1",
      },
      {
        name: "سطح 2",
        _id: "level2",
      },
      {
        name: "سطح 3",
        _id: "level3",
      },
    ];
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.name,
        });
      });
      setDataSelectLevel(options);
    }
    //get list of operator
    props.store.dispatch(UserAction.GetOperators());
  }, []);

  const [level, setLevel] = React.useState("");
  const [dataSelectLevel, setDataSelectLevel] = React.useState([]);
  let handleChangeLevel = (newValue) => {
    setLevel(newValue);
    // 
  };
  //set operator
  const [dataSelectOperator, setDataSelectOperator] = React.useState([]);
  const [operator, setOperator] = React.useState("");

  useEffect(() => {
    let data = props.operators;
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.first_name + " " + item.last_name,
        });
      });
      setDataSelectOperator(options);
    }
    // 
  }, props.operators);
  let handleChange = (newValue, actionMeta) => {
    setOperator(newValue);
  };

  const handleRoll = (event) => {
    axios({
      url: ApiUrl + "/update-oprator-roll/",
      method: "post",
      params: {},
      data: {
        id: operator.value,
        roll: level.value,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
      responseType: "arraybuffer",
    })
      .then(function (response) {
        // 
      })
      .catch((error) => {
        // 
      });
  };
  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title> تعیین سطح دسترسی اپراتور</title>
      </Helmet>
      <Card>
        <CardHeader className={classes.cardIconTitle} color="primary" icon>
          <CardIcon color="primary">
            <Assignment />
          </CardIcon>
          <h4 className={classes.cardIconTitle}> تعیین سطح دسترسی اپراتور</h4>
        </CardHeader>
        <CardBody>
          <Grid container spacing={1}>
            <Grid
              item
              xs={5}
              style={{
                display: "content",
                marginBottom: "0px",
                marginRight: "24px",
              }}
            >
              <Box>
                <CreatableSelect
                  closeMenuOnSelect={false}
                  isClearable
                  onChange={handleChange}
                  options={dataSelectOperator}
                  styles={customStyles}
                  defaultValue={operator}
                  placeholder="اپراتور مورد نظر را انتخاب کنید"
                />
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box>
                <CreatableSelect
                  closeMenuOnSelect={false}
                  isClearable
                  onChange={handleChangeLevel}
                  options={dataSelectLevel}
                  styles={customStyles}
                  defaultValue={level}
                  placeholder="سطح دسترسی مورد نظر خود را انتخاب کنید"
                />
              </Box>
            </Grid>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            name="buttonname"
            style={{ height: 40, display: "block", margin: "40px auto" }}
            onClick={() => {
              handleRoll();
            }}
          >
            ثبت
          </Button>
        </CardBody>
      </Card>
    </div>
  );
}
const mapStateToProps = (state) => {
  // 
  return {
    historyCall: state.user.history,
    pagination: state.user.pagination_call,
    loading: state.user.loading,
    error: state.user.error,
    operators: state.user.operators,
    queues: state.reports_2.queues,
  };
};
const mapDispatchToProps = (dispatch) => ({
  UserAction: bindActionCreators({ UserAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperatorAccessibility);
