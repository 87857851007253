import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { TimePicker } from "antd";
import "antd/dist/antd.css";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ReportsCallActivity from "components/Recharts/ReportsCallActivity";
import moment from "moment-jalaali";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { connect } from "react-redux/";
import CreatableSelect from "react-select/creatable";
import ReactTable from "react-table";
import { toast, ToastContainer } from "react-toastify";
import { ApiUrl, Url } from "../../config";
import ReportAction from "../../js/actions/report_action";
import UserAction from "../../js/actions/user_action";
import Spinner from "../../shared/Spinner/Spinner";
import Reset from "./../../assets/img/reset.png";

// import { CSVLink, CSVDownload } from "react-csv";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function Histories(props) {
  const { loading } = props;
  useEffect(() => {
    props.store.dispatch(ReportAction.getMidCalls());
    props.store.dispatch(ReportAction.GetAllQueues());
    props.store.dispatch(UserAction.GetOperators());
  }, loading);

  useEffect(() => {
    let data = props.operators;
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.first_name + " " + item.last_name,
        });
      });
      setDataSelectOperator(options);
    }
    // 
  }, props.operators);

  const handleSimple = (event) => {
    setSimpleSelect(event.target.value);
  };
  const [simpleSelect, setSimpleSelect] = React.useState("");

  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });

  ///////////
  const { RangePicker } = TimePicker;
  const [timeStart, settimeStart] = useState("");
  const [timeEnd, settimeEnd] = useState("");
  function onChange(time, timeString) {
    // 
    settimeStart(timeString[0]);
    settimeEnd(timeString[1]);
  }
  ///////////

  const [dataSelectOperator, setDataSelectOperator] = React.useState([]);
  const [operator, setOperator] = React.useState("");
  // 
  let handleChangeOpeartor = (newValue, actionMeta) => {
    setOperator(newValue);
  };

  ////
  useEffect(() => {
    let data = props.queues;
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.name === "کیپاد" ? "پی پاد" : item.name,
        });
      });
      setDataSelectQueue(options);
    }
  }, [props.queues]);
  const [queue, setQueue] = React.useState("");
  const [dataSelectQueue, setDataSelectQueue] = React.useState([]);
  let handleChangeQueue = (newValue, actionMeta) => {
    setQueue(newValue);
  };
  ////

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 55,
      border: "1px solid black",
      zIndex: 999999999999999999999,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 99999999 }),
    menu: (base) => ({ ...base, zIndex: 99999999 }),
  };
  const renderCustomInput = ({ ref }) => (
    <>
      <input
        readOnly
        ref={ref} // necessary
        placeholder="زمان گزارش را انتخاب کنید"
        value={
          selectedDayRange.from && selectedDayRange.to
            ? `${selectedDayRange.from.year}` +
              "/" +
              `${selectedDayRange.from.month}` +
              "/" +
              `${selectedDayRange.from.day}` +
              " تا " +
              `${selectedDayRange.to.year}` +
              "/" +
              `${selectedDayRange.to.month}` +
              "/" +
              `${selectedDayRange.to.day}`
            : ""
        }
        style={{
          textAlign: "center",
          padding: "8px",
          fontSize: "1rem",
          border: "1px solid ",
          borderRadius: "5px",
          outline: "none",
          height: 55,
          minHeight: 35,
          width: 500,
        }}
        className="my-custom-input-class" // a styling class
      />
      <a>
        <img
          src={Reset}
          style={{ width: "30px", height: "30px" }}
          onClick={() => {
            setSelectedDayRange({
              from: null,
              to: null,
            });
          }}
        />
      </a>
    </>
  );

  const [typeCall, setTypeCall] = React.useState("");
  let handleChangeType = (newValue, actionMeta) => {
    setTypeCall(newValue);
  };

  function secondsToHms(d) {
    d = Number(d);
    if (d < 1) {
      return 1 + " ثانیه ";
    }
    if (d === 0 || d == "Infinity" || d == "-Infinity" || isNaN(d)) {
      return 0 + " ثانیه ";
    }
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var hDisplay = h > 0 ? h + (h == 1 ? " ساعت" : " ساعت ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " دقیقه" : " دقیقه ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "ثانیه" : "ثانیه") : "";
    return hDisplay + " " + mDisplay + " " + sDisplay;
  }

  function secondsToHmsClock(d) {
    d = Number(d);
    if (d < 1) {
      return "01 : 00 : 00";
    }
    if (d === 0 || d == "Infinity" || d == "-Infinity" || isNaN(d)) {
      return 0;
    }
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var hDisplay = h > 0 ? (h < 10 ? "0" + h : h) : "00";
    var mDisplay = m > 0 ? (m < 10 ? "0" + m : m) : "00";
    var sDisplay = s > 0 ? (s < 10 ? "0" + s : s) : "00";
    return sDisplay + " : " + mDisplay + " : " + hDisplay;
  }

  const getExcell = () => {
    let queuesId = [];
    if (queue) {
      queue.forEach((item) => {
        queuesId.push(item.value);
      });
    }
    let dateG_from;
    let dateG_to;
    if (selectedDayRange.to) {
      let dateFromString =
        selectedDayRange.from.year + "-" + selectedDayRange.from.month + "-" + selectedDayRange.from.day;
      let dateToString = selectedDayRange.to.year + "-" + selectedDayRange.to.month + "-" + selectedDayRange.to.day;
      dateG_from = moment(dateFromString, "jYYYY-jM-jD").format("YYYY-MM-DD");
      dateG_to = moment(dateToString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    }
    let operatorsId = [];

    if (operator && operator.length) {
      operator.forEach((item) => {
        operatorsId.push(item.value);
      });
    }
    let typeList = [];

    if (typeCall && typeCall.length) {
      typeCall.forEach((item) => {
        typeList.push(item.value);
      });
    }
    // 
    axios({
      url: ApiUrl + "/reports-mid-calls-excell",
      method: "post",
      params: {},
      data: {
        dateFrom: dateG_from,
        dateTo: dateG_to,
        queuesId: queuesId,
        timeStart,
        timeEnd,
        typeList,
        operatorsId,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
      responseType: "arraybuffer",
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.xlsx");
        link.click();
      })
      .catch(() => {
        toast.error("دانلود فایل با خطا مواجه شد", { position: toast.POSITION.TOP_CENTER });
      });
  };

  const classes = useStyles();
  if (loading) return <Spinner />;

  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title> گزارش اپراتورها - حامی چت</title>
      </Helmet>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <Card>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={5}
                  style={{
                    margin: "24px",
                    marginTop: "24px",
                    marginBottom: "0px",
                  }}
                >
                  <CreatableSelect
                    isMulti
                    closeMenuOnSelect={false}
                    isClearable
                    onChange={handleChangeOpeartor}
                    options={dataSelectOperator}
                    defaultValue={operator}
                    styles={customStyles}
                    placeholder="اپراتور مورد نظر را انتخاب کنید"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    margin: "24px",
                    marginBottom: "0px",
                    marginRight: "0px",
                  }}
                >
                  <DatePicker
                    value={selectedDayRange}
                    renderInput={renderCustomInput}
                    onChange={setSelectedDayRange}
                    inputPlaceholder="زمان گزارش"
                    shouldHighlightWeekends
                    locale="fa"
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    margin: "24px",
                    marginBottom: "0px",
                    marginTop: "0px",
                    marginLeft: "0px",
                  }}
                >
                  <Box>
                    <CreatableSelect
                      isMulti
                      closeMenuOnSelect={false}
                      isClearable
                      onChange={handleChangeQueue}
                      options={dataSelectQueue}
                      styles={customStyles}
                      defaultValue={queue}
                      placeholder="صف مورد نظر خود را انتخاب کنید"
                    />
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={5}
                  style={{
                    marginBottom: "24px",
                    marginRight: "24px",
                  }}
                >
                  <CreatableSelect
                    isMulti
                    closeMenuOnSelect={false}
                    isClearable
                    onChange={handleChangeType}
                    options={[
                      { value: "call", label: "تماس های تصویری" },
                      { value: "chat", label: "تماس های متنی" },
                    ]}
                    styles={customStyles}
                    defaultValue={typeCall}
                    placeholder="نوع تماس"
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    margin: "24px",
                    marginBottom: "24px",
                    marginTop: "0px",
                  }}
                >
                  <RangePicker
                    onChange={onChange}
                    placeholder={["زمان آغاز", "زمان پایان"]}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    defaultValue={[moment(timeStart, "HH:mm:ss"), moment(timeEnd, "HH:mm:ss")]}
                    defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                    style={{
                      height: "60px",
                      width: "300px",
                      border: "1px solid black",
                      borderRadius: "4px",
                      cursor: "pointer",
                      fontSize: "17px",
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{
                    margin: "auto",
                    marginBottom: "24px",
                    marginTop: "0px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    name="buttonname"
                    style={{ height: 55 }}
                    onClick={() => {
                      // 
                      let queuesId = [];
                      if (queue) {
                        queue.forEach((item) => {
                          queuesId.push(item.value);
                        });
                      }
                      let operatorsId = [];

                      if (operator && operator.length) {
                        operator.forEach((item) => {
                          operatorsId.push(item.value);
                        });
                      }
                      let typeList = [];
                      if (typeCall && typeCall.length) {
                        typeCall.forEach((item) => {
                          typeList.push(item.value);
                        });
                      }
                      props.store.dispatch(
                        ReportAction.getMidCalls(selectedDayRange, queuesId, timeStart, timeEnd, operatorsId, typeList)
                      );
                    }}
                  >
                    اعمال
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    name="buttonname"
                    style={{ height: 55 }}
                    onClick={() => {
                      // 
                      props.store.dispatch(ReportAction.getMidCalls({}));
                    }}
                  >
                    دریافت همه گزارشات
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    name="buttonname"
                    style={{ height: 55 }}
                    onClick={() => getExcell()}
                  >
                    دریافت فایل
                  </Button>
                </Grid>
              </Grid>
            </Card>
            <CardBody>
              <ReportsCallActivity />
            </CardBody>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <ReactTable
                rtl
                data={props.calls ? props.calls : []}
                loading={props.loading}
                pagination={false}
                showPagination={false}
                filterable
                manual
                columns={[
                  {
                    Header: "اپراتور",
                    accessor: "operator",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "تعداد کل",
                    accessor: "numberCall",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "مدت زمان مکالمه",
                    accessor: "allCallTime",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => {
                      let allCallTime = row.original.allCallTime;
                      let hms = secondsToHms(allCallTime);
                      return <div>{hms}</div>;
                    },
                  },
                  {
                    Header: "میانگین کل",
                    accessor: "_",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => {
                      let midCalls = row.original.allCallTime / row.original.numberCall;
                      let hms = secondsToHms(midCalls);
                      return <div>{hms}</div>;
                    },
                  },
                  {
                    Header: "صف",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div className="actions-right">
                        {row.original.menus_report.map((e, i) => {
                          return (
                            <div className={"parts"} key={i}>
                              <img style={{ width: 25 }} src={Url + e.icon} />
                              <span>{e.menu}</span>
                            </div>
                          );
                        })}
                      </div>
                    ),
                  },
                  {
                    Header: "تعداد مکالمه",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div className="actions-right">
                        {row.original.menus_report.map((e, i) => {
                          return (
                            <div className={"parts"} style={{ marginRight: "30px" }} key={i}>
                              {e.number}{" "}
                            </div>
                          );
                        })}
                      </div>
                    ),
                  },
                  {
                    Header: "طول مکالمه",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div>
                        {row.original.menus_report.map((e, i) => {
                          return (
                            <div className={"parts"} key={i}>
                              {secondsToHmsClock(e.timeCall)}{" "}
                            </div>
                          );
                        })}
                      </div>
                    ),
                  },
                  {
                    Header: "میانگین",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div className="actions-right">
                        {row.original.menus_report.map((e, i) => {
                          return (
                            <div className={"parts"} key={i}>
                              {secondsToHmsClock(e.timeCall / e.number)}{" "}
                            </div>
                          );
                        })}
                      </div>
                    ),
                  },
                ]}
                className="-striped -highlight"
                showPaginationTop={false}
                showPaginationBottom={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => {
  // 
  return {
    calls: state.midCalls.data,
    loading: state.midCalls.loading,
    error: state.midCalls.error,
    queues: state.reports_2.queues,
    operators: state.user.operators,
  };
};

export default connect(mapStateToProps)(Histories);
