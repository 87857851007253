import React, { createContext, useState } from "react";

export const TicketContext = createContext();

const TicketContextProvider = (props) => {
  const [infoticket, setInfoticket] = useState([]);
  const [userid, setUserid] = useState(0);

  const saveTicketInfo = (data) => {
    setInfoticket([data]);
  };

  // 
  return (
    <TicketContext.Provider
      value={{
        saveTicketInfo,
        setInfoticket,
        infoticket,
        userid,
        setUserid,
      }}
    >
      {props.children}
    </TicketContext.Provider>
  );
};

export default TicketContextProvider;
