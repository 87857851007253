import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import TextareaAutosize from "react-autosize-textarea";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux/";
import StarRatingComponent from "react-star-rating-component";
import io from "socket.io-client";
import { ApiUrl, Url } from "../../config";

require("./style.css");
const useStyles = makeStyles(styles);

function Index(props) {
  let TimeWaiting;

  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);

  let { nationalCode, mobile, menu, name, idClient } = props.location.state;
  const [isFree, setIsFree] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [flagSend, setFlagSend] = useState("");
  const [uploadEvent, setUploadEvent] = useState("");

  const [out, setOut] = useState(false);
  const [times, setTime] = useState({ start: 0, end: 0 });
  const [operator, setOperator] = useState("");
  const [socketIo, setSocket] = useState(null);
  const [userCount, setUserCount] = useState(0);
  const [isSent, setIsSent] = useState(false);
  const [isEnded, setEnded] = useState(false);
  const [connected, isConnected] = useState(false);
  const [star, setStar] = useState(5);
  const [loading, isLoading] = useState(false);
  const [isHold, setIdHold] = useState(false);
  const [alertSend, setAlertSend] = useState(null);
  const [flagTextBox, setFlagTextBox] = useState(false);
  const [waitingTime, setWaitingTime] = useState(false);
  const [box, setBox] = useState(false);
  const [fullname, setfullname] = useState("");
  const [message, setMessage] = useState("");

  const [video, setVideo] = useState("");

  const ICE_SERVERS = [
    {
      urls: "turn:185.60.32.129:3478",
      credential: "HmCht_3432",
      username: "hamichat",
    },
  ];
  let local_media_stream = null;
  let peerConnection = null;
  let sharePeerConnection = null;
  let operator_id = 0;

  useEffect(() => {
    if (!isSent) {
      setIsSent(true);
      connection();
      getAvr();
    }
  }, null);

  const getAvr = () => {
    axios
      .get(ApiUrl + "/get-avr", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        isLoading(false);

        setVideo(response.data.data.video);
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };
  const init = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function (stream) {
        local_media_stream = stream;
        let self = document.getElementById("self");
        self.srcObject = stream;
        self.setAttribute("autoplay", "autoplay");
        self.setAttribute("playsinline", "playsinline");
      })
      .catch((e) => {
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="سیستم شما می بایست به دوربین مجهز باشد"
            onConfirm={() => confirm()}
            confirmBtnCssClass={classes.button + " " + classes.success}
            confirmBtnText="بازگشت"
          ></SweetAlert>
        );
      });
  };
  const mailBox = () => {
    props.history.push({
      pathname: "/client/box",
      state: {
        nationalCode,
        menu,
        name,
        mobile: localStorage.getItem("data_mobile"),
        idClient,
      },
    });
  };
  const confirm = () => {
    window.location.href = "/client-bot";
  };
  const submit = (e) => {
    e.preventDefault();

    var bodyFormData = new FormData(e.target);
    bodyFormData.append("customer", localStorage.getItem("data_mobile"));
    bodyFormData.append("operator", operator);
    bodyFormData.append("user_national_code", nationalCode);
    bodyFormData.append("menu", menu);
    bodyFormData.append("status", "chat");
    bodyFormData.append("date", moment().format("YYYY-MM-DDTHH:mm:ss.sTZD"));
    bodyFormData.append("date_time", moment().format("HH:mm:ss.s"));

    axios({
      url: ApiUrl + "/save-poll",
      method: "post",
      headers: {},
      data: bodyFormData,
    })
      .then(function (response) {})
      .catch(function (error) {})
      .finally(function () {
        window.location.href = "/client-bot";
      });
  };

  const connection = () => {
    if (!connected) {
      isConnected(true);
      let socket = io.connect(Url);
      setSocket(socket);
      socket.on("connect", function () {
        socket.emit("joinChat", {
          type: "client",
          code: nationalCode,
          menu: menu,
          mobile,
        });
      });

      socket.on("online_users", function (users, option) {
        if (option) {
          setWaitingTime(option.waiting);
          let waitingMili = moment(option.waiting, "HH:mm:ss").diff(moment().startOf("day"), "seconds") * 1000;
          TimeWaiting = setTimeout(() => {
            setBox(true);
            socket.disconnect();
          }, waitingMili);
        }
        let users_items = [];
        if (users) {
          for (var i = 0; i < users.length; i++) {
            if (users[i].socket !== socket.id) {
              users_items.push(users[i]);
            } else {
              break;
            }
          }

          setUserCount(users_items.length);
        }
      });
      socket.on("free", function (id, _id) {
        operator_id = id;
        setIsFree(true);
        setOperator(_id);
        clearTimeout(TimeWaiting);
      });

      socket.on("hold_call", function (status) {
        setIdHold(status);

        let video = document.getElementById("client");
        if (status) {
          video.setAttribute("style", "display:none");
        } else {
          video.setAttribute("style", "display:unset");
        }
      });
      socket.on("free_share", function (id) {
        socket.emit("watcher_screen", id);
      });
      socket.on("cancel_display", function () {
        sharePeerConnection && sharePeerConnection.close();
        sharePeerConnection = null;
        setIsShare(false);
      });

      socket.on("offer", function (id, description, _id) {
        setOperator(_id);

        peerConnection = new RTCPeerConnection({ iceServers: ICE_SERVERS });

        let stream = local_media_stream;
        if (stream) stream.getTracks().forEach((track) => peerConnection.addTrack(track, stream));
        peerConnection
          .setRemoteDescription(description)
          .then(() => peerConnection.createAnswer())
          .then((sdp) => peerConnection.setLocalDescription(sdp))
          .then(function () {
            socket.emit("answer", id, peerConnection.localDescription);
          });
        peerConnection.ontrack = async function (event) {
          let client = await document.getElementById("client");
          client.setAttribute("autoplay", "autoplay");
          client.setAttribute("playsinline", "playsinline");
          client.srcObject = await event.streams[0];
        };
        peerConnection.onicecandidate = function (event) {
          if (event.candidate) {
            socket.emit("candidate", id, event.candidate);
          }
        };
      });
      socket.on("offer_screen", function (id, description) {
        setIsShare(true);

        sharePeerConnection = new RTCPeerConnection({
          iceServers: ICE_SERVERS,
        });

        sharePeerConnection
          .setRemoteDescription(description)
          .then(() => sharePeerConnection.createAnswer())
          .then((sdp) => sharePeerConnection.setLocalDescription(sdp))
          .then(function () {
            socket.emit("answer_screen", id, sharePeerConnection.localDescription);
          });
        sharePeerConnection.ontrack = async function (event) {
          let client = await document.getElementById("client_screen");
          client.setAttribute("autoplay", "autoplay");
          client.setAttribute("playsinline", "playsinline");
          client.srcObject = await event.streams[0];
        };
        sharePeerConnection.onicecandidate = function (event) {
          if (event.candidate) {
            socket.emit("candidate_screen", id, event.candidate);
          }
        };
      });

      socket.on("candidate", function (id, candidate) {
        peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      });
      socket.on("candidate_screen", function (id, candidate) {
        sharePeerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      });
      socket.on("out_of_time", function (start, end) {
        setOut(true);
        setBox(true);
        setTime({
          start,
          end,
        });
      });

      socket.on("chat_message", function (msg) {
        let message = ` <div style=" word-break: break-all;" class="${msg.id == socket.id ? "me" : "other"}">${
          msg.message
        }</div>
`;

        var messages = document.getElementById("messages");
        messages.innerHTML = messages.innerHTML + message;
        messages.scrollTop = messages.scrollHeight;
      });
      socket.on("chat_message_file", function (msg) {
        let message = ` <div class="${msg.id == socket.id ? "me" : "other"}">
 
 
 
 <a   href="${msg.message}">جهت دانلود فایل کلیک کنید</a>
 
 
 
</div>
`;

        var messages = document.getElementById("messages");
        messages.innerHTML = messages.innerHTML + message;
        messages.scrollTop = messages.scrollHeight;
      });

      socket.on("disconnect", function () {
        setEnded(true);
      });
      socket.on("end_call", function () {
        setEnded(true);
      });
    }
  };
  const cancel = () => {
    window.location.href = "/client-bot";
  };

  const endCall = () => {
    socketIo.disconnect();
  };

  const sendMessage = function () {
    let textarea = document.getElementsByClassName("TextareaAutosize")[0];
    textarea.style.height = "46px";
    if (flagSend === false) {
      setFlagSend(true);
      uploadFile();
    }
    let message = document.getElementById("message");
    if (!message.value == "") {
      let items = {
        message: message.value,
        id: socketIo.id,
        national_code: nationalCode,
        mobile: localStorage.getItem("data_mobile"),
        operator_id: operator_id,
      };
      socketIo.emit("chat_message", items);
      message.value = "";
    }
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.target.style.height = "46px !important";
      event.target.placeholder = "پیام خود را وارد کنید";
      //sendMessage()
    }
  };
  const uploadFile = function (e) {
    let event = uploadEvent;

    let items = {
      message: " ... در حال ارسال فایل",
      id: socketIo.id,
    };
    socketIo.emit("chat_message", items);

    var fd = new FormData();
    fd.append("file", event);

    axios({
      url: ApiUrl + "/upload-chat-file",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("token"),
      },
      data: fd,
    })
      .then(function (response) {
        let message = document.getElementById("selectFile");

        let items = {
          message: Url + response.data.data.path,
          id: socketIo.id,
        };

        socketIo.emit("chat_message_file", items);
        message.value = "";
      })
      .catch(function (error) {})
      .finally(function () {});
  };

  const sendToTextBox = function (e) {
    e.preventDefault();
    if (localStorage.getItem("data_nationalcode")) {
      axios({
        url: ApiUrl + "/text-box",
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
        data: {
          fullname,
          message,
          menu,
          nationalCode,
          mobile,
          idClient,
          date: moment().format("YYYY-MM-DDTHH:mm:ss.sTZD"),
          date_time: moment().format("HH:mm:ss.s"),
        },
      })
        .then(function (response) {
          //
          setAlertSend(true);
          setFlagTextBox(false);
          setOut(false);
          setBox(false);
        })
        .catch(function (error) {
          // setAlertSend("ناموفق")
          //
        })
        .finally(function () {});
    } else {
      window.location.href = "/";
    }
  };
  const renderCalling = () => {
    return (
      <div className={"calling"}>
        <div className="header">
          <div className="logo"></div>
          <h3> {name === "کیپاد" ? "پی پاد" : name}</h3>
        </div>

        <div style={!isShare ? { display: "none" } : {}} className="video">
          <video style={{ display: isShare ? "block" : "none" }} autoPlay={true} id="client_screen" />
          {video.holding != "" && isHold && (
            <video src={Url + video.holding} playsInline controls loop autoPlay id="holding" />
          )}
        </div>

        <div
          className="btn-container"
          style={
            !isShare
              ? {
                  maxWidth: "95%",
                  flex: "0 0 95%",
                  margin: "auto auto 80px auto",
                }
              : {}
          }
        >
          <div className="chat" dir="rtl" style={{ height: "76vh" }}>
            <div id={"messages"} className={"messages"} style={{ maxHeight: "70vh" }}></div>
            <div className={"message-input"}>
              <TextareaAutosize
                autoComplete="off"
                dir="rtl"
                onKeyDown={onKeyDown}
                id={"message"}
                placeholder={"پیام خود را وارد کنید..."}
                type={"text"}
                className="TextareaAutosize"
              />
              <input
                id={"selectFile"}
                type={"file"}
                onChange={(e) => {
                  setFlagSend(false);
                  setUploadEvent(e.target.files[0]);
                }}
              />

              <input onClick={sendMessage} type={"button"} />
            </div>
          </div>
        </div>

        <div className="cancel-footer-bot">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button className={"cancel-call-bot"} onClick={(event) => endCall()}></button>
          </div>
        </div>
      </div>
    );
  };

  const renderWaiting = () => {
    return (
      <div className={"waiting"}>
        <div className="header">
          <div className="logo"></div>
          <h3> {name === "کیپاد" ? "پی پاد" : name}</h3>
        </div>

        <div className="waiters">
          <b style={{ marginRight: "30px" }}> {userCount} نفر در صف انتظار</b>
          <br />
          <span style={{ marginRight: "30px" }}>زمان تقریبی انتظار {userCount * 2} دقیقه</span>
        </div>
        {video.waiting != "" && (
          <div className="video-waiting">
            <video src={Url + video.waiting} loop controls autoPlay playsInline />
          </div>
        )}

        <div className="icon-container-bot">
          <div className="wainting-icon-chat-bot"></div>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <h4 className={{ marginRight: "30px" }}>در حال برقراری ارتباط با اولین اپراتور...</h4>
        </div>

        <div className="cancel-footer-bot">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <input onClick={(e) => cancel()} className={"cancel-call-bot"} type={"button"} />
          </div>
        </div>
      </div>
    );
  };

  const renderEnd = () => {
    return (
      <div>
        <div>
          <div className="header">
            <div className="logo"></div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <h2>از تماس شما متشکریم</h2>
          </div>

          <form onSubmit={(e) => submit(e)}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h5 className="infos">لطفا با تکمیل نظر سنجی ما را در بهبود خدمات یاری کنید</h5>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h6>میزان رضایت شما از ارتباط</h6>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <StarRatingComponent name="rate" value={star} onStarClick={(e) => setStar(e)} starCount={5} />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <textarea
                placeholder="لطفا نظرات خود را با ما در میان بگذارید"
                className={"message_area"}
                name="message"
              />
            </div>

            <br />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <input className={"cancel"} value={"ثبت نظر و بازگشت"} type={"submit"} />
            </div>
          </form>
        </div>
      </div>
    );
  };

  if (box === true && flagTextBox === false) {
    return (
      <div dir="rtl">
        <div style={{ marginBottom: 50 }} className={"video-container"}>
          <div>
            <div className="header">
              <div className="logo"></div>
            </div>
            {video.outtime != "" && (
              <div className="video-waitning">
                <video src={Url + video.outtime} playsInline controls loop autoPlay />
              </div>
            )}
            <h2>با تشکر از تماس شما</h2>
            <hr />
            {out ? (
              <h4>
                ساعات کاری از {times.start} تا {times.end} می باشد.
              </h4>
            ) : (
              <h4> همکاران ما مشغول هستند لطفا پیام خود را بگذارید تا در اسرع وقت با شما تماس بگیریم</h4>
            )}
            <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
            <input
              onClick={(e) => {
                setFlagTextBox(true);
              }}
              className={"cancel"}
              style={{ width: "200px" }}
              value={"ایجاد پیام در صندوق متنی"}
              type={"button"}
            />
            <br />{" "}
            <input
              onClick={(e) => {
                mailBox();
              }}
              className={"cancel"}
              style={{ width: "200px" }}
              value={"ایجاد پیام در صندوق تصویری"}
              type={"button"}
            />
            <br />
          </div>
        </div>
      </div>
    );
  }

  if (flagTextBox === true) {
    return (
      <div dir="rtl">
        <div style={{ marginBottom: 50 }} className={"video-container"}>
          <div>
            <div className="header">
              <div className="logo"></div>
            </div>
            <form className={classes.root} noValidate autoComplete="off">
              <label className="label">نام و نام خانوادگی</label>
              <input
                value={fullname}
                onChange={(e) => setfullname(e.target.value)}
                placeholder={" نام خود را وارد کنید"}
                className={"national-code-input"}
              />
              <label className="label">متن پیام</label>
              <input
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={"پیام خود را وارد کنید"}
                className={"national-code-input"}
              />
              <br />
              <hr />

              <input onClick={(e) => sendToTextBox(e)} className={"cancel"} value={"ارسال"} type={"submit"} />
              <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
            </form>
          </div>
        </div>
      </div>
    );
  }

  if (alertSend === true) {
    return (
      <div dir="rtl">
        <div style={{ marginBottom: 50 }} className={"video-container"}>
          <div>
            <div className="header">
              <div className="logo"></div>
            </div>
            <h4>با تشکر از تماس شما</h4>
            <br /> <br /> <br />
            <h4>تماس شما در صندوق متنی ذخیره شده به زودی با شما تماس خواهیم گرفت</h4>
            <br /> <br /> <br />
            <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div dir="rtl">
      {alert}

      <div className={"video-container-bot"}>{isEnded ? renderEnd() : isFree ? renderCalling() : renderWaiting()}</div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  // UserAction: bindActionCreators({UserAction}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
