import { css } from "@emotion/react";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import { ApiUrl } from "../../config";

function MessageTxt(props) {
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({
    title: "",
    message: "",
    phone: localStorage.getItem("data_mobile"),
    nationalcode: localStorage.getItem("data_nationalcode"),
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    props.page === 1 &&
      setDatas({
        title: data.title,
        message: data.message,
        phone: localStorage.getItem("data_mobile"),
        nationalcode: localStorage.getItem("data_nationalcode"),
      });
    setLoading(true);

    axios({
      url: `${ApiUrl}/add-message-text`,
      method: "POST",
      headers: {
        // Authorization: localStorage.getItem("token"),
      },
      data: datas,
    })
      .then(function (response) {
        // setData(response.data.data);
        // 
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="waiters">
        <br />
        <b style={{ marginRight: "30px" }}>پیام متنی</b>
      </div>
      <div>
        <label className="label">عنوان</label>

        <input {...register("title", { required: true })} placeholder="عنوان" className="national-code-input" />

        <label className="label">توضیحات</label>
        <textarea {...register("message", { required: true })} className="MessageBox__textarea" placeholder="توضیحات" />
        {loading ? (
          <button className="submit" type="submit">
            <ClipLoader color="#ffffff" loading={true} css={override} size={20} />
          </button>
        ) : (
          <input className="submit" type="submit" value="ثبت" />
        )}
      </div>
    </form>
  );
}

export default MessageTxt;
