import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux/";
import { ApiUrl } from "../../config";
import { CheckBox, CloseOutlined, Done, DoneOutline } from "@material-ui/icons";
import image from "../../assets/img/login/programming.png";
import clientAnimation from "../../assets/clientAnimation.json";
import Lottie from "lottie-react";
import Checkbox from "@material-ui/core/Checkbox";
import useWindowSize from "../../Sizes/UseWindowSize";
import LoginClientMobile from "../../views/Pages/LoginClientMobile";

require("./style.css");

function Index(props) {
  const [nationalCode, setNationalCode] = useState("");
  const [code, setCode] = useState("");
  const [nation, setNation] = useState("notValid");
  const [mob, setMob] = useState("notValid");
  const [step, setStep] = useState(1);
  const [resend, resendCode] = useState(true);
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState([]);
  const [agree, isagree] = useState(false);
  const [min, setMIn] = useState(1);
  const [sec, setSec] = useState(59);
  const [timer, setTimer] = useState(null);
  const windowSize = useWindowSize();
  var second = 59;
  var minute = 1;

  if (localStorage.getItem("data_mobile") != null) localStorage.removeItem("data_mobile");
  // window.location.href = "/client/direction"

  if (localStorage.getItem("data_idClient") != null) localStorage.removeItem("data_idClient");

  if (localStorage.getItem("data_idCall") != null) localStorage.removeItem("data_idCall");
  if (localStorage.getItem("data_nationalcode") != null) localStorage.removeItem("data_nationalcode");
  if (localStorage.getItem("data_timeMenu") != null) localStorage.removeItem("data_timeMenu");
  if (localStorage.getItem("data_timeLogin") != null) localStorage.removeItem("data_timeLogin");

  const startTimer = () => {
    let timer = setInterval(function () {
      if (second > 0) {
        second -= 1;
      } else {
        if (minute != 0) {
          second = 59;
          minute = 0;
        } else {
          resendCode(false);
        }
      }
      setSec(second);
      setMIn(minute);
    }, 1000);
    setTimer(timer);
  };

  const stopTimer = () => {
    clearInterval(timer);
  };

  const checkNationalId = (code) => {
    if (
      !/^\d{10}$/.test(code) ||
      code === "0000000000" ||
      code === "1111111111" ||
      code === "2222222222" ||
      code === "3333333333" ||
      code === "4444444444" ||
      code === "5555555555" ||
      code === "6666666666" ||
      code === "7777777777" ||
      code === "8888888888" ||
      code === "9999999999" ||
      code === "1234567891"
    ) {
      return false;
    }

    var sum = 0,
      chars = code.split("");
    for (let i = 0; i < 9; i++) sum += +chars[i] * (10 - i);
    var lastDigit,
      remainder = sum % 11;

    lastDigit = remainder < 2 ? remainder : 11 - remainder;

    return +chars[9] === lastDigit;
  };
  const checkMobile = (value) => {
    var emailRex = /^(\+98|0)?9\d{9}$/g;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  function direction() {
    if (code != "" && code.length == 4) {
      setMessage("");

      axios({
        url: ApiUrl + "/check-mobile",
        headers: {},
        data: {
          mobile,
          code,
        },
      })
        .then(function (response) {
          props.history.push({
            pathname: "/client/direction",
            state: {
              nationalCode,
              mobile,
            },
          });
        })
        .catch(function (error) {
          setMessage("کد وارد شده صحیح نمی باشد");
        });
    } else setMessage("کد وارد شده صحیح نمی باشد");
  }

  async function saveClient(nationalCode, mobile) {
    let now = moment().format("YYYY-MM-DDTHH:mm:ss.sTZD");
    let data = {
      nationalCode,
      mobile,
      enter_date: now,
    };
    return await axios({
      url: ApiUrl + "/save-client",
      method: "post",
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(function (response) {
        //
        let idClient = response.data.data._id;
        localStorage.setItem("data_timeLogin", now);
        localStorage.setItem("data_idClient", idClient);
        props.history.push({
          pathname: "/client/direction",
          state: {
            nationalCode,
            mobile,
            idClient,
          },
        });
      })
      .catch(function (error) {
        //
      })
      .finally(function () {
        // always executed
        //
      });
  }

  async function verify() {
    if (agree) {
      if (checkNationalId(nationalCode)) setNation("checked");
      else {
        setMessage("لطفا یک کد ملی معتبر وارد کنید");
        setNation("checkFailed");
      }

      if (checkMobile(mobile)) setMob("checked");
      else {
        setMessage("لطفا یک شماره همراه معتبر وارد کنید");
        setMob("checkFailed");
      }
    } else setMessage("شرایط و مقررات را نپذیرفته اید.");
  }

  useEffect(() => {
    if (nation === "checked" && mob === "checked") {
      saveClient(nationalCode, mobile);

      // setStep(2)
      // startTimer()
      // axios({
      //     url: ApiUrl + '/send-mobile',
      //     method: "post",
      //     headers: {},
      //     data: {
      //         mobile
      //     },

      // })
      //     .then(function (response) {

      //     })
      //     .catch(function (error) {

      //     })
    }
  }, [mob, nation]);

  function resendCodeAgain() {
    second = 59;
    minute = 1;
    resendCode(false);
    clearInterval(timer);
    startTimer();
    axios({
      url: ApiUrl + "/send-mobile",
      method: "post",
      headers: {},
      data: {
        mobile,
      },
    })
      .then(function (response) {})
      .catch(function (error) {});
  }

  const checkValidateNation = (value) => {
    setNationalCode(value);
    value.length > 9 ? setNation("valid") : setNation("notValid");
  };
  const checkValidateMobile = (value) => {
    setMobile(value);
    value.length > 10 ? setMob("valid") : setMob("notValid");
  };

  function renderEnter() {
    return (
      <div className={"form-container-client"}>
        <div>
          <h5 className="text">
            شما می‌توانید
            <span> 7روز هفته و 24 ساعته </span>
            به صورت چت تصویری و متن با کارشناسان ما در ارتباط باشید.
          </h5>
        </div>

        <div className={"input-container-client"}>
          <input
            value={nationalCode}
            onChange={(e) => checkValidateNation(e.target.value)}
            type={"number"}
            placeholder={"کد ملی خود را وارد کنید"}
            className={"national-code-input"}
            autoComplete="on"
            maxLength={10}
          />
          {nation === "checkFailed" ? (
            <CloseOutlined style={{ marginLeft: "5px", fill: "red", animation: "validate-animate .5s" }} />
          ) : (
            <DoneOutline
              style={{
                marginLeft: "5px",
                display: nation === "valid" ? "inline-block" : nation === "checked" ? "inline-block" : "none",
                animation: "validate-animate .4s",
                fill: nation === "valid" ? "green" : nation === "checked" && "green",
              }}
            />
          )}
        </div>
        <div className={"input-container-client"}>
          <input
            value={mobile}
            onChange={(e) => checkValidateMobile(e.target.value)}
            type={"number"}
            placeholder={"شماره تلفن همراه خود را وارد کنید"}
            className={"national-code-input"}
            autoComplete="on"
            maxLength={11}
          />
          {mob === "checkFailed" ? (
            <CloseOutlined style={{ marginLeft: "5px", fill: "red", animation: "validate-animate .5s" }} />
          ) : (
            <DoneOutline
              style={{
                marginLeft: "5px",
                display: mob === "valid" ? "inline-block" : mob === "checked" ? "inline-block" : "none",
                animation: "validate-animate .4s",
                fill: mob === "valid" ? "green" : mob === "checked" && "green",
              }}
            />
          )}
        </div>

        <div style={{ alignSelf: "flex-start" }}>
          <label className={"label-condition"}>
            <Checkbox checked={agree} onChange={(e) => isagree(e.target.checked)} />
            <a target={"_blank"} href={"/client/terms"}>
              {" "}
              قوانین و مقررات{" "}
            </a>
            تماس تصویری را میپذیرم
          </label>

          <label className={"error"}>{message}</label>
        </div>

        <button onClick={(e) => verify()} className={"submit-client"}>
          ورود به حامی چت
        </button>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            bottom: "5px",
          }}
        >
          <p style={{ margin: "0 5px", color: "var(--gray)" }}>توسعه داده شده توسط </p>
          <a style={{ textDecoration: "none", color: "var(--gray)" }} href="http://robintel.net">
            رابینتل
          </a>
        </div>
      </div>
    );
  }

  function renderVerifyMobile() {
    return (
      <div>
        <h5 className={"text"}>
          کد ارسال شده برای شماره <span>{mobile}</span> را در قسمت زیر وارد کنید.
        </h5>
        <span onClick={(e) => setStep(1)} className={"editnumber"}>
          ویرایش شماره تماس
        </span>

        <label className="label">کد فعالسازی</label>

        <input
          value={code}
          onChange={(e) => setCode(e.target.value)}
          type={"number"}
          placeholder={"کد فعالسازی ارسال شده را وارد کنید"}
          className={"national-code-input"}
        />

        <div className="resend">
          <div className="time">
            {min}:{sec}
          </div>

          <button
            disabled={resend}
            onClick={(e) => resendCodeAgain()}
            className={resend ? " resendcode disabled " : "resendcode"}
          >
            <u>ارسال مجدد کد</u>
          </button>
        </div>

        <label className={"error"}>{message}</label>

        <input onClick={(e) => direction()} className={"submit"} value={"ورود به حامی چت"} type={"button"} />
      </div>
    );
  }

  return (
    <>
      {windowSize === "lg" || windowSize === "big" || windowSize === "xlg" ? (
        <div dir="rtl" className={"firstpage"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100vh"
            viewBox="0 0 1924.099 1083.9"
            preserveAspectRatio={"none"}
          >
            <path
              className={"svg-path-background"}
              id="Path_1"
              data-name="Path 1"
              d="M0,1080S113.112,748.278,591.662,794.683s234.924-174.018,600.362-449.547S1484.955-2.9,1484.955-2.9H1922.9V1080Z"
              transform="translate(0.699 3.4)"
              fill="#f6881b"
            />
          </svg>
          <div className={"logo-client"} />

          <div className={"animation-client-background"}>
            <Lottie animationData={clientAnimation} />
          </div>

          {step == 1 ? renderEnter() : renderVerifyMobile()}
        </div>
      ) : (
        <LoginClientMobile
          nationalCode={nationalCode}
          setNationalCode={setNationalCode}
          mobile={mobile}
          setMobile={setMobile}
          message={message}
          setMessage={setMessage}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  // UserAction: bindActionCreators({UserAction}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
