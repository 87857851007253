import { css } from "@emotion/react";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import { ApiUrl } from "../../config";
import VideoPreview from "../commen/VideoPreview";

function MessageVideo(props) {
  const [title, setTitle] = useState(false);
  const [src, setSrc] = useState("");
  const [loading, setLoading] = useState(false);
  const [datass, setDatass] = useState({
    title: "",
    video: "",
    phone: localStorage.getItem("data_mobile"),
    nationalcode: localStorage.getItem("data_nationalcode"),
  });
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  // 
  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("file", src);

    setDatass({
      title: data.title,
      video: formData,
      phone: localStorage.getItem("data_mobile"),
      nationalcode: localStorage.getItem("data_nationalcode"),
    });

    setLoading(true);
    axios({
      url: `${ApiUrl}/add-message-video`,
      method: "POST",
      headers: {
        // Authorization: localStorage.getItem("token"),
      },
      data: datass,
    })
      .then(function (response) {
        // setData(response.data.data);
        // 
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;
  return (
    <div>
      <VideoPreview setTitle={setTitle} setSrc={setSrc} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="waiters">
          <br />
          <b style={{ marginRight: "30px" }}>پیام تصویری</b>
        </div>
        <div className="MessageBox__video">
          {/* <Controller
          name="file"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <VideoPreview setTitle={setTitle} setSrc={setSrc} {...field} />
          )}
        /> */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {title && (
              <>
                {" "}
                <label className="label">عنوان</label>
                <input {...register("title", { required: true })} placeholder="عنوان" className="national-code-input" />
              </>
            )}

            {loading ? (
              <button className="submit" type="submit">
                <ClipLoader color="#ffffff" loading={true} css={override} size={20} />
              </button>
            ) : (
              // <input className="submit" type="submit" value="ثبت" />
              <button type="submit">ثبت</button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default MessageVideo;
