// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactTable from "react-table";
import { ApiUrl } from "../../config";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function Users(props) {
  const [data, setData] = useState([]);
  const [loading, isLoading] = useState(false);
  const [rate, setRate] = useState(0);

  const getUsers = () => {
    isLoading(true);
    let id = props.match.params.id;

    axios
      .get(ApiUrl + "/get-polls/" + id, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        isLoading(false);

        setData(response.data.data.user);
        if (response.data.data.user.rates) {
          var rate = response.data.data.user.rates;
          var sum = 0;
          for (var i = 0; i < rate.length; i++) {
            sum += parseInt(rate[i].rate, 10); //don't forget to add the base
          }

          setRate(sum / rate.length);
        }
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };
  const changestatus = (id, status) => {
    isLoading(true);

    axios
      .get(ApiUrl + "/change-menu-item/" + id + "?status=" + status, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        isLoading(false);

        getUsers();
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };

  useEffect(() => {
    getUsers();
  }, loading);

  const classes = useStyles();
  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>گزارش نظر سنجی - حامی چت</title>
      </Helmet>
      <GridContainer>
        <GridItem xs={8}>
          <Card>
            <CardHeader color="info">
              <h5 className={classes.cardIconTitle}>بخش نظرات اپراتور</h5>
            </CardHeader>
            <CardBody>
              <h4>
                <span>اپراتور: </span>
                <span>
                  <b>{data.first_name + " " + data.last_name}</b>
                </span>
              </h4>
              <h4>
                <span>میانگین امتیاز: </span>
                <span>
                  <b>{rate}</b>
                </span>
              </h4>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={8}>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="info" icon>
              <CardIcon color="info">
                <Assignment />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <ReactTable
                rtl
                getTableProps={() => ({ style: { display: "block", textAlign: "right" } })}
                data={data ? data.rates : []}
                loading={loading}
                filterable
                columns={[
                  {
                    Header: "تلفن مشتری",
                    accessor: "customer",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "امتیاز",
                    accessor: "rate",
                    sortable: false,
                    filterable: false,
                  },

                  {
                    Header: "نظر",
                    accessor: "message",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default Users;
