const initialState = {
  time_select_menu: null,
  time_login: null,
  idCall_for_logout: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SAVE_CLIENT_TIME_ENTER_MENU": {
      return {
        ...state,
        time_select_menu: action.payload,
      };
    }
    case "SAVE_CLIENT_TIME_LOGIN": {
      return {
        ...state,
        time_login: action.payload,
      };
    }
    case "SAVE_CLIENT_ID_CALL_FOR_LOGOUT": {
      return {
        ...state,
        idCall_for_logout: action.payload,
      };
    }
    default:
      return state;
  }
}
