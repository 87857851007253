import { combineReducers } from "redux";
import client from "./client";
import customer from "./customer";
import midCalls from "./midCalls";
import reports from "./reports";
import reports_2 from "./reports_2";
import saveClient from "./saveClient";
import textBox from "./textBox";
import user from "./user";

export default combineReducers({
  user,
  customer,
  reports,
  reports_2,
  midCalls,
  client,
  textBox,
  saveClient,
});
