import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { TimePicker } from "antd";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment-jalaali";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { connect } from "react-redux/";
import CreatableSelect from "react-select/creatable";
import ReactTable from "react-table";
import { bindActionCreators } from "redux";
import ReportAction from "../../js/actions/report_action";
import UserAction from "../../js/actions/user_action";
import Spinner from "../../shared/Spinner/Spinner";
import Reset from "./../../assets/img/reset.png";
import "react-table/react-table.css";
import "./style.css";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function Histories(props) {
  
  const { loading, pagination } = props;
  useEffect(() => {
    getOperators();
    props.store.dispatch(ReportAction.GetAllQueues());
    getTransferReports();
  }, loading);
  useEffect(() => {
    let data = props.operators;
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.first_name + " " + item.last_name,
        });
      });
      setDataSelectOperator(options);
    }
    // 
  }, props.operators);
  const getOperators = () => {
    return props.store.dispatch(UserAction.GetOperators());
  };

  const getTransferReports = (pagination = { per_page: 20, page: 1 }) => {
    return props.store.dispatch(ReportAction.GetAllReportDirectCall(pagination));
  };

  const handleSimple = (event) => {
    setSimpleSelect(event.target.value);
  };
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [dataSelectOperator, setDataSelectOperator] = React.useState([]);
  const [operator, setOperator] = React.useState("");

  const [flag, setFlag] = React.useState("ALL");
  // 
  let handleChange = (newValue, actionMeta) => {
    setOperator(newValue);
  };

  ////
  useEffect(() => {
    let data = props.queues;
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.name === "کیپاد" ? "پی پاد" : item.name,
        });
      });
      setDataSelectQueue(options);
    }
  }, [props.queues]);

  const [queue, setQueue] = React.useState("");
  const [dataSelectQueue, setDataSelectQueue] = React.useState([]);
  let handleChangeQueue = (newValue, actionMeta) => {
    setQueue(newValue);
  };
  ////
  const [size, setSize] = React.useState(20);
  const { RangePicker } = TimePicker;
  const [timeStart, settimeStart] = useState("");
  const [timeEnd, settimeEnd] = useState("");
  function onChange(time, timeString) {
    settimeStart(timeString[0]);
    settimeEnd(timeString[1]);
  }
  const postFilter = (pagination = { per_page: 20, page: 1 }) => {
    let arrayOperatorsId = [];
    if (operator && operator.length) {
      operator.forEach((item) => {
        // 
        arrayOperatorsId.push(item.value);
      });
    }
    props.store.dispatch(ReportAction.GetDirectCallReport(arrayOperatorsId, selectedDayRange, timeStart, timeEnd));
  };

  ////

  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });

  const renderCustomInput = ({ ref }) => (
    <>
      <input
        readOnly
        ref={ref} // necessary
        placeholder="زمان گزارش را انتخاب کنید"
        value={
          selectedDayRange.from && selectedDayRange.to
            ? `${selectedDayRange.from.year}` +
              "/" +
              `${selectedDayRange.from.month}` +
              "/" +
              `${selectedDayRange.from.day}` +
              " تا " +
              `${selectedDayRange.to.year}` +
              "/" +
              `${selectedDayRange.to.month}` +
              "/" +
              `${selectedDayRange.to.day}`
            : ""
        }
        style={{
          textAlign: "center",
          padding: "8px",
          fontSize: "1rem",
          border: "1px solid ",
          borderRadius: "5px",
          outline: "none",
          height: 55,
          minHeight: 35,
          width: 480,
        }}
        className="my-custom-input-class" // a styling class
      />
    </>
  );
  /////

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 55,
      border: "1px solid black",
    }),
  };
  //

  const onPageChangeFunction = (pageIndex) => {
    // 
    if (flag === "ALL") {
      getTransferReports({ per_page: size, page: pageIndex + 1 });
    }
    if (flag === "FILTER") {
      postFilter({ per_page: size, page: pageIndex + 1 }, operator);
    }
  };
  const onPageSizeChangeFunction = (pageSize, pageIndex) => {
    // 
    setSize(pageSize);
    if (flag === "ALL") {
      getTransferReports({ per_page: pageSize, page: pageIndex + 1 });
    }
    if (flag === "FILTER") {
      postFilter({ per_page: pageSize, page: pageIndex + 1 }, operator);
    }
  };

  const classes = useStyles();
  if (loading) return <Spinner />;

  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>تماس خروجی - حامی چت</title>
      </Helmet>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
            </CardHeader>
            <Grid container spacing={3}>
              <Grid item xs={6} style={{ marginTop: "0px", marginRight: "24px" }}>
                <Box>
                  <DatePicker
                    value={selectedDayRange}
                    renderInput={renderCustomInput}
                    onChange={setSelectedDayRange}
                    inputPlaceholder="زمان گزارش"
                    shouldHighlightWeekends
                    calendarPopperPosition="bottom"
                    locale="fa"
                  />
                  <a>
                    <img
                      src={Reset}
                      style={{ width: "30px", height: "30px" }}
                      onClick={() => {
                        setSelectedDayRange({
                          from: null,
                          to: null,
                        });
                      }}
                    />
                  </a>
                </Box>
              </Grid>
              <Grid item xs={5} style={{ margin: "24px", marginTop: "0px" }}>
                <RangePicker
                  onChange={onChange}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placeholder={["زمان آغاز", "زمان پایان"]}
                  defaultValue={[moment(timeStart, "HH:mm:ss"), moment(timeEnd, "HH:mm:ss")]}
                  defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                  style={{
                    height: "60px",
                    width: "300px",
                    border: "1px solid black",
                    borderRadius: "4px",
                    cursor: "pointer",
                    fontSize: "17px",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={5}
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  marginRight: "24px",
                }}
              >
                <Box>
                  <CreatableSelect
                    isMulti
                    closeMenuOnSelect={false}
                    isClearable
                    onChange={handleChange}
                    options={dataSelectOperator}
                    styles={customStyles}
                    defaultValue={operator}
                    placeholder="اپراتور مورد نظر را انتخاب کنید"
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={6}
                style={{
                  margin: "24px",
                  marginBottom: "24px",
                  marginTop: "0px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  name="buttonname"
                  style={{ height: 55 }}
                  onClick={() => {
                    // 
                    setFlag("FILTER");
                    postFilter({ per_page: 20, page: 1 });
                  }}
                >
                  اعمال
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  name="buttonname"
                  style={{ height: 55 }}
                  onClick={() => {
                    // 
                    setFlag("ALL");
                  }}
                >
                  دریافت همه گزارشات
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  name="buttonname"
                  style={{ height: 55 }}
                  onClick={() => {}}
                >
                  دریافت فایل
                </Button>
              </Grid>
            </Grid>
            <CardBody>
              <ReactTable
                rtl
                style={{ height: "500px" }}
                data={props.historyCall ? props.historyCall : []}
                loading={props.loading}
                pagination={true}
                filterable
                manual
                columns={[
                  {
                    Header: "اپراتور ",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div>
                        {row.original.operator
                          ? row.original.operator.first_name + " " + row.original.operator.last_name
                          : ""}
                      </div>
                    ),
                  },
                  {
                    Header: "شماره تلفن",
                    accessor: "mobile",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "کدملی",
                    accessor: "user_national_code",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "تاریخ",
                    accessor: "start",
                    sortable: false,
                    filterable: false,
                    Cell: (row) =>
                      row.original.start ? (
                        <div>{moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD HH:mm:ss")}</div>
                      ) : null,
                  },
                ]}
                pageSize={props.pagination && props.pagination.size}
                page={props.pagination && props.pagination.page}
                pages={props.pagination && props.pagination.total}
                onPageChange={(pageIndex) => {
                  // 
                  onPageChangeFunction(pageIndex);
                }}
                onPageSizeChange={(pageSize, pageIndex) => {
                  // 
                  onPageSizeChangeFunction(pageSize, pageIndex);
                }}
                onFilteredChange={(column, value) => {
                  // 
                }}
                className="-striped -highlight"
                showPaginationTop={false}
                showPaginationBottom={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = (state) => {
  // 
  
  return {
    historyCall: state.reports.inputHistory,
    loading: state.reports.loading,
    error: state.reports.error,
    operators: state.reports.operators,
    // queues: state.reports_2.queues,
    // pagination: state.reports.pagination,
  };
};

const mapDispatchToProps = (dispatch) => {
  // 
  return {
    UserAction: bindActionCreators({ UserAction }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Histories);
