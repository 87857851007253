import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
// import $ from "jquery"
import { ApiUrl } from "../../config";
import { textState1 } from "./StateRecoil";

require("./style.css");
const useStyles = makeStyles(styles);
function OutofTime(props) {
  const [state, setState] = useRecoilState(textState1);
  const [flagTextBox, setFlagTextBox] = useState(2);
  const [nationalCode, setNationalCode] = useState(localStorage.getItem("data_nationalcode"));
  const [menu, setMenu] = useState(localStorage.getItem("menu"));
  const [name, setName] = useState(localStorage.getItem("name"));
  const [mobile, setMobile] = useState(localStorage.getItem("data_mobile"));
  const [idClient, setIdClient] = useState(localStorage.getItem("idClient"));
  const [fullname, setfullname] = useState("");
  const [message, setMessage] = useState("");
  const [alertSend, setAlertSend] = useState(null);
  const [out, setOut] = useState(false);
  const [box, setBox] = useState(false);

  const classes = useStyles();
  const mailBox = () => {
    props.history.push({
      pathname: "/client/box",
      state: {
        nationalCode,
        menu,
        name,
        mobile,
        idClient,
      },
    });
  };
  const cancel = () => {
    window.location.href = "/client/direction";
  };
  const sendToTextBox = function (e) {
    e.preventDefault();
    // let file = new FormData();
    // file.append("file", files.files[0]);
    if (localStorage.getItem("data_nationalcode")) {
      axios({
        url: ApiUrl + "/text-box",
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
        data: {
          fullname,
          message,
          menu,
          nationalCode,
          mobile,
          idClient,
          // file,
          date: moment().format("YYYY-MM-DDTHH:mm:ss.sTZD"),
          date_time: moment().format("HH:mm:ss.s"),
        },
      })
        .then(function (response) {
          // 
          setAlertSend(true);
          setFlagTextBox(3);
          setOut(false);
          setBox(false);
        })
        .catch(function (error) {
          // setAlertSend("ناموفق")
          // 
        })
        .finally(function () {});
    } else {
      window.location.href = "/";
    }
  };
  return (
    <div dir="rtl">
      {flagTextBox === 1 ? (
        <div style={{ marginBottom: 50 }} className={"video-container"}>
          <div className={"welcome-input"}>
            <div className="header">
              <div className="logo"></div>
            </div>
            <form className={classes.root} noValidate autoComplete="off">
              <label className="label">نام و نام خانوادگی</label>
              <input
                value={fullname}
                onChange={(e) => setfullname(e.target.value)}
                placeholder={" نام خود را وارد کنید"}
                className={"national-code-input"}
              />
              <label className="label">متن پیام</label>
              <input
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={"پیام خود را وارد کنید"}
                className={"national-code-input"}
              />
              <br />
              <hr />

              <input onClick={(e) => sendToTextBox(e)} className={"cancel"} value={"ارسال"} type={"submit"} />
              <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
            </form>
          </div>
        </div>
      ) : flagTextBox === 2 ? (
        <div style={{ marginBottom: 50 }} className={"video-container"}>
          <div className={"welcome-input"}>
            <div className="header">
              <div className="logo"></div>
            </div>
            {/* {video.outtime != "" && (
         <div className="video-waitning">
           <video
             src={Url + video.outtime}
             controls
             playsInline={true}
             loop
             autoPlay={true}
           />
         </div>
       )} */}
            <h2>با تشکر از تماس شما</h2>
            <hr />
            <h4> همکاران ما مشغول هستند لطفا پیام خود را بگذارید تا در اسرع وقت با شما تماس بگیریم</h4>
            <input
              onClick={(e) => {
                setFlagTextBox(1);
              }}
              className={"cancel"}
              style={{ width: "200px" }}
              value={"ایجاد پیام در صندوق متنی"}
              type={"button"}
            />
            <br />{" "}
            <input
              onClick={(e) => {
                mailBox();
              }}
              className={"cancel"}
              style={{ width: "200px" }}
              value={"ایجاد پیام در صندوق تصویری"}
              type={"button"}
            />
            <br /> <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
          </div>
        </div>
      ) : (
        <div style={{ marginBottom: 50 }} className={"video-container"}>
          <div className={"welcome-input"}>
            <div className="header">
              <div className="logo"></div>
            </div>
            <h2>با تشکر از تماس شما</h2>
            <h2>تماس شما در صندوق متنی ذخیره شده به زودی با شما تماس خواهیم گرفت</h2>
            <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
          </div>
        </div>
      )}
    </div>
  );
}

export default OutofTime;
