import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Hidden from "@material-ui/core/Hidden";
import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "./GeneralInfo.module.scss";
import { infoColor } from "../../assets/jss/material-dashboard-pro-react";

const GeneralInfo = (props) => {
  const [tick, setTick] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);

  // const [time , setTime]=useState(null)
  const [day, setDay] = useState(null);
  const [dateShamsi, setDateShamsi] = useState(null);
  const [dateMiladi, setDateMiladi] = useState(null);
  const [dateGhamari, setDateGhamari] = useState(null);
  const [dateEvent, setDateEvent] = useState(null);
  // const [dateEvent , setDateEvent] = useState(null);

  useEffect(() => {
    axios.get("https://api.keybit.ir/time/").then((response) => {
      setDay(response.data.date.weekday.name);
      setDateShamsi(response.data.date.full.official.usual.fa);
      setDateMiladi(response.data.date.other.gregorian.usual.fa);
      setDateGhamari(response.data.date.other.ghamari.usual.fa);
      // setDateEvent(response.data.date.day.events.local.text);
    });

    let tick = setInterval(() => {
      let time = new Date();
      setSeconds(time.getSeconds());
      setMinutes(time.getMinutes());
      setHours(time.getHours());

      if (tick < 60) {
        setTick((prev) => prev + 1);
      }
      if (tick < 60) {
        setTick(0);
      }
    }, 1000);
    return () => {
      clearInterval(tick);
    };
  }, []);

  useEffect(() => {}, []);

  try {
    return (
      <Card className="GeneralInfoContainer">
        {/* minWidth: "275px", */}
        <CardContent style={{ display: "flex", flexDirection: "column" }}>
          <div className={styles.today}>
            <div className="day">
              <Hidden only={["lg", "xl", "xs"]}>
                <h3 style={{ fontSize: 15, color: infoColor[0] }} color="secondary">
                  {day}
                </h3>
              </Hidden>

              <Hidden only={["sm", "md"]}>
                <h3 style={{ fontSize: 20, color: infoColor[0] }} color="secondary">
                  {day}
                </h3>
              </Hidden>
            </div>

            <div className="time">
              <Hidden only={["lg", "xl", "xs"]}>
                <h3 style={{ fontSize: 15, color: infoColor[0] }} color="secondary">
                  {hours}:{minutes}:{seconds}
                </h3>
              </Hidden>

              <Hidden only={["sm", "md"]}>
                <h3 style={{ fontSize: 20, color: infoColor[0] }} color="secondary">
                  {hours}:{minutes}:{seconds}
                </h3>
              </Hidden>
            </div>
          </div>

          <div className={styles.dateColumn}>
            <div className={styles.dateRow}>
              <Hidden only={["lg", "xl", "xs"]}>
                <h4 style={{ fontSize: 10 }} color="textSecondary">
                  تاریخ شمسی
                </h4>
              </Hidden>

              <Hidden only={["sm", "md"]}>
                <h4 style={{ fontSize: 12 }} color="textSecondary">
                  تاریخ شمسی
                </h4>
              </Hidden>

              <Hidden only={["lg", "xl", "xs"]}>
                <h4 style={{ fontSize: 10 }} color="textSecondary">
                  {dateShamsi}
                </h4>
              </Hidden>

              <Hidden only={["sm", "md"]}>
                <h4 style={{ fontSize: 12 }} color="textSecondary">
                  {dateShamsi}
                </h4>
              </Hidden>
            </div>

            <div className={styles.dateRow}>
              <Hidden only={["lg", "xl", "xs"]}>
                <h4 style={{ fontSize: 10 }} color="textSecondary">
                  تاریخ میلادی
                </h4>
              </Hidden>

              <Hidden only={["sm", "md"]}>
                <h4 style={{ fontSize: 12 }} color="textSecondary">
                  تاریخ میلادی
                </h4>
              </Hidden>

              <Hidden only={["lg", "xl", "xs"]}>
                <h4 style={{ fontSize: 10 }} color="textSecondary">
                  {dateMiladi}
                </h4>
              </Hidden>

              <Hidden only={["sm", "md"]}>
                <h4 style={{ fontSize: 12 }} color="textSecondary">
                  {dateMiladi}
                </h4>
              </Hidden>
            </div>

            <div className={styles.dateRow}>
              <Hidden only={["lg", "xl", "xs"]}>
                <h4 style={{ fontSize: 10 }} color="textSecondary">
                  تاریخ هجری
                </h4>
              </Hidden>

              <Hidden only={["sm", "md"]}>
                <h4 style={{ fontSize: 12 }} color="textSecondary">
                  تاریخ هجری
                </h4>
              </Hidden>

              <Hidden only={["lg", "xl", "xs"]}>
                <h4 style={{ fontSize: 10 }} color="textSecondary">
                  {dateGhamari}
                </h4>
              </Hidden>

              <Hidden only={["sm", "md"]}>
                <h4 style={{ fontSize: 12 }} color="textSecondary">
                  {dateGhamari}
                </h4>
              </Hidden>
            </div>

            {/* {dateEvent != null ? (
                  <div className={styles.dateRow}>
                    <Typography style={{ fontSize: 12 }} color="textSecondary">
                      مناسبت
                    </Typography>
                    <Typography style={{ fontSize: 12 }} color="textSecondary">
                      {dateEvent}
                    </Typography>
                  </div>
                ) : null} */}
          </div>
        </CardContent>
      </Card>
    );
  } catch (error) {
    return (
      <div
        style={{
          height: "330px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "10px",
          color: "rgb(157, 157, 157)",
        }}
      >
        <p>با عرض پوزش، خطایی از سمت سرور رخ داده است</p>
      </div>
    );
  }
};

export default GeneralInfo;
