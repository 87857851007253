import React, { useState } from "react";
import DefaultBox from "../views/commen/DefaultBox";
import MakeTicket from "../views/Ticket/MakeTicket";
import TicketContextProvider from "../views/Ticket/TicketContext";
import UpdateTicket from "../views/Ticket/UpdateTicket";
import UserTicketList from "../views/Ticket/UserTicketList";

function Ticket({ menu, nationalCode, mobile }) {
  const [ticket, setTicket] = useState(0);
  return (
    <div className="Ticket">
      {ticket === 0 && (
        <DefaultBox
          className="Ticket__DefaultBox"
          title="تیکت های پشتیبانی"
          subbody={Ticketarray}
          main={true}
          setTicket={setTicket}
        >
          <p>تیکت</p>
        </DefaultBox>
      )}
      {ticket === 1 && <MakeTicket setTicket={setTicket} personalid={nationalCode} phone={mobile} />}
      <TicketContextProvider>
        {ticket === 2 && <UserTicketList setTicket={setTicket} personalid={nationalCode} phone={mobile} />}
        {ticket === 3 && (
          <UpdateTicket setTicket={setTicket} update={false} userid={menu} personalid={nationalCode} phone={mobile} />
        )}
        {ticket === 4 && (
          <UpdateTicket setTicket={setTicket} update={true} userid={menu} personalid={nationalCode} phone={mobile} />
        )}
      </TicketContextProvider>
    </div>
  );
}

export default Ticket;

const Ticketarray = [
  {
    sub: "ایجاد تیکت ",
    id: 1,
  },
  {
    sub: "لیست  تیکت ها",
    id: 2,
  },
];
