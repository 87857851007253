import React, { useState } from "react";
import "../../scss/main.scss";
import MessageTxt from "./MessageTxt";
import MessageVideo from "./MessageVideo";

function MessageBox() {
  const [page, setPage] = useState(0);
  const handleExit = () => {
    window.location.href = "/client/direction";
  };
  return (
    <div dir="rtl" className="MessageBox directionpage">
      <div className="welcome-container">
        <div className="welcome-input">
          <div className="header">
            <div className="logo"></div>
            {page === 1 || page === 2 ? (
              <button onClick={() => setPage(0)}>بازگشت</button>
            ) : (
              <button onClick={(e) => handleExit()}>خروج</button>
            )}
          </div>
          {page === 0 && (
            <>
              {" "}
              <div className="waiters">
                <br />
                <b style={{ marginRight: "30px" }}>صندوق</b>
              </div>
              <h5 className="text">تمایل به ارسال پیام در کدام یک از فرم های زیر را دارید؟</h5>
              <div className="selector" onClick={() => setPage(1)}>
                <div className="chatmessage"></div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h4>پیام متنی</h4>
                </div>
              </div>
              <div className="selector" onClick={() => setPage(2)}>
                <div className="videocall"></div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h4>پیام تصویری</h4>
                </div>
              </div>{" "}
            </>
          )}
          {page === 1 && (
            <>
              <MessageTxt page={page} />{" "}
            </>
          )}
          {page === 2 && (
            <>
              <MessageVideo page={page} />{" "}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default MessageBox;
