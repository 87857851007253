import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import storeIcon from "../../assets/img/icons/storeIcon.svg";
import { Typography } from "@mui/material";

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "12px",
    padding: "16px 8px",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.15)",
    minWidth: "80px",
    flex: "1 0 0",
  },
});

function MobileQueueCard({ title, icon = storeIcon, onClick }) {
  const classes = useStyles();
  return (
    <div className={classes.container} onClick={onClick}>
      <img src={icon} width={"32px"} height={"32px"} />
      <Typography textAlign={"center"} fontSize={"12px"}>
        {title}
      </Typography>
    </div>
  );
}

export default MobileQueueCard;
