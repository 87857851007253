import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux/";
import { ApiUrl } from "../../config";

require("./style.css");

function Index(props) {
  const [nationalCode, setNationalCode] = useState([]);
  const [code, setCode] = useState("");
  const [step, setStep] = useState(1);
  const [resend, resendCode] = useState(true);
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState([]);
  const [agree, isagree] = useState(false);
  const [min, setMIn] = useState(1);
  const [sec, setSec] = useState(59);
  const [timer, setTimer] = useState(null);

  var second = 59;
  var minute = 1;

  if (localStorage.getItem("data_mobile") != null) window.location.href = "/client-bot/direction-bot";

  const startTimer = () => {
    let timer = setInterval(function () {
      if (second > 0) {
        second -= 1;
      } else {
        if (minute != 0) {
          second = 59;
          minute = 0;
        } else {
          resendCode(false);
        }
      }
      setSec(second);
      setMIn(minute);
    }, 1000);
    setTimer(timer);
  };

  const stopTimer = () => {
    clearInterval(timer);
  };

  const checkNationalId = (code) => {
    if (
      !/^\d{10}$/.test(code) ||
      code === "0000000000" ||
      code === "1111111111" ||
      code === "2222222222" ||
      code === "3333333333" ||
      code === "4444444444" ||
      code === "5555555555" ||
      code === "6666666666" ||
      code === "7777777777" ||
      code === "8888888888" ||
      code === "9999999999"
    ) {
      return false;
    }

    var sum = 0,
      chars = code.split("");
    for (let i = 0; i < 9; i++) sum += +chars[i] * (10 - i);
    var lastDigit,
      remainder = sum % 11;

    lastDigit = remainder < 2 ? remainder : 11 - remainder;

    return +chars[9] === lastDigit;
  };
  const checkMobile = (value) => {
    var emailRex = /^(\+98|0)?9\d{9}$/g;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  function direction() {
    if (code != "" && code.length == 4) {
      setMessage("");

      axios({
        url: ApiUrl + "/check-mobile",
        headers: {},
        data: {
          mobile,
          code,
        },
      })
        .then(function (response) {
          props.history.push({
            pathname: "/client-bot/direction-bot",
            state: {
              nationalCode,
              mobile,
            },
          });
        })
        .catch(function (error) {
          setMessage("کد وارد شده صحیح نمی باشد");
        });
    } else setMessage("کد وارد شده صحیح نمی باشد");
  }

  async function saveClient(nationalCode, mobile) {
    let data = {
      nationalCode,
      mobile,
      enter_date: moment().format("YYYY-MM-DDTHH:mm:ss.sTZD"),
    };
    return await axios({
      url: ApiUrl + "/save-client",
      method: "post",
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        // 
        let idClient = response.data.data._id;
        props.history.push({
          pathname: "/client-bot/direction-bot",
          state: {
            nationalCode,
            mobile,
            idClient,
          },
        });
      })
      .catch(function (error) {
        // 
      })
      .finally(function () {
        // always executed
        // 
      });
  }

  async function verify() {
    if (agree) {
      let nation = false,
        mob = false;

      if (checkNationalId(nationalCode)) nation = true;
      else setMessage("لطفا یک کد ملی معتبر وارد کنید");

      if (checkMobile(mobile)) mob = true;
      else setMessage("لطفا یک شماره همراه معتبر وارد کنید");

      if (nation && mob) {
        saveClient(nationalCode, mobile);
        setMessage("");

        // setStep(2)
        // startTimer()
        // axios({
        //     url: ApiUrl + '/send-mobile',
        //     method: "post",
        //     headers: {},
        //     data: {
        //         mobile
        //     },

        // })
        //     .then(function (response) {

        //     })
        //     .catch(function (error) {

        //     })
      }
    } else setMessage("شرایط و مقررات را نپذیرفته اید.");
  }

  function resendCodeAgain() {
    second = 59;
    minute = 1;
    resendCode(false);
    clearInterval(timer);
    startTimer();
    axios({
      url: ApiUrl + "/send-mobile",
      method: "post",
      headers: {},
      data: {
        mobile,
      },
    })
      .then(function (response) {})
      .catch(function (error) {});
  }

  function renderEnter() {
    return (
      <div>
        <div>
          <h5 className="text">
            شما می‌توانید
            <span> 7روز هفته و 24 ساعته </span>
            به صورت چت تصویری و متن با کارشناسان ما در ارتباط باشید.
          </h5>
        </div>
        <label className="label" style={{ marginRight: "30px" }}>
          کد‌ملی
        </label>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <input
            value={nationalCode}
            onChange={(e) => setNationalCode(e.target.value)}
            type={"number"}
            placeholder={"کد ملی خود را وارد کنید"}
            className={"national-code-input"}
          />
        </div>
        <label className="label" style={{ marginRight: "30px" }}>
          شماره تلفن ‌همراه
        </label>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <input
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            type={"number"}
            placeholder={"شماره تلفن همراه خود را وارد کنید"}
            className={"national-code-input"}
          />
        </div>

        <label className={"label-condition"}>
          <input type={"checkbox"} value={agree} onChange={(e) => isagree(e.target.value)} />
          <a target={"_blank"} href={"/client/terms"}>
            {" "}
            قوانین و مقررات{" "}
          </a>
          تماس تصویری را مطالعه کردم و با آن موافق هستم.
        </label>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <label className={"error"}>{message}</label>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <input onClick={(e) => verify()} className={"submitBot"} value={"ورود به حامی چت"} type={"button"} />
        </div>
      </div>
    );
  }

  function renderVerifyMobile() {
    return (
      <div>
        <h5 className={"text"}>
          کد ارسال شده برای شماره <span>{mobile}</span> را در قسمت زیر وارد کنید.
        </h5>
        <span onClick={(e) => setStep(1)} className={"editnumber"}>
          ویرایش شماره تماس
        </span>

        <label className="label">کد فعالسازی</label>

        <input
          value={code}
          onChange={(e) => setCode(e.target.value)}
          type={"number"}
          placeholder={"کد فعالسازی ارسال شده را وارد کنید"}
          className={"national-code-input"}
        />

        <div className="resend">
          <div className="time">
            {min}:{sec}
          </div>

          <button
            disabled={resend}
            onClick={(e) => resendCodeAgain()}
            className={resend ? " resendcode disabled " : "resendcode"}
          >
            <u>ارسال مجدد کد</u>
          </button>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <label className={"error"}>{message}</label>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <input onClick={(e) => direction()} className={"submitBot"} value={"ورود به حامی چت"} type={"button"} />
        </div>
      </div>
    );
  }

  return (
    <div dir="rtl">
      <div>
        <div>
          <div className={"header"}>
            <div className={"logo"}></div>
          </div>

          {step == 1 ? renderEnter() : renderVerifyMobile()}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  // UserAction: bindActionCreators({UserAction}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
