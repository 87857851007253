import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useWindowSize from "../../Sizes/UseWindowSize";
import { Box, Button, Typography } from "@mui/material";
import { Close } from "@material-ui/icons";
import logo from "../../assets/img/login/hami2.png";
import TextField from "@material-ui/core/TextField";
import MobileLoginInput from "../../components/MobileLoginInput/MobileLoginInput";
import MobileOtpInput from "../../components/MobileOtpInput/MobileOtpInput";
import MobileQueue from "../../components/MobileQueue/MobileQueue";

const useStyles = makeStyles({
  container: {
    padding: "138px 23px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "var(--mbGray)",
    height: "100dvh",
  },
});

function LoginClientMobile({ nationalCode, setNationalCode, mobile, setMobile, message, setMessage }) {
  const [step, setStep] = useState(1);

  const classes = useStyles();
  return (
    <div className={classes.container}>
      {step === 1 && (
        <MobileLoginInput
          setStep={setStep}
          nationalCode={nationalCode}
          setNationalCode={setNationalCode}
          mobile={mobile}
          setMobile={setMobile}
          message={message}
          setMessage={setMessage}
        />
      )}
      {step === 2 && <MobileOtpInput />}
    </div>
  );
}

export default LoginClientMobile;
