import React from "react";
import Menu from "./Menu";
import OperatorHeader from "./OperatorHeader";
import VideoBox from "./VideoBox";

function Operator({
  isFree,
  isChat,
  endCall,
  holdCall,
  ishold,
  user,
  isShare,
  shareScreen,
  roomId,
  stream,
  stopDisplay,
}) {
  return (
    <div className="Operator">
      {!user && <OperatorHeader endCall={endCall} />}
      <VideoBox isChat={isChat} isFree={isFree} user={user} ishold={ishold} isShare={isShare} />
      {!user && (
        <Menu
          endCall={endCall}
          holdCall={holdCall}
          ishold={ishold}
          isShare={isShare}
          shareScreen={shareScreen}
          roomId={roomId}
          stream={stream}
          stopDisplay={stopDisplay}
        />
      )}
    </div>
  );
}
export default Operator;
