import FormLabel from "@material-ui/core/FormLabel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux/";
import { bindActionCreators } from "redux";
import { ApiUrl, Url } from "../../config";
import UserAction from "../../js/actions/user_action";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
  },
};

const useStyles = makeStyles(styles);

function Users(props) {
  const [data, setData] = useState({
    email: "",
    username: "",
    type: "operator",
    first_name: "",
    last_name: "",
    national_code: "",
    mobile: "",
  });
  const [menus, setMenus] = useState([]);
  const getUsers = () => {
    axios
      .get(ApiUrl + "/menu-items", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        setMenus(response.data.data.menu_items);
      })
      .catch(function (error) {})
      .finally(function () {});
  };
  useEffect(() => {
    getUsers();
  }, []);

  function onSubmit(e) {
    e.preventDefault();

    props.store.dispatch(UserAction.CreateNewUser(data));
    setData({
      email: "",
      username: "",
      menu: [],
      first_name: "",
      last_name: "",
      national_code: "",
      mobile: "",
      roles: [],
    });
  }

  function handle_change(e) {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  }

  function handle_change_menu(e) {
    var options = e.target.options;
    var value = [];
    setData({
      ...data,
      menu: [],
    });
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setData({
      ...data,
      menu: value,
    });
  }

  const classes = useStyles();

  if (props.loading) return <div>در حال ارسال...</div>;
  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>کاربران - حامی چت</title>
      </Helmet>
      <form onSubmit={(e) => onSubmit(e)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>افزودن کاربر جدید</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={4}>
                      <FormLabel className={classes.labelHorizontal}>نام کاربری پارس لاجیک</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "username",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.username,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>ایمیل</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "email",
                          name: "email",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.email,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>تلفن همراه</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "number",
                          name: "mobile",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.mobile,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>نام</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "first_name",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.first_name,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>نام خانوادگی</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "last_name",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.last_name,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>کد ملی</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "number",
                          name: "national_code",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.national_code,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>نوع کاربر</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <select
                        onChange={(e) => {
                          handle_change(e);
                        }}
                        name="type"
                        className={"menu"}
                      >
                        <option value="operator">اپراتور</option>
                        <option value="admin">مدیر</option>
                      </select>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>سطح دسترسی</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <select
                        onChange={(e) => {
                          handle_change(e);
                        }}
                        name="roles"
                        className={"menu"}
                      >
                        <option value="level1">سطح 1</option>
                        <option value="level2">سطح 2</option>
                        <option value="level3">سطح 3</option>
                      </select>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12}>
                      <FormLabel className={classes.labelHorizontal}>بخش های مربوطه</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                      <select
                        onChange={(e) => {
                          handle_change_menu(e);
                        }}
                        name="menu"
                        className={"menus"}
                        multiple
                      >
                        {menus.map((e, i) => {
                          return (
                            <option style={{ background: `url(${Url + e.icon}) no-repeat` }} value={e._id} key={i}>
                              {e.name}
                            </option>
                          );
                        })}
                      </select>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button type={"submit"} color="rose">
                  ذخیره
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.user.data,
  loading: state.user.loading,
  error: state.user.error,
});

const mapDispatchToProps = (dispatch) => ({
  UserAction: bindActionCreators({ UserAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
