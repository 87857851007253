import { css } from "@emotion/react";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import { useRecoilState } from "recoil";
import { API_URL } from "../../contacs";
import { textState } from "../../layouts/Recoil";
import { PrimaryBtn } from "../commen/Btn";
import DefaultBox from "../commen/DefaultBox";

function UpdateTicket({ setTicket, update, personalid, phone }) {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;
  const [info, setInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [userid, setUserid] = useRecoilState(textState);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setLoading(true);
    setInfo({
      n_id: localStorage.getItem("data_nationalcode"),
      phone_number: localStorage.getItem("data_mobile"),
      verify_code: "",
    });
    handleUpdate();
  };
  const handleUpdate = () => {
    //------------------------------------
    axios({
      url: `${API_URL}/v1/ticket-hamichat/customer/${userid}`,
      method: "POST",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: info,
    })
      .then(function (response) {
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };
  return (
    <div className="Ticket">
      <DefaultBox
        className="Ticket__DefaultBox"
        title={update ? "ویرایش تیکت" : "پاسخ تیکت"}
        main={false}
        setTicket={setTicket}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="LoginDashboard__form">
            {!update && (
              <div className="MakeTicket__inputBox">
                <label>وضعیت</label>
                <select defaultValue="NEW" {...register("status")}>
                  <option value="NEW">جدید</option>
                  <option value="OPEN">باز</option>
                  <option value="CLOSE">بسته</option>
                </select>
              </div>
            )}

            <div className="MakeTicket__inputBox UpdateTicket__textarea">
              <label>متن</label>
              <textarea
                {...register("text", { required: false })}
                className="MakeTicket__textarea"
                placeholder=" توضیحات را وارد نمایید"
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => (e.target.placeholder = " توضیحات را وارد نمایید")}
              />
            </div>
          </div>
          <div className="LoginDashboard__BtnBox d-flex">
            <PrimaryBtn className="MakeTicket__Btn">
              {loading ? <ClipLoader color="#ffffff" loading={loading} css={override} size={15} /> : "پاسخ"}
            </PrimaryBtn>
          </div>
        </form>
      </DefaultBox>
    </div>
  );
}

export default UpdateTicket;
