import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import { AudioVisualizer, LiveAudioVisualizer } from "react-audio-visualize";
import mic from "../../assets/img/icons/mic.svg";
import { Box } from "@mui/material";
import { Close, Pause, PlayArrow, PlayArrowOutlined, Send, Stop, StopOutlined } from "@material-ui/icons";
import send from "../../assets/img/icons/send.svg";

const useStyles = makeStyles({
  voice: {
    backgroundColor: "var(--white)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    position: "absolute",
    left: "0",
    right: "0",
    bottom: "0",
    padding: "8px",
    width: "100%",
    zIndex: 9999,
  },
  recorded: {
    backgroundColor: "var(--white)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px",
    zIndex: 9999,
  },
});

function RecordVoice({ setFlagSend, sendMessage, setUploadEvent, uploadEvent }) {
  const classes = useStyles();
  const [permission, setPermission] = useState(false);
  const [stream, setStream] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audio, setAudio] = useState(null);
  const [playing, setPlaying] = useState(false);
  const mediaRecorder = useRef(null);
  const recordedRef = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [blob, setBlob] = useState(null);
  const mimeType = "audio/webm";
  const audioRef = useRef(null);
  
  const getMicPermission = async () => {
    if ("MediaRecorder" in window) {
        try {
          const streamData = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
          });
          setPermission(true);
          setStream(streamData);
        } catch (err) {
          alert(err.message);
        }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  useEffect(()=>{
    if (permission){
      startRecording()
    }
  },[stream])

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => {
        setPlaying(false);
      };
    }
  }, [audioRef.current]);
  const micHandler = async () => {
    getMicPermission()
  };
  const startRecording = async () => {
    setRecordingStatus("recording");
    const media = new MediaRecorder(stream, { type: mimeType });
    mediaRecorder.current = media;
    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };

    setAudioChunks(localAudioChunks);
  };
  const stopRecording = () => {
    setRecordingStatus("inactive");
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      const audioUrl = URL.createObjectURL(audioBlob);
      let file = new File([audioBlob], Date.now() + "voice.webm", { type: "audio/webm" });
      setFlagSend(false);
      setUploadEvent(file);
      setBlob(audioBlob);
      setAudio(audioUrl);
      setAudioChunks([]);
    };
  };

  const sendVoice = () => {
    sendMessage();
    setAudio(null);
  };
  return (
    <div>
      <img src={mic} alt={"icon"} width={"24px"} height={"24px"} onClick={(e) => micHandler()} />
      {recordingStatus === "recording" && (
        <Box className={classes.voice}>
          <Stop
            onClick={(e) => {
              setAudio(null);
              stopRecording();
            }}
          />

              <LiveAudioVisualizer
                  mediaRecorder={mediaRecorder.current}
                  width={200}
                  height={50}
                  barColor={"var(--mbBlue)"}
                  gap={5}
              />

        </Box>
      )}
      {audio && (
        <Box className={classes.voice}>
          <Close onClick={(e) => {
            setAudio(null)
            setPlaying(false)
            setFlagSend(true)
            setUploadEvent("")
          }} />
          <Box className={classes.recorded}>
            <AudioVisualizer blob={blob} width={200} height={50} barColor={"var(--mbBlue)"} />
            {!playing ? (
              <PlayArrow
                onClick={(e) => {
                  audioRef.current.play();
                  setPlaying(true);
                }}
              />
            ) : (
              <Pause
                onClick={(e) => {
                  audioRef.current.pause();
                  setPlaying(false);
                }}
              />
            )}
            <audio src={audio} ref={audioRef} controls hidden />
          </Box>

          <img src={send} onClick={sendVoice} style={{ transform: "rotate(240deg)" }} />
        </Box>
      )}
    </div>
  );
}

export default RecordVoice;
