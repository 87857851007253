import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import $ from "jquery";
import { Device } from "mediasoup-client";
import moment from "moment-jalaali";
import React, { Fragment, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import connect from "react-redux/es/connect/connect";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import io from "socket.io-client";
import rin from "../../assets/ring.mp3";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { ApiUrl, sendRequest, Url } from "../../config";
import CustomerAction from "../../js/actions/customer_action";
import imgMess from "../Client/files/call_message.gif";
import Off from "./files/off.png";
import on from "./files/on.png";
import standby from "./files/standby.png";
require("./style.css");

const useStyles = makeStyles(styles);
let local_media_stream = null;

function Dashboard(props) {
  const classes = useStyles();
  const [blobUrl, setBlobUrl] = useState(null);
  const [audio, setAudio] = useState(new Audio(rin));
  const [playing, setPlaying] = useState(false);

  //Get audio from server and play for ringtone
  const ringtoneHandler = async () => {
    try {
      const url = Url + localStorage.getItem("ringtone").replaceAll(" ", "%20");
      const res = await axios.get(url, { responseType: "arraybuffer", headers: { "Content-Type": "audio/mp3" } });
      const blob = new Blob([res.data], { type: "audio/mp3" });
      const blobUrl = window.URL.createObjectURL(blob);
      setBlobUrl(blobUrl);
      setAudio(new Audio(blobUrl));
    } catch (e) {
      toast.error(e.message);
    }
  };
  useEffect(() => {
    ringtoneHandler();
  }, []);

  useEffect(() => {
    audio.loop = true;
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  const [isFree, setIsFree] = useState(false);
  const [isChat, setIsChat] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [status, setStatus] = useState("off");
  const [socketIo, setSocket] = useState(null);
  const [userNationalCode, setUserNationalCode] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [isEnded, setEnded] = useState(false);
  const [id, setId] = useState(0);
  const [alerts, setAlert] = React.useState(null);
  const [tl, setTL] = React.useState(false);
  const [streamLocal, setStream] = React.useState(null);
  const [is_hold, setIsHold] = React.useState(false);
  const [roomId, setRoomId] = React.useState(false);

  const ICE_SERVERS = [
    {
      urls: "turn:185.60.32.129:3478",
      credential: "HmCht_3432",
      username: "hamichat",
    },
  ];
  let local_screen_stream = null;
  let peerConnection = null;
  let sharePeerConnection = null;

  let device = null;
  let deviceScreen = null;

  useEffect(() => {
    connection();
  }, [isFree]);

  let producerTransport = null;
  let producerTransportDisplay = null;
  let videoProducer = null;
  let audioProducer = null;
  let consumerTransport = null;
  let videoConsumers = {};
  let audioConsumers = {};
  let clientId = null;

  const [onlineUsers, setOnlineUsers] = useState([]);
  if (props.message == 100) {
    if (!tl) {
      setTL(true);
      setTimeout(function () {
        setTL(false);
      }, 6000);
    }
  }
  function removeRemoteVideo(id) {
    $("#remote").remove();
    $("#audio_remote").remove();
  }

  useEffect(() => {
    if (local_media_stream)
      local_media_stream.getTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
  }, [is_hold]);
  const init = (callback) => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function (stream) {
        local_media_stream = stream;

        callback(stream);
      })
      .catch((e) => {
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="سیستم شما می بایست به دوربین مجهز باشد"
            onConfirm={() => confirm()}
            confirmBtnCssClass={classes.button + " " + classes.success}
            confirmBtnText="بازگشت"
          ></SweetAlert>
        );
      });
  };
  const confirm = () => {
    window.location.href = "/";
  };
  const getUserData = (national_code) => {
    return props.store.dispatch(CustomerAction.getCustomer(national_code));
  };
  async function loadDevice(routerRtpCapabilities) {
    try {
      device = new Device();
    } catch (error) {
      if (error.name === "UnsupportedError") {
        // console.error("browser not supported");
      }
    }
    await device.load({ routerRtpCapabilities });
  }

  const connection = () => {
    let socket = io.connect(Url);

    let course_id = props.match.params.id;

    socket.on("connect", async function () {
      setSocket(socket);

      let type = await sendRequest("prepare_room_admin", { roomId: course_id }, socket);

      // socket.emit("join", {type: "admin", _id: localStorage.getItem("_id")});

      if (type == "chat") setIsChat(true);
      else {
        const data = await sendRequest("getRouterRtpCapabilities", { room_id: course_id }, socket);

        await loadDevice(data);

        subscribe(socket);
      }
    });
    socket.on("disconnect", function () {
      setIsFree(false);
      socketIo && socketIo.disconnect();
      setStatus("off");
    });

    async function subscribe(socketIo) {
      if (!consumerTransport) {
        const params = await sendRequest("createConsumerTransport", {}, socketIo);
        consumerTransport = await device.createRecvTransport(params);
        consumerTransport.on("connect", async ({ dtlsParameters }, callback, errback) => {
          sendRequest("connectConsumerTransport", { dtlsParameters: dtlsParameters }, socketIo)
            .then(callback)
            .catch(errback);
        });

        consumerTransport.on("connectionstatechange", (state) => {
          switch (state) {
            case "connecting":
              break;

            case "connected":
              // consumeCurrentProducers(clientId, socketIo);
              break;

            case "failed":
              producerTransport.close();
              break;

            default:
              break;
          }
        });
        consumeCurrentProducers(0, socketIo);
      }

      // --- join & start publish --
    }

    async function consumeCurrentProducers(clientId, socketIo) {
      const remoteInfo = await sendRequest("getCurrentProducers", { localId: socketIo.id }, socketIo).catch((err) => {
        return;
      });

      consumeAll(consumerTransport, remoteInfo.remoteVideoIds, remoteInfo.remoteAudioIds, socketIo);
    }
    const confirm = async (id, course_id) => {
      if (producerTransport) producerTransport.close();
      producerTransport = null;

      if (consumerTransport) consumerTransport.close();
      consumerTransport = null;

      device = null;
      // 

      init(async function (stream) {
        setAlert(null);
        setPlaying(false);
        setId(id);

        // 
        const dat = await sendRequest("accept", { id }, socket);

        setIsFree(true);

        const data = await sendRequest("getRouterRtpCapabilities", { room_id: course_id }, socket);

        await loadDevice(data);

        subscribe(socket);

        const params = await sendRequest("createProducerTransport", {}, socket);

        // 
        producerTransport = device.createSendTransport(params);

        producerTransport.on("connect", async ({ dtlsParameters }, callback, errback) => {
          sendRequest("connectProducerTransport", { dtlsParameters: dtlsParameters }, socket)
            .then(callback)
            .catch(errback);
        });
        producerTransport.on("produce", async ({ kind, rtpParameters }, callback, errback) => {
          try {
            const { id } = await sendRequest(
              "produce",
              {
                transportId: producerTransport.id,
                kind,
                rtpParameters,
                type: "teacher",
              },
              socket
            );

            callback({ id });
          } catch (err) {
            errback(err);
          }
        });

        producerTransport.on("connectionstatechange", (state) => {
          switch (state) {
            case "connecting":
              break;

            case "connected":
              break;

            case "failed":
              producerTransport.close();
              break;

            default:
              break;
          }
        });

        const videoTrack = local_media_stream.getVideoTracks()[0];

        if (videoTrack) {
          const trackParams = {
            track: videoTrack,
            encodings: [{ maxBitrate: 50000 }],
          };
          videoProducer = await producerTransport.produce(trackParams);
        }
        const audioTrack = local_media_stream.getAudioTracks()[0];
        if (audioTrack) {
          const trackParams = {
            track: audioTrack,
            encodings: [{ maxBitrate: 100000 }],
          };
          audioProducer = await producerTransport.produce(trackParams);
        }

        subscribe(socket);

        setIsHold(false);
      });
    };

    socket.on("newProducer", async function (message) {
      const remoteId = message.socketId;
      const prdId = message.producerId;
      const kind = message.kind;
      const name = message.name;
      const type = message.type;
      const size = message.size;
      subscribe(socket);

      if (type !== "screen") {
        if (kind === "video") {
          // 
          consumeAdd(consumerTransport, remoteId, prdId, kind, socket, name, type);
        } else if (kind === "audio") {
          consumeAdd(consumerTransport, remoteId, prdId, kind, socket, name, type);
        }
      }
    });
    socket.on("producerClosed", function (message) {
      const localId = message.localId;
      const size = message.size;
      const remoteId = message.remoteId;
      const kind = message.kind;
      const type = message.type;

      removeConsumer(remoteId, kind);
      removeRemoteVideo(remoteId);
    });

    function removeConsumer(id, kind) {
      if (kind === "video") {
        delete videoConsumers[id];
        // 
      } else if (kind === "audio") {
        delete audioConsumers[id];
        // 
      } else {
        // console.warn("UNKNOWN consumer kind=" + kind);
      }
    }

    function consumeAll(transport, remoteVideoIds, remotAudioIds, socket) {
      remoteVideoIds.forEach((rId) => {
        consumeAdd(transport, rId.id, null, "video", socket, rId.name, rId.type);
      });
      remotAudioIds.forEach((rId) => {
        consumeAdd(transport, rId.id, null, "audio", socket, rId.name, rId.type);
      });
    }
    async function consumeAdd(transport, remoteSocketId, prdId, trackKind, socket, name = "", type = "student") {
      // 

      const { rtpCapabilities } = device;

      // 

      const data = await sendRequest(
        "consumeAdd",
        {
          rtpCapabilities: rtpCapabilities,
          remoteId: remoteSocketId,
          kind: trackKind,
        },
        socket
      ).catch((err) => {
        // 
        // 
      });
      // 

      const { producerId, id, kind, rtpParameters } = data;
      if (prdId && prdId !== producerId) {
        // console.warn("producerID NOT MATCH");
      }
      // 

      let codecOptions = {};
      const consumer = await transport.consume({
        id,
        producerId,
        kind,
        rtpParameters,
        codecOptions,
      });
      // 

      addRemoteTrack(remoteSocketId, consumer.track, name, type, kind);
      addConsumer(remoteSocketId, consumer, kind);
      consumer.remoteId = remoteSocketId;
      consumer.on("transportclose", () => {
        //consumer.close();
        //removeConsumer(remoteId);
        //removeRemoteVideo(consumer.remoteId);
      });
      consumer.on("producerclose", () => {
        consumer.close();
        removeConsumer(consumer.remoteId, kind);
        removeRemoteVideo(consumer.remoteId);
      });
      consumer.on("trackended", () => {});

      //return stream;

      if (kind === "video") {
        await sendRequest("resumeAdd", { remoteId: remoteSocketId, kind: kind }, socket)
          .then(() => {})
          .catch((err) => {
            // 
          });
      }
    }

    function addConsumer(id, consumer, kind) {
      if (kind === "video") {
        videoConsumers[id] = consumer;
        // 
      } else if (kind === "audio") {
        audioConsumers[id] = consumer;
        // 
      } else {
        // console.warn("UNKNOWN consumer kind=" + kind);
      }
    }

    function findRemoteVideo(id, type, kind) {
      let element;
      if (kind === "audio") element = document.querySelector(`#audio_remote video`);
      else element = document.querySelector(`#remote video`);

      return element;
    }

    function addRemoteVideo(id, name = "", type = "", kind, track) {
      let existElement = findRemoteVideo(id, type, kind);
      if (existElement) {
        return existElement;
      }

      // let element = document.createElement('video');
      // const remoteContainer = document.getElementById('remote_container');

      switch (type) {
        case "screen":
          var remote_media = document.createElement("video");
          var parent = document.createElement("div");

          remote_media.setAttribute("autoplay", "autoplay");
          remote_media.setAttribute("playsinline", "playsinline");

          parent.setAttribute("id", "remote_" + id);
          if (kind === "audio") parent.setAttribute("style", "display:none");
          else parent.setAttribute("style", "");
          parent.append(remote_media);
          $("#display-container").append(parent);

          return remote_media;

        case "student":
        case "teacher":
          // var remote_media = document.createElement("video");
          // var parent = document.createElement("div");
          var details = document.createElement("div");
          var username = document.createElement("h3");

          details.setAttribute("class", "details-container");

          username.setAttribute("class", "name");
          username.innerText = name;

          details.append(username);
          remote_media.setAttribute("autoplay", "autoplay");
          remote_media.setAttribute("playsinline", "playsinline");
          parent.setAttribute("class", "student-camera-container");
          parent.setAttribute("style", "width:50%");

          if (kind === "audio") {
            parent.setAttribute("id", "remote_audio" + id);
            parent.append(remote_media);
            $("#audio-container").append(parent);

            // let element = document.getElementById(`remote`);
            // if (element) {
            //     var audiobar = document.createElement('div');
            //     var elementParent = document.createElement('div');
            //     const newStream = new MediaStream();
            //     newStream.addTrack(track);
            //     // let _hark = hark(newStream, {play: false});
            //     // _hark.on('volume_change', (dBs, threshold) => {
            //     //     let audioVolume = Math.round(Math.pow(10, dBs / 85) * 10);

            //     //     if (audioVolume === 1)
            //     //         audioVolume = 0;

            //     //     changeVolume(audioVolume, audiobar)

            //     // });
            //     elementParent.append(audiobar)
            //     elementParent.setAttribute("class", "barContainer");
            //     element.append(elementParent)
            // }
          } else {
            parent.setAttribute("id", "remote_" + id);
            parent.append(remote_media);
            parent.append(details);
            $("#video-container").append(parent);
          }

          return remote_media;
      }

      //
      // remoteContainer.appendChild(element);
      // element.id = 'remote_' + id;
      // element.width = 240;
      // element.height = 180;
      // element.volume = 0;
      // //element.controls = true;
      // element.style = 'border: solid black 1px;';
    }
    function addRemoteTrack(id, track, name = "", type = "", kind) {
      let video = findRemoteVideo(id, type, kind);

      if (!video) {
        video = addRemoteVideo(id, name, type, kind, track);
      }

      if (video.srcObject) {
        video.srcObject.addTrack(track);
        return;
      }

      const newStream = new MediaStream();
      newStream.addTrack(track);

      playVideo(video, newStream)
        .then(() => {
          video.volume = 1.0;
        })
        .catch((err) => {
          // console.error("media ERROR:", err);
        });
    }

    function playVideo(element, stream) {
      if (element.srcObject) {
        // console.warn("element ALREADY playing, so ignore");
        return;
      }
      element.srcObject = stream;
      element.volume = 0;
      return element.play();
    }

    const confirmChat = (id) => {
      setAlert(null);
      setPlaying(false);
      setId(id);
      socket.emit("accept-chat", id);
      setIsHold(false);
    };
    const hideAlert = (id) => {
      setAlert(null);
      setPlaying(false);
      socket.emit("rejected", id);
    };

    const hideAlertChat = (id) => {
      setAlert(null);
      setPlaying(false);
      socket.emit("rejected-chat", id);
    };

    socket.on("chat", function (id, room_id, mobile = "", code = "", menu = "", vip = false) {
      // 

      setPlaying(true);
      setAlert(
        <SweetAlert
          style={{ display: "block" }}
          title="درخواست تماس متنی جدید"
          onConfirm={() => confirmChat(id)}
          onCancel={() => hideAlertChat(id)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText="برقراری تماس"
          cancelBtnText="رد تماس"
          showCancel
        >
          <span>
            شماره تماس: {mobile}
            <br />
            کد ملی: {code}
            <br /> خدمت: {menu}
            <br />
            {vip ? "مشتری VIP" : ""}
          </span>
          <br />
          <img width={"150"} src={imgMess} />
        </SweetAlert>
      );
    });
    socket.on("answer", function (id, description) {
      try {
        peerConnection.setRemoteDescription(description);

        peerConnection.ontrack = function (event) {
          let self = document.getElementById("client");
          self.srcObject = event.streams[0];
        };
      } catch (e) {
        // 
      }
    });

    socket.on("answer-chat", function (id, national_code, mobile) {
      setUserNationalCode(national_code);
      setUserMobile(mobile);
      getUserData(national_code);

      setIsChat(true);
      setIsFree(true);
    });

    socket.on("watcher", async function (id, national_code, mobile) {
      setUserNationalCode(national_code);
      setUserMobile(mobile);
      getUserData(national_code);

      setIsFree(true);

      let self = document.getElementById("self");
      peerConnection = new RTCPeerConnection({ iceServers: ICE_SERVERS });
      let stream = local_media_stream;
      self.srcObject = stream;
      self.setAttribute("autoplay", "autoplay");
      self.setAttribute("playsinline", "playsinline");
      stream.getTracks().forEach((track) => peerConnection.addTrack(track, stream));
      peerConnection
        .createOffer()
        .then((sdp) => peerConnection.setLocalDescription(sdp))
        .then(function () {
          socket.emit("offer", id, peerConnection.localDescription);
        });
      peerConnection.onicecandidate = function (event) {
        if (event.candidate) {
          socket.emit("candidate", id, event.candidate);
        }
      };
    });

    socket.on("candidate", function (id, candidate) {
      peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
    });

    socket.on("left_user", function () {
      window.location.href = "/admin/admin-dashboard";
    });

    socket.on("left_user", function () {
      window.location.href = "/admin/admin-dashboard";
    });
    socket.on("end_call", function () {
      window.location.href = "/admin/admin-dashboard";
    });
    socket.on("chat_message", function (msg) {
      let message = ` <div class="${msg.operator_id == 0 ? "other" : "me"}">${msg.message}<br />
      <span
        style={{
          padding: 5,
          margin: 5,
          float: "left",
          fontSize: 10,
        }}
      >
        ${moment(msg.date, "YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD HH:mm:ss")}
      </span></div>
`;
      var messages = document.getElementById("messages");
      messages.innerHTML = messages.innerHTML + message;
      messages.scrollTop = messages.scrollHeight;
    });
    socket.on("chat_message_file", function (msg) {
      let message = ` <div class="${msg.operator_id == 0 ? "other" : "me"}">
 
  <a   href="${msg.message}">جهت دانلود فایل کلیک کنید</a>
  
 
</div>
`;

      var messages = document.getElementById("messages");
      messages.innerHTML = messages.innerHTML + message;
      messages.scrollTop = messages.scrollHeight;
    });

    socket.on("disconnect", function () {});

    socket.on("left_user", function () {
      if (producerTransport) producerTransport.close();
      producerTransport = null;

      if (consumerTransport) consumerTransport.close();
      consumerTransport = null;

      device = null;

      if (local_media_stream) {
        const tracks = local_media_stream.getTracks();

        tracks.forEach(function (track) {
          track.stop();
        });
      }
      if (local_screen_stream) {
        const tracks = local_screen_stream.getTracks();

        tracks.forEach(function (track) {
          track.stop();
        });
      }
      setAlert(null);
      setIsFree(false);
      setPlaying(false);
      setIsChat(false);
      setIsShare(false);
      socket.emit("cancel_display", id);
      removeRemoteVideo(0);
    });
  };

  //check session chat
  useEffect(() => {
    getRecentMessage();
  }, []);

  // 👇️ get ID from url
  const params = useParams();

  const getRecentMessage = function (e) {
    axios({
      url: ApiUrl + `/get-history/${params.id}`,
      method: "get",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        response.data.data.history.messages.forEach((msg) => {
          let message = `<div class="${msg.type === "user" ? "other" : "me"}" >${msg.message} <br />
          <span
            style={{
              padding: 5,
              margin: 5,
              float: "left",
              fontSize: 10,
            }}
          >
            ${moment(msg.date, "YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD HH:mm:ss")}
          </span></div> `;

          var messages = document.getElementById("messages");
          messages.innerHTML = messages.innerHTML + message;
        });
      })
      .catch(function (error) {})
      .finally(function () {});
  };
  let renderCalling = () => {
    return (
      <div className={"calling admin"}>
        {!isChat && (
          <div id="video-container" style={{ display: "flex", flexWrap: "wrap" }} className="video">
            {/* <video autoPlay={true} id="client"/> */}
          </div>
        )}

        <div style={isChat ? { width: "95%", flex: "0 0 95%", margin: "auto" } : {}} className="btn-container">
          <div className="chat">
            <Card>
              <CardHeader className={classes.cardIconTitle} color="primary" icon>
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
                <h4 style={{ color: "#424242", textAlign: "start", margin: "2%" }}>چت</h4>
              </CardHeader>
              <CardBody>
                <Fragment>
                  <div id={"messages"} className={"messages"}></div>
                </Fragment>
                <div className={"btn-container-top"}>
                  <div className="btns">
                    <a href="/admin">
                      <button
                        style={{
                          background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
                          boxShadow: "0 0 5px 0 #27052d",
                        }}
                      >
                        خروج
                      </button>
                    </a>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    );
  };

  const makeAlert = (text) => {
    alert(text);
  };

  const sendMessage = function () {
    let message = document.getElementById("message");
    let items = {
      message: message.value,
      id: socketIo.id,
      operator_id: localStorage.getItem("_id"),
    };

    socketIo.emit("chat_message", items);
    message.value = "";
  };
  const uploadFile = function (e) {
    var fd = new FormData();
    fd.append("file", e.target.files[0]);

    axios({
      url: ApiUrl + "/upload-chat-file",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("token"),
      },
      data: fd,
    })
      .then(function (response) {
        let message = document.getElementById("selectFile");

        let items = {
          message: Url + response.data.data.path,
          id: socketIo.id,
          operator_id: localStorage.getItem("_id"),
        };

        socketIo.emit("chat_message_file", items);
        message.value = "";
      })
      .catch(function (error) {})
      .finally(function () {});
  };
  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const renderStatus = () => {
    switch (status) {
      case "off":
        return Off;
      case "standby":
        return standby;
      case "on":
        return on;
    }
  };
  const renderStatusText = () => {
    switch (status) {
      case "off":
        return "خاموش";
      case "standby":
        return "استراحت";
      case "on":
        return "فعال";
    }
  };

  return (
    <GridContainer>
      {alerts}
      <GridItem md={12} sm={12}>
        <div dir="rtl">
          <div className={"video-container operator"}>
            {renderCalling()}
            {/* {

                            isFree ?
                                renderCalling() :
                                renderWaiting()
                        }

                         */}
            <div id="audio-container" className="video">
              {/* <video autoPlay={true} id="client"/> */}
            </div>
          </div>
        </div>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => ({
  customer: state.customer.customer,
  customer_loading: state.customer.loading,
  message: state.customer.message,
  loading: state.user.loading,
  error: state.user.error,
});

const mapDispatchToProps = (dispatch) => ({
  CustomerAction: bindActionCreators({ CustomerAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
