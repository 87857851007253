import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@mui/material";
import { Url } from "../../config";
import profile from "../../assets/img/faces/avatar.png";
import queue from "../../assets/img/icons/storeIcon.svg";
import backspace from "../../assets/img/icons/backspace.svg";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "16px",
    backgroundColor: "var(--white)",
    borderRadius: "16px",
    gap: "8px",
  },
});

function ActiveChats({ chats }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Box width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"} alignSelf={"stretch"}>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} alignSelf={"stretch"}>
          <img
            style={{ width: "48px", height: "48px", borderRadius: "50%" }}
            src={chats && chats[0].operator.avatar ? Url + chats[0].operator.avatar : profile}
          />
          <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography fontSize={"10px"}>کارشناس :</Typography>
              <Typography fontSize={"16px"}>{chats && chats[0].operator.last_name}</Typography>
            </Box>
            <Typography fontSize={"10px"}>آنلاین</Typography>
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={"8px"}>
          <Typography fontWeight={600} fontSize={"14px"}>
            کارتخوان
          </Typography>
          <img width={"16px"} height={"16px"} src={queue} alt="" />
        </Box>
      </Box>
      <Box width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"} alignSelf={"stretch"}>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={"8px"}>
          <Box width={"6px"} height={"6px"} sx={{ backgroundColor: "var(--mbOrange)", borderRadius: "50%" }} />
          <Typography fontSize={"10px"} fontWeight={600} color={"var(--mbOrange)"}>
            پیام جدید
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={"8px"}>
          <Typography fontSize={"10px"} fontWeight={600}>
            2 ساعت قبل
          </Typography>
          <Typography fontSize={"10px"} fontWeight={400} color={"var(--mbText)"}>
            زمان ایجاد:
          </Typography>
        </Box>
      </Box>
      <Box display={"flex"} padding={"8px"} alignItems={"center"} gap={"8px"}>
        <Box width={"32px"} height={"32px"} display={"flex"} padding={"4"} justifyContent={"center"} alignItems={"center"}>
          <img src={backspace} alt=""/>
        </Box>
        <Box width={"100%"} display={"flex"} flexDirection={"column"} alignItems={"flex-end"} gap={"8px"} overflow={"hidden"}>
          <Typography fontSize={"12px"} fontWeight={400} textOverflow={"ellipsis"} whiteSpace={"nowrap"}>
            بسیار عالی من برای شما لینک آموزش دریافت بسیار عالی من برای شما لینک آموزش دریافت
          </Typography>
          <Typography fontSize={"8px"} fontWeight={400} color={"var(--mbGray2)"}>11:12</Typography>
        </Box>

      </Box>
    </div>
  );
}

export default ActiveChats;
