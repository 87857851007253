import {
  FIND_HISTORY_CLIENT_CALL_FAILED,
  FIND_HISTORY_CLIENT_CALL_REQUEST,
  FIND_HISTORY_CLIENT_CALL_SUCCESS,
  FIND_HISTORY_CLIENT_FAILED,
  FIND_HISTORY_CLIENT_REQUEST,
  FIND_HISTORY_CLIENT_SUCCESS,
} from "../type/user_type";

const initialState = {
  data: null,
  loading: false,
  error: null,
  message: null,
  code: null,
  clients: null,
  client: null,
  history: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FIND_HISTORY_CLIENT_REQUEST: {
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
        message: null,
        code: null,
        clients: null,
        client: null,
      };
    }
    case FIND_HISTORY_CLIENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        client: action.payload.data,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
      };
    }
    case FIND_HISTORY_CLIENT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case FIND_HISTORY_CLIENT_CALL_REQUEST: {
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
        message: null,
        code: null,
        history: null,
      };
    }
    case FIND_HISTORY_CLIENT_CALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        history: action.payload.data,
        error: action.payload.error,
        message: action.payload.message,
        code: action.payload.code,
      };
    }
    case FIND_HISTORY_CLIENT_CALL_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
