import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  iframe: {
    width: "600px",
    height: "800px",
    borderRadius: "10px",
    boxShadow: "0 0 5px rgba(0,0,0,0.5)",
  },
});
function ChatBotAvandIframe(props) {
  const classes = useStyles();
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        id="iframeId"
        allowTransparency="true"
        frameBorder="0"
        src="http://localhost:3000/client-bo/direction-avand/09121234567/1272705676"
        allow="geolocation; microphone; camera"
        className={classes.iframe}
      ></iframe>
    </div>
  );
}

export default ChatBotAvandIframe;
