import { css } from "@emotion/react";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle, dangerColor, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApiUrl } from "../../config";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
  },
};

const useStyles = makeStyles(styles);
const fixNumbers = (value) => {
  var persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g];
  var arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];

  if (value === persianNumbers) {
    for (var i = 0; i < 10; i++) {
      var str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
    }
  }
  return str;
};
const validatetexterea = (value) => {
  if (value.length != 11) {
    return "شماره وارد شده صحیح نمی باشد";
  }
  return true;
};

const phoneRegExp = /^[0][9][0-9][0-9]{8,8}$/;
const checkNationalId = (code) => {
  if (
    !/^\d{10}$/.test(code) ||
    code === "0000000000" ||
    code === "1111111111" ||
    code === "2222222222" ||
    code === "3333333333" ||
    code === "4444444444" ||
    code === "5555555555" ||
    code === "6666666666" ||
    code === "7777777777" ||
    code === "8888888888" ||
    code === "9999999999"
  ) {
    return false;
  }

  var sum = 0,
    chars = code.split("");
  for (let i = 0; i < 9; i++) sum += +chars[i] * (10 - i);
  var lastDigit,
    remainder = sum % 11;

  lastDigit = remainder < 2 ? remainder : 11 - remainder;

  return +chars[9] === lastDigit;
};

function AddClientConfrence(props) {
  const [loader, setLoader] = useState(false);
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const errorsss = () => {
    toast.error("کد ملی تکراری است", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const success = () => {
    toast.success("اتاق با موفقیت ایجاد شد و لینک مشتری در کلیپ بورد ذخیره شد", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const onSubmit = (data) => {
    setLoader(true);
    if (data.type) {
      data.type = "video";
    } else {
      data.type = "text";
    }
    data["operatorId"] = localStorage.getItem("_id");
    axios({
      url: `${ApiUrl}/create-externalconfrence`,
      method: "POST",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    })
      .then(function ({ data }) {
        setLoader(false);
        success();
        navigator.clipboard.writeText(data.data.CustomerConfrence.link);
      })
      .catch(function (error) {
        setLoader(false);
        errorsss();
      });
  };
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;

  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>افزودن اتاق - حامی چت</title>
      </Helmet>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <form onSubmit={handleSubmit(onSubmit)} className="AddClientConfrence">
            <Card style={{ height: "437px" }}>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>افزودن اتاق مشتری</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <div className="AddClientConfrence__inputBox">
                  <FormLabel className={classes.labelHorizontal}>کد ملی مشتری</FormLabel>
                  <div style={{ width: "60%" }}>
                    <div className="service-box">
                      <input
                        {...register("data_nationalcode", {
                          required: true,
                          pattern: checkNationalId,
                          persianNumbers: fixNumbers,
                        })}
                        placeholder="###########"
                        onFocus={(e) => (e.target.placeholder = "")}
                        onBlur={(e) => (e.target.placeholder = "###########")}
                        className={
                          errors.mobile
                            ? "AddClientConfrence__input AddClientConfrence__errinput"
                            : "AddClientConfrence__input"
                        }
                      />
                    </div>
                    {errors.data_nationalcode?.type === "required" && (
                      <p style={{ color: dangerColor[0] }}>وارد کردن کد ملی الزامی می باشد</p>
                    )}
                    {errors.data_nationalcode?.type === "pattern" && (
                      <p style={{ color: dangerColor[0] }}>کد ملی معتبر نمی باشد</p>
                    )}
                  </div>
                </div>
                <div className="AddClientConfrence__inputBox">
                  <FormLabel className={classes.labelHorizontal}>شماره تماس مشتری</FormLabel>
                  <div style={{ width: "60%" }}>
                    <div className="service-box">
                      <input
                        {...register("data_mobile", {
                          required: true,
                          validate: validatetexterea,
                          pattern: phoneRegExp,
                          persianNumbers: fixNumbers,
                        })}
                        placeholder="09#########"
                        onFocus={(e) => (e.target.placeholder = "")}
                        onBlur={(e) => (e.target.placeholder = "09#########")}
                        className={
                          errors.mobile
                            ? "AddClientConfrence__input AddClientConfrence__errinput"
                            : "AddClientConfrence__input"
                        }
                      />
                    </div>
                    {errors.data_nationalcode?.type === "required" && (
                      <p style={{ color: dangerColor[0] }}>وارد کردن شماره همراه الزامی می باشد</p>
                    )}
                    {errors.data_nationalcode?.type === "validate" && (
                      <p style={{ color: dangerColor[0] }}> شماره وارد شده صحیح نمی باشد</p>
                    )}
                    {errors.data_nationalcode?.type === "pattern" && (
                      <p style={{ color: dangerColor[0] }}> شماره وارد شده معتبر نمی باشد</p>
                    )}
                  </div>
                </div>
                <div className="AddClientConfrence__inputBox">
                  <FormLabel className={classes.labelHorizontal}>تماس تصویری</FormLabel>
                  <div
                    style={{
                      width: "60%",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <input
                      {...register("type", {
                        required: false,
                      })}
                      type="checkbox"
                      className="AddClientConfrence__checkbox"
                    />
                  </div>
                </div>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button type={"submit"} color="rose" disabled={loader}>
                  {loader ? <ClipLoader color="#ffffff" loading={true} css={override} size={20} /> : "ارسال و کپی لینک"}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
      <ToastContainer />
    </div>
  );
}

export default AddClientConfrence;
