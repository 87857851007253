// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomTable from "components/CustomTable";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment-jalaali";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { connect } from "react-redux/";
import { bindActionCreators } from "redux";
// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import { API_URL } from "../../contacs";
import CustomerAction from "../../js/actions/customer_action";
import "../../scss/main.scss";
import UpdateTicketModal from "./UpdateTicketModal";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function Histories(props) {
  const { loading, problems, pagination, problems1 } = props;

  // 
  const [logic, setLogic] = useState(true);
  const [pro, setPro] = useState("");

  // useEffect(() => {
  //   fetchMore();
  //   fetchMorecostomer();
  // }, loading);
  useEffect(() => {
    if (!logic) {
      fetchMorecostomer();
    } else {
      fetchMore();
    }
    // fetchMorecostomer();
  }, [logic]);
  // 
  const fetchMore = (pagination = { per_page: 20, page: 1 }) => {
    return props.store.dispatch(CustomerAction.getProblems(pagination));
  };
  const fetchMorecostomer = (pagination = { per_page: 20, page: 1 }) => {
    return props.store.dispatch(CustomerAction.getProblemslogic(pagination));
  };
  const [infodelet, setInfodelet] = useState("");

  const handleDelet = (idd) => {
    setInfodelet({ status: "CLOSE" });
    axios
      .post(`${API_URL}/v1/ticket-hamichat/admin/${idd}`, infodelet, {
        headers: {
          // "content-type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setLogic(!!logic);
      })
      .catch((error) => {
        // 
      });
  };
  const [updateId, setUpdateId] = useState("");
  const [updateModal, setUpdateModal] = useState(false);
  const handleUpdate = (updateid) => {
    setUpdateId(updateid);
    openModal();
  };
  const closeModal = () => {
    setUpdateModal(false);
  };
  const openModal = () => {
    setUpdateModal(true);
  };
  const classes = useStyles();
  if (loading) return "loading";
  const handlecostomer = () => {
    setLogic(false);
    // fetchMorecostomer();
  };
  const handleLogic = () => {
    // fetchMore();
    setLogic(true);
  };
  return (
    <div dir="rtl">
      <button onClick={() => setLogic(false)} className={logic ? "ticketBtn-disactive" : "ticketBtn-active"}>
        تیکت مشتری
      </button>
      <button
        onClick={() => setLogic(true)}
        className={logic ? "ticketBtn-active" : "ticketBtn-disactive"}
        style={{ marginRight: "20px" }}
      >
        تیکت پارس لاجیک
      </button>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>لیست تیکت ها</h4>
            </CardHeader>
            <CardBody>
              {logic ? (
                <CustomTable
                  rtl
                  getTableProps={() => ({
                    style: { display: "block", textAlign: "right" },
                  })}
                  data={problems ? problems : []}
                  loading={loading}
                  filterable
                  columns={[
                    {
                      Header: "نام",
                      accessor: "operator.first_name",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "نام خانوادگی",
                      accessor: "operator.last_name",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "زمان",
                      accessor: "date",
                      sortable: false,
                      filterable: false,
                      Cell: (row) => <div>{moment(row.value, "YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD HH:mm:ss")}</div>,
                    },
                    {
                      Header: "کد ملی مشتری",
                      accessor: "user_national_code",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "شماره تماس مشتری",
                      accessor: "mobile",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "توضیحات",
                      accessor: "problem",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  pagination={pagination}
                  showPaginationTop={false}
                  showPaginationBottom={true}
                  fetchMore={fetchMore}
                  className="-striped -highlight"
                />
              ) : (
                <CustomTable
                  rtl
                  getTableProps={() => ({
                    style: { display: "block", textAlign: "right" },
                  })}
                  data={problems1 ? problems1 : []}
                  loading={loading}
                  filterable
                  columns={[
                    {
                      Header: "آیدی مشتری",
                      accessor: "user_id",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "زمان ایجاد",
                      accessor: "create_at",
                      sortable: false,
                      filterable: false,
                      Cell: (row) => <div>{moment(row.value, "YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD HH:mm:ss")}</div>,
                    },
                    {
                      Header: "زمان ویرایش",
                      accessor: "update_at",
                      sortable: false,
                      filterable: false,
                      Cell: (row) => <div>{moment(row.value, "YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD HH:mm:ss")}</div>,
                    },
                    {
                      Header: "اولوبت",
                      accessor: "priority",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "وضعیت",
                      accessor: "status",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "فایل ضمیمه شده",
                      accessor: "attach",
                      sortable: false,
                      filterable: false,
                      Cell: (row) => <div>{row.value !== null ? row.value : "__"}</div>,
                    },
                    {
                      Header: "عنوان تیکت",
                      accessor: "title",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "توضیحات",
                      accessor: "text",
                      sortable: false,
                      filterable: false,
                      Cell: (row) => (
                        <div>
                          <p> {JSON.parse(row.value)[0].message}</p>
                        </div>
                      ),
                    },
                    {
                      Header: "نقش",
                      accessor: "text",
                      sortable: false,
                      filterable: false,
                      Cell: (row) => (
                        <div>
                          <p> {JSON.parse(row.value)[0].role}</p>
                        </div>
                      ),
                    },
                    {
                      Header: "آی دی فرستنده",
                      accessor: "text",
                      sortable: false,
                      filterable: false,
                      Cell: (row) => (
                        <div>
                          <p> {JSON.parse(row.value)[0].sender_id}</p>
                        </div>
                      ),
                    },
                    {
                      Header: "زمان ارسال",
                      accessor: "text",
                      sortable: false,
                      filterable: false,
                      Cell: (row) => (
                        <div>
                          {JSON.parse(row.value)[0].time !== null
                            ? moment(JSON.parse(row.value)[0].time, "YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD HH:mm:ss")
                            : "__"}
                        </div>
                      ),
                    },
                    {
                      Header: "زمان بسته شدن تیکت",
                      accessor: "delete_at",
                      sortable: false,
                      filterable: false,
                      Cell: (row) => (
                        <div>
                          {row.value !== null
                            ? moment(row.value, "YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD HH:mm:ss")
                            : "__"}
                        </div>
                      ),
                    },
                    {
                      Header: "پاسخ",
                      accessor: "id",
                      sortable: false,
                      filterable: false,
                      Cell: (row) => (
                        <div onClick={() => handleUpdate(row.value)} style={{ cursor: "pointer" }}>
                          <AiOutlineEdit size={20} />
                        </div>
                      ),
                    },
                    {
                      Header: "حذف",
                      accessor: "id",
                      sortable: false,
                      filterable: false,
                      Cell: (row) => (
                        <div onClick={() => handleDelet(row.value)} style={{ cursor: "pointer" }}>
                          <AiOutlineDelete size={20} />{" "}
                        </div>
                      ),
                    },
                  ]}
                  pagination={pagination}
                  showPaginationTop={false}
                  showPaginationBottom={true}
                  fetchMore={fetchMorecostomer}
                  className="-striped -highlight"
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {updateModal && <UpdateTicketModal closeModal={closeModal} updateId={updateId} />}
    </div>
  );
}

const mapStateToProps = (state) => ({
  problems: state.customer.problems,
  problems1: state.customer.problems1,
  pagination: state.customer.pagination,
  loading: state.customer.loading,
  error: state.customer.error,
});
const mapDispatchToProps = (dispatch) => ({
  CustomerAction: bindActionCreators({ CustomerAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Histories);
