import React from "react";

function DefaultBox(props) {
  const handleBack = () => {
    props.setTicket(0);
  };
  const exit = () => {
    window.location.href = "/client/direction";
  };
  return (
    <div className={`DefaultBox ${props.className}`}>
      {props.main ? (
        <div className="d-flex alighn-items-center justify-content-space-between" style={{ position: "relative" }}>
          <div className="DefaultBox__top  d-flex alighn-items-center justify-content-space-between">
            <p>{props.title}</p>
          </div>
          <button className="Navigation__Btn" onClick={exit}>
            خروج
          </button>
        </div>
      ) : (
        <div className="DefaultBox__top   d-flex alighn-items-center justify-content-space-between">
          <p>{props.title}</p>
          {!props.log && <i className="fas fa-backspace" onClick={handleBack}></i>}
        </div>
      )}

      <div className={props.main ? "DefaultBox__body" : "DefaultBox__body DefaultBox__body-nohover"}>
        {props.main ? (
          <>
            {" "}
            <div className=" d-flex alighn-items-center justify-content-space-between">
              <p>{props.children} </p>
              {/* <div className={"arrow"}>
                <i className="fas fa-chevron-left"></i>
              </div> */}
              <i className="fas fa-chevron-left"></i>
            </div>
            {props.subbody.map((index, id) => (
              <>
                <div className="DefaultBox__subbody" onClick={() => props.setTicket(index.id)}>
                  <p>{index.sub}</p>
                </div>
              </>
            ))}
          </>
        ) : (
          <>{props.children} </>
        )}
      </div>
    </div>
  );
}

export default DefaultBox;
