import { Grid } from "@material-ui/core";
import React from "react";
import errImg from "../../assets/img/pic/chatError.jpg";
import style from "./ErrorCard.module.scss";

const ErrorCard = (props) => {
  return (
    <Grid container className={style.errCard}>
      <Grid item xs={8}>
        <img className={style.errImg} src={errImg} />
      </Grid>
      <Grid item xs={4}>
        <div className={style.errText}>{props.children}</div>
      </Grid>
    </Grid>
  );
};

export default ErrorCard;
