import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux/";
import pipad from "../../assets/img/paypod-final-logO-3.png";
import { ApiUrl, Url } from "../../config";
import { useHistory } from "react-router-dom";
import { ArrowBack, ExitToApp, History, QuestionAnswer, VideocamOutlined } from "@material-ui/icons";
import QueueCards from "../../components/QueueCards/QueueCards";
import { Grid } from "@mui/material";
import textMessageImg from "../../assets/img/chat/textMessage2.svg";
import useWindowSize from "../../Sizes/UseWindowSize";
import LoginClientMobile from "../../views/Pages/LoginClientMobile";
import MobileQueue from "../../components/MobileQueue/MobileQueue";
import MobileDirection from "../../components/MobileDirection/MobileDirection";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles({
  container: {
    padding: "138px 23px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "var(--mbGray)",
    height: "100vh",
  },
});

require("./style.css");

function Index(props) {
  const classes = useStyles();
  const classes2 = useStyles2();

  const [data, setData] = useState([]);
  const [loading, isLoading] = useState(false);
  const [check, setCheck] = useState(true);
  const [logedin, isLogedin] = useState(false);
  const [step, setStep] = useState(1);
  const [name, setName] = useState(1);
  const [id, setId] = useState(1);
  const [nationalCode, setNationalCode] = useState("");
  const [mobile, setMobile] = useState();
  const [alerts, setAlerts] = useState("");
  const [welcomeData, setWelcomeData] = useState("");
  const windowSize = useWindowSize();

  const myHistory = useHistory();
  const token = localStorage.getItem("token");

  const getWelcomeMsg = async () => {
    const res = await axios
      .get(ApiUrl + "/get-wellcom-msg", { headers: { Authorization: token } })
      .then((res) => {
        res.data.data.option && setWelcomeData(res.data.data.option);
        res.data.data.option.status == 1
          ? setAlerts(
              <SweetAlert
                title={""}
                type={"info"}
                confirmBtnText={"بازگشت"}
                onClickOutside={() => {
                  setAlerts("");
                }}
                onConfirm={() => {
                  setAlerts("");
                }}
                onCancel={() => {
                  setAlerts("");
                }}
              >
                {res.data.data.option ? res.data.data.option.value : ""}
              </SweetAlert>
            )
          : setAlerts("");
      })
      .catch((res) => {});
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    getWelcomeMsg();
  }, []);
  useEffect(() => {
    let userData = localStorage.getItem("data_nationalcode");
    let userDataMobile = localStorage.getItem("data_mobile");

    if (userData != null && userDataMobile != null) {
      setNationalCode(userData);
      setMobile(userDataMobile);
    } else {
      if (props.location.state == undefined) {
        window.location.href = "/";
        return null;
      }
      if (props.location.state.nationalCode === null || props.location.state.nationalCode === undefined) {
        window.location.href = "/";
      }
      localStorage.setItem("data_nationalcode", props.location.state.nationalCode);
      localStorage.setItem("data_mobile", props.location.state.mobile);
      setNationalCode(props.location.state.nationalCode);
      setMobile(props.location.state.mobile);
    }
  }, [logedin]);

  const exit = () => {
    localStorage.removeItem("data_nationalcode");
    localStorage.removeItem("data_mobile");
    window.location.href = "/";
  };

  function handleCall(e, name) {
    setName(name);
    setId(e);
    setStep(2);
    //
    //
  }

  //
  const confirm = () => {
    window.location.href = "/";
  };

  function MeasureConnectionSpeed() {
    var imageAddr = "https://hamichat.pep.co.ir/tester.jpg";
    var downloadSize = 2996096; //bytes

    var startTime, endTime;
    var download = new Image();
    download.onload = function () {
      endTime = new Date().getTime();
      showResults();
    };

    download.onerror = function (err, msg) {
      setAlerts(
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="خطا در بارگزاری اطلاعات"
          onConfirm={() => confirm()}
          confirmBtnCssClass={classes.button + " " + classes.success}
          confirmBtnText="بازگشت"
        ></SweetAlert>
      );
    };

    startTime = new Date().getTime();
    var cacheBuster = "?nnn=" + startTime;
    download.src = imageAddr + cacheBuster;

    function showResults() {
      var duration = (endTime - startTime) / 1000;
      var bitsLoaded = downloadSize * 8;
      var speedBps = (bitsLoaded / duration).toFixed(2);
      var speedKbps = (speedBps / 1024).toFixed(2);
      var speedMbps = (speedKbps / 1024).toFixed(2);
      let minSpeed = 0.5;
      if (speedMbps > minSpeed) {
        makeCall();
      } else {
        setAlerts(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="اینترنت نامناسب"
            onConfirm={() => setAlerts("")}
            confirmBtnCssClass={classes.button + " " + classes.success}
            confirmBtnText="بازگشت"
          >
            <p>
              متاسفانه سرعت اینترنت شما جهت برقراری تماس صوتی و تصویری مناسب نمی باشد. لطفا از گزینه پیام متنی استفاده
              کنید.
            </p>
          </SweetAlert>
        );
      }
      // ShowProgressMessage([
      //     "Your connection speed is:",
      //     speedBps + " bps",
      //     speedKbps + " kbps",
      //     speedMbps + " Mbps"
      // ]);
    }
  }

  function handleCalling() {
    let currentTimestamp = new Date();
    localStorage.setItem("data_timeMenu", currentTimestamp.getTime());
    window.setTimeout(MeasureConnectionSpeed, 1);

    setAlerts(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="در حال ارزیابی سرعت اینترنت"
        onConfirm={() => {}}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText=" لطفا منتظر بمانید..."
      ></SweetAlert>
    );
  }

  function makeCall() {
    props.history.push({
      pathname: "/client/call",
      state: {
        nationalCode,
        menu: id,
        name: name,
        mobile,
      },
    });
  }

  function handleOperatorHistory() {
    props.history.push({
      pathname: "/client/callHistory",
      state: {
        nationalCode,
        menu: id,
        name: name,
        mobile,
      },
    });
  }

  function handleChat() {
    props.history.push({
      pathname: "/client/chat",
      state: {
        nationalCode,
        menu: id,
        name: name,
        mobile,
      },
    });
  }

  const getUsers = () => {
    isLoading(true);

    axios
      .get(ApiUrl + "/menu-items?status=true", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        isLoading(false);

        setData(response.data.data.menu_items);
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };

  useEffect(() => {
    getUsers();
  }, loading);
  const colors = ["#FFFFE0", "#F5F5F5", "#F0FFFF", "#F8F8FF", "#F5FFFA", "#E0FFFF"];
  return (
    <>
      {windowSize === "lg" || windowSize === "big" || windowSize === "xlg" ? (
        <div dir="rtl" className={"directionpage"}>
          {alerts}
          <div className={"welcome-container"}>
            {step == 2  ? (
              <div className={"welcome-input"}>
                <div className={"header"}>
                  <b style={{ width: "105px" }}> {name === "کیپاد" ? "پی پاد" : name}</b>
                  <button onClick={() => handleOperatorHistory()}>
                    <History className={"icon"} />
                  </button>
                  <button className={"return-direction"} onClick={(e) => setStep(1)}>
                    <ArrowBack className={"icon"} style={{ marginRight: "2px" }} />
                  </button>
                </div>
                <h5 className="text">نوع ارتباط با پشتیبانی را انتخاب کنید</h5>

                <div onClick={(e) => handleCalling()} className="selector">
                  <div className="chatmessage">
                    <VideocamOutlined style={{ color: "var(--blue)", fontSize: "60px" }} />
                  </div>
                  <h4> تماس تصویری</h4>
                </div>

                <div onClick={(e) => handleChat()} className="selector">
                  <div className="chatmessage">
                    <img src={textMessageImg} style={{ width: "50px", height: "50px", objectFit: "contain" }} />
                  </div>
                  <h4> تماس متنی</h4>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "50px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                ></div>
              </div>
            ) : (
              <div className={"welcome-input"} style={{ height: "100%" }}>
                <div className={"header"}>
                  <div className={"logo"}></div>
                  <div style={{ display: "flex", justifyContent: "space-around", padding: "10px" }}>
                    <ExitToApp className={"icon"} onClick={(e) => exit()} fontSize={"large"} />
                  </div>
                </div>
                <h5 className="text">به پشتیبانی از کدام خدمات نیاز دارید؟</h5>
                <Grid container justifyContent={"center"} alignItems={"center"} className={"options"}>
                  {data.map((e, i) => {
                    return (
                      <QueueCards
                        colors={colors[i]}
                        key={e._id}
                        name={e.name}
                        img={Url + e.icon}
                        id={e._id}
                        onClick={(j) => handleCall(e._id, e.name)}
                      />
                      //             <div className={"option-client"} key={i}>
                      //                 {e.name === "کیپاد" ? (
                      //                     <div className={"option-item"} onClick={(j) => handleCall(e._id, e.name)}>
                      //                         {/* <div
                      //   style={{
                      //     background: `url("../../assets/img/WhatsApp Image 2021-10-25 at 11.07.10 AM.png") no-repeat`,
                      //   }}
                      //   className={"logo "}
                      // ></div> */}
                      //                         <div className={"logo "}>
                      //                             <img src={pipad} alt={pipad} className={"logosm"}/>
                      //                             {/* <img src={pipad1} alt={pipad1} className={"logosm1"} /> */}
                      //                         </div>
                      //                         <div className={"text"}>پی‌پاد</div>
                      //
                      //                         <div className={"arrow"}></div>
                      //                     </div>
                      //                 ) : (
                      //
                      //                     <div className="list-type1" onClick={(j) => handleCall(e._id, e.name)}>
                      //                         <div className={"list-type1-child"}>
                      //                             <img style={{objectFit: "contain"}} width={"100%"} height={"100%"}
                      //                                  src={Url + e.icon} alt={"عکس صف"}/>
                      //                         </div>
                      //                         <span className={"text"}>{e.name}</span>
                      //                     </div>
                      //
                      //
                      //                 )}
                      //             </div>
                    );
                  })}
                </Grid>
              </div>
            )}
          </div>
        </div>
      ) : step === 1 ? (
        <div className={classes2.container}>
          <MobileQueue setStep={setStep}/>
        </div>
      ):
          <div className={classes2.container}>
            <MobileDirection setStep={setStep}/>
          </div>
      }
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  // UserAction: bindActionCreators({UserAction}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
