import React, { Fragment } from "react";

function DetailProblem({ sbmitCustomerProblem, userNationalCode, userMobile }) {
  return (
    <Fragment>
      <form
        style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}
        onSubmit={(event) => sbmitCustomerProblem(event)}
      >
        <div className={"customer_input"}>
          <label>کد ملی کاربر</label>
          <input value={userNationalCode} name="national_code" />
        </div>
        <div className={"customer_input"}>
          <label>شماره تماس کاربر</label>
          <input value={userMobile} name="mobile" />
        </div>

        <div className={"customer_input"}>
          <label>توضیحات</label>
          <textarea name="problem" />
        </div>

        <div>
          <input value={"ثبت"} type={"submit"} className={"submit"} name="mobile" />
        </div>
      </form>
    </Fragment>
  );
}

export default DetailProblem;
