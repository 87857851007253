import { css } from "@emotion/react";
import FormLabel from "@material-ui/core/FormLabel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import Button from "components/CustomButtons/Button";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { connect } from "react-redux/";
import ClipLoader from "react-spinners/ClipLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { bindActionCreators } from "redux";
import { ApiUrl } from "../../config";
import UserAction from "../../js/actions/user_action";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
  },
};

const useStyles = makeStyles(styles);

function Users(props) {
  const [loading, isLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const success = () => {
    toast.success("اتاق با موفقیت ایجاد شد", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };
  const [users, setusers] = useState([]);
  const [roomName, setRoomName] = useState("");
  const [userName, setUserName] = useState("");
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;
  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    axios
      .get(ApiUrl + "/get-users?size=1000&page=1", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        setusers(response.data.data.users);
      })
      .catch(function (error) {})
      .finally(function () {});
  };

  const onSubmit = async (data) => {
    // e.preventDefault();
    // var bodyFormData = new FormData(data);
    
    isLoading(true);
    // e.target.reset();
    await axios
      .post(ApiUrl + "/create-confrence", data, { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        isLoading(false);
        success();
      })
      .catch((res) => {
        isLoading(false);
      });
    // axios({
    //     url: ApiUrl + "/create-confrence",
    //     method: "post",
    //     headers: {
    //         Authorization: localStorage.getItem("token"),
    //     },
    //     data: data,
    // })
    //     .then(function (response) {
    //         // 
    //         isLoading(false);
    //         success();
    //     })
    //     .catch(function (error) {
    //         isLoading(false);
    //     })
    //     .finally(function () {
    //         isLoading(false);
    //     });
  };

  const mySubmit = (e) => {
    e.preventDefault();
    handleSubmit(onSubmit)(e);
  };

  const classes = useStyles();

  if (props.loading) return <div>در حال ارسال...</div>;
  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>افزودن اتاق - حامی چت</title>
      </Helmet>
      <div id={"from"}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <form onSubmit={mySubmit}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>افزودن اتاق جدید</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <div className="AddClientConfrence__inputBox">
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>نام اتاق</FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={8}>
                        <div className="service-box">
                          <input
                            {...register("name", {
                              required: true,
                            })}
                            className={
                              errors.mobile
                                ? "AddClientConfrence__input AddClientConfrence__errinput"
                                : "AddClientConfrence__input"
                            }
                          />
                        </div>
                      </GridItem>
                    </div>
                    {errors.name && <p style={{ color: "#f44336" }}>وارد کردن نام اتاق الزامی می باشد</p>}
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>کاربران</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <select
                        // name="users"
                        className={"menus"}
                        multiple
                        {...register("users", { required: true })}
                      >
                        {users.map((e, i) => {
                          return (
                            <option value={e._id} key={i}>
                              {e.first_name + " " + e.last_name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.users && <p style={{ color: "#f44336" }}>انتخاب حداقل یک کاربر الزامی می باشد</p>}
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button type={"submit"} color="rose" disabled={loading}>
                    {loading ? <ClipLoader color="#ffffff" loading={true} css={override} size={20} /> : "ذخیره"}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
          {/* <GridItem xs={12} sm={12} md={6}>
            <AddClientConfrence success={success} />
          </GridItem> */}
        </GridContainer>
      </div>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.user.data,
  loading: state.user.loading,
  error: state.user.error,
});

const mapDispatchToProps = (dispatch) => ({
  UserAction: bindActionCreators({ UserAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
