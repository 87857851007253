import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Typography } from "@mui/material";
import { Close } from "@material-ui/icons";
import logo from "../../assets/img/login/hami2.png";
import Edit from "../../assets/img/icons/Edit.svg";
import timerIcon from "../../assets/img/icons/icon3.svg";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
  otpInput: {
    width: "40px !important",
    height: "56px !important",
    borderRadius: "8px",
    fontSize: "30px",
    margin: "0 8px",
    border: "1px solid var(--black)",
    "&:focus": {
      border: "1px solid var(--mbOrange)",
      outlined: "none",
    },
  },
});

function MobileOtpInput(props) {
  const [otp, setOtp] = useState("");
  const classes = useStyles();
  return (
    <Box
      display={"flex"}
      padding={"16px"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      flexDirection={"column"}
      backgroundColor={"var(--white)"}
      borderRadius={"16px"}
      gap={"24px"}
    >
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
        <Box
          padding={"8px"}
          borderRadius={"8px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ backgroundColor: "var(--mbLightGray)" }}
        >
          <Close style={{ width: "16px", height: "16px" }} />
        </Box>
        <Typography fontSize={"16px"}>ورود</Typography>
      </Box>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} padding={"32px 0"}>
        <img src={logo} width={"100px"} />
      </Box>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={"8px"} flexDirection={"column"}>
        <Typography fontSize={"16px"} fontWeight={500}>
          کد ارسالی را وارد کنید
        </Typography>
        <Typography fontSize={"14px"} fontWeight={400}>
          کد ۴ رقمی به شماره شما پیامک شد
        </Typography>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"8px"}
          sx={{ backgroundColor: "var(--mbLightOrange)", borderRadius: "16px", padding: "8px" }}
        >
          <img src={Edit} width={"16px"} height={"16px"} />
          <Typography>09136091344</Typography>
        </Box>
      </Box>
      <OtpInput
        inputStyle={classes.otpInput}
        onChange={setOtp}
        value={otp}
        renderInput={(props) => <input {...props} />}
      />
      <Box display={"flex"} gap={"8px"}>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <img src={timerIcon} width={"16px"} height={"16px"} />
          <Typography fontSize={"10px"} color={"var(--mbPink)"}>
            00:30
          </Typography>
        </Box>
        <Typography fontWeight={300} fontSize={"14px"} color={"var(--mbMono)"}>
          ارسال مجدد پیامک‌
        </Typography>
        <Typography fontWeight={400} fontSize={"14px"}>
          پیامکی دریافت نکردید؟
        </Typography>
      </Box>
    </Box>
  );
}

export default MobileOtpInput;
