import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import axios from "axios";
import Detail from "components/Detail/Detail";
import { Btn } from "components/share/Btn/Btn";
import { LinkMessage, Message, MessageList, Socketid } from "layouts/Recoil";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useForm } from "react-hook-form";
import { connect } from "react-redux/";
import StarRatingComponent from "react-star-rating-component";
import { useRecoilState } from "recoil";
import ErrorCard from "shared/ErrorCard/ErrorCard";
import io from "socket.io-client";
import notification from "../../assets/sounds/notification.mp3";
// import $ from "jquery"
import { ApiUrl, sendRequest, Url } from "../../config";
import logo from "./files/logo.jpeg";

import { Button } from "@mui/material";
import { EditMessage } from "layouts/Recoil";
import useWindowSize from "../../Sizes/UseWindowSize";
import MobileChatWaiting from "../../components/MobileChatWaiting/MobileChatWaiting";
import MobileChat from "../../components/MobileChat/MobileChat";
import MobileCallWaiting from "../../components/MobileCallWaiting/MobileCallWaiting";
import MobileCall from "../../components/MobileCall/MobileCall";
import {createCollectionIndexed,clearTransaction,createTransaction,deleteIndexedDb,readDataIndexedDb,updateIndexedDb} from 'indexedDB/clientDB'

import { Device } from "mediasoup-client";
import $ from "jquery";

require("./style.css");
const useStyles = makeStyles(styles);

function Index(props) {
  const windowSize = useWindowSize();
  let TimeWaiting;
  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);

  let { nationalCode, mobile, menu, name, idClient } = props.location.state;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    createCollectionIndexed();
    readDataIndexedDb(setMessagList);
  }, []);
  const onSubmittxt = (data) => {
    let file = new FormData();
    file.append("file", data.file[0]);
    setfullname(data.fullname);
    setMessageForm(data.message);
    if (localStorage.getItem("data_nationalcode")) {
      axios({
        url: ApiUrl + "/text-box",
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
        data: {
          fullname,
          message,
          menu,
          nationalCode,
          mobile,
          idClient,
          file,
          date: moment().format("YYYY-MM-DDTHH:mm:ss.sTZD"),
          date_time: moment().format("HH:mm:ss.s"),
        },
      })
        .then(function (response) {
          //
          setAlertSend(true);
          setFlagTextBox(false);
          setOut(false);
          setBox(false);
        })
        .catch(function (error) {
          // setAlertSend("ناموفق")
          //
        })
        .finally(function () {});
    } else {
      window.location.href = "/";
    }
  };
  const [isFree, setIsFree] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [fullname, setfullname] = useState("");
  const [files, setFiles] = useState("");
  // const [message, setMessage] = useState("");
  const [flagSend, setFlagSend] = useState("");
  const [uploadEvent, setUploadEvent] = useState("");
  const [out, setOut] = useState(false);
  const [alertSend, setAlertSend] = useState(null);
  const [flagTextBox, setFlagTextBox] = useState(false);
  const [waitingTime, setWaitingTime] = useState(false);
  const [box, setBox] = useState(false);
  const [times, setTime] = useState({ start: 0, end: 0 });
  const [operator, setOperator] = useState("");
  const [socketIo, setSocket] = useState(null);
  const [userCount, setUserCount] = useState(0);
  const [isSent, setIsSent] = useState(false);
  const [isEnded, setEnded] = useState(false);
  const [connected, isConnected] = useState(false);
  const [star, setStar] = useState(5);
  const [loading, isLoading] = useState(false);
  const [isHold, setIdHold] = useState(false);
  const [message, setMessageForm] = useRecoilState(Message);
  const [messagList, setMessagList] = useRecoilState(MessageList);
  const [socktid, setSocktid] = useRecoilState(Socketid);
  const [linkMessag, setLinkMessag] = useRecoilState(LinkMessage);
  const [video, setVideo] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [otherTyping, setOtherTyping] = useState(false);
  const [qsOp, setQsOpr] = useState([]);
  const [chats, setChats] = useState([]);
  const [roomIdVideo, setRoomIdVideo] = useState("");
  const [chatToVideo, setChatToVideo] = useState(false);
  const [localMedia, setLocalMedia] = useState(null);
  const [tracks, setTracks] = useState(null);
  const [shareScreen, setShareScreen] = useState(null);
  const [editMessage, setEditMessage] = useState("");
  const [chatToVideoData, setChatToVideoData] = useState({
    id: "",
    roomId: "",
    _id: "",
    id_call_history: "",
  });

  //indexedDb
  
  //

  useEffect(() => {
    clearTransaction();
  }, []);
  useEffect(() => {
    socketIo && socketIo.emit("is_typing", isTyping);
  }, [isTyping]);

  useEffect(() => {
    socketIo &&
      socketIo.on("other_typing", (otherTyping) => {
        setOtherTyping(!!otherTyping);
      });
  }, [socketIo]);

  const onTypingMessage = () => {
    //
    if (timeoutId !== null) clearTimeout(timeoutId);
    if (!isTyping) setIsTyping(true);

    const timeout = setTimeout(() => {
      setIsTyping(false);
    }, 2000);

    setTimeoutId(timeout);
  };

  const ICE_SERVERS = [
    {
      urls: "turn:185.60.32.129:3478",
      credential: "HmCht_3432",
      username: "hamichat",
    },
  ];
  let local_media_stream = null;
  let peerConnection = null;
  let sharePeerConnection = null;
  let operator_id = 0;

  useEffect(() => {
    if (!isSent) {
      setIsSent(true);

      connection();
      getAvr();
    }
  }, null);

  const getAvr = () => {
    axios
      .get(ApiUrl + "/get-avr?menu=" + menu, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        isLoading(false);

        setVideo(response.data.data.video);
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };
  const init = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function (stream) {
        local_media_stream = stream;
        setLocalMedia(stream);
      })
      .catch((e) => {
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="سیستم شما می بایست به دوربین مجهز باشد"
            onConfirm={() => confirm()}
            confirmBtnCssClass={classes.button + " " + classes.success}
            confirmBtnText="بازگشت"
          ></SweetAlert>
        );
      });
  };

  const confirm = () => {
    window.location.href = "/";
  };
  const submit = (e) => {
    e.preventDefault();

    var bodyFormData = new FormData(e.target);
    bodyFormData.append("customer", mobile);
    bodyFormData.append("operator", operator);
    bodyFormData.append("user_national_code", nationalCode);
    bodyFormData.append("menu", menu);
    bodyFormData.append("status", "chat");
    bodyFormData.append("date", moment().format("YYYY-MM-DDTHH:mm:ss.sTZD"));
    bodyFormData.append("date_time", moment().format("HH:mm:ss.s"));
    axios({
      url: ApiUrl + "/save-poll",
      method: "post",
      headers: {},
      data: bodyFormData,
    })
      .then(function (response) {})
      .catch(function (error) {})
      .finally(function () {
        window.location.href = "/client/direction";
      });
  };
  //mediasoup

  let device = null;
  let producerTransport = null;
  let videoProducer = null;
  let audioProducer = null;
  let consumerTransport = null;
  let videoConsumers = {};
  let audioConsumers = {};

  async function subscribe(socketIo, course_id) {
    // --- prepare transport ---
    if (!consumerTransport) {
      const params = await sendRequest("createConsumerTransport", {}, socketIo);
      consumerTransport = await device.createRecvTransport(params);

      // --- join & start publish --
      consumerTransport.on("connect", async ({ dtlsParameters }, callback, errback) => {
        sendRequest("connectConsumerTransport", { dtlsParameters: dtlsParameters }, socketIo)
          .then(callback)
          .catch(errback);
      });

      consumerTransport.on("connectionstatechange", (state) => {
        switch (state) {
          case "connecting":
            break;

          case "connected":
            // consumeCurrentProducers(clientId, socketIo);
            break;

          case "failed":
            producerTransport.close();
            break;

          default:
            break;
        }
      });
      // await consumeCurrentProducers(operator_id, socketIo);
    }
  }
  async function consumeCurrentProducers(clientId, socketIo) {
    const remoteInfo = await sendRequest("getCurrentProducers", { localId: socketIo.id }, socketIo).catch((err) => {
      return;
    });

    consumeAll(consumerTransport, remoteInfo.remoteVideoIds, remoteInfo.remoteAudioIds, socketIo);
  }
  function removeConsumer(id, kind) {
    if (kind === "video") {
      delete videoConsumers[id];
      //
    } else if (kind === "audio") {
      delete audioConsumers[id];
      //
    } else {
      // console.warn("UNKNOWN consumer kind=" + kind);
    }
  }
  function consumeAll(transport, remoteVideoIds, remotAudioIds, socket) {
    remoteVideoIds.forEach(async (rId) => {
      await consumeAdd(transport, rId.id, null, "video", socket, rId.name, rId.type);
    });
    remotAudioIds.forEach(async (rId) => {
      await consumeAdd(transport, rId.id, null, "audio", socket, rId.name, rId.type);
    });
  }
  async function consumeAdd(transport, remoteSocketId, prdId, trackKind, socket, name = "", type = "student") {
    //
    let codecOptions = {};
    let consumer = null;
    const { rtpCapabilities } = device;

    const data = await sendRequest(
      "consumeAdd",
      {
        rtpCapabilities: rtpCapabilities,
        remoteId: remoteSocketId,
        kind: trackKind,
      },
      socket
    )
      .then(async (res) => {
        const { producerId, id, kind, rtpParameters } = res;
        if (prdId && prdId !== producerId) {
          // console.warn("producerID NOT MATCH");
        }

        await transport
          .consume({
            id,
            producerId,
            kind,
            rtpParameters,
            codecOptions,
          })
          .then((res) => {
            console.log("tracks", res);
            consumer = res;
            setTracks(res.track);
          })
          .catch((res) => {
            console.log(res);
          });
        //
        // const {track} = consumer
        // setTracks(track)

        // addRemoteTrack(remoteSocketId, consumer.track, name, type, kind);
        addConsumer(remoteSocketId, consumer, kind);
        consumer.remoteId = remoteSocketId;
        consumer.on("transportclose", () => {
          consumer.close();
          removeConsumer(consumer.remoteId);
          //removeRemoteVideo(consumer.remoteId);
        });
        consumer.on("producerclose", () => {
          consumer.close();
          removeConsumer(consumer.remoteId, kind);
          // removeRemoteVideo(consumer.remoteId);
        });
        consumer.on("trackended", () => {});

        //return stream;

        if (kind === "video") {
          await sendRequest("resumeAdd", { remoteId: remoteSocketId, kind: kind }, socket)
            .then(() => {})
            .catch((err) => {});
        }
      })
      .catch((res) => {
        console.log("err");
      });
  }
  function addConsumer(id, consumer, kind) {
    if (kind === "video") {
      videoConsumers[id] = consumer;
      //
    } else if (kind === "audio") {
      audioConsumers[id] = consumer;
      //
    } else {
      // console.warn("UNKNOWN consumer kind=" + kind);
    }
  }
  async function loadDevice(routerRtpCapabilities) {
    try {
      device = new Device();
    } catch (error) {
      if (error.name === "UnsupportedError") {
        // console.error("browser not supported");
      }
    }
    await device.load({ routerRtpCapabilities });
  }
  //end mediasoup

  const connection = () => {
    if (!connected) {
      isConnected(true);
      let socket = io.connect(Url);
      setSocket(socket);
      let date_select_menu = localStorage.getItem("data_timeMenu");
      let time_select_menu = moment(date_select_menu, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss.s");
      let date_login = localStorage.getItem("data_timeLogin");
      let time_login = moment(date_login, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss.s");
      let direct = false;
      if (name == "directCall") {
        direct = true;
      }
      socket.on("connect", function () {
        socket.emit("joinChat", {
          type: "client",
          code: nationalCode,
          menu: menu,
          menu_name: name,
          mobile,
          idClient,
          date_select_menu,
          time_select_menu,
          date_login,
          time_login,
          socket_id: socket.id,
          direct: direct,
        });
      });
      socket.on("out_of_wait", function () {
        localStorage.setItem("name", name);
        localStorage.setItem("idClient", idClient);
        localStorage.setItem("menu", menu);
        window.location.href = "/client/message";
      });
      socket.on("newProducer", async function (message) {
        const remoteId = message.socketId;
        const prdId = message.producerId;
        const kind = message.kind;
        const name = message.name;
        const type = message.type;
        const size = message.size;

        if (kind === "video") {
          await consumeAdd(consumerTransport, remoteId, prdId, kind, socket, name, type);
        } else if (kind === "audio") {
          // console.warn('-- audio NOT SUPPORTED YET. skip remoteId=' + remoteId + ', prdId=' + prdId + ', kind=' + kind);

          await consumeAdd(consumerTransport, remoteId, prdId, kind, socket, name, type);
        }
      });
      socket.on("producerClosed", function (message) {
        const localId = message.localId;
        const remoteId = message.remoteId;
        const kind = message.kind;
        const size = message.size;
        const type = message.type;

        removeConsumer(remoteId, kind);
        // removeRemoteVideo(remoteId);
      });
      socket.on("online_users_queue", function (users, option) {
        if (option) {
          setWaitingTime(option.waiting);
          let waitingMili = moment(option.waiting, "HH:mm:ss").diff(moment().startOf("day"), "seconds") * 1000;
          TimeWaiting = setTimeout(() => {
            setBox(true);
            socket.disconnect();
          }, waitingMili);
        }
        //

        let userIndex = 0;
        console.log(users);
        if (users) {
          for (var i = 0; i < users.length; i++) {
            if (users[i].socket == socket.id) {
              userIndex = i;
            }
          }
          setUserCount(userIndex);
        }
      });
      socket.on("free", async function (id, _id, roomId, id_call_history) {
        console.log("free");
        operator_id = id;
        setIsFree(true);
        setOperator(_id);
        setRoomIdVideo(roomId);
        clearTimeout(TimeWaiting);
        //
        let temp = [];

        if (localStorage.getItem("data_idCall") !== null) {
          //
          let local = JSON.parse(localStorage.getItem("data_idCall"));
          //
          //
          temp = local;
        }
        //
        temp.push(id_call_history);
        localStorage.setItem("data_idCall", JSON.stringify(temp));

        //mediasoup start

        //mediasoup end
      });
      socket.on("hold_call", function (status) {
        setIdHold(status);

        let video = document.getElementById("client");
        if (status) {
          video.setAttribute("style", "display:none");
        } else {
          video.setAttribute("style", "display:unset");
        }
      });
      socket.on("free_share", function (id) {
        socket.emit("watcher_screen", id);
      });
      socket.on("cancel_display", function () {
        sharePeerConnection && sharePeerConnection.close();
        sharePeerConnection = null;
        setIsShare(false);
      });
      socket.on("online_operators", function (users) {
        //
        let items = [];
        console.log(users);
        //
        if (users) {
          let users_items = users.filter((e) => {
            if (e) {
              e.menu.map((i) => {
                if (!items[i]) items[i] = [];
                items[i].push(e);
              });
            }
          });

          setQsOpr(users);
        }
      });
      socket.on("offer", function (id, description, _id) {
        setOperator(_id);

        peerConnection = new RTCPeerConnection({ iceServers: ICE_SERVERS });

        let stream = local_media_stream;
        if (stream) stream.getTracks().forEach((track) => peerConnection.addTrack(track, stream));
        peerConnection
          .setRemoteDescription(description)
          .then(() => peerConnection.createAnswer())
          .then((sdp) => peerConnection.setLocalDescription(sdp))
          .then(function () {
            socket.emit("answer", id, peerConnection.localDescription);
          });
        peerConnection.ontrack = async function (event) {
          // let client = await document.getElementById("client");
          // client.setAttribute("autoplay", "autoplay");
          // client.setAttribute("playsinline", "playsinline");
          // client.srcObject = await event.streams[0];
        };
        peerConnection.onicecandidate = function (event) {
          if (event.candidate) {
            socket.emit("candidate", id, event.candidate);
          }
        };
      });
      socket.on("offer_screen", function (id, description) {
        setIsShare(true);

        sharePeerConnection = new RTCPeerConnection({
          iceServers: ICE_SERVERS,
        });

        sharePeerConnection
          .setRemoteDescription(description)
          .then(() => sharePeerConnection.createAnswer())
          .then((sdp) => sharePeerConnection.setLocalDescription(sdp))
          .then(function () {
            socket.emit("answer_screen", id, sharePeerConnection.localDescription);
          });
        sharePeerConnection.ontrack = async function (event) {
          // let client = await document.getElementById("client_screen");
          // client.setAttribute("autoplay", "autoplay");
          // client.setAttribute("playsinline", "playsinline");
          // client.srcObject = await event.streams[0];
        };
        sharePeerConnection.onicecandidate = function (event) {
          if (event.candidate) {
            socket.emit("candidate_screen", id, event.candidate);
          }
        };
      });
      socket.on("candidate", function (id, candidate) {
        peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      });
      socket.on("candidate_screen", function (id, candidate) {
        sharePeerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      });
      socket.on("out_of_time", function (start, end, waiting) {
        setOut(true);
        setBox(true);
        setTime({
          start,
          end,
        });
      });
      socket.on("chat_message", function (msg) {
        //
        {
          msg.id === socket.id ? setSocktid((prev) => [...prev, true]) : setSocktid((prev) => [...prev, false]);
        }
        let msg_notification = new Audio(notification);
        msg_notification.loop = false;
        if (msg.operator_id != 0) {
          msg_notification.play();
        }
        createTransaction(msg);
        readDataIndexedDb(setMessagList);
        // setMessagList((prev) => [...prev, msg]);
        setLinkMessag((prev) => [...prev, false]);
      });
      socket.on("chat_message_file", function (msg) {
        {
          msg.id === socket.id ? setSocktid((prev) => [...prev, true]) : setSocktid((prev) => [...prev, false]);
        }
        const obj = {
          ...msg,
          file: true,
        };

        createTransaction(obj);
        readDataIndexedDb(setMessagList);
        // setMessagList((prev) => [...prev, msg]);
        setLinkMessag((prev) => [...prev, true]);
      });
      socket.on("disconnect", function () {
        setEnded(true);
        clearTransaction();
      });
      socket.on("end_call", function () {
        setEnded(true);
        clearTransaction();
      });
      socket.on("message_edit", (msg) => {
        if (msg.is_delete === true) {
          deleteIndexedDb(msg._id, setMessagList);
        }
        updateIndexedDb(msg, setMessagList);
      });
      socket.on("active_chats", (msg) => {
        const nationalCode = localStorage.getItem("data_nationalcode");
        const index = msg.findIndex((x) => x.user.code === nationalCode);
        setChats(msg[index]);
      });
    }
  };
  // const freeCallVideo = async (id, roomId, _id, id_call_history)=>{
  //   // await sendRequest("prepare_room", { roomId }, socketIo);

  //   if (!device){
  //     const data = await sendRequest("getRouterRtpCapabilities", { room_id: roomId }, socketIo);

  //     await loadDevice(data);
  //   }

  //   if (!producerTransport) {
  //     const params = await sendRequest("createProducerTransport", {}, socketIo);

  //     producerTransport = device.createSendTransport(params);
  //   }

  //   //

  //   producerTransport.on("connect", async ({ dtlsParameters }, callback, errback) => {
  //     //

  //     sendRequest("connectProducerTransport", { dtlsParameters: dtlsParameters }, socketIo)
  //         .then(callback)
  //         .catch(errback);
  //   });
  //   producerTransport.on("produce", async ({ kind, rtpParameters }, callback, errback) => {
  //     try {
  //       const { id } = await sendRequest(
  //           "produce",
  //           {
  //             transportId: producerTransport.id,
  //             kind,
  //             rtpParameters,
  //             type: "student",
  //           },
  //           socketIo
  //       );

  //       callback({ id });
  //       //
  //     } catch (err) {
  //       //
  //       errback(err);
  //     }
  //   });

  //   producerTransport.on("connectionstatechange", (state) => {
  //     switch (state) {
  //       case "connecting":
  //         //

  //         break;

  //       case "connected":
  //         //

  //         break;

  //       case "failed":
  //         //

  //         producerTransport.close();
  //         break;

  //       default:
  //         break;
  //     }
  //   });

  //   const videoTrack = localMedia.getVideoTracks()[0];

  //   if (videoTrack) {
  //     const trackParams = {
  //       track: videoTrack,
  //       encodings: [{ maxBitrate: 50000 }],
  //     };
  //     videoProducer = await producerTransport.produce(trackParams);
  //   }
  //   const audioTrack = localMedia.getAudioTracks()[0];
  //   if (audioTrack) {
  //     const trackParams = {
  //       track: audioTrack,
  //       encodings: [{ maxBitrate: 100000 }],
  //     };
  //     audioProducer = await producerTransport.produce(trackParams);
  //   }

  //  await subscribe(socketIo, roomId);
  // }
  // useEffect(()=>{
  //   if (chatToVideo){
  //     init()
  //   }
  // },[chatToVideo])
  // useEffect(()=>{
  //   if (localMedia && chatToVideo ){
  //     freeCallVideo(chatToVideoData.id,chatToVideoData.roomId,chatToVideoData._id,chatToVideoData.id_call_history)
  //   }
  // },[localMedia])

  const cancel = () => {
    endCall();
    window.location.href = "/client/direction";
  };

  const endCall = () => {
    socketIo.emit("end_call");
    socketIo.disconnect();
    setMessagList([]);
    setSocktid([]);
  };

  const sendMessage = function () {
    if (flagSend === false) {
      setFlagSend(true);
      uploadFile();
    }

    // let message = document.getElementById("message");
    if (!message == "") {
      //
      let items = {
        message: message,
        id: socketIo.id,
        national_code: nationalCode,
        mobile: mobile,
        operator_id: operator_id,
      };
      socketIo.emit("chat_message", items);
      // message = "";
      setMessageForm("");
    }
  };
  const onKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    } else if (event.key === "Enter" && event.shiftKey) {
    }
  };
  const mailBox = () => {
    props.history.push({
      pathname: "/client/box",
      state: {
        nationalCode,
        menu,
        name,
        mobile,
        idClient,
      },
    });
  };

  const endCallChat = () => {
    socketIo.emit("disconnect");
    socketIo.disconnect();
  };

  const uploadFile = function (e) {
    let event = uploadEvent;

    let items = {
      message: " ... در حال ارسال فایل",
      id: socketIo.id,
    };

    //

    socketIo.emit("chat_message", items);
    var fd = new FormData();
    fd.append("file", event);

    axios({
      url: ApiUrl + "/upload-chat-file",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: fd,
    })
      .then(function (response) {
        let message = document.getElementById("selectFile");

        let items = {
          message: Url + response.data.data.path,
          id: socketIo.id,
        };

        socketIo.emit("chat_message_file", items);
        message.value = "";
        setUploadEvent("");
      })
      .catch(function (error) {})
      .finally(function () {});
  };

  const sendToTextBox = function (e) {
    e.preventDefault();
    // let file = new FormData();
    // file.append("file", files.files[0]);
    if (localStorage.getItem("data_nationalcode")) {
      axios({
        url: ApiUrl + "/text-box",
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
        data: {
          fullname,
          message,
          menu,
          nationalCode,
          mobile,
          idClient,
          // file,
          date: moment().format("YYYY-MM-DDTHH:mm:ss.sTZD"),
          date_time: moment().format("HH:mm:ss.s"),
        },
      })
        .then(function (response) {
          //

          setAlertSend(true);
          setFlagTextBox(false);
          setOut(false);
          setBox(false);
        })
        .catch(function (error) {
          // setAlertSend("ناموفق")
          //
        })
        .finally(function () {});
    } else {
      window.location.href = "/";
    }
  };

  // const joinToCall = ()=>{
  //   setIsFree(false)
  //   setChatToVideo(true)
  //   let date_select_menu = localStorage.getItem("data_timeMenu");
  //   let time_select_menu = moment(date_select_menu, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss.s");
  //   let date_login = localStorage.getItem("data_timeLogin");
  //   let time_login = moment(date_login, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss.s");
  //   let direct = false;
  //   console.log("idClient",idClient)
  //   socketIo && socketIo.emit("joinVideoCall",{
  //     type: "client",
  //     code: nationalCode,
  //     menu: menu,
  //     menu_name: name,
  //     mobile,
  //     idClient,
  //     date_select_menu,
  //     time_select_menu,
  //     date_login,
  //     time_login,
  //     socket_id: socketIo.id,
  //     direct: direct,
  //     operator:chats && chats[0].operator
  //   })
  // }

  const renderCalling = () => {
    try {
      return (
        <>
          {windowSize === "lg" || windowSize === "big" || windowSize === "xlg" ? (
            <div
              className={"calling"}
              style={{ position: "absolute", width: "100%", height: "100%", top: "20px", right: "0" }}
            >
              <div className="header">
                <div className="logo"></div>
                <h3> {name === "کیپاد" ? "پی پاد" : name}</h3>
              </div>

              <Detail
                sendMessage={sendMessage}
                customer={false}
                confirmVip={false}
                userNationalCode={false}
                userMobile={false}
                loading={loading}
                sbmitCustomerProblem={false}
                setFlagSend={setFlagSend}
                setUploadEvent={setUploadEvent}
                uploadEvent={uploadEvent}
                user={true}
                otherTyping={otherTyping}
                onTypingMessage={onTypingMessage}
              />
              <div>
                <Btn className="OperatorHeader__endCall OperatorHeader__ChatEndCall" onClick={() => endCall()}>
                  پایان تماس
                </Btn>
              </div>
            </div>
          ) : chatToVideo ? (
            <MobileCall
              chats={chats}
              track={tracks}
              localMedia={localMedia}
              endCall={endCall}
              isShare={isShare}
              sendMessage={sendMessage}
              setFlagSend={setFlagSend}
              setUploadEvent={setUploadEvent}
              uploadEvent={uploadEvent}
              isTyping={isTyping}
              setIsTyping={setIsTyping}
              otherTyping={otherTyping}
              onTypingMessage={onTypingMessage}
              shareScreen={shareScreen}
              editMessage={editMessage}
              setEditMessage={setEditMessage}
              onKeyDown={onKeyDown}
            />
          ) : (
            <MobileChat
              roomId={roomIdVideo}
              chats={chats}
              cancel={endCallChat}
              endCall={endCall}
              sendMessage={sendMessage}
              loading={loading}
              setFlagSend={setFlagSend}
              setUploadEvent={setUploadEvent}
              uploadEvent={uploadEvent}
              otherTyping={otherTyping}
              onTypingMessage={onTypingMessage}
              setEnded={setEnded}
            />
          )}
        </>
      );
    } catch (error) {
      return (
        // <div
        //   className={"calling"}
        //   style={{ position: "absolute", width: "100%", height: "100%", top: "20px", right: "0", display: 'flex',flexDirection : 'column', justifyContent : 'center', alignItems : 'center' }}
        // >
        //   <div style={{height : '80%', width : '100%', textAlign: 'center' }}>با عرض پوزش، خطایی در سرور رخ داده است.</div>
        //   <Btn className="OperatorHeader__endCall OperatorHeader__ChatEndCall" onClick={() => endCall()}>
        //         پایان تماس
        //   </Btn>
        // </div>
        <div
          className={"calling"}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "20px",
            right: "0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ErrorCard>
            <div style={{ height: "80%", width: "100%", textAlign: "center" }}>
              با عرض پوزش، خطایی در سرور رخ داده است.
            </div>
            <Btn className="OperatorHeader__endCall OperatorHeader__ChatEndCall" onClick={() => endCall()}>
              پایان تماس
            </Btn>
          </ErrorCard>
        </div>
      );
    }
  };

  const renderWaiting = () => {
    return (
      <>
        {windowSize === "lg" || windowSize === "big" || windowSize === "xlg" ? (
          <div className={"waiting"}>
            <div className="header">
              <div className="logo"></div>
              <h3> {name === "کیپاد" ? "پی پاد" : name}</h3>
            </div>
            <div className="waiters">
              <b> {userCount} نفر در صف انتظار</b>
              <br />
              <span> زمان تقریبی انتظار {userCount * 2} دقیقه</span>
            </div>
            {/* {video.waiting != "" && ( */}
            <div className="video-waitning">
              {/* <video src={Url + video.waiting} loop controls autoPlay playsInline /> */}
              <video
                src={
                  video.waiting
                    ? video.waiting
                    : "https://pep.arvanvod.ir/axXzYxdY2p/e70LK7LROr/origin_PgM2qetvmttUJyWeDeeG5pyw7rwC6f4XN4sSKUNG.mp4"
                }
                loop
                controls
                autoPlay
                playsInline
              />
            </div>
            {/* )} */}
            <h4 style={{ margin: "10px" }}>در حال برقراری ارتباط با اولین اپراتور...</h4>

            <div className="cancel-footer-bottem">
              <Button
                sx={{
                  color: "var(--black)",
                  backgroundColor: "var(--chiliRed)",
                  fontSize: "15px",
                  borderRadius: "10px",
                }}
                size={"large"}
                onClick={(e) => cancel()}
              >
                پایان تماس
              </Button>
            </div>
          </div>
        ) : chatToVideo ? (
          <MobileCallWaiting endCall={endCall} cancel={cancel} chats={chats} />
        ) : (
          <MobileChatWaiting cancel={cancel} userCount={userCount} onlineOperators={qsOp} />
        )}
      </>
    );
  };

  const renderEnd = () => {
    return (
      <div className={"ended"}>
        <div className={"welcome-input"}>
          <div className="header">
            <div className="logo"></div>
          </div>

          <h2>از تماس شما متشکریم</h2>
          <form onSubmit={(e) => submit(e)}>
            <h4 className="infos">لطفا با تکمیل نظر سنجی ما را در بهبود خدمات یاری کنید</h4>
            <h6>میزان رضایت شما از ارتباط</h6>

            <StarRatingComponent name="rate" value={star} onStarClick={(e) => setStar(e)} starCount={5} />
            <textarea placeholder="لطفا نظرات خود را با ما در میان بگذارید" className={"message_area"} name="message" />
            <br />
            <input className={"cancel"} value={"ثبت نظر و بازگشت"} type={"submit"} />
          </form>
        </div>
      </div>
    );
  };
  const renderOutOfTime = () => {
    return (
      <div className={"ended"}>
        <div className={"welcome-input"}>
          <img src={logo} />
          <h2>با تشکر از تماس شما</h2>
          <hr />
          <form onSubmit={(e) => submit(e)}>
            <h4>لطفا به این گفت و گو امتیاز دهید</h4>
            <StarRatingComponent name="rate" value={star} onStarClick={(e) => setStar(e)} starCount={5} />
            <h4>اگر نظری در مورد این گفتگو دارید بیان کنید.</h4>
            <textarea className={"message_area"} name="message" />
            <br />
            <input className={"cancel"} value={"ثبت نظر و بازگشت"} type={"submit"} />
          </form>
        </div>
      </div>
    );
  };

  //flagTextBox is for sandogh_matni
  if (box === true && flagTextBox === false) {
    return (
      <div dir="rtl">
        <div style={{ marginBottom: 50, height: "100%" }} className={"video-container"}>
          <div className={"welcome-input"}>
            <div className="header">
              <div className="logo"></div>
            </div>
            {video.outtime != "" && (
              <div className="video-waitning">
                <video src={Url + video.outtime} playsInline controls loop autoPlay />
              </div>
            )}
            <h2>با تشکر از تماس شما</h2>
            <hr />
            {out ? (
              <h4>
                ساعات کاری از {times.start} تا {times.end} می باشد.
              </h4>
            ) : (
              <h4> همکاران ما مشغول هستند لطفا پیام خود را بگذارید تا در اسرع وقت با شما تماس بگیریم</h4>
            )}
            <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
            <input
              onClick={(e) => {
                setFlagTextBox(true);
              }}
              className={"cancel"}
              style={{ width: "200px" }}
              value={"ایجاد پیام در صندوق متنی"}
              type={"button"}
            />
            <br />{" "}
            <input
              onClick={(e) => {
                mailBox();
              }}
              className={"cancel"}
              style={{ width: "200px" }}
              value={"ایجاد پیام در صندوق تصویری"}
              type={"button"}
            />
            <br />
          </div>
        </div>
      </div>
    );
  }

  if (flagTextBox === true) {
    return (
      // <div dir="rtl">
      //   <form
      //     onSubmit={handleSubmit(onSubmittxt)}
      //     style={{ marginBottom: 50 }}
      //     className={"video-container"}
      //   >
      //     <div className={"welcome-input"}>
      //       <div className="header">
      //         <div className="logo"></div>
      //       </div>
      //       <form className={classes.root} noValidate autoComplete="off">
      //         <label className="label">نام و نام خانوادگی</label>
      //         <input
      //           {...register("fullname")}
      //           className={"national-code-input"}
      //           placeholder={" نام خود را وارد کنید"}
      //           onFocus={(e) => (e.target.placeholder = "")}
      //           onBlur={(e) => (e.target.placeholder = "نام خود را وارد کنید")}
      //         />
      //         {/* <input
      //           value={fullname}
      //           onChange={(e) => setfullname(e.target.value)}
      //           placeholder={" نام خود را وارد کنید"}
      //           className={"national-code-input"}
      //         /> */}
      //         <label className="label">متن پیام</label>
      //         <input
      //           {...register("message")}
      //           className={"national-code-input"}
      //           placeholder={" پیام خود را وارد کنید"}
      //           onFocus={(e) => (e.target.placeholder = "")}
      //           onBlur={(e) => (e.target.placeholder = "پیام خود را وارد کنید")}
      //         />
      //         {/* <input
      //           value={message}
      //           onChange={(e) => setMessage(e.target.value)}
      //           placeholder={"پیام خود را وارد کنید"}
      //           className={"national-code-input"}
      //         /> */}
      //         <input {...register("file", { required: false })} />

      //         {/* <input
      //           type="file"
      //           // value={files}
      //           onChange={(e) => setFiles(e.target.files)}
      //         /> */}
      //         <br />
      //         <hr />

      //         <input
      //           // onClick={(e) => sendToTextBox(e)}
      //           className={"cancel"}
      //           value={"ارسال"}
      //           type={"submit"}
      //         />
      //         <input
      //           onClick={(e) => cancel()}
      //           className={"cancel"}
      //           value={"بازگشت"}
      //           type={"button"}
      //         />
      //       </form>
      //     </div>
      //   </form>
      // </div>
      <div dir="rtl">
        <div style={{ marginBottom: 50, height: "100%" }} className={"video-container"}>
          <div className={"welcome-input"}>
            <div className="header">
              <div className="logo"></div>
            </div>
            <form className={classes.root} noValidate autoComplete="off">
              <label className="label">نام و نام خانوادگی</label>
              <input
                value={fullname}
                onChange={(e) => setfullname(e.target.value)}
                placeholder={" نام خود را وارد کنید"}
                className={"national-code-input"}
              />
              <label className="label">متن پیام</label>
              <input
                value={message}
                onChange={(e) => setMessageForm(e.target.value)}
                placeholder={"پیام خود را وارد کنید"}
                className={"national-code-input"}
              />
              <br />
              <hr />

              <input onClick={(e) => sendToTextBox(e)} className={"cancel"} value={"ارسال"} type={"submit"} />
              <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
            </form>
          </div>
        </div>
      </div>
    );
  }

  if (alertSend === true) {
    return (
      <div dir="rtl">
        <div style={{ marginBottom: 50, height: "100%" }} className={"video-container"}>
          <div className={"welcome-input"}>
            <div className="header">
              <div className="logo"></div>
            </div>
            <h2>با تشکر از تماس شما</h2>
            <h2>تماس شما در صندوق متنی ذخیره شده به زودی با شما تماس خواهیم گرفت</h2>
            <input onClick={(e) => cancel()} className={"cancel"} value={"بازگشت"} type={"button"} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      dir="rtl"
      className="directionpage"
      style={{ overflowY: windowSize === "lg" || windowSize === "big" || windowSize === "xlg" ? "auto" : "hidden" }}
    >
      {alert}

      <div style={{ marginBottom: 50, height: "93%" }} className={"video-container"}>
        {isEnded ? renderEnd() : isFree ? renderCalling() : renderWaiting()}

        {windowSize === "lg" ||
          windowSize === "big" ||
          (windowSize === "xlg" && (
            <div className="slidingtrms">
              <ul>
                <li>
                  به جهت افزایش رضایت و پاسخگویی بهتر به شما عزیزان کلیه تصاویر، هنگام ارتباط با کارشناسان ما ضبط می
                  گردد.
                </li>
                <li>رعایت کلیه شئونات اسلامی در هنگام برقراری ارتباط با کارشناسان ما الزامی می باشد.</li>
                <li>جهت استفاده بهتر از این سرویس می بایست از یک بستر اینترنت پایدار و پرسرعت استفاده نمائید.</li>
              </ul>
            </div>
          ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  // UserAction: bindActionCreators({UserAction}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
